<template>
  <div class="selected-filters">
    <ul class="">
      <span v-if="noLabel" v-for="(item, index) in items" class="single-filter p-1 mr-2 mb-1 px-3">
          {{ item.name }}
          <i class="ml-2 icon-close-no-background" @click="removeFilter(i)"></i>
      </span>

      <span v-for="(filters, index) in items" class="">
        <span v-for="(filter, i) in filters" class="single-filter py-1 px-3 mr-1 mb-2" v-if=" index != 'priceMin' && index != 'priceMax' && !isRoutine  ">
          <span v-if="showLabel" class="filter-name">{{ humanize(index) }}:</span> {{ filter.name }}
          <i class="ml-2 icon-close-no-background" @click="removeFilter(index, i)"></i>
        </span>
        <span v-for="(item, i) in parentFilters" class="single-filter p-1 mr-2 mb-1 px-3" v-if="isRoutine">
          <span class="filter-name">type: </span> {{ item.name }}
          <i class="ml-2 icon-close-no-background" @click="removeFilter(i)"></i>
        </span>
      </span>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SelectedFilters',
  components: {},
  props: {
    items: {
      type: Array,
      default: []
    },
    isRoutine: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: []
    },
    showLabel:{
      type: Boolean,
      default: false
    },
    noLabel: {
      type:Boolean,
      default: false
    }
  },
  data () {
    return {
      items: []
    }
  },
  mounted() {
  },
  methods: {
    removeFilter: function(index, i = null) {
      if(this.isRoutine){
        this.items.splice(index, 1)
        this.items = null
      }else{
        this.items[index].splice(i, 1)  
      }
      this.$emit('input', this.items)
      
    },
    humanize: function(str) {
      return str.match(/^[a-z]+|[A-Z][a-z]*/g).map(function(x){
        return x[0].toUpperCase() + x.substr(1).toLowerCase();
      }).join(' ');
    }
  },
}
</script>
