<template>
	<div class="search-no-results-product">
		<div class="search-no-results-product__heading">
			Fill the form below to request a product. 
			include the brand and product name
		</div>

		<form 
			class="search-no-results-product__form" 
			@submit.prevent="submit" 
			>
			<b-row 
				class="mb-3" 
				align-v="center"
				>
				<b-col 
					cols="12"
					>
					<label>
						SUBJECT: Product request
					</label>
				</b-col>
			</b-row>

			<b-row>
				<b-col md="12">
					<textarea 
						v-model="content" 
						rows="10"
						placeholder="PRODUCT(S)"
						/>
					</b-col>
				</b-row>
			<b-row>
				<b-col md="12">
					<button 
						class="btn search-no-results-product__button" 
						:class="{'disabled': isLoading}" 
						type="submit"
						>
						<span v-if="!isLoading">
							SEND
						</span>
						<span v-else>
							SENDING...
						</span>
				</button>
				</b-col>
			</b-row>
		</form>
	</div>
</template>

<script>
	import Spinner from '@/ui/Spinner.vue';
	import APIClient from '@/api/requests'

	export default {
		name: 'SearchNoResultsProduct',
		components: {

		},
		props: {
			product: null
		},
		mounted() {

		},
		data() {
            return {
                isLoading: false,
                content: '',
                message: ''
            }
        },
		methods: {
			submit () {
		      if(this.isLoading) return;

		      if(!this.content.length) {
		      	this.$bvToast.toast(`Please add a message`, {
		            title: 'Error',
		            autoHideDelay: 5000,
		            toastClass: 'error',
		            appendToast: true,
		        })
		      	return;
		      }

		      this.isLoading = true
		      let data = {
		        user_id: this.user.id,
		        type: 'Product request',
		        subject: 'Product request',
		        content: this.content
		      }
		      
		      APIClient.submitContactForm(data)
		        .then( res => {
		          this.content = ''
		          this.$bvToast.toast(res.data, {
		            title: 'Thank you. Your message has been sent!',
		            autoHideDelay: 2000,
		            toastClass: 'success',
		            appendToast: true,
		          })
		        })
		        .catch( err => {
		          console.error(err)
		          this.message = err.data
		          this.$bvToast.toast(`Sorry, something went wrong`, {
		            title: 'Error',
		            autoHideDelay: 5000,
		            toastClass: 'error',
		            appendToast: true,
		          })
		        })
		        .finally( () => {
		          this.isLoading = false
		        })
		    },
		},
		computed: {
		    user: function () {
		      return this.$store.getters.profile
		    }
		}
	}
</script>
