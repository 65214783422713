import axios from 'axios'

const baseURL = process.env.VUE_OPEN_WEATHER_URL
const owInstance = axios.create({
  baseURL,
  timeout: 20000,
  transformRequest: [function (data, headers) {
    return JSON.stringify(data)
  }],
})

export default owInstance
