<template>
	<div class="page-moodboard">

    <!-- <moodboard-menu>
    </moodboard-menu> -->

    <moodboard-banner
      :main-image="bannerBackground"
      :secondary-image="secondaryImage"
      :title="'MOOD<br>BOARD'"
      :subtitle="subtitle"
      :text="topCopy"
      :signature="topSignature"
      >
    </moodboard-banner>

    <div class="page-moodboard__main-content">

      <moodboard-columns
        :title="secondTitle"
        :columnOne="secondCopy"
        >
      </moodboard-columns>

      <moodboard-image
        :image="contentImage1"
        >
      </moodboard-image>

      <moodboard-columns
        :title="thirdTitle"
        :columnOne="thirdCopyColumnOne"
        :columnTwo="thirdCopyColumnTwo"
        >
      </moodboard-columns>

      <moodboard-quote
        :text="thirdQuote"
        >
      </moodboard-quote>

      <moodboard-columns
        :title="fourthTitle"
        :columnOne="fourthCopyColumnOne"
        :columnTwo="fourthCopyColumnTwo"
        >
      </moodboard-columns>
    </div>


    <simple-routine-slider 
      :is-shelf="false"
      :routine="routineClipped"
      ref="routineSlider"
      :isLoading="false"
      :items="products"
      :title="productsTitle"
      class="page-moodboard__routines"
      />

    <div class="page-moodboard__main-content">
      <moodboard-block
        :image="blockImage"
        :title="fifthTitle"
        :tagline="'beautéboard'"
        :link="{ url: 'https://beauteboard.com', title: 'beauteboard.com' }"
        :cta="{ url: fifthLinkUrl, title: fifthLinkText }"
        >
      </moodboard-block>
    </div>

    <moodboard-footer class="" :title="footerTitle" :data="footer_slides" />

	</div>
</template>

<script>

import APIClient from '@/api/requests';

import Swiper from '@/assets/scripts/vendor/swiper.esm.bundle'
import MoodboardFooter from '@/components/MoodboardFooter.vue'
import SimpleRoutineSlider from '@/components/simple/moodboard/SimpleRoutineSlider.vue'
import MoodboardBlock from '@/components/simple/moodboard/MoodboardBlock.vue';
import MoodboardCollage from '@/components/simple/moodboard/MoodboardCollage.vue';
import MoodboardBanner from '@/components/simple/moodboard/MoodboardBanner.vue';
import MoodboardColumns from '@/components/simple/moodboard/MoodboardColumns.vue';
import MoodboardImage from '@/components/simple/moodboard/MoodboardImage.vue';
import MoodboardQuote from '@/components/simple/moodboard/MoodboardQuote.vue';
import MoodboardMenu from '@/components/simple/moodboard/MoodboardMenu.vue';

export default {
  name: 'Moodboard',
  data() {
  	return {
  		slider: null
  	}
  },
  data() {
    return {
      bannerBackground: '',
      secondaryImage: '',
      contentImage1: '',
      blockImage: '',
      products: [],
      productsTitle: null,
      footer_slides: [],
      imagesCollage: [],
      topCopy: null,
      secondTitle: null,
      secondCopy: null,
      thirdQuote: null,
      thirdTitle: null,
      thirdCopyColumnOne: null,
      thirdCopyColumnTwo: null,
      fifthTitle: null,
      fifthLinkText: null,
      fifthLinkUrl: null,
      subtitle: null,
      // fifthImage: null,
      topSignature: null,
      fourthCopyColumnOne: null,
      fourthCopyColumnTwo: null,
      footerTitle: null
    }
  },
  components: {
  	Swiper,
    MoodboardFooter,
    SimpleRoutineSlider,
    MoodboardBlock,
    MoodboardCollage,
    MoodboardBanner,
    MoodboardColumns,
    MoodboardImage,
    MoodboardQuote,
    MoodboardMenu,
  },
  mounted () {

    APIClient.moodboardPage(this.$route.params.slug)
      .then( res => {
        console.log(res) 

        // Setup banner
        this.bannerBackground = res.data.top_main_image
        this.secondaryImage = res.data.top_secondary_image
        this.subtitle = res.data.top_title
        this.topCopy = res.data.top_copy
        this.topSignature = res.data.top_signature

        // First paragraph
        

        // Collage
        this.contentImage1 = res.data.collage_image

        // Second paragraph
        this.secondTitle = res.data.second_title
        this.secondCopy = res.data.second_copy

        // Quote
        this.thirdQuote = res.data.third_quote

        // Third paragraph
        this.thirdTitle = res.data.third_title
        this.thirdCopyColumnOne = res.data.third_copy_first_column
        this.thirdCopyColumnTwo = res.data.third_copy_second_column

        // Products
        this.productsTitle = res.data.products_title
        this.products = res.data.products

        // Fourth
        this.fourthTitle = res.data.fourth_title
        this.fourthCopyColumnOne = res.data.fourth_copy_first_column
        this.fourthCopyColumnTwo = res.data.fourth_copy_second_column

        // Fifth Contentthird_copy_second_column
        this.fifthTitle = res.data.fifth_title
        this.fifthLinkText = res.data.fifth_link_text
        this.fifthLinkUrl = res.data.fifth_link_url
        this.blockImage = res.data.fifth_image

        // Footer

        this.footerTitle = res.data.footer_title

        this.footer_slides = [
        {
          "link": res.data.footer_one_link,
          "image": res.data.footer_one_image,
          "title": res.data.footer_one_text,
        },
        {
          "link": res.data.footer_three_link,
          "image": res.data.footer_three_image,
          "title": res.data.footer_three_text,
        },
        {
          "link": res.data.footer_three_link,
          "image": res.data.footer_three_image,
          "title": res.data.footer_three_text,
        },
        {
          "link": res.data.footer_four_link,
          "image": res.data.footer_four_image,
          "title": res.data.footer_four_text,
        },
        {
          "link": res.data.footer_five_link,
          "image": res.data.footer_five_image,
          "title": res.data.footer_five_text,
        },
        {
          "link": res.data.footer_six_link,
          "image": res.data.footer_six_image,
          "title": res.data.footer_six_text,
        }
      ];
      

      })
      .catch( err => {
        console.error(err)
      })
      .finally( () => {

      })

    this.secondaryImage = require('@/assets/images/moodboard/secondary.jpg');
    this.contentImage1 = require('@/assets/images/moodboard/example.jpg');
    this.blockImage = require('@/assets/images/moodboard/block-background-2.jpg');

    // this.imagesCollage = [
    //   {
    //     'image': `url(${require('@/assets/images/moodboard/1.png')})`,
    //     'label': '@loremipsum'
    //   },
    //   {
    //     'image': `url(${require('@/assets/images/moodboard/2.png')})`,
    //     'label': '@loremipsum'
    //   },
    //   {
    //     'image': `url(${require('@/assets/images/moodboard/3.png')})`,
    //     'label': '@loremipsum'
    //   },
    //   {
    //     'image': `url(${require('@/assets/images/moodboard/2.png')})`,
    //     'label': '@loremipsum'
    //   },
    //   {
    //     'image': `url(${require('@/assets/images/moodboard/5.png')})`,
    //     'label': '@loremipsum'
    //   },
    //   {
    //     'image': `url(${require('@/assets/images/moodboard/6.png')})`,
    //     'label': '@loremipsum'
    //   },
    //   {
    //     'image': `url(${require('@/assets/images/moodboard/1.png')})`,
    //     'label': '@loremipsum'
    //   },
    //   {
    //     'image': `url(${require('@/assets/images/moodboard/2.png')})`,
    //     'label': '@loremipsum'
    //   },
    // ]

  

    // this.bannerBackground = `url(${require('@/assets/images/moodboard/banner.jpg')})`;
    // this.products = [
    //   {
    //     "id": 162,
    //     "name": "Overtime Mask",
    //     "image": "https://be-staging.beauteboard.com/storage/uploads/product_images/overtime-mask-2020-03-14-zDWsKp.jpg",
    //     "buy_link": "https://summerfridays.com/collections/all/products/overtime-mask2",
    //     "brand": "Summer Fridays",
    //     "price_us": "44.00",
    //     "price_uk": "39.00",
    //     "use_start": "2021-05-15 07:51:57",
    //     "use_end": null,
    //     "start_see_results": null,
    //     "stars": null,
    //     "brand_link": "summer-fridays",
    //     "link": "https://summerfridays.com/collections/all/products/overtime-mask2",
    //     "price": "44.00",
    //     "price_symbol": "$",
    //     "laravel_through_key": 118
    //   },
    //   {
    //     "id": 162,
    //     "name": "Overtime Mask",
    //     "image": "https://be-staging.beauteboard.com/storage/uploads/product_images/overtime-mask-2020-03-14-zDWsKp.jpg",
    //     "buy_link": "https://summerfridays.com/collections/all/products/overtime-mask2",
    //     "brand": "Summer Fridays",
    //     "price_us": "44.00",
    //     "price_uk": "39.00",
    //     "use_start": "2021-05-15 07:51:57",
    //     "use_end": null,
    //     "start_see_results": null,
    //     "stars": null,
    //     "brand_link": "summer-fridays",
    //     "link": "https://summerfridays.com/collections/all/products/overtime-mask2",
    //     "price": "44.00",
    //     "price_symbol": "$",
    //     "laravel_through_key": 118
    //   },
    //   {
    //     "id": 162,
    //     "name": "Overtime Mask",
    //     "image": "https://be-staging.beauteboard.com/storage/uploads/product_images/overtime-mask-2020-03-14-zDWsKp.jpg",
    //     "buy_link": "https://summerfridays.com/collections/all/products/overtime-mask2",
    //     "brand": "Summer Fridays",
    //     "price_us": "44.00",
    //     "price_uk": "39.00",
    //     "use_start": "2021-05-15 07:51:57",
    //     "use_end": null,
    //     "start_see_results": null,
    //     "stars": null,
    //     "brand_link": "summer-fridays",
    //     "link": "https://summerfridays.com/collections/all/products/overtime-mask2",
    //     "price": "44.00",
    //     "price_symbol": "$",
    //     "laravel_through_key": 118
    //   },
    //   {
    //     "id": 162,
    //     "name": "Overtime Mask",
    //     "image": "https://be-staging.beauteboard.com/storage/uploads/product_images/overtime-mask-2020-03-14-zDWsKp.jpg",
    //     "buy_link": "https://summerfridays.com/collections/all/products/overtime-mask2",
    //     "brand": "Summer Fridays",
    //     "price_us": "44.00",
    //     "price_uk": "39.00",
    //     "use_start": "2021-05-15 07:51:57",
    //     "use_end": null,
    //     "start_see_results": null,
    //     "stars": null,
    //     "brand_link": "summer-fridays",
    //     "link": "https://summerfridays.com/collections/all/products/overtime-mask2",
    //     "price": "44.00",
    //     "price_symbol": "$",
    //     "laravel_through_key": 118
    //   }
    // ]
  }
}
</script>
