<template>
  <div class="wishlist-select my-4">
    <ul>

    	<li v-for="(wishlist, index) in wishlists">
	    	<input
				type="checkbox"
				:id="`select_wishlist_${index}`"
				v-model="selectedWishlists"
				:value="wishlist.id"
				/>
	    	<label 
	    		:for="`select_wishlist_${index}`"
	    		>
	    		<div
	    			class="image"
	    			:style="{ backgroundImage: wishlist.image }"
	    			/>
	    		<div class="name">
	    			{{wishlist.name}}
	    		</div>
	    	</label>
	    </li>
    </ul>

    <div class="bottom d-flex align-items-center justify-content-between my-3">
	    <a 
	    	@click="openCreateModal"
	    	class="add"
	    	>
	    	+ add new collection
	    </a>

	    <b-button
	    	class="save px-4"
	    	@click="addToWishlists"
	    	>
	    	<span v-if="!isLoading">
	    		save		
	    	</span>
	    	<span v-if="isLoading">
	    		<b-spinner small type="grow" label="Spinning"></b-spinner>	
	    	</span>
	    </b-button>
	</div>

    <wishlist-modal 
      @onWishlistCreated="refreshWishlists" 
      :show-wishlists-on-new-created="false"
      />
  </div>
</template>

<script>
import APIClient from '@/api/requests';
import WishlistModal from '@/ui/WishlistModal.vue'
import { helperMixin } from '@/mixins/helperMixin.js'

export default {
  name: 'WishlistSelect',
  data () {
    return {
      wishlists: [],
      selectedWishlists: []
    }
  },
  mixins: [helperMixin],
  components: {
  	WishlistModal
  },
  props: {
  	product: {
  		type: Object,
  		default: null
  	}
  },
  computed: {
	    isLoggedIn: function () {
	      return this.$store.getters.isLoggedIn
	    },
	},
  mounted () {
  	if(this.isLoggedIn)
  		this.getWishlists()
  },
  methods: {
  	getWishlists: function () {
      APIClient.getWishlists()
	      .then( res => {
			this.wishlists = res.data.map( wishlist => {
	          wishlist.image = this.getWishlistCover(wishlist.image)
	          return wishlist;
	        })
	      })
	      .catch( err => {
	        console.error(err)
	      })
	      .finally( () => {

	      })
  	},
  	openCreateModal: function () {
      this.$bvModal.show('wishlist_modal')
    },
    refreshWishlists: function() {
      this.getWishlists();
    },
    addToWishlists: function() {

    	if(!this.selectedWishlists.length) {
    		this.$bvToast.toast(`Please select a wishlist`, {
	           	title: 'Error',
	            autoHideDelay: 5000,
	            toastClass: 'error',
	            appendToast: true,
	         })
    		return
    	}

    	if(this.isLoading) return;

    	let data = {
    		id: this.selectedWishlists,
	        product_id: this.product.id
    	}


	     APIClient.saveToWishlist(data)
	        .then( res => {

	          let messagesAdded = [] 
	          let messagesAlreadyAdded = []
	          res.data.productAddedInList.forEach( item => {
	          	messagesAdded.push(`Product added to ${item.list_name}`)

	          })

	          res.data.products_already_in_list.forEach( item => {
	          	messagesAlreadyAdded.push(`Product was already added to ${item.list_name}`)
	          })

	          messagesAdded.forEach( item => {
	          	this.$bvToast.toast( item, {
		            title: 'Success',
		            autoHideDelay: 5000,
		            toastClass: 'success',
		            appendToast: true,
		          })
	          })

	         	messagesAlreadyAdded.forEach( item => {
		          	this.$bvToast.toast( item, {
			            title: 'Alert',
			            autoHideDelay: 5000,
			            toastClass: 'info',
			            appendToast: true,
			          })
		          })

	         	this.$emit("onSelect")
	          
	        })
	        .catch( err => {
	          console.error(err)
	        })
    }
  }
}
</script>

