<template>
    <div class="moodboard">
        <b-container fluid>
        	<b-row>
        		<b-col cols="12">
                    <div class="container px-0">
            			<div class="d-flex justify-content-between align-items mb-5 px-0 px-sm-5">
    	        			<h2 class="moodboard__title">
    	        				<!-- Moodboard -->
                                CURATED SETS
    	        			</h2>
                            <div class="moodboard__pagination"></div>
    	        		</div>
                    </div>
        		</b-col>
        		<b-col cols="12">
        			<div class="swiper-container moodboard__slider">
        				<div class="swiper-wrapper">
        					<div 
        						class="swiper-slide moodboard__slide"
        						v-for="item in items"
        						>
        						<a 
        							:href="item.link"
        							target="_blank"
        							>
        							<div class="moodboard__slide-inner">
        								<div class="moodboard__label-wrapper">
											<div class="moodboard__label">
												{{ item.title }}
											</div>
										</div>
        								<div class="moodboard__thumb-wrapper">
			        						<div 
			        							class="moodboard__thumb"
			        							:style="{ backgroundImage: `url(${item.filename})` }" 
			        							>
			        						</div>
			        					</div>
		        					</div>
	        					</a>
        						
        					</div>
        				</div>
        				<div class="swiper-next">
        					<svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="14.775" height="7.277" viewBox="0 0 14.775 7.277"><g data-name="Grupo 144"><path data-name="Trazado 104" d="M14.776 3.639L11.193.101a.33.33 0 0 0-.467.467l2.759 2.737H.334a.334.334 0 1 0 0 .668h13.151L10.726 6.71a.33.33 0 0 0 .467.467z" fill="#fff"/></g></svg>
        				</div>
        			</div>
        		</b-col>
        	</b-row>
        </b-container>
    </div>
</template>

<script>
	import APIClient from '@/api/requests'
	import Swiper from '@/assets/scripts/vendor/swiper.esm.bundle';

    export default {
        name: 'Moodboard',
        components: {},
        data() {
            return {
        		isLoading: false,
        		items: null,
        		slider: null
            }
        },
        props: {},
        mounted() {
        	this.slider = new Swiper(`.moodboard .swiper-container`, {
                slidesPerView: 1,
                slidesPerGroup: 1,
                spaceBetween: 35,
                init: false,
                loop: true,
                loopAdditionalSlides: 3,
                watchSlidesVisibility: true,
                navigation: {
                    nextEl: '.moodboard .swiper-next',
                    prevEl: '.moodboard .swiper-prev'
                },
                pagination: {
                    el: '.moodboard__pagination',
                    type: 'custom',
                    renderCustom: function (swiper, current, total) {
                        return current + ' <span class="divider">-</span> ' +  total;
                    },
                },
                breakpoints: {
                    991: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                    },
                    500: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                    },
                },
            })

        	this.isLoading = true;
        	APIClient.getMoodboard()
        		.then( res => {
        			this.items = res.data
        			
        			var self = this
        			this.$nextTick( () => {
        				self.initSlider()
        			})        			
        		})
        		.catch( err => {

        		})
        		.finally( () => {
        			this.isLoading = false;
        		})
        },
        methods: {
        	initSlider: function() {
				this.slider.init()
        	}
        }
    }
</script>

