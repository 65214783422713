<template>
  <div class="checkbox-multi-select" :class="{ 'show-scrollbar': showMore }">
    <div class="searchbar" v-if="searchable">

        <input type="text" class="form-control" name="search" v-model="search" placeholder="search brands">
        <div class="container letter-filter my-3 px-0">
          <b-row align-h="between">
            <b-col sm="auto">
              <a :class="selectedFilter == 'ae' ? 'active' : ''" @click="filterByLetters('ae')">A - E</a>
            </b-col>
            <b-col sm="auto">
              <a :class="selectedFilter == 'fl' ? 'active' : ''" @click="filterByLetters('fl')">F - L</a>
            </b-col>
            <b-col sm="auto">
              <a :class="selectedFilter == 'mr' ? 'active' : ''" @click="filterByLetters('mr')">M - R</a>
            </b-col>
            <b-col sm="auto">
              <a :class="selectedFilter == 'sz' ? 'active' : ''" @click="filterByLetters('sz')">S - Z</a>
            </b-col>
          </b-row>
        </div>
      </div>
    <simplebar class="checkbox-list-wrapper">
      <ul id="checkbox-list">      
        <div v-if="!showMore" :class="customLayout">
          <li
            v-for="(item, index) in filteredList.slice(0, 8)"
            :key="index"
          >
            <b-form-checkbox
              :id="name + index"
              v-model="selected"
              :name="name + index"
              :value="item"
              unchecked-value="0"
              >
              {{ item.name }}
            </b-form-checkbox>
          </li>
          <a
            v-if="filteredList.length > 8"
            @click="showMore = true"
            class="show-more mt-3"
            >
            show {{ filteredList.length - 8 }} more
          </a>
        </div>
        <div v-else :class="customLayout">
          <li
            v-for="(item, index) in filteredList"
            :key="index"
            >
            <b-form-checkbox
              :id="name + index"
              v-model="selected"
              :name="name + index"
              :value="item"
              unchecked-value="0"
              >
              {{ item.name }}
            </b-form-checkbox>
          </li>
        </div>
      </ul>
    </simplebar>
  </div>
</template>

<script>

import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
let helper = require('@/assets/scripts/main.js');

export default {
  name: 'CheckboxMultiSelect',
  components: {
    simplebar
  },
  props: {
    options: {
      type: Array,
      default: function () { return [] }
    },
    selected: {
      type: Array,
      default: function () { return [] }
    },
    searchable: {
      type: Boolean,
      default: false
    },
    customLayout: {
      type: String,
      default: 'one-column'
    }
  },
  data () {
    return {
      showMore: false,
      search: '',
      selectedFilter: null
    }
  },
  watch: {
    selected: function (val) {
      this.$emit('change', val)
    }
  },
  methods: {
    filterByLetters: function(filter) {
      this.selectedFilter = filter
      let letters = helper.getLettersBetween(filter.charAt(0), filter.charAt(1));
      this.filteredList = this.options.filter( option => {
        for (var i = letters.length - 1; i >= 0; i--) {  
          if(option.name.toLowerCase().startsWith(letters[i])){
            return true;
          }
        }
        return false;
      });
    }
  },
  computed: {
    filteredList() {
      return this.options.filter( option => {

        let isBetweenLetters
        if(this.selectedFilter){
          let letters = helper.getLettersBetween(this.selectedFilter.charAt(0), this.selectedFilter.charAt(1));
          isBetweenLetters = false
          for (var i = letters.length - 1; i >= 0; i--) {  
            if(option.name.toLowerCase().startsWith(letters[i])){
              isBetweenLetters = true;
            }
          }        
        }else{
          isBetweenLetters = true
        }

        return option.name.toLowerCase().includes(this.search.toLowerCase()) && isBetweenLetters;
      })
    },    
  }
}
</script>
