<template>
  <div class="slider-box">
    <h5>
      {{ title }}
    </h5>

    <div v-if="data">
      <div class="image-container">
        <router-link :to="{ name: 'selected-products', params: { id: slug }}">

          <vue-load-image>
            <div 
              slot="image" 
              class="image"
              :style="`background-image: url(${data.image})`" 
              :data-src='data.image' 
              />
              <div 
                class="image-error" 
                slot="error"
                >
                <span>
                  <!-- Couldn't load image -->
                </span>
              </div>
          </vue-load-image>


          <!-- <progressive-background 
            class="image" 
            :src="data.image" 
            size="contain"
            >
            <div 
              slot="content" 
              slot-scope="{ visible }"
              >
              <div v-show="visible">
                <b-spinner 
                  type="grow" 
                  label="Spinning"
                  >
                </b-spinner>
              </div>
            </div>
          </progressive-background> -->
        </router-link>
      </div>
    </div>
    <div class="empty-container" v-else>
      {{ emptyMessage }}
    </div>
  </div>
</template>

<script>

import Swiper from '@/assets/scripts/vendor/swiper.esm.bundle';

export default {
  name: 'SliderBox',
  props: {
    title: '',
    data: null,
    emptyMessage: '',
    slug: null
  },
  data () {
    return {
      slider: null
    }
  }
}
</script>
