import Vue from 'vue'
import Router from 'vue-router'

// Layouts
import DashboardLayout from '../layouts/DashboardLayout.vue'
import SimpleLayout from '../layouts/SimpleLayout.vue'
import SimpleHome from '../pages/simple/Home.vue'

// Simple layouts
import Login from '../pages/simple/Login.vue'
import Register from '../pages/simple/Register.vue'
import Quiz from '../pages/simple/Quiz.vue'
import About from '../pages/simple/About.vue'
import PublicProduct from '../pages/simple/PublicProduct.vue'
import Moodboard from '../pages/simple/Moodboard.vue'
import HomeSimple from '../pages/simple/Home.vue'

// Dashboard Layouts
import DashboardHome from '../pages/dashboard/Home.vue'
import Account from '../pages/dashboard/Account.vue'
import Wishlists from '../pages/dashboard/Wishlists.vue'
import SingleWishlist from '../pages/dashboard/SingleWishlist.vue'
import SelectedProducts from '../pages/dashboard/SelectedProducts.vue'
import Overview from '../pages/dashboard/Overview.vue'
import PageNotFound from '../pages/PageNotFound.vue'
import Terms from '../pages/dashboard/Terms.vue'
import Privacy from '../pages/dashboard/Privacy.vue'
import Brands from '../pages/dashboard/Brands.vue'
import ForYou from '../pages/dashboard/ForYou.vue'
import Routines from '../pages/dashboard/Routines.vue'

import store from '../store'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: SimpleLayout,
      meta: { transitionName: 'fade' },
      children: [
        {
          path: '/',
          name: 'home-simple',
          component: HomeSimple,
          meta: { transitionName: 'slide' }
        },
        {
          path: '/login',
          name: 'login',
          component: Login,
          meta: { transitionName: 'slide' }
        },
        {
          path: '/register',
          name: 'register',
          component: Register
        },
        {
          path: '/home',
          name: 'simple-home',
          component: SimpleHome
        },
        {
          path: '/quiz',
          component: SimpleLayout,
          children: [
            {
              path: '',
              name: 'quiz',
              component: Quiz,
              meta: { transitionName: 'slide' }
            }
          ]
        },
        {
          path: '/terms',
          name: 'terms',
          component: Terms
        },
        {
          path: '/privacy',
          name: 'privacy',
          component: Privacy
        },
        {
          path: '/about',
          name: 'about',
          component: About,
        },
        {
          path: '/product/:section/:brand/:slug',
          name: 'public-product',
          component: PublicProduct,
        },
        {
          path: '/moodboard/:slug',
          name: 'moodboard',
          component: Moodboard,
        },
        {
          path: 'routines',
          name: 'routines',
          component: Routines,
          meta: { 
            transitionName: 'fade'
          }
        },
      ]
    },    
    {
      path: '/me',
      name: 'home',
      component: DashboardLayout,
      meta: { transitionName: 'fade' },
      children: [
        {
          path: '',
          name: 'dashboard-home',
          component: DashboardHome,
          meta: {
            requiresAuth: true,
            transitionName: 'fade'
          }
        },
        {
          path: 'account',
          name: 'account',
          component: Account,
          meta: {
            requiresAuth: true,
            transitionName: 'fade'
          },
        },
        {
          path: 'collections',
          name: 'wishlists',
          component: Wishlists,
          meta: { 
            requiresAuth: true,
            transitionName: 'fade' 
          }
        },
        {
          path: 'collections/:id',
          name: 'single-wishlist',
          component: SingleWishlist,
          meta: { 
            requiresAuth: true,
            transitionName: 'fade'
          }
        },
        {
          path: 'dashboard',
          name: 'overview',
          component: Overview,
          meta: { 
            requiresAuth: true,
            transitionName: 'fade' 
          }
        },
        {
          path: 'overview/:id',
          name: 'selected-products',
          component: SelectedProducts,
          meta: { 
            requiresAuth: true,
            transitionName: 'fade' 
          }
        },
        {
          path: 'brand/:brand',
          name: 'brands',
          component: Brands,
          meta: { 
            requiresAuth: true,
            transitionName: 'fade'
          }
        },
        {
          path: 'products/new',
          name: 'foryou',
          component: ForYou,
          meta: { 
            requiresAuth: true,
            transitionName: 'fade'
          }
        },
      ]
    },
    { 
      path: "*", 
      name: 'not-found',
      component: PageNotFound,
      meta: {
        transitionName: 'fade'
      }
    }
  ]
})

router.beforeEach((to, from, next) => {

  // Scroll to top after page changes (only if it's not a product modal)
  if(!to.query.product && !from.query.product) {
    var VueScrollTo = require('vue-scrollto');
    var options = {
      easing: 'ease-in',
      offset: -60,
      force: true,
      cancelable: false,
    }
    VueScrollTo.scrollTo('#app', 0, options);  
  }

  if(to.name == 'login' && store.getters.isLoggedIn) {
    next('/me')
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    console.log(to.path)
    const redirect = to.path
    console.log(redirect)

    next({ name: 'login', query: { redirect: redirect } });

    // alert("asdf");
    // next('/')
  } else {
    next()
  }
})

export default router
