<template>
  	<div class="search-results">
  		<transition name="fade">
	  		<b-container class="search-results__inner container-fluid" v-show="!isLoading">
	  			<b-row class="h-100">
	  				<div class="col-md-6 search-results__brands px-0" v-if="brands.length || !hasResults">
					  	<simplebar class="search-results__simplebar py-2 p-md-5" ref="brands_simplebar">
					  		<div class="search-results__top">
						  		<div>search results:</div>
						  		"{{ term }}"
						  	</div>

						  	<!-- No results -->
						  	<div v-show="!hasResults">
						  		<search-no-results-brand />
						  	</div>

						  	<div v-show="hasResults">
							  	<div class="search-results__items search-results__items--brands">
									<div v-for="(item, index) in brands">
										<search-result-brand :brand="item" />
									</div>
								</div>
							</div>
						</simplebar>
					</div>

					<div class="col-12 search-results__products" :class="{ 'col-md-6 single-column': brands.length || !hasResults }">
						<simplebar class="search-results__simplebar px-2 py-4 p-md-5" ref="products_simplebar">
							<div class="search-results__top" :class="{ 'd-none': brands.length || !hasResults }">
						  		<div>search results:</div>
						  		"{{ term }}"
						  	</div>

						  	<!-- No results -->
						  	<div v-show="!hasResults">
						  		no results
						  		<search-no-results-product />
						  	</div>

						  	<div v-show="hasResults">
								<div class="search-results__items search-results__items--products">
									<div v-for="(item, index) in products">
										<search-result-product 
											:product="item" 
											@onProductClick="openProductModal"
											/>
									</div>
								</div>

								<button 
									@click="loadMore"
									class="search-results__load-more" 
									:class="{ 'search-results__load-more--hidden': !showLoadMore }"
									>
									<template v-if="!isLoadingProducts">
										Load more
									</template>
									<template v-else>
										Loading...
									</template>
								</button>
							</div>
						</simplebar>
					</div>
				</b-row>
		  	</b-container>
		 </transition>

		 <transition name="fade">
		 	<spinner v-show="isLoading" />
		 </transition>

		 <transition name="fade">
		 	<div v-show="!isLoading && (!brands.length && !products.length)">
		 		
		 	</div>
		 </transition>

	  	<!-- <div class="bottom">
			  	<simplebar 
			  		class="list" 
			  		id="search_list"
			  		data-simplebar-auto-hide="false"
			  		v-if="!isLoading"
			  		>
			  		<div
						class="item container mb-4 pb-4" 
						v-if="brands.length" 
						v-for="(item, index) in brands" 
						key="item.id"
						>
							<b-row>
								<b-col 
									cols="auto"
									class="px-0"
									@click="openBrandLink(item.link)"
									>
									<div class="image-wrapper">
										<div 
											class="image" 
											:style="{ backgroundImage: `url(${item.image})` }"
											/>
									</div>
								</b-col>

								<b-col class="pr-0 pl-5">
									<div class="content">
										<div>
											<div class="brand" @click="openBrand(item)">
												{{ item.name }}
											</div>
										</div>
										<div>
											<b-button 
												class="view-more py-0" 
												@click="openBrandLink(item.link)"
												>
											  	View Brand
											</b-button> 
										</div>
									</div>                 
								</b-col>

							</b-row>
					</div>
					<div
						class="item container mb-4 pb-4" 
						v-if="products.length" 
						v-for="(item, index) in products" 
						key="item.id"
						>
							<b-row>
								<b-col 
									cols="auto"
									class="px-0"
									@click="$bvModal.show(`search_${item.id}`)"
									>
									<div class="image-wrapper">
										<div 
											class="image" 
											:style="{ backgroundImage: `url(${item.image})` }"
											/>
									</div>
								</b-col>

								<b-col class="pr-0 pl-5">
									<div class="content">
										<div>
											<div class="brand" @click="openBrand(item)">
												{{ item.brand }}
											</div>
											<div @click="$bvModal.show(`search_${item.id}`)">
												<div class="name">
												 	{{ item.name }}
												</div>
												<div class="categories mb-3">
													{{ item.categories }}
												</div>
											</div>
										</div>
										<div>
											<b-button 
												class="view-more py-0" 
												@click="$bvModal.show(`search_${item.id}`)"
												>
											  	View details
											</b-button> 
										</div>
									</div>                 
								</b-col>
								<routine-modal :modalId="`routine_${item.id}`" :source="item" />
								<product-modal :modalId="`search_${item.id}`" section="search" :source="item" :sourceNavigation="products" :sourceIndex="index"/>

							</b-row>
					</div>  

					<div 
						v-if="!products.length && !brands.length"
						class="no-results"
						>
						<div class="mb-3">No results.</div>
						Didn't find a product? Click <a @click="openContactModal">here</a> 
						to let us know if we should add a new one.
					</div>
				</simplebar>
				<spinner v-if="isLoading"/>
		</div>
 -->

 		<product-modal
 			:modalId="`search_product`" 
 			section="search" 
 			:source="selectedProduct" 
 			/>
		<contact-modal 
			:customId="'request_product'" 
			:formToShow="'Product Request'"
			/>
  	</div>
</template>

<script>

import Spinner from '@/ui/Spinner.vue';
import ProductModal from '@/ui/ProductModal.vue'
import RoutineModal from '@/ui/RoutineModal.vue'
import ContactModal from '@/ui/ContactModal.vue'
import Simplebar from 'simplebar-vue';
import SearchResultProduct from '@/components/dashboard/search/SearchResultProduct.vue'
import SearchResultBrand from '@/components/dashboard/search/SearchResultBrand.vue'
import SearchNoResultsBrand from '@/components/dashboard/search/SearchNoResultsBrand.vue'
import SearchNoResultsProduct from '@/components/dashboard/search/SearchNoResultsProduct.vue'

import 'simplebar/dist/simplebar.min.css';

export default {
  name: 'SearchResults',
  components: {
  	Simplebar,
  	Spinner,
  	RoutineModal,
  	ProductModal,
  	ContactModal,
  	SearchResultProduct,
  	SearchResultBrand,
  	SearchNoResultsBrand,
  	SearchNoResultsProduct
  },
  props: {
	products: [],
	brands: [],
	isLoading: false,
	term: '',
	showLoadMore: false,
	isLoadingProducts: false
  },
  data() {
  	return {
  		selectedProduct: null
  	}
  },
  mounted() {

  },
  watch: {
  	brands: function() {
  		this.recalculate()
  	},
  	products: function() {
  		this.recalculate()
  	}
  },
  computed: {
  	hasResults: function () {
  		return this.brands.length || this.products.length
  	}
  },
  methods: {
  	recalculate: function() {
  		if(this.$refs.products_simplebar) this.$refs.products_simplebar.$el.SimpleBar.recalculate()
		if(this.$refs.brands_simplebar) this.$refs.brands_simplebar.$el.SimpleBar.recalculate()
  	},
  	openContactModal: function() {
      this.$bvModal.show(`request_product`)
  	},
  	openBrand: function(product) {
      if(product.brand_link != null && product.brand_link.length > 0) {
        this.$router.push(`/me/brand/${product.brand_link}`)
      }
    },
    openBrandLink: function(link) {
    	this.$router.push(`/me/brand/${link}`)
    },
    openProductModal: function(product) {
    	this.selectedProduct = product
    	this.$bvModal.show(`search_product`)
    },
    loadMore: function() {
    	this.$emit('onLoadMore')
    }
  }
}
</script>
