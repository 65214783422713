<template>
  <div class="following h-100">
    <h4>following</h4>

    <div class="frame">
      <ul class="p-4">
        <li>
          <div class="circle"></div>
          <button>unfollow</button>
        </li>
        <li>
          <div class="circle"></div>
          <button>unfollow</button>
        </li>
        <li>
          <div class="circle"></div>
          <button>unfollow</button>
        </li>
        <li>
          <div class="circle"></div>
          <button>unfollow</button>
        </li>
        <li>
          <div class="circle"></div>
          <button>unfollow</button>
        </li>
      </ul>
      <div class="overlay">
        Coming soon
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Following',
  components: {},
  props: {
    
  },
  data () {
    return {
     
    }
  }
}
</script>
