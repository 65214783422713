var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"notifications_modal","title":"all notifications","body-class":"p-0","modal-class":"notifications-modal","hide-footer":"true","centered":""},on:{"show":_vm.getAllNotifications,"hidden":_vm.closeModal}},[_c('div',{attrs:{"slot":"modal-header-close"},slot:"modal-header-close"},[_c('i',{staticClass:"icon-close-no-background"})]),_vm._v(" "),_c('div',{staticClass:"container"},[_c('div',{staticClass:"py-4 px-0 px-lg-2"},[_c('simplebar',{staticClass:"notifications-wrap container",attrs:{"data-simplebar-auto-hide":"false"}},[(!_vm.isLoading)?_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[(_vm.hasNotifications)?_c('div',{staticClass:"notifications"},[_c('ul',{staticClass:"list"},_vm._l((_vm.notifications),function(item,item_index){return _c('li',{key:_vm.index,staticClass:"container item mb-3 notification",on:{"click":function($event){return _vm.openSingleNotification(item)}}},[_c('b-row',{staticClass:"py-2",attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"pr-0",attrs:{"sm":"2","cols":"2"}},[_c('img',{staticClass:"mx-auto w-100",attrs:{"src":require("@/assets/images/logos/bb-submark.png")}})]),_vm._v(" "),_c('b-col',{attrs:{"sm":"10","cols":"10"}},[_c('b-row',[_c('b-col',[_c('label',[_vm._v(_vm._s(item.title))])]),_vm._v(" "),_c('b-col',{staticClass:"text-right time"},[_c('label',[_vm._v("\n                                       "+_vm._s(_vm.moment(item.date).calendar(null, {
                                          sameDay: '[Today]',
                                          nextDay: '[Tomorrow]',
                                          nextWeek: 'dddd',
                                          lastDay: '[Yesterday]',
                                          lastWeek: 'MMM DD',
                                          sameElse: 'MMM DD'
                                        }))+"\n                                    ")])])],1),_vm._v(" "),_c('b-row',[_c('b-col',[_c('p',[_vm._v(_vm._s(item.content.stripHtmlTags()))])])],1)],1)],1)],1)}),0)]):_c('div',{staticClass:"notifications"},[_c('div',{staticClass:"no-notifications text-center my-5"},[_vm._v("\n                        no notifications\n                      ")])])])],1):_vm._e(),_vm._v(" "),(_vm.isLoading)?_c('spinner'):_vm._e()],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }