<template >
    <div 
        class="filter-input routine-filter"
        >
        <div 
            class="filter-input__search" 
            v-if="search"
            >
            <b-container>
                <b-row>            
                    <input 
                        class="form-control px-3 py-2" 
                        type="text" 
                        placeholder="search for a product type" 
                        v-model="searchTerm"
                        >
                    </input>
                    <i class="icon-search"></i>
                </b-row>
            </b-container>
        </div>

        <div class="filter-input__items-wrapper">
            <ul class="filter-input__items">
                <li
                    v-for="(item, index) in options"
                    :key="index"
                    class="filter-input__item"
                    >
                    <input 
                        :id="`${componentId}_${customKey}_${index}`" 
                        :type="type"
                        :value="item[customKey]"
                        v-model="selected"
                        />
                    <label :for="`${componentId}_${customKey}_${index}`">
                        {{ item[label] }}
                    </label>
                </li>
            </ul> 
            <a
                class="filter-input__submit btn"
                v-if="multiple"
                @click="submit"
                >
                filter
            </a>  
        </div>
    </div>
</template>

<script>
    import EventBus from '@/event-bus'

    export default {
        name: 'FilterInput',
        components: {},
        props: {
            options: {
                type: Array,
                default: []
            },
            value: {
                type: Array,
                default: []
            },
            search: {
                type: Boolean,
                default: false
            },
            multiple: {
                type: Boolean,
                default: false
            },
            label: {
                type: String,
                default: 'name'
            },
            customKey: {
                type: String,
                default: 'id'
            }
        },
        data () {
            return {
                searchTerm: '',
                options: [],
                selected: [],
                componentId: null
            }
        },
        methods: {
            submit: function() {
                this.$emit('input', this.selectedOptions)
                console.log(this.selectedOptions)
                EventBus.$emit('routine-filter-changed', this.selectedOptions);
            },
        },
        watch: {
            selected: function (val) {
                if(!this.multiple) {
                    this.$emit('input', this.selectedOptions)
                }
            },
            value: function(val) {
                if(Array.isArray(this.selected)) {
                    this.selected = val.map(e => e.id)
                }
            }
        },
        mounted() {
            this.componentId = this._uid;
        },
        computed: {
            type() {
                return this.multiple ? 'checkbox' : 'radio';
            },
            selectedOptions() {
                let selected = []
                if(!this.multiple) {
                    const selectedObject = this.options.filter( e => e[this.customKey] == this.selected)
                    return selectedObject[0]
                }
                this.options.forEach((item) => {
                    this.selected.forEach(it => {
                        if(it == item[this.customKey]) {
                            selected.push(item)
                        }
                    })
                })
                return selected
            }
        }
    }
</script>
