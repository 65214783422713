<template>
 	<div class="moodboard-columns">

	  	<div class="container">
	  		<div class="row">
	  			<div class="moodboard-columns__column col-md-4">
	  				<h3 class="moodboard-columns__title">
	  					{{ title }}
	  				</h3>
	  			</div>

	  			<div 
	  				class="moodboard-columns__column" 
	  				:class="{ 'col-md-4': columnTwo, 'col-md-8': !columnTwo }"
	  				v-html="columnOne" 
	  				>
	  			</div>

	  			<div 
	  				class="moodboard-columns__column col-md-4" 
	  				v-html="columnTwo" 
	  				v-if="columnTwo"
	  				>
	  			</div>
	  		</div>
	  	</div>

	</div>
</template>

<script>

	export default {
	  	name: 'MoodboardColumns',
		data () {
		    return {

		    }
		},
		components: {
			
		},
		props: {	
			title: '',
			columnOne: null,
			columnTwo: null,
   		},
		mounted() {
			  	
		},
		methods: {
		    
		}
	}
</script>
