var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"skin-journey-item"},[_c('div',{staticClass:"image-container"},[_c('div',{staticClass:"circle"},[_c('img',{attrs:{"src":_vm.source.skin_options.image}})])]),_vm._v(" "),_c('div',{staticClass:"info-container"},[_c('div',{staticClass:"info"},[_c('span',{staticClass:"mb-0"},[_vm._v("\n          "+_vm._s(_vm.source.skin_options.option)+"\n        ")]),_vm._v(" "),_c('span',{staticClass:"date float-right"},[_vm._v("\n          "+_vm._s(_vm.moment(_vm.source.updated_at).format('MMM D'))+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"days-ago"},[_vm._v("\n      "+_vm._s(_vm.moment(_vm.source.updated_at).calendar(null, {
          sameDay: '[Today]',
          nextDay: '[Tomorrow]',
          nextWeek: 'dddd',
          lastDay: '[Yesterday]',
          lastWeek: 'DD/MM/YYYY',
          sameElse: 'DD/MM/YYYY'
        }))+"\n    ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }