<template>
  <simplebar 
    data-simplebar-auto-hide="false" 
    class="notifications-wrap"
    >
    <transition name="fade-faster">
      <b-row v-if="!isLoading">
        <b-col sm="12">
          <h4 class="mb-3">
            notifications
          </h4>

          <!-- Notifications list -->
          <div 
            class="notifications" 
            v-if="notifications.length"
            >
            <ul class="list">
              <li
                v-for="(item, item_index) in notifications"
                :key="index"
                class="container item mb-3 notification"
                @click="$bvModal.show(`notification_${item.id}`)"
                >
                <b-row
                  class="py-3"
                  align-v="center"
                  >
                  <b-col
                    cols="2"
                    class="pr-0 col-2"
                    >
                    <img
                      src="@/assets/images/logos/bb-submark.png"
                      class="mx-auto w-100"
                      >
                  </b-col>
                  <b-col 
                    cols="10" 
                    sm="10"
                    >
                    <label>
                      {{ item.title }}
                    </label>
                    <p>
                      {{ item.content.stripHtmlTags() }}
                    </p>
                  </b-col>
                </b-row>

                <notification-modal 
                  :modalId="`notification_${item.id}`" 
                  section="notification" 
                  :source="item" 
                  :sourceNavigation="items" 
                  :sourceIndex="index" 
                  @closed="markAsRead(item_index)" 
                  @onOpenAllNotifications="openAllNotifications"
                  />
              </li>
            </ul>

          </div>      
          <!-- No notifications -->
          <div v-else>
            <div class="no-notifications text-center my-5">
              <div>
                no new notifications
              </div>

              <button 
                class="mb-2 mt-4 btn btn-outline-secondary" 
                @click="openAllNotifications"
                >
                view all
              </button>
            </div>

          </div>  

        </b-col>
      </b-row>
    </transition>
    <transition name="fade-faster">
      <spinner v-if="isLoading" />
    </transition>
  </simplebar>
</template>

<script>

import APIClient from '@/api/requests'
import NotificationModal from '@/ui/NotificationModal.vue'
import simplebar from 'simplebar-vue';
import Spinner from '@/ui/Spinner.vue'
import 'simplebar/dist/simplebar.min.css';
import EventBus from '@/event-bus'

let helper = require('@/assets/scripts/main.js');

export default {
  name: 'Notifications',
  components: {
    NotificationModal,
    simplebar,
    Spinner
  },
  data () {
    return {
      notifications: [],
      selectedIndex: 0,
      isLoading: false
    }
  },
  mounted () {
    this.moment = require('moment');
    this.isLoading = true
    APIClient.getUnreadNotifications(20)
      .then(resp => {
        this.notifications = resp.data
      })
      .catch(err => {
        console.error(err)
      })
      .finally( () => {
        this.isLoading = false
      })
  },
  methods: {
    markAsRead: function (index) {
      let notificationId = this.notifications[index].id      
      APIClient.markNotificationAsRead({ notification_id: [notificationId] })
        .then(resp => {
          
        })
        .catch(err => {
          // console.log(err)
        })
        .finally( () => {
          this.notifications.splice(index, 1);
        })
    },
    openNotification: function(index) {
      this.selectedIndex = index
      this.$refs['notification-modal'].show()
    },
    closeNotifications: function(index) {      
      let notificationsIds = this.notifications[index].map( item => item.id )
      APIClient.markNotificationAsRead({ notification_id: notificationsIds })
        .then(resp => {

        })
        .catch(err => {
          // console.log(err)
        })
        .finally( () => {
          delete this.notifications[index];
          this.$forceUpdate()

          if(helper.isObjectEmpty(this.notifications)){this.notifications = null}
        })
    },
    openNotifications: function () {
      this.$root.$emit('onClickOpenNotification')
    },
    openAllNotifications: function () {
      // this.$root.emit('onClickOpenNotification')
      this.$root.$emit('bv::show::modal', 'notifications_modal', '#btnShow')
      // EventBus.$emit('my-shelf-changed', this.isRecommendations);
    }
  }
}
</script>

<style></style>
