<template>
	<div class="about-slide-first">

    <div class="position-absolute app-name">
      beautéboard
    </div>    

	  <b-container>
      <b-row>
        <b-col 
          cols="12"
          md="7"
          class="content"
          >
          <h1>
            about us
          </h1>
          <div class="description">
            shopping for beauty
          </div>
        </b-col>

        <b-col 
          cols="12"
          md="5"
          class="video-background"
          >

        </b-col>
      </b-row>
    </b-container>

	</div>
</template>

<script>



export default {
  name: 'AboutSlideFirst',
  data() {
  	return {
  		
  	}
  },
  components: {

  },
  mounted () {
  	
  }
}
</script>
