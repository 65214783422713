<template>
  <div class="my-resolutions overview-section">
    <h2 class="section-heading">
      My Resolutions
    </h2>
    <b-row>

      <!-- Six Months -->
    	<b-col 
    		cols="12" 
    		md="6"
        class="list"
    		>
    		<div class="subtitle">
    			for the next 6 months:
    		</div>

    		<ul>
          <b-form-group>
            <li
              v-for="(item, index) in optionsSix"
              :key="index"
              class="pb-3"
              >
              <div class="custom-control custom-checkbox">
                <div class="number">
                  {{index + 1}}
                </div>
                <input 
                  :checked="optionsSixSelected.includes(item.id)" 
                  type="checkbox" class="custom-control-input" 
                  :id="`checkbox_skin_goal_${item.id}`" 
                  :value="item.id"
                >
                <label 
                  @click="send(item.id)" 
                  class="custom-control-label" 
                  :for="`checkbox_skin_goal_${item.id}`"
                  >
                  {{item.name}}
                </label>
              </div>
            </li>
          </b-form-group>
	      </ul>
    	</b-col>

      <!-- Twelve Months -->
      <b-col 
        cols="12" 
        md="6"
        class="list"
        >
        <div class="subtitle">
          for the next 12 months:
        </div>

        <ul>
            <b-form-group>
              <li
                v-for="(item, index) in optionsTwelve"
                :key="index"
                class="pb-3"
                >
                <div class="custom-control custom-checkbox">
                  <div class="number">
                    {{index + 1}}
                  </div>
                  <input 
                    :checked="optionsTwelveSelected.includes(item.id)" 
                    type="checkbox" class="custom-control-input" 
                    :id="`checkbox_skin_goal_${item.id}`" 
                    :value="item.id"
                  >
                  <label 
                    @click="send(item.id)" 
                    class="custom-control-label" 
                    :for="`checkbox_skin_goal_${item.id}`"
                    >
                    {{item.name}}
                  </label>
                </div>
              </li>
            </b-form-group>
          </ul>
      </b-col>

    </b-row>
  </div>
</template>

<script>

import APIClient from '@/api/requests'

export default {
  name: 'MyResolutions',
  components: {},
  data() {
    return {
      isLoading: false,
      sixMonthsResolutions: [],
      twelveMonthsResolutions: [],
      optionsSix: [],
      optionsSixSelected: [],
      optionsTwelve: [],
      optionsTwelveSelected: []
    }
  },
  mounted () {
  	this.getUserSixSkinGoals()
  	this.getUserTwelveSkinGoals()
  },
  methods: {
  	send: function (checked) {
      APIClient.updateUserSkinGoals(checked)
        .then(resp => {
            
          })
          .catch(err => {
            console.error(err)
          })
    },
    getUserSixSkinGoals: function() {
    	APIClient.getUserSkinGoals(6)
	        .then(resp => {      
	          for (var i = resp.data.length - 1; i >= 0; i--) {
	            let selected = resp.data[i];
	            selected.skin_goals[0].id = selected.id
	            this.optionsSix.push({...selected.skin_goals[0]})
	            if(selected.checked == "1") this.optionsSixSelected.push(parseInt(selected.id))
	          }
	        })
	        .catch(err => {
	          console.error(err)
	        })
	        .finally( () => {
	          this.isLoading = false
	        })
    },
    getUserTwelveSkinGoals: function() {
    	APIClient.getUserSkinGoals(12)
	        .then(resp => {      
	          for (var i = resp.data.length - 1; i >= 0; i--) {
	            let selected = resp.data[i];
	            selected.skin_goals[0].id = selected.id
	            this.optionsTwelve.push({...selected.skin_goals[0]})
	            if(selected.checked == "1") this.optionsTwelveSelected.push(parseInt(selected.id))
	          }
	        })
	        .catch(err => {
	          console.error(err)
	        })
	        .finally( () => {
	          this.isLoading = false
	        })
    }
  }
}
</script>

