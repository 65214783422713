<template>
  <div class="moodboard-collage">
  	<div class="moodboard-collage__inner">
      <div class="moodboard-collage__item" v-for="index in 6" :key="index">
        <div class="moodboard-collage__image-wrapper">
          <div class="moodboard-collage__label" v-if="images[index]">
            {{ images[index].label }}
          </div>
          <div 
            class="moodboard-collage__image" 
            v-if="images[index]"
            :style="{ backgroundImage: `${images[index].image}` }" 
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

	export default {
	  name: 'MoodboardCollage',
		data () {
		    return {

		    }
		},
		components: {
			
		},
		props: {
      images: []
    },
  	mounted() {
		  	
		},
		methods: {
		    
		}
	}
</script>
