export const helperMixin = {
  created() {
    // console.log('Hello from the mixin!')
  },
  methods: {
    isMobile() {
    	return (this.$mq == "mobile") ? true : false;
    },
    parsePaginatedData(d) {
    	let meta = {
	        nextPage: d.next_page_url,
	        previousPage: d.prev_page_url,
	        total: d.total,
	        totalPages: d.last_page,
	        currentPage: d.current_page,
	        from: d.from,
	        to: d.to,
	    }
	    return {
	    	items: d.data,
	    	meta: meta
	    };
    },
    hasNextPage(meta) {
    	return meta.currentPage < meta.totalPages;
    },
    hasPreviousPage(meta) {
    	return meta.currentPage > 1 
    },
    getWishlistCover(image) {
      let backgrounds = [
        `url(${require('@/assets/images/backgrounds/wishlist-1.jpg')})`,
        `url(${require('@/assets/images/backgrounds/wishlist-2.jpg')})`,
        `url(${require('@/assets/images/backgrounds/wishlist-3.jpg')})`
      ]

      if(image) {
        return `url(${require('@/assets/images/wishlists-covers/' + image + '.jpg')})`
      } else {
        return backgrounds[Math.floor((Math.random() * 3))]
      }
    },
    validURL(str) {
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return !!pattern.test(str);
    },
    isObject(o){ 
      return typeof o == "object";
    },
  }
}