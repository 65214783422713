<template>
	<div class="moodboard-image">
		<img 
			class="moodboard-image__image" 
			:src="image"
			/>
	</div>
</template>

<script>
	export default {
		name: 'MoodboardImage',
		data () {
			return {

			}
		},
		components: {
			
		},
		props: {
			image: ''
		},
		mounted() {

		},
		methods: {

		}
	}
</script>
