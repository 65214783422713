<template>
  <div class="review-single">

    <b-row>
      <b-col 
        cols="12" 
        md="3" 
        class="image-container"
        >
        <progressive-background 
          class="image" 
          :src="source.product_details.image" 
          size="contain"
          >
          <div 
            slot="content" 
            slot-scope="{ visible }"
            >
            <div v-show="visible">
              <b-spinner 
                type="grow" 
                label="Spinning" 
                />
            </div>
          </div>
        </progressive-background>
      </b-col>

      <b-col 
        cols="12" 
        md="9" 
        class="content-container"
        >
        <b-row class="heading">
          <b-col 
            cols="12"
            md="8"
            >
            <div class="title">
              {{ source.product_details.name }}
            </div>

            <div class="product-name">
              {{ source.title }}
            </div>

            <div class="rating-container">
              my rating: 
              <rating 
                :score="source.stars || 0" 
                show-score="false" 
                :customStyle="'dark'" 
                />
            </div>
          </b-col>
          <b-col 
            cols="12"
            md="4"
            class="text-md-right text-center mt-md-0 mt-4"
            >
            <b-button @click="showEditForm">
              <span v-if="!isLoading">
                edit 
                <svg svg-inline="" focusable="false" role="presentation" data-name="noun_Edit pencil_1416596" xmlns="http://www.w3.org/2000/svg" width="10.124" height="10.156" viewBox="0 0 10.124 10.156"><g data-name="Grupo 457"><path data-name="Trazado 505" d="M9.92 1.118L9.009.204a.724.724 0 0 0-1.008 0L.639 7.59.6 7.629 0 10.156l2.519-.6 7.4-7.425a.729.729 0 0 0 .001-1.013zM1.046 7.75L7.4 1.37l1.356 1.361L2.4 9.106zm-.155.369l1.143 1.147-1.511.369zm8.758-6.278l-.6.6-1.357-1.362.6-.6a.328.328 0 0 1 .446 0l.911.914a.322.322 0 0 1 .1.233.313.313 0 0 1-.1.211z" fill="#bbb"/></g></svg>
              </span>
              <span v-else>
                <b-spinner 
                  small 
                  type="grow" 
                  label="Spinning"
                  />   
              </span>
            </b-button>
            <b-button @click="showConfirmation(modalId)">
              delete
            </b-button>
          </b-col>
        </b-row>
        <b-row class="review-content">
          <b-col cols="12">
            {{ source.content }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <confirmation-modal 
      :message="'Are you sure you want to delete this review?'" 
      :modal-id="modalId"
      @onConfirm="deleteReview"
      />

  </div>
</template>

<script>

import APIClient from '@/api/requests'
import Rating from '@/ui/Rating.vue';
import ConfirmationModal from '@/ui/ConfirmationModal.vue'

export default {
  name: 'ReviewSingle',
  components: {
    Rating,
    ConfirmationModal
  },
  data() {
    return {
      isLoading: false,
    }
  },
  props: {
    source: null
  },
  methods: {
    showConfirmation(id) {
      if(this.isLoading) return
      this.$bvModal.show(id)
    },
    deleteReview() {
      let data = {
        review_id: this.source.id
      }
      this.isLoading = true
      APIClient.deleteReview(data)
        .then( resp => {
          this.$emit('onDelete')
        })
        .catch( err => {
          console.error(err)
        })
        .finally( () => {
          this.isLoading = false
        })
    },
    showEditForm() {
      let self = this
      this.isLoading = true
      setTimeout(() => {
        self.isLoading = false
      }, 2000)
      
      this.$emit("onShowEditModal", this.source)
    }
  },
  computed: {
    modalId: function() {
      return `review_${this.source.id}`;
    }
  }
}
</script>

