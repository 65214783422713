<template>
  <div class="product-banner">
    <div class="product-banner__inner">
      <h3 class="product-banner__title">
        curated for<br>oily skin
      </h3>

       <a class="product-banner__link">
        Shop<span class="divider"></span>$275
      </a>
    </div>
  </div>
</template>

<script>
  import { helperMixin } from '@/mixins/helperMixin.js'

  export default {
    name: 'ProductBanner',
    components: {

    },
    mixins: [helperMixin],
    data() {
      return {
        slider: null
      }
    },
    mounted () {
      
    },
    methods: {

    }
  }
</script>
