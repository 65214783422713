<template>
	<div class="cv-header">
		<div class="cv-header-nav">
			<button
				
				class="previousPeriod"
				@click.prevent="onInput(headerProps.previousPeriod)"
			>
				<i class="icon-caret-left"></i>
			</button>
			<button
				
				class="nextPeriod"
				@click.prevent="onInput(headerProps.nextPeriod)"
			>
				<i class="icon-caret-right"></i>
			</button>
		</div>
		<div class="periodLabel">
			<slot name="label">{{ headerProps.periodLabel }}</slot>
		</div>
		<div class="changeView">
			<svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="15.404" height="15.404" viewBox="0 0 15.404 15.404"><path data-name="Trazado 61454" d="M12.9 2.824C12.5 2.609 10 1.266 8.633.405a1.272 1.272 0 0 0-1.862 0C5.4 1.265 2.9 2.609 2.5 2.824H0V15.4h15.4V2.824zM6.42 1.22v.063a1.284 1.284 0 1 0 2.567 0V1.22a88.72 88.72 0 0 0 2.839 1.6H3.581a84.778 84.778 0 0 0 2.839-1.6zM.513 5.135H14.89v9.755H.513z" fill="#666" fill-rule="evenodd"/></svg>
			<input 
              type="checkbox" 
              @change="onChangeView" 
              v-model="listView"
              class="uiswitch" 
            />
			<svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="17.2" height="12.825" viewBox="0 0 17.2 12.825"><g data-name="noun_bullet list_1726653"><g data-name="Grupo 819"><g data-name="Grupo 818"><g data-name="Grupo 817" fill="#666"><circle data-name="Elipse 371" cx="1.287" cy="1.287" r="1.287"/><circle data-name="Elipse 372" cx="1.287" cy="1.287" r="1.287" transform="translate(0 5.126)"/><circle data-name="Elipse 373" cx="1.287" cy="1.287" r="1.287" transform="translate(0 10.251)"/><path data-name="Trazado 61451" d="M5.405.772H16.69a.522.522 0 0 1 .515.515.522.522 0 0 1-.515.515H5.405a.522.522 0 0 1-.515-.515.522.522 0 0 1 .515-.515z"/><path data-name="Trazado 61452" d="M5.405 5.898H16.69a.522.522 0 0 1 .515.515.522.522 0 0 1-.515.515H5.405a.522.522 0 0 1-.515-.515.522.522 0 0 1 .515-.515z"/><path data-name="Trazado 61453" d="M5.405 11.023H16.69a.522.522 0 0 1 .515.515.522.522 0 0 1-.515.515H5.405a.522.522 0 0 1-.515-.515.522.522 0 0 1 .515-.515z"/></g></g></g></g></svg>
		</div>
	</div>
</template>
<script>
export default {
	name: "CustomCalendarViewHeader",
	data() {
		return {
			listView: false
		}
	},
	props: {
		headerProps: {
			type: Object,
			required: true,
		},
	},
	methods: {
		onInput(d) {
			this.$emit("input", d)
		},
		onChangeView() {
			this.$emit("onChangeView", this.listView)
		}
	},
}
</script>
<style>
.cv-header {
	display: flex;
	flex: 0 1 auto;
	flex-flow: row nowrap;
	align-items: center;
	min-height: 2.5em;
	border-width: 1px 1px 0 1px;
}
.cv-header .periodLabel {
	display: flex;
	flex: 1 1 auto;
	flex-flow: row nowrap;
	min-height: 1.5em;
	line-height: 1;
	font-size: 1.5em;
}
.cv-header,
.cv-header button {
	border-style: solid;
	border-color: #ddd;
}
.cv-header-nav,
.cv-header .periodLabel {
	margin: 0.1em 0.6em;
}
.cv-header-nav button,
.cv-header .periodLabel {
	padding: 0.4em 0.6em;
}
.cv-header button {
	box-sizing: border-box;
	line-height: 1em;
	font-size: 1em;
	border-width: 1px;
}
</style>