<template>
  <b-row class="slider-articles h-100 pl-0 pl-sm-5 py-4">
    <b-col sm="12">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            v-for="(item, index) in slides"
            :key="index"
            class="swiper-slide article"
          >
            <div
              class="w-100 h-100 content"
              :style="{ backgroundImage: `url(${item.thumb})` }"
            >
              <div>
                <h3>{{ item.title }}</h3>
                <div class="mt-4">
                  <a
                    class="btn btn-outline-dark"
                    target="_blank"
                    :href="item.link"
                  >{{ item.label }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <!-- If we need navigation buttons -->
        <div class="swiper-prev">
          <svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="14.774" height="7.277" viewBox="0 0 14.774 7.277"><g data-name="Grupo 145"><path data-name="Trazado 105" d="M0 3.639L3.582.101a.331.331 0 0 1 .468.467L1.29 3.305h13.151a.334.334 0 0 1 0 .668H1.29L4.05 6.71a.331.331 0 1 1-.468.467z" fill="#fff"/></g></svg>
        </div>
        <div class="swiper-next">
          <svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="14.775" height="7.277" viewBox="0 0 14.775 7.277"><g data-name="Grupo 144"><path data-name="Trazado 104" d="M14.776 3.639L11.193.101a.33.33 0 0 0-.467.467l2.759 2.737H.334a.334.334 0 1 0 0 .668h13.151L10.726 6.71a.33.33 0 0 0 .467.467z" fill="#fff"/></g></svg>
        </div>

        <!-- Bullets -->
        <div class="pagination float-right mt-2" />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import Swiper from '@/assets/scripts/vendor/swiper.esm.bundle'

export default {
  name: 'SliderArticles',
  components: {},
  data () {
    return {
      slides: [
        {
          link: 'https://view.flodesk.com/emails/5ebc28a67eb65f00276b72d1',
          title: 'Mandy Madden\'s moodboard',
          thumb: `${require('@/assets/images/carousel/post-1.jpg')}`,
          label: 'View'
        },
        {
          link: 'https://www.instagram.com/beauteboard',
          title: 'Instagram',
          thumb: `${require('@/assets/images/carousel/post-2.jpg')}`,
          label: 'View'
        },
        {
          link: 'http://google.com',
          title: 'LACTIC 3',
          thumb: 'https://image.freepik.com/free-vector/colorful-memphis-design-background-vector_53876-81744.jpg',
          label: 'View'
        },
        {
          link: 'http://google.com',
          title: 'LACTIC 4',
          thumb: 'https://image.freepik.com/free-vector/colorful-memphis-design-background-vector_53876-81744.jpg',
          label: 'View'
        },
        {
          link: 'http://google.com',
          title: 'LACTIC 5',
          thumb: 'https://image.freepik.com/free-vector/colorful-memphis-design-background-vector_53876-81744.jpg',
          label: 'View'
        }
      ]
    }
  },
  created: function () {},
  mounted () {
    new Swiper('.slider-articles .swiper-container', {
      slidesPerView: 'auto',
      spaceBetween: 30,
      pagination: {
        el: '.pagination'
      },
      loop: true,
      navigation: {
        nextEl: '.slider-articles .swiper-next',
        prevEl: '.slider-articles .swiper-prev'
      },
      breakpoints: {
        768: {
          slidesPerView: 1,
        }
      }
    })
  }
}
</script>

<style></style>
