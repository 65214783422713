<template>
    <div class="single-wishlist pb-5">
      <section class="header">
        <simple-header />
      </section>
      <section class="mt-5 px-5 container content">
        <b-row class="mb-3">
          <b-col cols="12">
            <div class="top-nav pb-2 mb-3">
              <b-row class="mb-3 align-items-center">
                <b-col cols="12" md="6" class="text-center text-md-left mb-3 mb-md-0">
                  <span class="counter" v-if="products">{{ products.length }} items</span>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="px-0 px-md-3">
            <div class="grid" v-if="products">
              <div class="item" v-for="(item, index) in products">
                  <Product :source="item.product_details" :sourceNavigation="products" :sourceIndex="index" />
              </div>
            </div>
          </b-col>
        </b-row>
      </section>


      <!-- Pagination -->
      <b-row align-h="end" class="my-5" v-if="meta">
        <b-col
          sm="5"
          class="float-right product-pagination text-right"
        >
          <label>showing {{ meta.from }} - {{ meta.to }} of {{ meta.total }}</label>
          <div class="nav-pagination">
            <a @click="getPreviousPage" :class="{ disabled: meta.currentPage <= 1 }">
              <svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="5.54" height="10.777" viewBox="0 0 5.54 10.777"><path data-name="Trazado 201" d="M0 5.389l4.835 5.389.705-.705-4.23-4.684L5.54.705 4.835 0z"/></svg>
            </a>
            {{ meta.currentPage }}  /  {{ meta.totalPages }}
            <a @click="getNextPage" :class="{ disabled: meta.currentPage >= meta.totalPages }">
              <svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="5.54" height="10.777" viewBox="0 0 5.54 10.777"><path data-name="Trazado 200" d="M5.538 5.389L.703 10.778l-.705-.705 4.23-4.684L-.002.705.703 0z"/></svg>
            </a>
          </div>
        </b-col>

      </b-row>

    </div>

</template>

<script>

import APIClient from '@/api/requests'
import Product from '@/ui/products_browser/Product.vue'
import SimpleHeader from '@/ui/SimpleHeader'
import WishlistsSlider from '@/components/dashboard/overview/WishlistsSlider'

export default {
  name: 'SelectedProducts',
  components: {
    Product,
    SimpleHeader
  },
  computed: {
  },
  data () {
    return {
      isLoading: true,
      products: null,
      meta: null
    }
  },
  mounted () {

    this.isLoading = true

    switch(this.$route.params.id){
      case 'top-rated':  
        APIClient.getTopRated(12)
          .then( res => {
            
            this.setProductsDetails(res.data)
            console.log(res.data)
          })
          .catch( err => {
            console.error(err)
          })
          .finally( () => {
            this.isLoading = false
          })  
          break;
      case 'repurchased': 
        APIClient.getRepurchased(12)
          .then( res => {
            this.setProductsDetails(res.data)
          })
          .catch( err => {
            console.error(err)
          })
          .finally( () => {
            this.isLoading = false
          })  
          break;
      case 'breakups': 
        APIClient.getBreakUps(12)
          .then( res => {
            this.setProductsDetails(res.data)
          })
          .catch( err => {
            console.error(err)
          })
          .finally( () => {
            this.isLoading = false
          })  
          break;
    }
  },
  methods: {
    setProductsDetails(data) {
      this.products = data.data
      this.meta = {
        nextPage: data.next_page_url,
        previousPage: data.prev_page_url,
        total: data.total,
        totalPages: data.last_page,
        currentPage: data.current_page,
        from: data.from,
        to: data.to,
      }
    }
  }
}
</script>
