<template>
	<div class="search-no-results-brand">
		<div class="search-no-results-brand__text">
			No search<br>
			Results
		</div>

		<div class="search-no-results-brand__image">
		</div>
	</div>
</template>

<script>

	import Spinner from '@/ui/Spinner.vue';

	export default {
		name: 'SearchNoResultsBrand',
		components: {

		},
		props: {
			product: null
		},
		mounted() {

		},
		methods: {
		}
	}
</script>
