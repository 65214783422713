<template>
  <div>
    <h4>Register</h4>
    <form @submit.prevent="register">
      <label for="name">Name</label>
      <div>
        <input
          id="name"
          v-model="name"
          type="text"
          required
          autofocus
        >
      </div>

      <label for="email">E-Mail Address</label>
      <div>
        <input
          id="email"
          v-model="email"
          type="email"
          required
        >
      </div>

      <label for="password">Password</label>
      <div>
        <input
          id="password"
          v-model="password"
          type="password"
          required
        >
      </div>

      <label for="password-confirm">Confirm Password</label>
      <div>
        <input
          id="password-confirm"
          v-model="password_confirmation"
          type="password"
          required
        >
      </div>

      <div>
        <button type="submit">
          Register
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      profile_preferences: null
    }
  },
  methods: {
    register: function () {
      if (this.password !== this.password_confirmation) {
        return
      }
      let data = {
        name: this.name,
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation
      }
      this.$store
        .dispatch('register', data)
        .then(resp => {
          // console.log(resp)
          APIClient.getFullProfile()
            .then(resp => {
              // console.log({...resp.data})
              console.log(resp.data)
              this.profile_preferences = resp.data
              this.$store.dispatch('getProfile').then(() => { 
                console.log(profile_preferences)
                if(this.profile_preferences.skintypes){
                  if(this.profile_preferences.skintypes.length) {
                    // redirect to Home
                    this.$router.push('/me')                
                  }else{
                    // redirect to Quiz
                    this.$router.push('/quiz')
                  }
                } else {
                  this.$router.push('/quiz')
                }
                this.isLoading = false;
              })
              
            })
            .catch(err => {
              console.error(err)
              this.isLoading = false;
            })
        })
        .catch(err => {
        // console.log(err))
        })
    }
  }
}
</script>
