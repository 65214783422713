export default {
  methods: {
    showToast(message, customClass, title = null) {

    	if(!title) {
    		if(customClass == "success") {
    			title = "Success"
    		}
    		if(customClass == "error") {
    			title = "Error"
    		}
    		if(customClass == "info") {
    			title = "Alert"
    		}
    	}

    	this.$bvToast.toast(message, {
	        title: title,
	        autoHideDelay: 5000,
	        toastClass: customClass,
	        appendToast: true,
	    })
    }
  }
}