<template>
  <div class="lists-slider">
    <h4>my lists</h4>

    <div class="frame">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            v-for="(item, index) in slides"
            :key="index"
            class="swiper-slide article"
          >
            <div
              class="image"
              :style="{ backgroundImage: `url(${item.image})` }"
            >
              <div>
                <h3>{{ item.name }}</h3>
                <div class="mt-4">
                  <a
                    class="btn btn-outline-dark"
                    target="_blank"
                    :href="item.link"
                  >View Article</a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

       <div class="swiper-prev">
          <i class="icon-arrow-slider-left"></i>
        </div>
        <div class="swiper-next">
          <i class="icon-arrow-slider-right"></i>
        </div>
    </div>

  </div>
</template>

<script>
import APIClient from '@/api/requests'
import Swiper from '@/assets/scripts/vendor/swiper.esm.bundle'

export default {
  name: 'ListsSlider',
  components: {
    Swiper
  },
  props: {
    
  },
  data () {
    return {
      slides: [],
      swiper: null
    }
  },
  mounted () {
    APIClient.getWishlists()
      .then( res => {
        let wishlist = res.data[0]
        APIClient.getWishlist(wishlist.id)
          .then( r => {
            // console.log(r.data)
            this.slides = r.data.products
            let self = this
            setTimeout( () => {
              self.swiper.update();  
            }, 100)
            
          })
          .catch( err => {
            console.error(err)
          })
          .finally( () => {

          })
      })
      .catch( err => {
        console.error(err)
      })
      .finally( () => {

      })
    this.swiper = new Swiper('.lists-slider .swiper-container', {
      slidesPerView: 1,
      spaceBetween: 10,
      pagination: {
        el: '.pagination'
      },
      loop: true,
      loopAdditionalSlides: 3, 
      navigation: {
        nextEl: '.swiper-next',
        prevEl: '.swiper-prev'
      }   
    })
  }
}
</script>
