<template>
    <div class="brands-page" id="brands_page">
      <loader-full-screen v-if="isLoading"/>
      <section class="header">
        <simple-header />
      </section>
      <b-container
        fluid
        class="p-0"
        >
        <b-row no-gutters>
          <b-col sm="12">
            <section-header
              :title="'new for you'"
              :background-url="brandImage"
              id="skin_header"
              >
              searching for products that meet your needs takes time, 
              so we’ve done the hard work for you. here’s the latest additions we’ve added and curated for you.
            </section-header>
          </b-col>
        </b-row>
      </b-container>

      <b-container>
        <b-row>
          <b-col sm="12" class="background-white">
            <products-browser
              :showFilters="false"
              :showNewProducts="true"
              ref="productsBrowser"
              />
          </b-col>
        </b-row>
      </b-container>

    </div>
</template>

<script>
import APIClient from '@/api/requests'
import ProductsBrowser from '@/components/dashboard/home/ProductsBrowser.vue'
import SectionHeader from '@/ui/SectionHeader.vue'
import SimpleHeader from '@/ui/SimpleHeader'
import LoaderFullScreen from '@/ui/LoaderFullScreen.vue'
import { helperMixin } from '@/mixins/helperMixin.js'

export default {
  name: 'ForYou',
  components: {
    ProductsBrowser,
    SectionHeader,
    SimpleHeader,
    LoaderFullScreen
  },
  mixins: [helperMixin],
  data() {
    return {
      section: '',
      brand: '',
      brandIds: [],
      brandTitle: '',
      brandDescription: '',
      brandImage: '',
      isLoading: false,
    }
  },
  created() {
    this.section = this.$route.params.section
    this.brand = this.$route.params.brand
  },
  mounted () {
    this.brandImage = `my-dashboard-banner`
    this.$forceUpdate()
    // APIClient.getNewProducts()
    //   .then( resp => {
    //     console.log(resp.data[0])
    //     this.brandTitle = resp.data[0].brand_name
    //     this.brandDescription = resp.data[0].brand_description
    //     this.brandImage = resp.data[0].brand_banner
    //     this.brandIds = resp.data.map( e => { return e.brand_id })

    //     console.log(resp)

    //     this.$refs.productsBrowser.getFilters(this.brand);          

    //     setTimeout( () => {
    //       var VueScrollTo = require('vue-scrollto');
    //       VueScrollTo.scrollTo('#brands_page');
    //     }, 1500)
        
    //   })
    //   .catch( err => {
    //     console.error(err)
    //     this.$router.push('/404')
    //   })
    //   .finally( () => {
    //     let self = this
    //     setTimeout( () => {
    //       self.isLoading = false
    //     }, 500)
        
    //   })


  },
  computed: {
    profile: function () {
      return this.$store.getters.profile
    }
  },
  methods: {
    
  }
}

</script>