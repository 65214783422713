<template>
  <b-modal 
    :id="modalId"  
    title="Notification" 
    body-class="p-0" 
    modal-class="notification-modal" 
    @show="hideSlider" 
    @hidden="closeModal" 
    hide-footer=true
    centered
    >

      <div class="container" v-if="source">
        <b-row class="subheader py-2" align-v="center">
          <b-col cols="2" lg="auto">
            <img
              :src="source.image"
              class="mx-auto w-100"
            >
          </b-col>
          <b-col cols="10" lg="auto">
            <div class="title">
              beautéboard
            </div>
            <small class="date">
              {{ moment(source.date).calendar(null, {
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                nextWeek: 'dddd',
                lastDay: '[Yesterday]',
                lastWeek: 'DD/MM/YYYY',
                sameElse: 'DD/MM/YYYY'
              }) }}
            </small>
          </b-col>

          <b-col cols="auto" class="view-all ml-auto">
            <a @click="openAllNotifications">view all</a>
          </b-col>
                  
        </b-row>        
    </div>

    <div class="container py-4 px-4" v-if="source">
      <b-row>
        <b-col sm="12">
          <div v-html="source.content">
          </div>
        </b-col>        
      </b-row>
    </div>
    
  </b-modal>
</template>

<script>

import APIClient from '@/api/requests';

require('@/assets/scripts/main.js');

export default {
  name: 'NotificationModal',
  components: {
    
  },
  props: {
    source: Object,
    modalId: String,
    sourceNavigation: {
      type: Array,
      default: function () { return [] }
    },
    sourceIndex: {
      type: Number,
      default: function () { return [] }
    },
    previousNotification: {
      type: Object,
      default: undefined
    },
    nextNotification: {
      type: Object,
      default: undefined
    },
    section: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      notification: {},
      moment: require('moment')
    }
  },
  methods: {        
    closeModal: function () {
      // alert("asdf")
      this.$emit('closed')
      this.$root.$emit('bv::hide::modal', 'modal-1', '#btnShow')
    },
    showPreviousNotification: function () {
      if (!this.nextNotification) return;
      // console.log(`${this.section.value}_${this.nextNotification.id}`)
      this.$bvModal.hide(`${this.section}_${this.source.id}`);
      this.$bvModal.show(`${this.section}_${this.nextNotification.id}`);
    },
    showPreviousNotification: function () {  
      if (!this.previousNotification) return;
      this.$bvModal.hide(`${this.section}_${this.source.id}`);
      this.$bvModal.show(`${this.section}_${this.previousNotification.id}`);
    },
    openAllNotifications: function () {
      // this.$root.$emit('onOpenAllNotifications')
      this.$root.$emit('bv::show::modal', 'notifications_modal', '#btnShow')
      this.$emit('closed')
      this.$bvModal.hide(`modal_notification`);
    }
  },
  created () {


  },
  mounted () {
    
  }
}
</script>
