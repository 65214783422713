<template>
  <div class="ingredients-glossary overview-section">
    <h2 class="section-heading">
      Ingredient glossary for your skin
    </h2>

    <b-row>

      <b-col 
        cols="12" 
        md="6"
        >
        <simplebar class="list-wrapper mb-5 mb-md-0">
          <ul class="list">
            <li 
              v-for="item in data"
              @click="selectItem(item)"
              >
              <span>
                {{item.name}}
              </span>

              <!-- <svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"><g data-name="Grupo 709"><g data-name="Elipse 341" fill="#fff" stroke="#000" stroke-width=".8"><circle cx="7.5" cy="7.5" r="7.5" stroke="none"/><circle cx="7.5" cy="7.5" r="7.1" fill="none"/></g><g data-name="Grupo 111"><path data-name="Trazado 174" d="M7.429 4v3.428H4v.643h3.429v3.428h.643V8.071H11.5v-.643H8.072V4z"/></g></g></svg> -->
            </li>
          </ul>
        </simplebar>

        <!-- <a 
          class="show-more float-right"
          @click="showMore" 
          v-if="data.slice(0, limit).length < data.length"
          >
          + show 8 more
        </a> -->
      </b-col>

      <b-col 
        cols="12" 
        md="6"
        >
        <div>
          <b-tabs card v-if="selectedItem">
            <b-tab title="Glossary" active>
              <b-card-text>
                <table class="w-100">
                  <thead>
                    <tr>
                      <th>ingredient</th>
                      <th>ewg score</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="col-6 px-0 name">
                        {{selectedItem.name}}
                      </td>
                      <td class="col-6 px-0 score">
                        <div class="number">
                          {{ selectedItem.ewg_score || 1 }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table class="w-100">
                  <thead>
                    <tr>
                      <th>what it does</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="description">
                        <span v-if="selectedItem.description">
                          {{selectedItem.description}}
                        </span>
                        <span v-else>
                          No description found
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
               
                
                
              </b-card-text>
            </b-tab>
            <b-tab title="EWG Chart">
              <b-card-text class="text-left">
                <!-- {{selectedItem.ewg_score}} -->
                <!-- Coming Soon... -->
                <div class="ewg-info">
                  We believe in transparency when it comes to  ingredients in the products curated for you. That is why each ingredient is listed with their Environmental Working Group toxicity scores.
                </div>

                <table class="w-100 mt-4 ewg-table">
                  <thead>
                    <tr>
                      <th>ewg score</th>
                      <th>what it means</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="col-3 px-0 ">
                        <div class="circle">
                          1-2
                        </div>
                      </td>
                      <td class="col-9 px-3">
                        <div class="number">
                          High Safety
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="col-3 px-0 ">
                        <div class="circle">
                          3-6
                        </div>
                      </td>
                      <td class="col-9 px-3">
                        <div class="number">
                          Moderate Safety
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="col-3 px-0 ">
                        <div class="circle">
                          7-10
                        </div>
                      </td>
                      <td class="col-9 px-3">
                        <div class="number">
                          Low Safety
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </b-card-text>
            </b-tab>
          </b-tabs>
        </div>
      </b-col>

    </b-row>
  </div>
</template>

<script>

import APIClient from '@/api/requests'
import Spinner from '@/ui/Spinner.vue'
import Simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

export default {
  name: 'IngredientsGlossary',
  components: {
  	Spinner,
    Simplebar
  },
  data() {
    return {
      isLoading: false,
      data: [],
      selectedItem: null,
      limit: 8,
    }
  },
  mounted() {
    this.isLoading = true
    APIClient.getIngredientsGlossary()
      .then(resp => {
        this.data = resp.data
        this.selectedItem = this.data[0]
      })
      .catch(e => {
        console.error(e)
      })
      .finally(() => {
        this.isLoading = false
      })
  },
  methods: {
    selectItem: function(item) {
      this.selectedItem = item
    },
    showMore: function() {
      this.limit += 8
      console.log(this.limit)
    }
  }
}
</script>

