<template>
  <div class="quiz-preview text-center container">  
    <b-row>	
      <b-col cols="6" md="12">
      	<h2>
          <template v-if="section == 'skin'">
            <template v-if="!showSelectedValues">
              {{ titleOnHover }}
            </template>
            <template v-else>
              {{ section }}
            </template>
          </template>
          <template v-else>
            {{ section }}
          </template>
          
        </h2>

    	  <div class="image-wrapper mx-auto mb-5">
    	    <div 
            class="preview preview__image" 
            :style="{ backgroundImage: `url(${previewData.image})` }"  
            v-if="previewData.imageSelected"
          />
          <div 
            class="preview preview__image" 
            :style="{ backgroundImage: `url(${imageOnHover})` }" 
            v-if="!previewData.imageSelected"
          />
    	  </div>
      </b-col>

      <b-col cols="6" md="12">

    	  <div class="details container mx-auto d-flex d-md-block flex-column justify-content-center" v-if="section != 'skin'">
    	    <b-row class="mb-4">
    	      <b-col cols="12" md="6" class="px-0 mb-3 mb-md-2">
    	        {{ previewData.row_1.label }}
    	      </b-col>
    	      <b-col cols="12" md="6" class="bordered px-0 pb-2">
    	        {{ previewData.row_1.value }}
    	      </b-col>
    	    </b-row>
    	    <b-row class="mb-4">
    	      <b-col cols="12" md="6" class="px-0 mb-3 mb-md-2">
    	         {{ previewData.row_2.label }}
    	      </b-col>
    	      <b-col cols="12" md="6" class="bordered px-0 pb-2">
    	      	 {{ previewData.row_2.value }}
    	      </b-col>
    	    </b-row>
    	    <b-row class="mb-4">
    	      <b-col cols="12" md="6" class="px-0 mb-3 mb-md-2">
    	        {{ previewData.row_3.label }}
    	      </b-col>
    	      <b-col cols="12" md="6" class="bordered px-0 pb-2">
    	      	 {{ previewData.row_3.value }}
    	      </b-col>
    	    </b-row>
    	  </div>

        <div class="details container mx-auto d-flex d-md-block flex-column justify-content-center" v-if="section == 'skin'">
          <b-row class="mb-4" v-show="!showSelectedValues">
            <b-col cols="12" md="12" class="px-0 mb-3 mb-md-2 description-on-hover">
              {{ descriptionOnHover }}
            </b-col>
          </b-row>

          <template v-if="showSelectedValues">
            <b-row class="mb-4">
              <b-col cols="12" md="6" class="px-0 mb-3 mb-md-2">
                {{ previewData.row_1.label }}
              </b-col>
              <b-col cols="12" md="6" class="bordered px-0 pb-2">
                {{ previewData.row_1.value }}
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col cols="12" md="6" class="px-0 mb-3 mb-md-2">
                 {{ previewData.row_2.label }}
              </b-col>
              <b-col cols="12" md="6" class="bordered px-0 pb-2">
                 {{ previewData.row_2.value }}
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col cols="12" md="6" class="px-0 mb-3 mb-md-2">
                {{ previewData.row_3.label }}
              </b-col>
              <b-col cols="12" md="6" class="bordered px-0 pb-2">
                 {{ previewData.row_3.value }}
              </b-col>
            </b-row>
          </template>
        </div>

      </b-col>
    </b-row>
  </div>
</template>

<script>

import EventBus from '@/event-bus'

export default {
  name: 'QuizPreview',
  props: {
  	section: {
  		type: String,
  		default: ''
  	},
  	previewData: {
  		type: Object,
  		default: null
  	},
    imageOnHover: {
      type: String,
      default: ''
    },
    descriptionOnHover: {
      type: String,
      default: ''
    },
    titleOnHover: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showSelectedValues: true  
    }
  },
  created() {
      EventBus.$on('showSelectedValue', this.updateHoveredDescription)
  },
  beforeDestroy() {
      EventBus.$off('showSelectedValue')
  },
  components: {

  },
  mounted () {    

  },
  computed: {
    
  },
  methods: {
  	updateHoveredDescription: function(value) {
        // console.log(value)
        this.showSelectedValues = value
        this.$forceUpdate()
     }
  },
  watch: {
    
  }
}
</script>
