<template>
  <div class="challenges overview-section no-border">
    <h2 class="section-heading">
      Challenges (coming soon)
    </h2>

    <b-row>
      <b-col cols="12">
        <img 
          class="mt-4" 
          src="@/assets/images/placeholders/challenge.png"
          />
      </b-col>
    </b-row>
  </div>
</template>

<script>

import APIClient from '@/api/requests'
import Spinner from '@/ui/Spinner.vue'

export default {
  name: 'Challenges',
  components: {
  	Spinner
  },
  data() {
    return {
      isLoading: false,
      data: [],
    }
  },
  mounted() {
    
  },
  methods: {

  }
}
</script>

