<template>
  <div class="footer">

  	<product-banner></product-banner>

<!--   	<b-container>
  		<b-row>
  			<b-col cols="12" class="px-4"> -->
  				<!-- <div class="footer__newsletters">
	  				<h2 class="footer__title">
	  					editions <span>newsletter takeovers</span>
	  				</h2>
	  				<div class="position-relative">
					  	<div class="swiper-container">
					  		<div class="swiper-wrapper">
					  			<div 
					  				class="swiper-slide footer__slide"
					  				v-for="slide in data"
					  				>
					  				<a 
					  					:href="slide.link"
					  					target="_blank"
					  					>
						  				<h4 class="footer__slide-title">
						  					{{ slide.title }}
						  				</h4>
						  				<div 
						  					class="footer__slide-thumb"
						  					:style="{ backgroundImage: `url(${slide.image})` }" 
						  					>
						  				</div>

						  				<div class="footer__slide-subtitle">
						  					{{ slide.subtitle }}
						  				</div>
						  			</a>
					  			</div>
					  		</div>
					  	</div>
					  	<div class="swiper-prev footer__slide-arrow">
			  				<svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="5.54" height="10.777" viewBox="0 0 5.54 10.777"><path data-name="Trazado 201" d="M0 5.389l4.835 5.389.705-.705-4.23-4.684L5.54.705 4.835 0z"/></svg>
			  			</div>
			  			<div class="swiper-next footer__slide-arrow">
			  				<svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="5.54" height="10.777" viewBox="0 0 5.54 10.777"><path data-name="Trazado 200" d="M5.538 5.389L.703 10.778l-.705-.705 4.23-4.684L-.002.705.703 0z"/></svg>
			  			</div>
					</div>
				</div> -->
<!-- 			</b-col>
		</b-row>
	</b-container> -->

	<b-container class="footer__bottom" fluid>
		<b-row>
			<b-col cols="12" md="4" class="col-12 d-none d-md-block">
				<div class="footer__times">
					<a 
						href=""
						target="_blank"
						>
						beauty curated for you
					</a>
				</div>
			</b-col>
			<b-col cols="12" md="4" class="col-12 d-none d-md-block">
				<div class="d-flex align-items-center justify-content-center">
					<a 
						href=""
						target="_blank"
						>
						<svg class="footer__logo" svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.6 32.2"><path d="M16 14.9c-.9-.9-2-1.6-3.2-2l-.2-.1c-.9-.3-1.9-.5-2.9-.6-.7-.1-1.4 0-2.1.1-1.2.2-2.4.6-3.4 1.1-.6.3-1.1.6-1.6 1V0S.8.2 0 .4c.4.8.7 1.6.7 2.5v25.9c0 .3 0 .7-.1 1 0 .5-.1 1-.2 1.5 0 .1-.1.3-.1.4 0 .1 0 .2.1.2h.1c.1 0 .1 0 .2-.1s.3-.2.4-.3c.3-.2.5-.4.8-.7.2 0 .4.1.6.2h.1c.2 0 .3.1.5.2l.3.1c1.9.7 4 .9 6 .6.4-.1.9-.2 1.3-.3 1.5-.3 3-1 4.3-1.9.4-.3.7-.6 1-1 1.7-2 2.6-4.6 2.5-7.2.2-2.3-.8-4.7-2.5-6.6zm-1.8 14.2c-.4.5-.9 1-1.5 1.4 0 0-.4.3-.7.5-.5.3-1 .5-1.6.7-.6.2-1.1.3-1.7.3-1 .1-2 0-3-.3-.2-.1-.6-.2-.9-.3-.9-.3-.8-.3-1.2-.4l-.6-.3c-.1 0-.2-.1-.3-.2-.1-.1-.1-.2-.1-.4v-7.7-6.6.8-1c0-.2-.1-.2-.3-.2s-.4.1-.6.1h-.1s-.1 0-.1-.1v-.1c.1-.1.1-.1.2-.1l.9-.6c.1 0 .1-.1.3-.2.7-.4 1.4-.9 2.1-1.2.9-.4 1.8-.7 2.7-.8 1-.2 2-.1 3 .1 1.5.5 2.8 1.5 3.6 2.9 1.3 1.9 2 4.2 2 6.6.2 2.6-.6 5.1-2.1 7.1z" fill="#fff"/></svg>
					</a>
				</div>
			</b-col>
			<b-col cols="12" md="4" class="col-12 d-none d-md-block">
				<div class="footer__times">
					© beautéboard. 2021. All rights reserved.
				</div>
			</b-col>
			<b-col cols="6" md="2">
				<a 
					href="https://beauteboard.com/about.html"
					target="_blank"
					>
					ABOUT
				</a>
			</b-col>
			<b-col cols="6" md="2">
				<a 
					@click="openContactModal"
					>
					CONTACT
				</a>
			</b-col>
			<b-col cols="12" md="4" class="col-12 d-none d-md-block">
				<img
	                    src="@/assets/images/logos/logo-footer.png"
	                    class="footer__logo-2"

	                    />
			</b-col>
			<b-col cols="6" md="2">
				<router-link 
                    :to="{ name: 'terms' }"
                    target="_blank"
                    >
                    T&C
                </router-link>
			</b-col>
			<b-col cols="6" md="2">
				<router-link 
					target="_blank"
                    :to="{ name: 'privacy' }"
                    >
                    PRIVACY
                </router-link>
			</b-col>
			<b-col cols="12" class="col-12 d-block d-md-none">
				<div class="footer__times">
					beauty curated for you
					<br>
					© beautéboard. 2020. All rights reserved.
				</div>
			</b-col>
		</b-row>
	</b-container>

	<contact-modal :id="contact_modal" />
  </div>
</template>

<script>
	import APIClient from '@/api/requests';
	import Swiper from '@/assets/scripts/vendor/swiper.esm.bundle';
	import ContactModal from '@/ui/ContactModal.vue'
	import ProductBanner from '@/components/common/ProductBanner.vue'

	export default {
	  	name: 'AppFooter',
		data () {
		    return {
		      data: null,
		      slider: null
		    }
		},
		components: {
			ContactModal,
			ProductBanner,
		},
		computed: {
		    isLoggedIn: function () {
		      return this.$store.getters.isLoggedIn
		    },
		},
	  	mounted() {
		  	APIClient.getNewsletterEditions()
		  		.then( res => {
		  			this.data = res.data

		  			var self = this
	    			this.$nextTick( () => {
	    				self.initSlider()
	    			}) 
		  		})
		  		.catch( err => {

		  		})
		  		.finally( () => {

		  		})

		  	this.slider = new Swiper(`.footer .swiper-container`, {
	            slidesPerView: 1,
	            spaceBetween: 20,
	            init: false,
	            loop: true,
	            watchSlidesVisibility: true,
	            navigation: {
	                nextEl: '.footer .swiper-next',
	                prevEl: '.footer .swiper-prev'
	            },
	            breakpoints: {
	                991: {
	                    slidesPerView: 5,

	                },
	                500: {
	                    slidesPerView: 2,
	                },
	            },
	            on: {
	            	init: function () {
	            	},
	            	slideChange: function () {
	            	}
	            }
	        })
		},
		methods: {
		    initSlider: function() {
				this.slider.init()
		    },
		    openContactModal: function() {
		    	if(this.isLoggedIn) {
		      		this.$bvModal.show(`contact_modal`)
		    	} else {
		    		location.href = "mailto:info@beauteboard.com?subject=";
		    	}
		  	},
		}
	}
</script>
