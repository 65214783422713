<template>
  <div class="routine-tracker overview-section">
    <b-row>
      <b-col 
        cols="12"
        md="6"
        >
        <h2 class="section-heading">
          Product Tracker
        </h2>

        <div class="form my-4">
          <div class="form-group">
            <label>
              products from your routines
            </label>
            <multiselect 
              v-model="productSelected" 
              :options="options" 
              :searchable="false" 
              :close-on-select="true" 
              :show-labels="false"
              open-direction="bottom"
              track-by="id"
              label="name" 
              placeholder="Pick a value"
              >  
            </multiselect>
          </div>

          <div class="form-group">
            <label>
              start date
            </label>
            <div class="input-wrapper date-wrapper">
              <input 
                type="date" 
                v-model="startDate"
                :min="today"
                class="form-control" 
                id="start-date"
                >
              <input 
                disabled 
                class="formated-date form-control" 
                :value="formatedStartDate" 
                />
            </div>
          </div>

          <div class="form-group">
            <label>
              end date
            </label>
            <div class="input-wrapper date-wrapper">
              <input 
                type="date" 
                v-model="endDate"
                :min="startDate" 
                class="form-control"
                >
              <input 
                disabled 
                class="formated-date form-control" 
                :value="formatedEndDate" 
                />
            </div>
          </div>

          <div class="form-group">
            <button
              class="btn btn-black w-100 mt-3"
              @click="addToTracker"
              >
              <span v-if="isLoadingForm">
                <b-spinner 
                  small 
                  type="grow" 
                  label="Spinning"
                  >    
                </b-spinner>
              </span>
              <span v-else>
                <i class="icon-add"><span class="path2"/></i>
                <span v-if="!Boolean(editingTracker)">
                  add to routine tracker
                </span>
                <span v-else>
                  edit tracker
                </span>
              </span>
            </button>
          </div>

        </div>
      </b-col>

      <b-col 
        cols="12"
        md="6"
        >
        <div class="calendar-wrapper">
          <custom-calendar-view
            :events="items"
            :show-date="showDate"
            :time-format-options="{ hour: 'numeric', minute: '2-digit' }"
            :enable-drag-drop="true"
            :disable-past="false"
            :disable-future="false"
            :show-event-times="true"
            :display-period-uom="'month'"
            :display-period-count="1"
            :starting-day-of-week="0"
            :class="themeClasses"
            :weekdayNameFormat="'narrow'"
            :period-changed-callback="periodChanged"
            :current-period-label="useTodayIcons ? 'icons' : ''"
            :hideCalendar="showList"
            @click-date="onClickDay"
            @click-event="onClickItem"
          >
            <custom-calendar-view-header
              slot="header"
              slot-scope="{ headerProps }"
              :header-props="headerProps"
              @input="setShowDate"
              @onChangeView="onChangeView"
            />

            <div 
              class="events-dots"
              slot="dayContent" 
              slot-scope="{ events }"
              >
              <span 
                class="event-start" 
                v-if="events.startEvents.length"
                />
              <span 
                class="event-end" 
                v-if="events.endEvents.length"
                />
            </div>

            <div slot="event">
              <div />
            </div>

          </custom-calendar-view>

          <events-list 
            v-if="showList" 
            :data="eventsList"
            ref="eventsList"
            :month-selected="monthSelected"
            @onChange="getProductsTracked"
            @onClickEdit="editTracker"
            />

        </div>

<!--         <div class="hint tracked">
          <span/>  tracked products
        </div> -->

        <div class="hints">
          <div class="hint start">
            <span/>  start date
          </div>

          <div class="hint end">
            <span/>  end date
          </div>
        </div>

      </b-col>

    </b-row>
  </div>
</template>

<script>

import APIClient from '@/api/requests'
import Spinner from '@/ui/Spinner.vue'
import CustomCalendarViewHeader from '@/ui/custom_calendar/CustomCalendarViewHeader.vue'
import CustomCalendarView from '@/ui/custom_calendar/CustomCalendarView.vue'
import EventsList from '@/components/dashboard/overview/EventsList.vue'
import Multiselect from 'vue-multiselect'
import { CalendarView, CalendarViewHeader, CalendarMathMixin } from "vue-simple-calendar"

export default {
  name: 'RoutineTracker',
  components: {
  	Spinner,
    Multiselect,
    CustomCalendarViewHeader,
    CustomCalendarView,
    EventsList
  },
  mixins: [CalendarMathMixin],
  data() {
    return {
      isLoading: false,
      isLoadingForm: false,
      options: [],
      productSelected: null,
      showList: false,
      moment: require('moment'),
      startDate: null,
      endDate: null,
      today: null,
      showDate: this.thisMonth(1),
      calendarEvents: [],
      useDefaultTheme: true,
      useTodayIcons: false,
      items: [],
      editingTracker: null,
      monthSelected: null,
      eventsList: []
    }
  },
  mounted() {
    this.today = this.startDate = this.endDate = this.moment().format('YYYY-MM-DD')
    this.isLoading = true
    APIClient.getProductsToTrack()
      .then(resp => {
        let products = []
        resp.data.forEach((item) => {
          products = products.concat(item.products)
        })
        this.options = products
      })
      .catch(e => {
        console.error(e)
      })
      .finally(() => {
        this.isLoading = false
      })
    this.getProductsTracked();
  },
  computed: {
    themeClasses() {
      return {
        "theme-default": this.useDefaultTheme
      }
    },
    formatedStartDate() {
      return this.moment(this.startDate).format('dddd, DD MMMM');
    },
    formatedEndDate() {
      return this.moment(this.endDate).format('dddd, DD MMMM');
    }
  },
  watch: {
        startDate: function(val, oldVal) {
          console.log(val)
          var threeMonthsLater = this.moment(val).add(3, 'M');
          this.endDate = threeMonthsLater
        },
  },
  methods: {
    thisMonth(d, h, m) {
      const t = new Date()
      return new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0)
    },
    onClickDay(d) {
      console.log(`You clicked: ${d.toLocaleDateString()}`)
    },
    onClickItem(e) {
      this.message = `You clicked: ${e.title}`
    },
    getProductsTracked() {
      APIClient.getProductsTracked()
        .then(resp => {
          this.updateCalendarEvents(resp.data)
        })
        .catch(e => {
          console.error(e)
        })
        .finally(() => {
          
        })
    },
    setShowDate(d) {
      this.showDate = d
    },
    updateCalendarEvents(events) {
      this.calendarEvents = events
      this.items = [];
      this.calendarEvents.forEach(e => {
        let event = {
          startDate: e.start_date,
          endDate: e.end_date,
          id: `e${Math.random().toString(36).substr(2, 10)}`
        }
        this.items.push(event)
      })

      let self = this
      setTimeout(() => {
        if(self.$refs.eventsList){
          self.$refs.eventsList.updateView(self.calendarEvents)
        }
      }, 100)

    },
    updateEventsList() {
      // Filter events of the current month only
      this.eventsList = this.calendarEvents.filter( event => {

        let monthStart = this.moment(event.start_date, 'YYYY-MM-DD').format('MM/YYYY')
        let monthEnd = this.moment(event.end_date, 'YYYY-MM-DD').format('MM/YYYY')

        if( this.monthSelected == monthStart || this.monthSelected == monthEnd ) {
          return event;
        }
      })

      this.$forceUpdate()

      // Update child component with lists of month
      let self = this
      setTimeout(() => {
        if(self.$refs.eventsList) self.$refs.eventsList.updateView()
      }, 100)
    },
    periodChanged(range, eventSource) {
      this.monthSelected = this.moment(range.periodStart).format('MM/YYYY')
      this.updateEventsList()
    },
    addToTracker() {

      if(this.isLoadingForm) return;

      if(!this.productSelected) {
        this.$bvToast.toast('Please, select a product.', {
          title: 'Error',
          autoHideDelay: 5000,
          toastClass: 'error',
          appendToast: true,
        })
        return
      }

      let data = {
        id: this.editingTracker,
        product_id: this.productSelected.product_id || this.productSelected.id,
        start_date: this.startDate,
        end_date: this.endDate,
        notes: '' // Not used now
      };

      this.isLoadingForm = true

      APIClient.saveTracker(data)
        .then( res => {
          this.$bvToast.toast(`Product saved`, {
            title: 'Success',
            autoHideDelay: 5000,
            toastClass: 'success',
            appendToast: true,
          })
          this.productSelected = null
          this.getProductsTracked();
        })
        .catch( err => {
          this.$bvToast.toast(err.response.data.message, {
            title: 'Error',
            autoHideDelay: 5000,
            toastClass: 'error',
            appendToast: true,
          })
        })
        .finally( () => {
          this.isLoadingForm = false
          this.editingTracker = null
        })
    },
    onChangeView(listView) {
      this.showList = listView
      this.updateEventsList()
    },
    editTracker(item) {
      this.editingTracker = item.id
      this.productSelected = item.products
      this.startDate = item.start_date
      this.endDate = item.end_date
    }
  },
}
</script>

