<template>
	<div class="moodboard-quote">

		<div class="container">
  			<div class="row">
				<div class="moodboard-quote__inner offset-md-4 col-md-8">
	  				<div class="moodboard-quote__quote">
						{{ text }}
					</div>
	  			</div>
	  		</div>
	  	</div>
	</div>
</template>

<script>
	export default {
		name: 'MoodboardImage',
		data () {
			return {

			}
		},
		components: {
			
		},
		props: {
			text: ''
		},
		mounted() {

		},
		methods: {

		}
	}
</script>
