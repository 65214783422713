<template>
    <b-modal 
      id="info_modal" 
      :title="info.title"
      @ok="confirms"
      :ok-title="okTitle"
      :ok-only="true"
      :ok-variant="'black'"
      centered
      >
      <div 
      	class="px-4" 
      	v-html="info.message" 
      	/>
    </b-modal>
</template>

<script>
export default {
  name: 'InfoModal',
  components: {},
  props: {
    info: {
    	type: Object,
    	default: null,
    },
    okTitle: {
      type: String,
      default: "ok",
    }
  },
  methods: {
    confirms: function() {
      this.$emit('onOkClicked')
    }
  },
  data () {
    return {
     
    }
  },
}
</script>
