var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
		'cv-wrapper',
		'locale-' + _vm.languageCode(_vm.displayLocale),
		'locale-' + _vm.displayLocale,
		'y' + _vm.periodStart.getFullYear(),
		'm' + _vm.paddedMonth(_vm.periodStart),
		'period-' + _vm.displayPeriodUom,
		'periodCount-' + _vm.displayPeriodCount,
		{
			past: _vm.isPastMonth(_vm.periodStart),
			future: _vm.isFutureMonth(_vm.periodStart),
			noIntl: !_vm.supportsIntl,
		} ]},[_vm._t("header",null,{"headerProps":_vm.headerProps}),_vm._v(" "),(!_vm.hideCalendar)?_c('div',{staticClass:"cv-header-days"},[_vm._l((_vm.weekdayNames),function(label,index){return [_vm._t("dayHeader",[_c('div',{key:_vm.getColumnDOWClass(index),staticClass:"cv-header-day",class:_vm.getColumnDOWClass(index)},[_vm._v("\n\t\t\t\t\t"+_vm._s(label)+"\n\t\t\t\t")])],{"index":_vm.getColumnDOWClass(index),"label":label})]})],2):_vm._e(),_vm._v(" "),(!_vm.hideCalendar)?_c('div',{staticClass:"cv-weeks"},_vm._l((_vm.weeksOfPeriod),function(weekStart,weekIndex){return _c('div',{key:(weekIndex + "-week"),class:[
				'cv-week',
				'week' + (weekIndex + 1),
				'ws' + _vm.isoYearMonthDay(weekStart) ]},[_vm._l((_vm.daysOfWeek(weekStart)),function(day,dayIndex){return _c('div',{key:_vm.getColumnDOWClass(dayIndex),class:[
					'cv-day',
					_vm.getColumnDOWClass(dayIndex),
					'd' + _vm.isoYearMonthDay(day),
					'd' + _vm.isoMonthDay(day),
					'd' + _vm.paddedDay(day),
					'instance' + _vm.instanceOfMonth(day),
					{
						today: _vm.isSameDate(day, _vm.today()),
						outsideOfMonth: !_vm.isSameMonth(day, _vm.defaultedShowDate),
						past: _vm.isInPast(day),
						future: _vm.isInFuture(day),
						last: _vm.isLastDayOfMonth(day),
						lastInstance: _vm.isLastInstanceOfMonth(day),
					} ].concat( ((_vm.dateClasses && _vm.dateClasses[_vm.isoYearMonthDay(day)]) || null) ),on:{"click":function($event){return _vm.onClickDay(day)},"drop":function($event){$event.preventDefault();return _vm.onDrop(day, $event)},"dragover":function($event){$event.preventDefault();return _vm.onDragOver(day)},"dragenter":function($event){$event.preventDefault();return _vm.onDragEnter(day, $event)},"dragleave":function($event){$event.preventDefault();return _vm.onDragLeave(day, $event)}}},[_c('div',{staticClass:"cv-day-number"},[_vm._v(_vm._s(day.getDate()))]),_vm._v(" "),_vm._t("dayContent",null,{"day":day,"events":_vm.getEventsOfDay(day)})],2)}),_vm._v(" "),_vm._l((_vm.getWeekItems(weekStart)),function(e){return [_vm._t("event",[_c('div',{key:e.id,staticClass:"cv-event",class:e.classes,style:(("top:" + (_vm.getItemTop(e)) + ";" + (e.originalEvent.style))),attrs:{"draggable":_vm.enableDragDrop,"title":e.title},domProps:{"innerHTML":_vm._s(_vm.getItemTitle(e))},on:{"dragstart":function($event){return _vm.onDragStart(e, $event)},"mouseenter":function($event){return _vm.onMouseEnterItem(e, $event)},"mouseleave":function($event){return _vm.onMouseLeaveItem(e, $event)},"click":function($event){$event.stopPropagation();return _vm.onClickItem(e, $event)}}})],{"event":e,"weekStartDate":weekStart,"top":_vm.getItemTop(e)})]})],2)}),0):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }