<template>
	<transition name="fade">
		<div class="">
			<simple-header/>
			<div class="privacy regular-page">
				<template v-if="data">
					<h1 class="regular-page__title">
						{{ data.name }}
					</h1>

					<div 
						class="regular-page__content" 
						v-html="data.content"
						>
					</div>
				</template>
				<template v-else>
					<div class="spinner-wrapper">
			          <spinner />
			        </div>
				</template>
			</div>

			<app-footer />
		</div>
	</transition>
</template>

<script>
	import APIClient from '@/api/requests';
	import Spinner from '@/ui/Spinner.vue';
	import SimpleHeader from '@/ui/SimpleHeader';
	import AppFooter from '@/components/AppFooter.vue'

	export default {
		name: 'Privacy',
		components: {
			SimpleHeader,
			AppFooter
		},
		data () {
			return {
				data: null,
				isLoading: true
			}
		},
		mounted() {
			APIClient.getPrivacy()
				.then( res => {
					this.data = res.data
				})
				.error( err => {
					console.error(err)
				})
				.finally( () => {
					this.isLoading = false
				})
		},

	}
</script>