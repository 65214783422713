<template>
  <div class="skin-journey-item">
    <div class="image-container">
      <div class="circle">
        <img :src="source.skin_options.image" />
      </div>
    </div>
    <div class="info-container">
      <div class="info">                         
          <span class="mb-0">
            {{ source.skin_options.option }}
          </span>
          <span class="date float-right">
            {{ moment(source.updated_at).format('MMM D') }}
          </span>
      </div>
      <div class="days-ago">
        {{ moment(source.updated_at).calendar(null, {
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd',
            lastDay: '[Yesterday]',
            lastWeek: 'DD/MM/YYYY',
            sameElse: 'DD/MM/YYYY'
          }) }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SkinJourneyItem',
  data() {
    return {
      moment: require('moment')
    }
  },
  props: {
    source: null
  },
  mounted () {

  },
  methods: {

  }
}
</script>

