<template>
  <b-col
    sm="12"
    class="product py-4 h-100"
  >
    <div class="product-content">

      <span v-if="removeIcon">
        <svg @click="showConfirm" svg-inline="" class="button-remove" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="9" height="12.456" viewBox="0 0 9 12.456"><g data-name="Grupo 386" fill="#c2c2c2"><path data-name="Trazado 483" d="M.926 11.021c0 .807.472 1.434 1.029 1.434h5.059c.579 0 1.029-.657 1.029-1.434V1.908H.926zm5.038-8.008c0-.179.086-.3.214-.3s.214.12.214.3v7.111c0 .179-.086.3-.214.3s-.214-.12-.214-.3zm-1.694 0c0-.179.086-.3.214-.3s.214.12.214.3v7.111c0 .179-.086.3-.214.3s-.214-.12-.214-.3zm-1.694 0c0-.179.086-.3.214-.3s.214.12.214.3v7.111c0 .179-.086.3-.214.3s-.214-.12-.214-.3z"/><path data-name="Trazado 484" d="M8.134 1.7H6.2V.923a.843.843 0 0 0-.706-.926H3.463a.843.843 0 0 0-.706.926V1.7H.843C.365 1.7 0 2.208 0 2.806V3.5c0 .149.114.3.228.3h8.544c.114 0 .228-.149.228-.3v-.687A1.016 1.016 0 0 0 8.134 1.7zm-4.9-.747a.3.3 0 0 1 .251-.329h2.028a.3.3 0 0 1 .251.329v.777H3.258V.953z"/></g></svg>
      </span>

      <div class="top">
        <a @click="$bvModal.show(`filter_${source.id}`)">
          <!-- <progressive-background class="image" :src="source.image" size="contain">
            <div slot="content" slot-scope="{ visible }">
              <div v-show="visible">
                <b-spinner type="grow" label="Spinning"></b-spinner>
              </div>
            </div>
          </progressive-background> -->

          <vue-load-image>
            <div 
              slot="image" 
              class="image"
              :style="`background-image: url(${source.image})`" 
              :data-src='source.image' 
              />
            <div 
              class="image-error" 
              slot="error"
              >
              <span>
                <!-- Couldn't load image -->
              </span>
            </div>

          </vue-load-image>

          <div class="product__more">
            view details
          </div>
          <!-- <vue-load-image>
            <img slot="image" :src="source.image"/>
            <div slot="error">error message</div>
          </vue-load-image> -->
           <div v-if="source.has_shopify_link == 'yes' ">
              <img
                src="@/assets/images/logos/b.png"
                class="product__shopify"
                />
            </div>
        </a>

        <div class="product__bottom">
          <div class="product__left my-3">
            <div class="brand" :class="{link: source.brand && source.brand.brand_link != '' }" @click="openBrand(source)">
              {{ source.brand }}
            </div>
            <div class="name">
              <a @click="$bvModal.show(`filter_${source.id}`)">
                {{ source.name }}
              </a>
            </div>
          </div>

          <div class="product__right price">
            {{ price }}
          </div>
        </div>
      </div>

      <!-- <b-container>
        <b-row>
          <b-col cols="4" md="4" class="px-0 pr-sm-3 price pt-2">
            
          </b-col>
          <b-col cols="8" md="8" class="pl-2 pr-sm-0 pr-0 pl-sm-0">
            <b-button 
              class="view-more d-none d-md-block float-right px-2 px-sm-3" 
              @click="$bvModal.show(`filter_${source.id}`)" 
              >
              View details
            </b-button>
          </b-col>
        </b-row>
      </b-container> -->
    </div>

    <confirmation-modal 
        :message="'Are you sure you want to delete this item?'" 
        :modal-id="`remove_item_${source.id}`"
        @onConfirm="onRemove"
        />

   <!--  <product-modal 
      :modalId="`filter_${source.id}`" 
      section="filter" 
      :source="source" 
      :sourceNavigation="this.sourceNavigation" 
      :sourceIndex="this.sourceIndex" 
      :addProductQueryToUrl="addToUrlOnModalOpen"
      />
 -->
    <new-product-modal
      :modalId="`filter_${source.id}`" 
      section="filter" 
      :source="source" 
      :sourceNavigation="this.sourceNavigation" 
      :sourceIndex="this.sourceIndex" 
      :addProductQueryToUrl="addToUrlOnModalOpen"
      />
  </b-col>
</template>

<script>
import ProductModal from '@/ui/ProductModal.vue'
import NewProductModal from '@/ui/NewProductModal.vue'
import ConfirmationModal from '@/ui/ConfirmationModal';
import VueLoadImage from 'vue-load-image'

export default {
  name: 'Product',
  components: {
    ProductModal,
    ConfirmationModal,
    NewProductModal,
    'vue-load-image': VueLoadImage
  },
  props: {
    source: {
      type: Object,
      default: function () { return {} }
    },
    sourceNavigation: {
      type: Array,
      default: function () { return [] }
    },
    sourceIndex: {
      type: Number,
      default: function () { return [] }
    },
    removeIcon: {
      type: Boolean,
      default: false
    },
    addToUrlOnModalOpen: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      modalShow: false
    }
  },
  computed: {
    price: function () {
        return `${this.source.price_symbol}${this.source.price}`
    }
  },
  methods: {
    onError (error) {
      console.error(Error)
    },
    onRemove: function() {
      this.$emit('onRemove')
    },
    showConfirm: function() {
      console.log(`remove_item_${this.source.id}`)
      this.$bvModal.show(`remove_item_${this.source.id}`)
    },
    openBrand: function(product) {
      if(product.brand_link != null && product.brand_link.length > 0) {
        this.$router.push(`/me/brand/${product.brand_link}`)
      }
    }
  },
  mounted () {

    // this.source.renameProperty('price_us', 'price');
  }
}
</script>
