import Vue from 'vue'
import App from './App.vue'
import Axios from 'axios'
import BootstrapVue from 'bootstrap-vue'
import router from './router'
import store from './store'
import VueMq from 'vue-mq'
import VueProgressiveImage from 'vue-progressive-image'
import wysiwyg from "vue-wysiwyg"
import VueAnalytics from 'vue-analytics'
import ToastMixin from './mixins/toastMixin.js';
// import $ from 'jquery';


let VueScrollTo = require('vue-scrollto');

Vue.config.productionTip = false
Vue.config.silent = true

Vue.use(BootstrapVue)

var os = require("os");
os.hostname();

// alert(os.hostname())
if(process.env.NODE_ENV == 'production' && !os.hostname().includes('staging') ) {
  console.log('tracking GA')
  Vue.use(VueAnalytics, {
    id: 'UA-131808137-1',
    router
  })
}

Vue.use(VueScrollTo, {
     container: "body",
     duration: 500,
     easing: "ease",
     offset: 0,
     force: true,
     cancelable: true,
     onStart: false,
     onDone: false,
     onCancel: false,
     x: false,
     y: true
})

Vue.use(wysiwyg, {
  hideModules: { 
    "image": true,
    "table": true,
    "code": true,
    "removeFormat": true,
    "separator": true
  }
});

Vue.use(VueProgressiveImage)

Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    mobile: 992,
    desktop: Infinity
  },
  defaultBreakpoint: 'desktop' // customize this for SSR
})

Vue.prototype.$http = Axios
// const token = localStorage.getItem('token')
// if (token) {
  // Vue.prototype.$http.defaults.headers.common['Authorization'] = `Bearer ${token}`
// }

Vue.mixin(ToastMixin);

let handleOutsideClick
Vue.directive('closable', {
  bind (el, binding, vnode) {
    // Here's the click/touchstart handler
    // (it is registered below)
    handleOutsideClick = (e) => {
      e.stopPropagation()
      // Get the handler method name and the exclude array
      // from the object used in v-closable
      const { handler, exclude } = binding.value

      // This variable indicates if the clicked element is excluded
      let clickedOnExcludedEl = false
      exclude.forEach(refName => {
        // We only run this code if we haven't detected
        // any excluded element yet
        if (!clickedOnExcludedEl) {
          // Get the element using the reference name
          if(!vnode.context.$refs[refName])
            return;
          const excludedEl = vnode.context.$refs[refName].$el
          // See if this excluded element
          // is the same element the user just clicked on
          clickedOnExcludedEl = excludedEl.contains(e.target)
        }
      })

      // We check to see if the clicked element is not
      // the dialog element and not excluded
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        // If the clicked element is outside the dialog
        // and not the button, then call the outside-click handler
        // from the same component this directive is used in
        vnode.context[handler]()
      }
    }
    // Register click/touchstart event listeners on the whole page
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  },

  unbind () {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('touchstart', handleOutsideClick)
  }
})

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
