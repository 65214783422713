<template>
  <div 
    class="nav-pagination"
    v-if="meta"
    >
    <a 
      @click="getPreviousPage" 
      :class="{ disabled: meta.currentPage <= 1 }"
      >
      <svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="5.54" height="10.777" viewBox="0 0 5.54 10.777"><path data-name="Trazado 201" d="M0 5.389l4.835 5.389.705-.705-4.23-4.684L5.54.705 4.835 0z"/></svg>
    </a>
    
    {{ meta.currentPage }}  /  {{ meta.totalPages }}
    
    <a 
      @click="getNextPage" 
      :class="{ disabled: meta.currentPage >= meta.totalPages }"
      >
      <svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="5.54" height="10.777" viewBox="0 0 5.54 10.777"><path data-name="Trazado 200" d="M5.538 5.389L.703 10.778l-.705-.705 4.23-4.684L-.002.705.703 0z"/></svg>
    </a>
  </div>
</template>

<script>

import { helperMixin } from '@/mixins/helperMixin.js'
export default {
  name: 'Pagination',
  components: {},
  mixins: [helperMixin],
  props: {
    meta: {
      type: Object,
      required: true,
      default: null
    }
  },
  methods: {
    getNextPage: function() {
      if(!this.hasNextPage(this.meta)) return
      this.$emit('onNext')
    },
    getPreviousPage: function() {
      if (!this.hasPreviousPage(this.meta)) return
      this.$emit('onPrevious')
    }
  }
}

</script>
