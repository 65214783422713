<template>
    <div class="home-menu-mobile" v-if="isMobile()">

            <div class="home-menu-mobile__inner">
                <router-link 
                    class="home-menu-mobile__login-button"
                    v-if="!isLoggedIn"
                    :to="{ name: 'login' }"
                    >
                    <i class="icon-user"></i>
                </router-link>

                <router-link 
                    class="home-menu-mobile__login-button"
                    v-if="isLoggedIn"
                    :to="{ name: 'account' }"
                    >
                    <i class="icon-user"></i>
                </router-link>

                <button 
                    class="home-menu-mobile__search-button"
                    @click="toggleSearch"
                    >
                    <i class="icon-search"></i>
                </button>  

                <div>
                    <a v-scroll-to="'#skin_header'">
                        Shop
                    </a>
                </div>
                <div v-if="!isLoggedIn">
                    <router-link 
                        class=""
                        :to="{ name: 'quiz' }"
                        >
                        Skin Quiz
                    </router-link>
                </div>
                <div>
                    <a v-scroll-to="'#routines'">
                        Routines
                    </a>
                </div>

                <!-- <div>
                    <a v-scroll-to="'#skin_header'">
                        skin
                    </a>
                </div>
                <div>
                    <a v-scroll-to="'#makeup_header'">
                        makeup
                    </a>
                </div>
                <div>
                    <a v-scroll-to="'#hair_header'">
                        hair
                    </a>
                </div> -->
            </div>

            

        <search-overlay ref="searchOverlay" />
    </div>
</template>

<script>
    import SearchOverlay from '@/components/simple/home/SearchOverlay.vue'
    import { helperMixin } from '@/mixins/helperMixin.js'

    export default {
      name: 'HomeMenuMobile',
      components: {
        SearchOverlay
      },
      mixins: [helperMixin],
      data() {
        return {

        }
      },
     computed: {
        isLoggedIn: function () {
          return this.$store.getters.isLoggedIn
        },
      },
      mounted () {
        var scrollPosition = window.scrollY;
        var logoContainer = document.getElementsByClassName('home-menu')[0];

        window.addEventListener('scroll', function() {
            scrollPosition = window.scrollY;
            if (scrollPosition >= 30) {
                logoContainer.classList.add('scrolled');
            } else {
                logoContainer.classList.remove('scrolled');
            }
        });
      },
      methods: {
        toggleSearch: function() {
            this.$refs.searchOverlay.toggle();
        }
      }
    }
</script>
