<template>
  <div class="beaute-curated">
    <div class="beaute-curated__inner">
      <div class="beaute-curated__left">
        <h2>
          beauty curated<br>for you
        </h2>
      </div>    

      <div class="beaute-curated__right">
        <div class="beaute-curated__copy">
          introducing our new hyper-curated skincare routines, for your skin type and concerns
        </div>
      </div>    

    </div>
  </div>
</template>

<script>
  import { helperMixin } from '@/mixins/helperMixin.js'
  import Swiper from '@/assets/scripts/vendor/swiper.esm.bundle';

  export default {
    name: 'BeauteCurated',
    components: {

    },
    mixins: [helperMixin],
    data() {
      return {
        slider: null
      }
    },
    mounted () {
      
    },
    methods: {

    }
  }
</script>
