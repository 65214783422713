<template>
  <div class="slider-boxes overview-section">
    <b-row>

      <b-col 
        cols="12" 
        md="4"
        class="mb-md-0 mb-4"
        >
        <slider-box 
          :title="'Top Rated'" 
          :data="topRated" 
          :empty-message="'Not rated products yet'" 
          :slug="'top-rated'" 
          />
      </b-col>

      <b-col 
        cols="12" 
        md="4"
        >
        <slider-box 
          :title="'Repurchased'" 
          :data="repurchased" 
          :empty-message="'No re-purchased products yet'" 
          :slug="'repurchased'" 
          />
      </b-col>

      <b-col 
        cols="12" 
        md="4"
        >
        <slider-box 
          :title="'break-ups'" 
          :data="breakUps" 
          :empty-message="'No break-ups yet'" 
          :slug="'breakups'" 
          />
      </b-col>

    </b-row>
  </div>
</template>

<script>

import APIClient from '@/api/requests'
import Spinner from '@/ui/Spinner.vue'
import SliderBox from '@/ui/SliderBox.vue'

export default {
  name: 'SliderBoxes',
  components: {
  	Spinner,
    SliderBox
  },
  data() {
    return {
      isLoading: false,
      topRated: null,
      breakUps: null,
      repurchased: null
    }
  },
  mounted() {
    this.isLoading = true
    APIClient.getTopRated(1)
      .then(resp => {
        console.log("top rated")
        console.log(resp.data)
        this.topRated = resp.data[0].product_details
      })
      .catch(e => {
        console.error(e)
      })
      .finally(() => {
        this.isLoading = false
      })

    APIClient.getBreakUps(1)
      .then(resp => {
        console.log(resp.data.data)
        if(resp.data.data.length) {
            this.breakUps = resp.data.data[0].products[0]
        }
        console.log(this.breakUps)

      })
      .catch(e => {
        console.error(e)
      })
      .finally(() => {
        this.isLoading = false
      })

    APIClient.getRepurchased(1)
      .then(resp => {
        this.repurchased = resp.data[0]
      })
      .catch(e => {
        console.error(e)
      })
      .finally(() => {
        this.isLoading = false
      })
  }
}
</script>

