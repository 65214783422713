<template>
  <div class="skin-journey-tab">
    <b-container>
    	<b-row>
    		<b-col 
          cols="12" 
          md="4"
          class="pr-md-4 left" 
          >
          <skin-journey-list @onSelected="updateInfo" />
    		</b-col>

        <b-col 
          cols="12" 
          md="8" 
          class="pl-md-4 right"
          >
          <skin-journey-notes :skin-journey="selectedSkinJourney" />
        </b-col>
    	</b-row>
    </b-container>
  </div>
</template>

<script>

import SkinJourneyList from '@/components/dashboard/skin-journey/SkinJourneyList.vue'
import SkinJourneyNotes from '@/components/dashboard/skin-journey/SkinJourneyNotes.vue'
import APIClient from '@/api/requests'

export default {
  name: 'SkinJourneyTab',
  components: {
  	SkinJourneyList,
    SkinJourneyNotes
  },
  data() {
    return {
      journeyItems: null,
      selectedSkinJourney: null,
      moment: null
    }
  },
  mounted () {

    this.moment = require('moment');

    var month = this.moment().format('M');
    var year = this.moment().format('YYYY');

    APIClient.getSkinJouney(month, year)
      .then( resp => {
          this.journeyItems = resp.data

          this.selectedSkinJourney = this.journeyItems[0]
        })
        .catch( err => {
          console.log(err)
        })
        .finally( () => {
          this.isLoadingSkinJourney = false;
        })
  },
  methods: {
    updateInfo: function(data) {
      this.selectedSkinJourney = data
    }
  }
}
</script>

