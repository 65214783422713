<template>
    <div class="brands-page" id="brands_page">
      <loader-full-screen v-if="isLoading"/>
      <section class="header">
        <simple-header />
      </section>
      <b-container
        fluid
        class="p-0"
        >
        <b-row no-gutters>
          <b-col sm="12">
            <section-header
              :title="'Routines'"
              :background-url="brandImage"
              id="skin_header"
              >
              Lorem ipsum
            </section-header>
          </b-col>
        </b-row>
      </b-container>

    </div>
</template>

<script>
import APIClient from '@/api/requests'
import SectionHeader from '@/ui/SectionHeader.vue'
import SimpleHeader from '@/ui/SimpleHeader'
import LoaderFullScreen from '@/ui/LoaderFullScreen.vue'
import { helperMixin } from '@/mixins/helperMixin.js'

export default {
  name: 'Routines',
  components: {
    SectionHeader,
    SimpleHeader,
    LoaderFullScreen
  },
  mixins: [helperMixin],
  data() {
    return {
      brandImage: '',
      isLoading: false,
    }
  },
  created() {

  },
  mounted () {
    this.brandImage = `my-dashboard-banner`
    this.$forceUpdate()
  },
  computed: {
    profile: function () {
      return this.$store.getters.profile
    }
  },
  methods: {
    
  }
}

</script>