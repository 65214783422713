<template>
  <b-row class="main-form">
	  <b-col cols="12" md="6">
	    <div class="container px-0 px-lg-2">
	      <b-row>
	        <!-- <b-col cols="12">
	          <h2>tell us about your experience</h2>
	        </b-col> -->
	        <b-col cols="12">
	          <label class="mr-3">my rating *</label>
	          <rating :customStyle="'dark'" :score="form.stars" :showScore="false" />
	        </b-col>

	        <b-col cols="12" class="radio-options my-2">
	          <span>
	            <input type="radio" name="ratingSelected" id="one" value="1" v-model="form.stars">
	            <label for="one" class="px-2 py-1 mr-1">nope</label>
	          </span>
	          <span>
	            <input type="radio" name="ratingSelected" id="two" value="2" v-model="form.stars">
	            <label for="two" class="px-2 py-1 mr-1">okay</label>
	          </span>
	          <span>
	            <input type="radio" name="ratingSelected" id="three" value="3" v-model="form.stars">
	            <label for="three" class="px-2 py-1 mr-1">good</label>
	          </span>
	          <span>
	            <input type="radio" name="ratingSelected" id="four" value="4" v-model="form.stars">
	            <label for="four" class="px-2 py-1 mr-1">great</label>
	          </span>
	          <span>
	            <input type="radio" name="ratingSelected" id="five" value="5" v-model="form.stars">
	            <label for="five" class="px-2 py-1 mr-1">superb</label>
	          </span>
	        </b-col>

	        <b-col cols="12">
	          <b-container class="px-0 px-lg-3">
	            <b-row>
	               <b-col cols="12" class="pl-3 pl-lg-0">
	                <label>
	                  add photo
	                </label>
	              </b-col>
	            </b-row>
	            <b-row>
	              <b-col cols="12" lg="8" class="pl-3 pl-lg-0 mb-4 mb-md-0">
	                <div class="photo-placeholder">
	                  <input type="file" ref="file" v-on:change="handleUpload()">
	                  <div class="content">
	                    <svg svg-inline="" class="mb-2" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="38.427" height="27.465" viewBox="0 0 38.427 27.465"><path data-name="Trazado 604" d="M17.131 23.299a8.114 8.114 0 1 0-8.094-8.133 8.108 8.108 0 0 0 8.094 8.133zm0-14.931a6.817 6.817 0 1 1-6.837 6.8 6.808 6.808 0 0 1 6.837-6.796z" fill="#d6d5d5"/><circle data-name="Elipse 327" cx="1.414" cy="1.414" r="1.414" transform="translate(28.683 6.68)" fill="#d6d5d5"/><path data-name="Trazado 605" d="M17.131 19.371a.725.725 0 0 0 .472-.2.631.631 0 0 0 .2-.432.621.621 0 0 0-.629-.629 2.9 2.9 0 0 1-2.908-2.908.682.682 0 0 0-.632-.664h-.079a.616.616 0 0 0-.55.668 4.134 4.134 0 0 0 4.126 4.165z" fill="#d6d5d5"/><path data-name="Trazado 606" d="M26.6 25.147H1.847a.537.537 0 0 1-.55-.55V5.737a.537.537 0 0 1 .55-.55h30.647a.537.537 0 0 1 .55.55v8.8H34.3v-8.8a1.828 1.828 0 0 0-1.807-1.807h-8.211L22.868.393a.6.6 0 0 0-.59-.393H11.905a.669.669 0 0 0-.511.393L9.98 3.929H1.807A1.828 1.828 0 0 0 0 5.737V24.6a1.828 1.828 0 0 0 1.807 1.8h24.754v-1.253zM12.416 1.3h9.469l1.061 2.672H11.355z" fill="#d6d5d5"/><path data-name="Trazado 607" d="M34.263 21.964v-4.126h-1.336v4.126h-4.126V23.3h4.126v4.165h1.336V23.3h4.165v-1.336z" fill="#d6d5d5"/></svg> 
	                    <div>
	                      drag and drop images or 
	                      <button class="btn mt-2">upload a file</button>
	                    </div>
	                  </div>
	                </div>
	              </b-col>
	              <b-col cols="12" lg="4" class="pl-3 pl-lg-0">
	              	<div class="image-preview" :style="{ backgroundImage: `url(${form.image})` }">
		                <span v-if="form.image">
		                  <i class="icon-close" @click="removeImage"><span class="path1"></span><span class="path2"></span></i>
		                </span>
		                <svg svg-inline="" v-if="!form.image" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="43.323" height="38.464" viewBox="0 0 43.323 38.464"><g data-name="Grupo 678" fill="#989898"><path data-name="Trazado 646" d="M.001 0v38.464h43.322V0zm42.741 37.884H.58V.584h42.163v37.3z"/><path data-name="Trazado 647" d="M40.135 2.849H2.814v28.775h37.321zm-.58.58v21.24l-10.8-13.108-.228.188-.23-.178-9.941 12.872-5.083-5.825-.219.191-.227-.18-9.433 11.873V3.433zm-26.49 15.833l10.279 11.781H3.705zm11.049 11.781l-5.388-6.175.021.017 9.787-12.67 11.021 13.37v5.458z"/><path data-name="Trazado 648" d="M10.295 12.619a3.578 3.578 0 1 0-3.578-3.578 3.582 3.582 0 0 0 3.578 3.578zm0-6.576a3 3 0 1 1-3 3 3 3 0 0 1 3-3z"/></g></svg>
		              </div>
	              </b-col>
	            </b-row>
	          </b-container>
	        </b-col>

	        <b-col cols="12" class="mt-4">
	          <label>
	            why do you love this product?
	          </label>
	        </b-col>

	        <b-col cols="12">
	          <div v-if="whyLovedOptions">
	            <multiselect
	                :options="whyLovedOptions"
	                :multiple="false"
	                :clear-on-select="true"
	                :preserve-search="false"
	                :taggable="false"
	                :preselect-first="false"
	                placeholder="select up to 5"
	                :allow-empty="false"
	                selectLabel=""
	                label="name"
	                @select="addWhyLovedSelected"
	                track-by="id"
	                class="mt-2"
	                open-direction="bottom"
	              />
	            </div>
	            <div>
	              <selected-filters :items="{selected: form.whyLovedSelectedAll }" />
	            </div>
	        </b-col>
	      </b-row>
	    </div>
	  </b-col>

	  <!-- Text Fields -->
	  <b-col cols="12" md="6">
	    <form @submit.prevent="submitReview">
	      <div class="form-group">
	        <label>review title *</label>
	        <input type="text" class="form-control" v-model="form.title" placeholder="example: great features!" required />
	      </div>
	      <div class="form-group">
	        <label>review *</label>
	        <textarea class="form-control" v-model="form.content" rows="6" required />
	      </div>
	      <div class="form-group">
	        <label>would you recommend this product to a friend?</label>
	        <div>
	          <b-col cols="12" class="radio-options yes-no my-2 px-0">
	            <span>
	              <input type="radio" name="recommend" id="yes" value="1" v-model="form.wouldRecommend">
	              <label for="yes" class="px-2 mr-1">yes</label>
	            </span>
	            <span>
	              <input type="radio" name="recommend" id="no" value="0" v-model="form.wouldRecommend">
	              <label for="no" class="px-2 mr-1">no</label>
	            </span>
	          </b-col>
	        </div>
	      </div>  

	      <div class="form-group" v-if="form.stars <= 2 && form.stars != null">
	      	<label>
	      		would you like to add this products to your break-ups list?”
	      	</label>
	      	<div>
	          <b-col cols="12" class="radio-options yes-no my-2 px-0">
	            <span>
	              <input type="radio" name="add_breakup" id="breakup_yes" value="1" v-model="form.addToBreakup">
	              <label for="breakup_yes" class="px-2 mr-1">yes</label>
	            </span>
	            <span>
	              <input type="radio" name="add_breakup" id="breakup_no" value="0" v-model="form.addToBreakup">
	              <label for="breakup_no" class="px-2 mr-1">no</label>
	            </span>
	          </b-col>
	        </div>
	      </div>

	      <button class="btn btn-submit float-right">
	        <span v-if="isSendingReview">
	          Sending...
	        </span>
	        <span v-else>
	        	<span v-if="!isEditing">
	          		post review
	          	</span>
	          	<span v-else>
	          		save review
	          	</span>
	        </span>
	      </button>

	    </form>
	  </b-col>
	</b-row>
</template>

<script>
import APIClient from '@/api/requests'
import Multiselect from 'vue-multiselect'
import SelectedFilters from '@/ui/SelectedFilters.vue'
import { helperMixin } from '@/mixins/helperMixin.js'

export default {
  name: 'ReviewForm',
  data() {
  	return {
  		isSendingReview: false,
  	}
  },
  props: {
  	form: null,
  	whyLovedOptions: null,
  	productId: null,
  	isEditing: false,
  	review: null
  },
  components: {
  	Multiselect,
  	SelectedFilters
  },
  mixins: [helperMixin],
  mounted () {

  },
  methods: {
  	submitReview() {
      if(this.isSendingReview) return;

      this.isSendingReview = true

      let args = {
        "user_id": this.profile.id,
        "product_id": this.productId,
        "stars": this.form.stars,
        "title": this.form.title,
        "content": this.form.content,
        "would_recommend": this.form.wouldRecommend,
      }

      if(this.form.stars <= 2 && this.form.addToBreakup == '1') {
      	this.addToBreakups()
      }

      if(this.form.whyLovedSelectedAll.length){
    	args.why_love = this.form.whyLovedSelectedAll.map( (el) => {
     		return el.id
    	})
      }

      if(this.form.image){
        args.image = this.form.image;
      }

      if(this.isEditing) {
      	args.id = this.review.review_id
      	APIClient.editReview(args)
	        .then( res => {
	          this.$bvToast.toast(`Your review has been saved`, {
	            title: 'Success',
	            autoHideDelay: 5000,
	            toastClass: 'success',
	            appendToast: true,
	          })
	          this.$emit('onAdded')
	        })
	        .catch( err => {
	          this.$bvToast.toast(`Sorry, something went wrong`, {
	            title: 'Error',
	            autoHideDelay: 5000,
	            toastClass: 'error',
	            appendToast: true,
	          })
	          console.error(err)
	        })
	        .finally( () => {
	          this.isSendingReview = false
	        })
      } else {
	      APIClient.createReview(args)
	        .then( res => {
	          this.$bvToast.toast(`Your review has been sent`, {
	            title: 'Success',
	            autoHideDelay: 5000,
	            toastClass: 'success',
	            appendToast: true,
	          })
	          this.showForm = false;
	          this.form = {
	            stars: null,
	            title: null,
	            content: null,
	            image: null
	          }
	          this.$emit('onAdded')
	        })
	        .catch( err => {
	          this.$bvToast.toast(`Sorry, something went wrong`, {
	            title: 'Error',
	            autoHideDelay: 5000,
	            toastClass: 'error',
	            appendToast: true,
	          })
	          console.error(err)
	        })
	        .finally( () => {
	          this.isSendingReview = false
	        })
	  }
    },
    addToBreakups() {
    	let data = {
    		product_id: this.productId
    	}
    	APIClient.saveToBreakup(data)
    		.then( resp => {
    			console.log(resp)
    		})
    		.catch(err => {
    			console.error(err)
    		})
    },
    handleUpload() {
      	this.file = this.$refs.file.files[0];
        const reader = new FileReader();
        let self = this
        reader.onload = function(){
          var dataURL = reader.result;
          self.form.image = dataURL
        };
        reader.readAsDataURL(this.file);
    },
    removeImage() {
      this.form.image = null
    },
    addWhyLovedSelected(option) {
      this.form.whyLovedSelectedAll.push(option)
    },
  },
  computed: {
    profile: function () {
      return this.$store.getters.profile
    }
  },
}
</script>
