<template>
  <div class="loader-fullscreen">
  	<spinner/>
  </div>
</template>

<script>
import Spinner from '@/ui/Spinner.vue'
export default {
  name: 'LoaderFullScreen',
  components: {
  	Spinner
  },
  props: {
    meta: {

    }
  },
}

</script>
