<template>
    <div 
        class="search-overlay container"
        :class="{ 'visible': isOpened }"
        >
        <button 
            class="close"
            @click="toggle()"
            >
            <template v-if="!isMobile()">
                <svg svg-inline="" :class="{ 'black': !searchTerm.length }" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="11.882" height="11.882" viewBox="0 0 11.882 11.882"><path data-name="Trazado 230" d="M5.958 6.472l5.424 5.408.5-.5-5.425-5.441.017-.017L11.882.514l-.5-.516-5.441 5.441-.017-.017L.517-.002.001.514l5.441 5.425-.017.017-5.423 5.423.516.5 5.425-5.425z" fill-rule="evenodd"/></svg>
            </template>
            <template v-else>
                <svg svg-inline="" v-if="!searchTerm.length" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="11.882" height="11.882" viewBox="0 0 11.882 11.882"><path data-name="Trazado 230" d="M5.958 6.472l5.424 5.408.5-.5-5.425-5.441.017-.017L11.882.514l-.5-.516-5.441 5.441-.017-.017L.517-.002.001.514l5.441 5.425-.017.017-5.423 5.423.516.5 5.425-5.425z" fill-rule="evenodd"/></svg>
                <svg svg-inline="" class="search-overlay__arrow" v-if="searchTerm.length" focusable="false" role="presentation" data-name="Grupo 98" xmlns="http://www.w3.org/2000/svg" width="39.922" height="31.148" viewBox="0 0 39.922 31.148"><path data-name="Trazado 153" d="M16.805.516a1.741 1.741 0 0 1 0 2.461L5.95 13.832h32.234a1.742 1.742 0 1 1 0 3.484H5.955L16.81 28.171a1.744 1.744 0 1 1-2.466 2.466L.516 16.809a1.6 1.6 0 0 1-.217-.268.733.733 0 0 1-.076-.132c-.026-.059-.09-.111-.09-.175l-.051-.174a.851.851 0 0 1-.043-.145 1.677 1.677 0 0 1 0-.681.988.988 0 0 1 .043-.149l.051-.175c.03-.06.09-.11.09-.17a.717.717 0 0 1 .076-.132 1.849 1.849 0 0 1 .217-.273L14.344.511a1.742 1.742 0 0 1 2.466 0z"/></svg>
            </template>
        </button>
       

        <b-row class="h-100">
            <b-col
                cols="12"
                md="3"
                class="p-4 p-md-5 h-100 position-relative recent-wrapper mt-4 mt-md-0"
                >

                <!-- Search Form -->
                <b-row>
                    <b-col cols="12">
                        <div class="search-form">
                            <input 
                                placeholder="what are you looking" 
                                type="text" 
                                id="search_input"
                                v-model="searchTerm" 
                                v-on:keyup="search"
                                :class="{ 'filled': searchTerm.length }"
                                />
                            <button 
                                @click="searchTerm = ''" 
                                v-if="searchTerm.length"
                                class="clear-search"
                                >
                                <svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="11.882" height="11.882" viewBox="0 0 11.882 11.882"><path data-name="Trazado 230" d="M5.958 6.472l5.424 5.408.5-.5-5.425-5.441.017-.017L11.882.514l-.5-.516-5.441 5.441-.017-.017L.517-.002.001.514l5.441 5.425-.017.017-5.423 5.423.516.5 5.425-5.425z" fill-rule="evenodd"/></svg>
                            </button>
                        </div>
                    </b-col>
                </b-row>

                <!-- Trending and Recent searches -->
                <b-row :class="{ 'd-none d-md-flex': searchTerm.length }">
                    <b-col cols="12">
                        <transition name="fade-faster">
                            <div>
                                <div class="trending-searches mt-4 py-2">
                                    <label>trending searches:</label>
                                    <div>
                                        <div 
                                            class="item mb-2" 
                                            v-for="item in trendingSearches" 
                                            @click="search(item.keyword, true)"
                                            >
                                            <span>
                                                <i class="icon-recent mr-3"></i> 
                                                {{ item.keyword }}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="recent-searches mb-4 py-2">
                                    <label>
                                        my recent searches:
                                    </label>
                                    <div>
                                        <div 
                                            class="item mb-2" 
                                            v-for="item in recentSearches"
                                            >
                                            <span @click="search(item.keyword, true)">
                                                <i class="icon-recent mr-3"></i> 
                                                {{ item.keyword }}
                                            </span>
                                            <a
                                                class="remove"
                                                @click="removeRecentSearch(item)"
                                                >
                                                <svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="11.882" height="11.882" viewBox="0 0 11.882 11.882"><path data-name="Trazado 230" d="M5.958 6.472l5.424 5.408.5-.5-5.425-5.441.017-.017L11.882.514l-.5-.516-5.441 5.441-.017-.017L.517-.002.001.514l5.441 5.425-.017.017-5.423 5.423.516.5 5.425-5.425z" fill-rule="evenodd"/></svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </b-col>
                </b-row>
            </b-col>

            <b-col
                cols="12"
                md="9"
                class="search-wrapper px-0 h-100"
                >
                <transition name="fade-faster">

                    <!-- Search Results -->
                    <search-results 
                        ref="searchResults"
                        :products="products" 
                        :brands="brands"
                        v-if="searchTerm.length" 
                        :is-loading="isLoading"
                        :term="searchTerm"
                        @onLoadMore="getNextPage"
                        :is-loading-products="isLoadingProducts"
                        :show-load-more="nextPageUrl"
                        />

                    <!-- Default View (no search) -->
                    <div 
                        class="h-100" 
                        v-if="!searchTerm.length"
                        >
                        <div class="container h-100">
                            <b-row class="h-100">
                                <router-link 
                                    :to="{ name: 'overview'}" 
                                    class="col-12 col-md-4 pb-0 block block-1"
                                    >
                                    <div class="block__top">
                                        <div class="block__number">
                                            N˚1
                                        </div>
                                        <svg svg-inline="" focusable="false" role="presentation" data-name="Grupo 98" xmlns="http://www.w3.org/2000/svg" width="39.922" height="31.148" viewBox="0 0 39.922 31.148"><path data-name="Trazado 153" d="M16.805.516a1.741 1.741 0 0 1 0 2.461L5.95 13.832h32.234a1.742 1.742 0 1 1 0 3.484H5.955L16.81 28.171a1.744 1.744 0 1 1-2.466 2.466L.516 16.809a1.6 1.6 0 0 1-.217-.268.733.733 0 0 1-.076-.132c-.026-.059-.09-.111-.09-.175l-.051-.174a.851.851 0 0 1-.043-.145 1.677 1.677 0 0 1 0-.681.988.988 0 0 1 .043-.149l.051-.175c.03-.06.09-.11.09-.17a.717.717 0 0 1 .076-.132 1.849 1.849 0 0 1 .217-.273L14.344.511a1.742 1.742 0 0 1 2.466 0z"/></svg>
                                    </div>

                                    <div class="block__thumb">
                                    </div>
                                    <div class="block__bottom">
                                        <h2>
                                            my dashboard
                                        </h2>
                                        <label>
                                            skin overview and preferences
                                        </label>
                                    </div>
                                </router-link>
                                <router-link 
                                    :to="{ name: 'account'}" 
                                    class="col-12 col-md-4 pb-0 block block-2"
                                    >

                                    <div class="block__top">
                                        <div class="block__number">
                                            N˚2
                                        </div>
                                        <svg svg-inline="" focusable="false" role="presentation" data-name="Grupo 98" xmlns="http://www.w3.org/2000/svg" width="39.922" height="31.148" viewBox="0 0 39.922 31.148"><path data-name="Trazado 153" d="M16.805.516a1.741 1.741 0 0 1 0 2.461L5.95 13.832h32.234a1.742 1.742 0 1 1 0 3.484H5.955L16.81 28.171a1.744 1.744 0 1 1-2.466 2.466L.516 16.809a1.6 1.6 0 0 1-.217-.268.733.733 0 0 1-.076-.132c-.026-.059-.09-.111-.09-.175l-.051-.174a.851.851 0 0 1-.043-.145 1.677 1.677 0 0 1 0-.681.988.988 0 0 1 .043-.149l.051-.175c.03-.06.09-.11.09-.17a.717.717 0 0 1 .076-.132 1.849 1.849 0 0 1 .217-.273L14.344.511a1.742 1.742 0 0 1 2.466 0z"/></svg>
                                    </div>

                                    <div class="block__thumb">
                                    </div>

                                    <div class="block__bottom">
                                        <h2>
                                            my account
                                        </h2>
                                        <label>
                                            edit profile details
                                        </label>
                                    </div>
                                </router-link>
                                <router-link 
                                    :to="{ name: 'wishlists'}" 
                                    class="col-12 col-md-4 pb-0 block block-3"
                                    >
                                    <div class="block__top">
                                        <div class="block__number">
                                            N˚3
                                        </div>
                                        <svg svg-inline="" focusable="false" role="presentation" data-name="Grupo 98" xmlns="http://www.w3.org/2000/svg" width="39.922" height="31.148" viewBox="0 0 39.922 31.148"><path data-name="Trazado 153" d="M16.805.516a1.741 1.741 0 0 1 0 2.461L5.95 13.832h32.234a1.742 1.742 0 1 1 0 3.484H5.955L16.81 28.171a1.744 1.744 0 1 1-2.466 2.466L.516 16.809a1.6 1.6 0 0 1-.217-.268.733.733 0 0 1-.076-.132c-.026-.059-.09-.111-.09-.175l-.051-.174a.851.851 0 0 1-.043-.145 1.677 1.677 0 0 1 0-.681.988.988 0 0 1 .043-.149l.051-.175c.03-.06.09-.11.09-.17a.717.717 0 0 1 .076-.132 1.849 1.849 0 0 1 .217-.273L14.344.511a1.742 1.742 0 0 1 2.466 0z"/></svg>
                                    </div>

                                    <div class="block__thumb">
                                    </div>

                                    <div class="block__bottom">
                                        <h2>
                                            my collections
                                        </h2>
                                        <label>
                                        {{ wishlistsCount }} collections
                                        </label>
                                    </div>
                                </router-link>
                            </b-row>
                        </div>
                    </div>
                </transition>
            </b-col>
        </b-row>
    </div>
</template>

<script>

    import APIClient from '@/api/requests'
    import SearchResults from '@/components/dashboard/search/SearchResults.vue'
    import { helperMixin } from '@/mixins/helperMixin.js'

    export default {
        name: 'SearchOverlay',
        components: {
            SearchResults
        },
        props: {

        },
        data() {
            return {
                isLoading: false,
                products: [],
                brands: [],
                inputTimer: null,
                searchTerm: '',
                trendingSearches : [],
                recentSearches : [],
                nextPageUrl: null,
                isLoadingProducts: false,
                previousSearchTerm: ''
            }
        },
        mixins: [helperMixin],
        computed: {
            wishlistsCount: function () {
                return this.$store.getters.wishlistsCount
            }
        },
        methods: {
            toggle () {
                this.isOpened = !this.isOpened

            // Focus input on search open
            if(this.isOpened) {
                setTimeout(() => {
                    document.getElementById('search_input').focus()          
                }, 100)
            }
            this.$forceUpdate()
                if(this.isOpened) this.getUserData()
            },
            removeRecentSearch: function(term) {
                this.recentSearches = this.recentSearches.filter( item => {
                    return item.keyword != term.keyword
                })
                let data = {
                    "keyword": term.keyword
                }
                APIClient.removeRecentSearch(data)
                    .then( res => {
                        console.log(res)
                    })
                    .catch( err => {
                        console.error(err)
                    })
                    .finally( () => {

                    })
            },
            search: function (term = null, forceSearch = false) {

                if(this.previousSearchTerm == this.searchTerm && !forceSearch) {
                    return
                }

                if(term && (typeof term) == "string") this.searchTerm = term

                this.previousSearchTerm = this.searchTerm

                clearTimeout(this.inputTimer);

                if(!this.searchTerm.length){
                    this.products = []
                    this.isLoading = false;
                    return;
                }

                let self = this;      
                this.isLoading = true;
                this.inputTimer = setTimeout(function(){
                    self.products = []
                    self.brands = []

                    // Search Products
                    APIClient.searchProducts({ params: { s: self.searchTerm } } )
                        .then( resp => {
                            self.nextPageUrl = resp.data.next_page_url
                            self.products = resp.data.data
                            self.products = self.products.map( product => {
                                product.categories = product.categories.map( category => { return category.category_name })
                                product.main_category = product.categories[0]
                                return product
                            })
                        })
                        .catch( err => {
                            console.log(err)
                        })
                        .finally( () => {
                            self.isLoading = false;
                        })

                    // Search Brands
                    APIClient.searchBrands({ params: { s: self.searchTerm } } )
                        .then( resp => {
                            console.log("brands")
                            console.log(resp.data)
                            self.brands = resp.data.data
                        })
                        .catch( err => {
                            console.log(err)
                        })
                        .finally( () => {
                            self.isLoading = false;
                        })
                }, 1000)
            },
            getUserData () {
                APIClient.getUserSearchData()
                    .then(resp => {
                        this.trendingSearches = resp.data.trending.map( item => {
                            item.keyword = decodeURIComponent(item.keyword)
                            return item;
                        });
                        this.recentSearches = resp.data.recent
                        this.recentSearches = []
                        var self = this;
                        Object.keys(resp.data.recent).map(function(key) {
                            self.recentSearches.push(resp.data.recent[key])
                        });
                        this.recentSearches = this.recentSearches.map( item => {
                            item.keyword = decodeURIComponent(item.keyword)
                            return item;
                        });
                    })
                    .catch(err => {
                        console.log(err)
                    })   
            },
            getNextPage: function () {

                if (!this.nextPageUrl) return;

                this.isLoadingProducts = true;
                console.log(this.nextPageUrl)
                APIClient.searchProductByPage(this.nextPageUrl)
                    .then( resp => {
                        console.log(resp.data.data)
                        this.products = this.products.concat(resp.data.data)

                        this.products = this.products.map( product => {
                            product.categories = product.categories.map( category => { return category.category_name })
                            product.main_category = product.categories[0]
                            return product
                        })

                        this.nextPageUrl = resp.data.next_page_url
                    })
                    .catch( err => {
                        console.error(err)
                    })
                    .finally( () => {
                        this.isLoadingProducts = false;
                    })
            },
        }
    }
</script>
