import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'
import APIClient from '../api/requests'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: JSON.parse(localStorage.getItem('user')) || {},
    wishlistsCount: localStorage.getItem('wishlists_count') || '',
  },
  mutations: {
    auth_request (state) {
      state.status = 'loading'
    },
    auth_success (state, token) {
      state.status = 'success'
      state.token = token
    },
    auth_error (state) {
      state.status = 'error'
    },
    signup_request (state) {
      state.status = 'loading'
    },
    signup_error (state) {
      state.status = 'error'
    },
    signup_success (state) {
      state.status = 'success'
    },
    logout (state) {
      state.status = ''
      state.token = ''
      state.user = {}
    },
    get_profile (state) {
      state.status = 'loading'
    },
    get_profile_success (state, user) {
      state.status = 'success'
      state.user = user
    },
    get_profile_error (state) {
      state.status = 'error'
    },
    get_wishlists_count (state) {
      state.status = 'loading'
    },
    get_wishlists_count_success (state, wishlistsCount) {
      state.status = 'success'
      state.wishlistsCount = wishlistsCount
    },
    get_wishlists_count_error (state) {
      state.status = 'error'
    }
  },
  actions: {
    login ({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        APIClient.login(user)
          .then(resp => {
            const token = resp.data.access_token
            // console.log(resp)
            // console.log(token)
            localStorage.setItem('token', token)
            // Axios.defaults.headers.common['Authorization'] = token
            commit('auth_success', token)
            resolve(resp)
          })
          .catch(err => {
            commit('auth_error')
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },
    register ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('signup_request')
        APIClient.registerUser(data)
          .then(resp => {
            const token = resp.data.access_token
            localStorage.setItem('token', token)
            // Axios.defaults.headers.common['Authorization'] = token
            commit('auth_success', token)
            resolve(resp)
          })
          .catch(err => {
            commit('auth_error')
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },
    logout ({ commit }) {
      return new Promise(resolve => {
        commit('logout')
        localStorage.clear('')
        delete Axios.defaults.headers.common['Authorization']
        resolve()
      })
    },
    getProfile ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('get_profile')
        APIClient.getProfile()
          .then(resp => {
            // console.log(resp)
            commit('get_profile_success', resp.data)
            localStorage.setItem('user', JSON.stringify(resp.data))
            // console.log(localStorage.getItem('user'))
            resolve(resp)
          })
          .catch(err => {
            commit('get_profile_error')
            reject(err)
          })
      })
    },
    getWishlistsCount ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('get_wishlists_count')
        APIClient.getWishlists()
          .then(resp => {
            commit('get_wishlists_count_success', resp.data.length)
            localStorage.setItem('wishlists_count', JSON.stringify(resp.data.length))
            // console.log(localStorage.getItem('user'))
            resolve(resp)
          })
          .catch(err => {
            commit('get_wishlists_count_error')
            reject(err)
          })
      })
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    profile: state => state.user,
    wishlistsCount: state => state.wishlistsCount
  }
})
