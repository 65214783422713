<template>
 	<div class="moodboard-banner">

 		<div class="moodboard-banner__signature">
  			<img :src="signature">
  		</div>	  

	  	<div class="moodboard-banner__fixed">
		  	<div class="moodboard-banner__left">
	  			<div class="moodboard-banner__top">

		  			<h1 
		  				class="moodboard-banner__title" 
		  				v-html="title"
		  				>
		  			</h1>
		  		</div>

		  		<div class="moodboard-banner__bottom">
		  			<!-- <h2 class="moodboard-banner__subtitle">
		  				{{ subtitle }}
		  			</h2> -->
		  			<div class="moodboard-banner__subtitle" v-html="subtitle">
		  			</div>
		  			<div class="moodboard-banner__text">
		  				{{ text }}
		  			</div>
		  		</div>
		  	</div>

		  	<div 
		  		class="moodboard-banner__right"
		  		:style="{ backgroundImage: `url(${mainImage})` }"
		  		>
		  	</div>
		</div>

		<div class="moodboard-banner__overlay">
			<div class="moodboard-banner__secondary-image-wrapper">
		  		<img 
		  			class="moodboard-banner__secondary-image" 
		  			:src="secondaryImage"
		  			/>
		  	</div>
	  	</div>
	</div>
</template>

<script>

	export default {
	  	name: 'MoodboardBanner',
		data () {
		    return {

		    }
		},
		components: {
			
		},
		props: {	
			title: '',
			subtitle: '',
			text: '',
			mainImage: '',
			secondaryImage: '',
			signature: '',
   	},
	  mounted() {
		  	
		},
		methods: {
		    
		}
	}
</script>
