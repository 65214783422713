<template>
    <div class="profile" id="profile_section">
        <div class="container">
            <b-row 
                align-h="between" 
                class="py-2 profile__menu align-items-center"
                no-gutters
                >
                <b-col cols="auto">
                    <div class="d-flex align-items-center">
                        <router-link 
                            :to="{ name: 'account'}"
                            class="d-flex align-items-center"
                            >
                            <div 
                                class="profile__thumb mr-3" 
                                :style="{ backgroundImage: `url(${profile.avatar})` }"
                                >
                            </div>
                            <div class="profile__name">
                                {{ profile.name }}
                            </div>
                        </router-link>
                    </div>
                </b-col>

                <b-col 
                    cols="auto" 
                    class="d-flex align-items-center profile__items"
                    v-if="preferences && feature"
                    >
                    <template v-if="Array.isArray(feature)">
                        <template v-for="(item, index) in feature">
                            <div 
                                class="profile__item" 
                                v-for="value in preferences[item].slice(0, 2)"
                                >
                                {{value.name}}
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div 
                            class="profile__item" 
                            v-for="value in preferences[feature].slice(0, 2)"
                            >
                            {{value.name}}
                        </div>
                    </template>
                    <div 
                        class="profile__item profile__item--more"
                        @click="toggleProfile"
                        >
                        <svg svg-inline="" focusable="false" role="presentation" data-name="Componente 21 – 10" xmlns="http://www.w3.org/2000/svg" width="22" height="4" viewBox="0 0 22 4"><circle data-name="Elipse 398" cx="2" cy="2" r="2" fill="#e6e6e6"/><circle data-name="Elipse 399" cx="2" cy="2" r="2" transform="translate(9)" fill="#e6e6e6"/><circle data-name="Elipse 400" cx="2" cy="2" r="2" transform="translate(18)" fill="#e6e6e6"/></svg>
                    </div>
                </b-col>

                <b-col 
                    cols="12" 
                    class="d-sm-none mt-4"
                    >
                    <div class="routines__switch d-sm-flex justify-content-center">
                            <div class="can-toggle can-toggle--size-small">

                                <input 
                                    id="b" 
                                    v-model="isShelf" 
                                    type="checkbox"
                                    @change="updateMyShelf"
                                    :disabled="isLoadingRoutines"
                                    >
                                <label for="b">
                                    <div 
                                        class="can-toggle__switch" 
                                        data-checked="my shelf" 
                                        data-unchecked="recommendations"
                                        >        
                                    </div>
                                </label>
                            </div>
                    </div>
                </b-col>
            </b-row>

            <div class="position-relative profile__dropdown-wrapper">
                <div 
                    class="profile__dropdown"
                    id="profile_dropdown"
                    v-if="preferences"
                    v-show="showProfile"
                    >
                    <div class="profile__dropdown-header">
                        my beauty profile

                        <div class="profile__dropdown-skin" :style="{ backgroundColor: preferences.skincolor[0].color_code }">
                        </div>
                    </div>
                    <div class="profile__dropdown-body">
                        <table class="table table-borderless">
                            <tbody>
                                <tr>
                                    <th scope="row">skin concerns</th>
                                    <td>
                                        {{ preferences['skinconcerns'].map( (el) => el.name ).join(' / ') }}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">hair type</th>
                                    <td>
                                        {{ preferences['hairtypes'].map( (el) => el.name ).join(' / ') }}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">hair concerns</th>
                                    <td>
                                        {{ preferences['hairconcerns'].map( (el) => el.name ).join(' / ') }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="float-right">
                            <router-link 
                                :to="{ name: 'account', params: { allowEdit: true }}" 
                                class="btn profile__edit"
                                >
                                edit <i class="icon-edit"></i>
                            </router-link>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <location-modal></location-modal>

    </div>
</template>

<script>
    import APIClient from '@/api/requests'
    import Spinner from '@/ui/Spinner'
    import LocationModal from '@/ui/LocationModal'
    import EventBus from '@/event-bus'

    export default {
        name: 'Profile',
        components: {
            Spinner,
            LocationModal
        },
        data () {
            return {
                preferences: null,
                file: '',
                isLoading: false,
                showProfile: false,
                isShelf: false,
                isLoadingRoutines: false
            }
        },
        props: {
            feature: null
        },
        computed: {
            profile: function () {
                return this.$store.getters.profile
            },
        },
        created() {
            window.addEventListener('click', this.closeDropdown)
            EventBus.$on('got-routine-products', this.updateRecommendationsOption)
            EventBus.$on('loading-routines-status', this.updateButtonStatus)
        },
        beforeDestroy() {
            window.removeEventListener('click', this.updateRecommendationsOption)
            EventBus.$off('got-routine-products')
            EventBus.$off('loading-routines-status')
        },
        mounted () {
            APIClient.getFullProfile()
                .then(resp => {
                    this.preferences = resp.data

                    this.$store.dispatch('getProfile').then(() => { 
                        this.isLoading = false;
                    })

                    if(this.preferences.location == null) {
                        this.$bvModal.show('location_modal')
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        },
        methods: {
            updateButtonStatus: function(value) {
                this.isLoadingRoutines = value
            },
            updateRecommendationsOption: function(hasProductsInShelf) {
                this.isShelf = hasProductsInShelf;
            },
            toggleProfile: function() {
                this.showProfile = !this.showProfile;
                this.updateMenuHeight();
            },
            updateMenuHeight: function() {
                const menuHeight = document.getElementById('routine_menu_items').clientHeight + 1
                document.getElementById('profile_dropdown').style.minHeight = menuHeight + "px";
            },
            closeDropdown: function(e) {
                if(!this.$el.contains(e.target)) {
                    this.showProfile = false;
                }
            },
            handleUpload: function() {
                this.file = this.$refs.file.files[0];

                if(this.file.size * 0.00000095367432 > 3) {
                    this.showToast(
                        "Sorry, this image exceeds the limit of 3MB.", 
                        'error', 
                        'Error'
                        );
                    return
                }

                const reader = new FileReader();
                this.isLoading = true;
                let self = this
                reader.onload = function(){
                    var dataURL = reader.result;
                    APIClient.changeProfileImage({image: dataURL})
                        .then(resp => {
                            self.$store.dispatch('getProfile').then(() => {})
                        })
                        .catch(err => {
                        self.showToast(
                            "Please, try again.", 
                            'error', 
                            "Something went wrong"
                            );
                        })
                        .finally( () => {
                            self.isLoading = false;
                        })
                };
                reader.readAsDataURL(this.file);
            },
            editPhoto: function () {
                if(this.isLoading) return
                this.$refs.file.click()
            },
            updateMyShelf: function() {
                if(this.isLoadingRoutines){
                    return
                }
                EventBus.$emit('my-shelf-changed', this.isShelf);
            }
        }
    }
</script>
