<template>
    <div class="wishlists pb-5">
      <section v-show="!isLoading" class="header">
         <simple-header />
        <div class="top">
        </div>
      </section>

      <section class="mt-5 container">
        <b-row>

          <b-col
            cols="12" 
            >
            <b-row>
              <b-col 
                cols="12" 
                md="3" 
                class="mb-5 px-4"
                v-for="item in wishlists"
                >
                    <wishlist-item
                      :wishlist="item"
                      />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </section>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import APIClient from '@/api/requests'
import SimpleHeader from '@/ui/SimpleHeader'
import WishlistItem from '@/ui/WishlistItem'
// import WishlistsSlider from '@/components/dashboard/overview/WishlistsSlider'
import { helperMixin } from '@/mixins/helperMixin.js'

export default {
  name: 'Wishlists',
  components: {
    SimpleHeader,
    WishlistItem
  },
  mixins: [helperMixin],
  data () {
    return {
      wishlists: [],
      product: null,
      isLoading: true
    }
  },
  mounted () {
    APIClient.getWishlists()
      .then(res => {

        this.wishlists = res.data.map( wishlist => {
          wishlist.image = this.getWishlistCover(wishlist.image)
          return wishlist;
        })

        this.redirectToFirstWishlist(this.wishlists[0].id);

      })
      .catch(err => {
        console.error(err)
      })  
  },
  methods: {
    redirectToFirstWishlist: function(id) {
      this.$router.push(`/me/collections/${id}`)
    }
  }
}
</script>
