<template>
  <div class="section-header">
    <!-- <div class="py-5">
      <div class="container">
        <b-row class="mb-3 section-header__inner" :class="{'justify-content-end': position == 'right', 'justify-content-start': position == 'left' }">
          <b-col :class="{ 'col-sm-8': position != 'right', 'col-sm-4 d-sm-flex flex-column align-items-end pr-sm-5': position == 'right' }">
            <div class="text-center text-sm-left">
              <h2 class="text-center text-lg-left">{{ title }}</h2> 
              <div class="section-header__total">
                {{total}} products 
                <template v-if="curatedMessage">
                  {{ curatedMessage }}
                </template>
              </div>
              <div class="section-header__copy">
                <slot v-if="!isMobile()" />
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="p-5 text-center mobile" v-if="isMobile()">
      <div class="section-header__copy">
        <slot />
      </div>
    </div> -->
    <div class="section-header__inner">
      <h2 class="section-header__title">
        {{ title }}
      </h2>
      <div class="section-header__content">
        <div class="section-header__text">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { helperMixin } from '@/mixins/helperMixin.js'
import EventBus from '@/event-bus'

export default {
  name: 'SectionHeader',
  components: {},
  props: {
    title: String,
    decription: String,
    background: String,
    backgroundUrl: {
      type: String,
      default: null
    },
    curatedMessage: {
      type: String,
      default: null
    },
    position: {
      type: String,
      default: 'left'
    },
    total: {
      type: String,
      default: null
    },
  },
  mixins: [helperMixin],
  mounted () {
    // this.background = `url(${require('@/assets/images/backgrounds/' + this.background  + '.jpg')})`
  },
  methods: {
    // updateTotalProducts: function(total) {
    //   console.log(total)
    //   this.totalProducts = total
    // }
  },
  computed: {
    // backgroundProperty: function() {
    //   if(this.backgroundUrl) {
    //     if(this.validURL(this.backgroundUrl)) {
    //       return `url(${this.backgroundUrl})`
    //     }

    //     var url = require('@/assets/images/backgrounds/' + this.backgroundUrl + '.jpg');
    //     return `url(${url})`
    //   } else {
    //     return `url(${require('@/assets/images/backgrounds/skin.jpg')})`
    //   }
    // }
  }

}
</script>
