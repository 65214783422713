<template>
  <b-row class="recommendations h-100">
    <b-col
      class="ampm"
      sm="12"
      :style="{ backgroundImage: background }"
    >
      <b-row
        align-h="end"
        class=""
      >
        <b-col sm="6">
          <div class="am mt-5">
            <label>A.M.</label>
            <ul>
              <li>cleanser</li>
              <li>toner</li>
              <li>sunscreen</li>
            </ul>
          </div>
          <div class="pm mt-4">
            <label>P.M.</label>
            <ul>
              <li>cleanser</li>
              <li>toner</li>
              <li>sunscreen</li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'Recommendations',
  components: {},
  data () {
    return {
      background: `url(${require('@/assets/images/backgrounds/articles.jpg')})`
    }
  }
}
</script>

<style></style>
