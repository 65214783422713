<template>
	<div class="page-about">
		<div class="swiper-container">
			<div class="swiper-wrapper">
				<div class="swiper-slide">
					<about-slide-first />
				</div>
				<div class="swiper-slide">Slide 2</div>
				<div class="swiper-slide">Slide 3</div>
			</div>
		</div>
	</div>
</template>

<script>

import Swiper from '@/assets/scripts/vendor/swiper.esm.bundle'
import AboutSlideFirst from '@/components/simple/about/AboutSlideFirst.vue'

export default {
  name: 'About',
  data() {
  	return {
  		slider: null
  	}
  },
  components: {
  	Swiper,
  	AboutSlideFirst
  },
  mounted () {
  	this.slider = new Swiper(`.page-about .swiper-container`, {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: false,
        breakpoints: {
          
        }
    })
  }
}
</script>
