<template>
    <div class="home-moodboard">
        <div class="home-moodboard__inner">
            <h2 class="home-moodboard__title">
                CURATED SETS
            </h2>

            <!-- Pagination -->
            <!-- <div class="home-moodboard__pagnation"></div> -->

            <!-- Slider -->
            <div class="home-moodboard__slider-wrapper">
                <div class="home-moodboard__slider swiper-container">
                    <div class="swiper-wrapper">
                        <div class="home-moodboard__slide swiper-slide" v-for="slide in items">
                            <a 
                                :href="slide.link"
                                >
                                <div 
                                    class="home-moodboard__slide-image"
                                    :style="{ backgroundImage: `url(${slide.image || slide.filename})` }" 
                                    >
                                </div>

                                <div class="home-moodboard__slide-title">
                                    {{ slide.title }}
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <!-- Arrows -->
                <div class="swiper-prev home-moodboard__slide-arrow">
                    <svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="14.774" height="7.277" viewBox="0 0 14.774 7.277"><g data-name="Grupo 145"><path data-name="Trazado 105" d="M0 3.639L3.582.101a.331.331 0 0 1 .468.467L1.29 3.305h13.151a.334.334 0 0 1 0 .668H1.29L4.05 6.71a.331.331 0 1 1-.468.467z" fill="#fff"/></g></svg>
                </div>
                <div class="swiper-next home-moodboard__slide-arrow">
                    <svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="14.775" height="7.277" viewBox="0 0 14.775 7.277"><g data-name="Grupo 144"><path data-name="Trazado 104" d="M14.776 3.639L11.193.101a.33.33 0 0 0-.467.467l2.759 2.737H.334a.334.334 0 1 0 0 .668h13.151L10.726 6.71a.33.33 0 0 0 .467.467z" fill="#fff"/></g></svg>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import APIClient from '@/api/requests';
    import Swiper from '@/assets/scripts/vendor/swiper.esm.bundle';

    export default {
      name: 'HomeMoodboard',
      components: {},
      data() {
        return {
            items: [],
            isLoading: false,
        }
      },
      mounted() {
        this.slider = new Swiper(`.home-moodboard__slider`, {
            // slidesPerView: 1,
            spaceBetween: 80,
            init: false,
            loop: true,
            freeMode: true,
            speed: 1000,
            slidesPerView: 'auto',
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
              },
            watchSlidesVisibility: true,
            navigation: {
                nextEl: '.home-moodboard .swiper-next',
                prevEl: '.home-moodboard .swiper-prev'
            },
            pagination: {
                // el: '.home-moodboard__pagnation',
                // type: 'custom',
                // renderCustom: function (swiper, current, total) {
                //     return current + ' <span class="divider">-</span> ' +  total;
                // },
            },
            // breakpoints: {
            //     991: {
            //         slidesPerView: 3,
            //     },
            //     500: {
            //         slidesPerView: 2,
            //     },
            // },
        })

        APIClient.getMoodboardPublic()
            .then( res => {
                this.items = res.data.concat(res.data)

                console.log(this.items)

                var self = this
                this.$nextTick( () => {
                    this.slider.init()
                })                  
            })
            .catch( err => {
                console.error(err)
            })
            .finally( () => {
                this.isLoading = false;
            })
        },
    }
</script>
