<template>
  <div class="page-not-found">
      <div>
        <h1>404</h1> 
        <h2>Page not found</h2>
        <a 

          class="btn btn-black mt-4"
          @click="goHome()"
          >
          Go back to Home
        </a>
      </div>
  </div>
</template>

<script>

export default {
  name: 'PageNotFound',
  components: {
    
  },
  data () {
    return {

    }
  },
  methods: {
    goHome() {
      this.$router.push('/me')
    }
  },
  mounted () {

  },
  computed: {

  },
}
</script>
