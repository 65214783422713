<template>
  <div>
    <b-row class="justify-content-between align-items-center">
      <h2 class="section-heading col pr-0">
        Monthly Overview
      </h2>

      <b-col 
        cols="auto"
        class="nav-arrows"
        >
<!--         <svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="14.774" height="7.277" viewBox="0 0 14.774 7.277"><g data-name="Grupo 145"><path data-name="Trazado 105" d="M0 3.639L3.582.101a.331.331 0 0 1 .468.467L1.29 3.305h13.151a.334.334 0 0 1 0 .668H1.29L4.05 6.71a.331.331 0 1 1-.468.467z" fill="#fff"/></g></svg> -->
          <i 
            class="icon-caret-left"
            @click="previousMonth()"
            >
          </i>
          <i 
            class="icon-caret-right"
            @click="nextMonth()"
            >
              
          </i>
<!--         <svg svg-inline="" @click="nextMonth()" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="14.775" height="7.277" viewBox="0 0 14.775 7.277"><g data-name="Grupo 144"><path data-name="Trazado 104" d="M14.776 3.639L11.193.101a.33.33 0 0 0-.467.467l2.759 2.737H.334a.334.334 0 1 0 0 .668h13.151L10.726 6.71a.33.33 0 0 0 .467.467z" fill="#fff"/></g></svg> -->
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <div 
          class="spinner-wrapper"
          v-if="isLoading"
          >
          <spinner />
        </div>
        <div v-else>
          <!-- <transition name="fade"> -->
            <ul 
              class="skin-journey-list" 
              v-if="journeyItems && journeyItems.length"
              >
                <li 
                  v-for="item in journeyItems" 
                  class="mb-3"
                  align-v="start"
                  @click="selectSkinJourney(item)"
                  >
                  <skin-journey-item :source="item" />
                </li>
              
            </ul>
            <div 
              class="empty"
              v-else
              >
              There is no data for this month.
            </div>
          <!-- </transition> -->
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>

import APIClient from '@/api/requests'
import Spinner from '@/ui/Spinner.vue'
import SkinJourneyItem from '@/components/dashboard/skin-journey/SkinJourneyItem.vue'

export default {
  name: 'SkinJourneyList',
  components: {
    Spinner,
    SkinJourneyItem
  },
  data() {
    return {
      journeyItems: null,
      moment: null,
      isLoading: false,
      selectedMonth: null,
      moment: require('moment'),
    }
  },
  mounted () {
    this.moment = require('moment');

    var month = this.moment().format('M');
    var year = this.moment().format('YYYY');

    this.selectedMonth = this.moment().format('MM/YYYY')
    
    this.getDataFromMonth()
  },
  methods: {
    selectSkinJourney(item) {
      this.$emit('onSelected', item)
    },
    previousMonth() {
      this.selectedMonth = this.moment(this.selectedMonth, "MM/YYYY").subtract(1, 'months').format("MM/YYYY");
      this.getDataFromMonth()
    },
    nextMonth() {
      this.selectedMonth = this.moment(this.selectedMonth, "MM/YYYY").add(1, 'months').format("MM/YYYY");
      this.getDataFromMonth()
    },
    getDataFromMonth() {
      this.isLoading = true;

      var month = this.moment(this.selectedMonth, "MM/YYYY").format('M');
      var year = this.moment(this.selectedMonth, "MM/YYYY").format('YYYY');

      APIClient.getSkinJouney(month, year)
        .then( resp => {
            this.journeyItems = resp.data
          })
          .catch( err => {
            console.log(err)
          })
          .finally( () => {
            this.isLoading = false;
          })
    }
  }
}
</script>

