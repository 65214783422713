<template>
    <div class="page-overview">
      <section class="header">
        <simple-header />
      </section>

      <b-container 
        class="content display-flex"
        >
        <b-row class="d-flex flex-direction-column flex-lg-row-reverse">

          <b-col 
            cols="12" 
            lg="4"
            class="px-0"
            >
            <wishlists-slider />
          </b-col>

          <b-col 
            cols="12" 
            lg="8"
            class="main-content px-0"
            >

            <div class="top">
              <h2>
                my dashboard
              </h2>
            </div>

            <div class="">
              <b-tabs 
                fill
                class="main-tabs"
                v-model="tabIndex"
                >
                <b-tab title="overview">
                  <overview-tab />
                </b-tab>
                <b-tab title="skin journey">
                  <skin-journey-tab />
                </b-tab>
                <b-tab title="reviews">
                  <my-reviews-tab />
                </b-tab>
                <!-- <b-tab title="referrals">
                  <referrals-tab />
                </b-tab> -->
              </b-tabs>
            </div>
          </b-col>

        </b-row>
      </b-container>
      
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import APIClient from '@/api/requests'
import SimpleHeader from '@/ui/SimpleHeader'
import OverviewTab from '@/components/dashboard/overview/OverviewTab'
import WishlistsSlider from '@/components/dashboard/overview/WishlistsSlider'
import SkinJourneyTab from '@/components/dashboard/overview/SkinJourneyTab'
import MyReviewsTab from '@/components/dashboard/overview/MyReviewsTab'
import ReferralsTab from '@/components/dashboard/overview/ReferralsTab'

export default {
  name: 'Overview',
  components: {
    SimpleHeader,
    OverviewTab,
    WishlistsSlider,
    SkinJourneyTab,
    MyReviewsTab,
    ReferralsTab
  },
  data () {
    return {
      tabIndex: 0
    }
  },
  mounted() {
    if(this.$route.params.tab == "referrals") {
      this.tabIndex = 3
    }else if(this.$route.params.tab == "reviews") {
      this.tabIndex = 2
    }
  }
}
</script>
