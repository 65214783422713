<template>
  <div class="routine container h-100">
    <b-row class="h-100">
      <b-col sm="12" class="p-0">
        <div class="accordion-content h-100">
          <ul class="accordion-list">
            <li :class="{ active: selectedTab == 'daytime' }">
              <input
                id="rad1"
                type="radio"
                name="rad"
                checked="checked"
                @click="selectTab(0)"
                class="accordion-radio"
                >
              <label
                class="accordion-tab"
                for="rad1"
                >
                <div>daytime routine</div>
              </label>
              <div class="accslide py-4 px-lg-5 px-2">
                <div class="content" :class="{ 'invisible': isChangingTabs }">
                  <div class="content-header p-4">
                    <h4 class="mb-3">
                      DAYTIME ROUTINE
                    </h4>
                    <routine-form 
                      routine="1" 
                      @onProductAdded="addProductToSlider" 
                      @onDropdownToggle="toggleOpacity"
                      />
                  </div>
                  <div class="content-body">
                    <div class="my-5">
                      <routine-slider 
                        ref="daytimeSlider" 
                        section="daytime" 
                        :source="products[0]" 
                        :class="{'transparent': addTransparency}"
                        />
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li :class="{ active: selectedTab == 'makeup' }">
              <input
                id="rad2"
                type="radio"
                name="rad"
                @click="selectTab(1)"
                class="accordion-radio"
                >
              <label
                class="accordion-tab"
                for="rad2"
                >
                <div>makeup routine</div>
              </label>
              <div class="accslide py-4 px-lg-5 px-2">
                <div class="content" :class="{ 'invisible': isChangingTabs }">
                  <div class="content-header p-4">
                    <h4 class="mb-3" >MAKEUP ROUTINE</h4>
                    <routine-form 
                      routine="3" 
                      @onProductAdded="addProductToSlider" 
                      @onDropdownToggle="toggleOpacity"
                      />
                  </div>
                  <div class="content-body container">
                    <div class="row">
                      <routine-slider 
                        ref="makeupSlider" 
                        section="makeup" 
                        :source="products[2]"
                        :class="{'transparent': addTransparency}"
                        />
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li :class="{ active: selectedTab == 'nighttime' }">
              <input
                id="rad3"
                type="radio"
                name="rad"
                @click="selectTab(2)"
                class="accordion-radio"
              >
              <label
                class="accordion-tab"
                for="rad3"
              >
                <div>night time routine</div>
              </label>
              <div class="accslide py-4 px-lg-5 px-2">
                <div class="content" :class="{ 'invisible': isChangingTabs }">
                  <div class="content-header p-4">
                    <h4 class="mb-3" >NIGHT TIME ROUTINE</h4>
                    <routine-form 
                      routine="2" 
                      @onProductAdded="addProductToSlider" 
                      @onDropdownToggle="toggleOpacity"
                      />
                  </div>
                  <div class="content-body container">
                    <div class="row">
                      <routine-slider 
                        ref="nighttimeSlider" 
                        section="nightime" 
                        :source="products[1]" 
                        :class="{'transparent': addTransparency}"
                        />
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li :class="{ active: selectedTab == 'haircare' }">
              <input
                id="rad4"
                type="radio"
                name="rad"
                @click="selectTab(3)"
                class="accordion-radio"
              >
              <label
                class="accordion-tab"
                for="rad4"
              >
                <div>hair care routine</div>
              </label>
              <div class="accslide py-4 px-lg-5 px-2">
                <div class="content" :class="{ 'invisible': isChangingTabs }">
                  <div class="content-header p-4">
                    <h4 class="mb-3" >HAIR CARE ROUTINE</h4>
                    <routine-form 
                      routine="4" 
                      @onProductAdded="addProductToSlider"
                      @onDropdownToggle="toggleOpacity" 
                      />
                  </div>
                  <div class="content-body container">
                    <div class="row">
                      <routine-slider 
                        ref="haircareSlider" 
                        section="haircare" 
                        :source="products[3]" 
                        :class="{'transparent': addTransparency}"
                        />
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RoutineForm from '@/ui/routine/RoutineForm.vue'
import RoutineSlider from '@/components/dashboard/home/RoutineSlider.vue'
import APIClient from '@/api/requests'

export default {
  name: 'Profile',
  components: {
    RoutineForm,
    RoutineSlider
  },
  data () {
    return {
      products: [],
      selectedTab: 'daytime',
      selectedTabIndex: 1,
      addTransparency: false,
      isChangingTabs: false,
    }
  },
  mounted () {
    this.getRoutines();

    this.$root.$on('routine_changed', () => {
      this.getRoutines();
      console.log("routine changed")
    })
  },
  methods: {
    getRoutines: function () {
      APIClient.getRoutines()
      .then(resp => {
        this.products = resp.data
        this.selectTab(0)
        this.$root.$emit('routines_updated')
      })
      .catch(err => {
        console.log(err)
      })
    },  
    addProductToSlider: function(product) {
      switch(this.selectedTabIndex){
        case 0:          
          this.$refs.daytimeSlider.hideSlider();
          break;
        case 1:
          this.$refs.makeupSlider.hideSlider();          
          break;
        case 2:
          this.$refs.nighttimeSlider.hideSlider();          
          break;
        case 3:
          this.$refs.haircareSlider.hideSlider();          
          break;
      }

      let self = this
      setTimeout(() => {
        APIClient.getRoutines()
          .then(resp => {
            self.products = resp.data
            self.selectTab(self.selectedTabIndex, false)
          })
          .catch(err => {
            console.error(err)
          })
      },1000)

    },
    toggleOpacity: function (filterOpen) {
      this.addTransparency = filterOpen
    },
    selectTab: function (index, fadeAnimation = true) {

      let keepSliderHeight
      if(fadeAnimation) { 
        this.isChangingTabs = true
        keepSliderHeight = false
      } else {
        keepSliderHeight = true
      }

      this.selectedTab = ''
      this.selectedTabIndex = index
      switch(index){
        case 0:          
          this.selectedTab = 'daytime'
          this.$refs.daytimeSlider.updateSlider(keepSliderHeight);
          break;
        case 1:
          this.selectedTab = 'makeup'
          this.$refs.makeupSlider.updateSlider(keepSliderHeight);          
          break;
        case 2:
          this.selectedTab = 'nighttime'
          this.$refs.nighttimeSlider.updateSlider(keepSliderHeight);          
          break;
        case 3:
          this.selectedTab = 'haircare'
          this.$refs.haircareSlider.updateSlider(keepSliderHeight);          
          break;
      }
    }  
  }
}
</script>
