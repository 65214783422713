<template>
  <div class="skin-feels px-4 mt-4 mb-2 pb-3">
    <label class="mb-4 w-100">my skin feels:  <span class="float-right" v-if="selected"><img class="image" :src="selected.image"/></span></label>
    <div v-if="!isLoading" :class="{ nopointerevents: isSaving }">
      <div v-if="!skinFeelSubmited"> 
        <ul class="pb-2">
          <b-row v-for="item in items" align-h="between" class="mb-3">
            <div class="col-auto">
              <img :src="item.image" class="image"/><span class="name">{{ item.name }}</span>
            </div>
            <div class="col-auto">
              <input type="radio" v-model="selected" :value="item" class="uiswitch float-right" @change="save" />
            </div>
          </b-row>
        </ul>
      </div>
      <div v-else>
        <h3 class="text-center">{{ selected.name }}</h3>
        <div class="text-center">
          <button @click="editSkinFeel" class="edit p-0 my-3">
            <i class="icon-edit"></i>
            <!-- <svg svg-inline="" focusable="false" role="presentation" data-name="noun_Edit pencil_1416596" xmlns="http://www.w3.org/2000/svg" width="10.124" height="10.156" viewBox="0 0 10.124 10.156"><g data-name="Grupo 457"><path data-name="Trazado 505" d="M9.92 1.118L9.009.204a.724.724 0 0 0-1.008 0L.639 7.59.6 7.629 0 10.156l2.519-.6 7.4-7.425a.729.729 0 0 0 .001-1.013zM1.046 7.75L7.4 1.37l1.356 1.361L2.4 9.106zm-.155.369l1.143 1.147-1.511.369zm8.758-6.278l-.6.6-1.357-1.362.6-.6a.328.328 0 0 1 .446 0l.911.914a.322.322 0 0 1 .1.233.313.313 0 0 1-.1.211z" fill="#bbb"/></g></svg> -->
          </button>
        </div>
        <div class="text-center">
          <button @click="showSkinJourney" class="btn btn-outline-light view-journey">View my skin journey</button>
        </div>
      </div>
    </div>  
    <spinner v-if="isLoading" />
  </div>
  
</template>

<script>

import APIClient from '@/api/requests';
import Spinner from '@/ui/Spinner.vue'
import { helperMixin } from '@/mixins/helperMixin.js'

export default {
  name: 'SkinFeels',
  components: {
    Spinner
  },
  mixins: [helperMixin],
  props: {
    
  },
  data () {
    return {
      items: [],
      selected: null,
      isSaving: false,
      skinFeelSubmited: false,
      isLoading: false
    }
  },
  methods: {
    editSkinFeel: function () {
      // console.log(this.items); 
      this.skinFeelSubmited = false
    },
    save: function () {
      if (this.isSaving) return;
      this.isSaving = true;      
      APIClient.saveSkinFeelOptions(this.selected.id)
        .then ( res => {
          // console.log(res)
          this.skinFeelSubmited = true
          // this.items = res.data
        })
        .catch ( err => {
          console.error(err)
        })
        .finally ( () => {
          this.isSaving = false;
        })
    },
    showSkinJourney: function() {
      this.$emit('showSkinJourney', true)
    }
  },
  mounted () {
    this.isLoading = true;
    APIClient.getSkinFeelOptions()
      .then ( res => {
        // console.log(res)
        this.items = res.data.options
        if(res.data.selectedToday){
          this.skinFeelSubmited = true
          this.selected = res.data.selectedToday
        }
      })
      .catch ( err => {
        console.error(err)
      })
      .finally ( () => {
          this.isLoading = false;
      })
  }
}
</script>
