<template>
  <div class="notifications-wrap-mobile px-4 py-4 container">
    <b-row v-if="!isLoading">
      <b-col sm="12">
        <div class="notifications" v-if="hasNotifications">
          <div v-for="(items, index) in notifications" >
            <div class="">
              <!-- <a class="float-right"> -->
                <i class="icon-close" @click="closeNotifications(index)">
                  <span class="path1"></span><span class="path2"></span>
                </i>
              <!-- </a> -->
            </div>
            
            <ul class="list">
              <li
                v-for="(item, item_index) in items"
                :key="index"
                class="container item notification"
                 @click="$bvModal.show(`notification_${item.id}`)"
              >
                <b-row
                  class=""
                  align-v="center"
                >
                  <b-col
                    cols="2"
                    class="pr-0 col-2"
                  >
                    <img
                      src="@/assets/images/logos/bb-submark.png"
                      class="mx-auto w-100"
                    >
                  </b-col>
                  <b-col cols="10" sm="10">
                    <label>{{ item.title }}</label>
                    <p>{{ item.content.stripHtmlTags() }}</p>
                  </b-col>
                </b-row>

                <notification-modal :modalId="`notification_${item.id}`" section="notification" :source="item" :sourceNavigation="items" :sourceIndex="index" @closed="markAsRead(index, item_index)" />

              </li>
            </ul>
          </div>            

        </div>      
        <div v-else class="notifications">


        </div>  

      </b-col>
    </b-row>
  </div>
</template>

<script>

import APIClient from '@/api/requests'
import NotificationModal from '@/ui/NotificationModal.vue'

let helper = require('@/assets/scripts/main.js');

export default {
  name: 'NotificationsMobile',
  components: {
    NotificationModal,
  },
  data () {
    return {
      notifications: {},
      visibleNotification: null,
      selectedIndex: 0,
      isLoading: false
    }
  },
  mounted () {
    this.isLoading = true
    APIClient.getUnreadNotifications(10)
      .then(resp => {
        // console.log(resp.data)
        this.notifications = resp.data.groupBy('date')
        this.visibleNotification = this.notifications[0]
      })
      .catch(err => {
        console.error(err)
      })
      .finally( () => {
        this.isLoading = false
      })
  },
  methods: {
    markAsRead: function (dateIndex, index) {
      let notificationId = this.notifications[dateIndex][index].id      
      APIClient.markNotificationAsRead({ notification_id: [notificationId] })
        .then(resp => {
          // console.log("notification marked as read")
        })
        .catch(err => {
          // console.log(err)
        })
        .finally( () => {
          this.notifications[dateIndex].splice(index, 1);
          // console.log(this.notifications[dateIndex])
          if(!this.notifications[dateIndex].length){
            // console.log("should remove")
            delete this.notifications[dateIndex];
            this.$forceUpdate()
          }else{
            // console.log("adsf");
          }
        })
    },
    openNotification: function(index) {
      this.selectedIndex = index
      this.$refs['notification-modal'].show()
    },
    closeNotifications: function(index) {      
      let notificationsIds = this.notifications[index].map( item => item.id )
      APIClient.markNotificationAsRead({ notification_id: notificationsIds })
        .then(resp => {
          // console.log("notifications marked as read")
        })
        .catch(err => {
          // console.log(err)
        })
        .finally( () => {
          delete this.notifications[index];
          this.$forceUpdate()
          // console.log(this.notifications)
          if (Object.getOwnPropertyNames(this.notifications).length === 0) this.notifications = [];
        })
    }
  },
  computed: {
    hasNotifications() {
      return !helper.isObjectEmpty(this.notifications)
    }
  }
}
</script>

<style></style>
