<template>
    <div 
        class="routine-slider" 
        :class="{ 'routine-slider--loading': isLoading, 'routine-slider--opacity': showOpacity}"
        >    
        <div class="swiper-container" ref="routineSlider">
            <div class="swiper-wrapper">
                <div
                    v-for="(item, index) in items"
                    :key="index"
                    class="swiper-slide routine-slider__slide"
                    >
                    <product-routine 
                        :slider-index="index" 
                        :source="item" 
                        :routine="routine"
                        :is-shelf="isShelf"
                        @onProductRemoved="removeProductFromSlider" 
                        @onAddToWishlistClicked="openWishlistModal"
                    />
                </div>
                <template v-if="items.length > 0 && items.length < 3">
                    <div
                        v-if="items.length < 3 && items.length > 0"
                        v-for="index in 3 - items.length"
                        :key="index"
                        class="swiper-slide routine-slider__slide"
                        >
                        <div 
                            class="routine-slider__add-product"
                            @click="addProduct"
                            >
                            add product

                            <svg class="routine-slider__add-icon" svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g data-name="Grupo 695"><path data-name="Trazado 61338" d="M9.333 0v9.338H0v1.333h9.333v9.333h1.333v-9.333H20V9.338h-9.333V.005z" fill="#484848"/></g></svg>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div class="routine-slider__prev">
            <i class="icon-arrow-slider-left">
            </i>
        </div>
        <div class="routine-slider__next">
            <i class="icon-arrow-slider-right">
            </i>
        </div>

        <div 
            v-show="!items.length || showEmptyForm" 
            class="routine-slider__empty px-4"
            :class="{ 'routine-slider__empty--overlay': showEmptyForm }"
            ref="routineSliderEmpty"
            :style="{ height: emptyMessageHeight }"
            >
            <div v-show="isShelf && !isLoading">
                <routine-empty
                    :showClose="showEmptyForm"
                    :routine="routine"
                    @onClose="showEmptyForm = false"
                    >
                </routine-empty>
            </div>
            <template v-if="!isShelf && !isLoading">
                No products found.
            </template>

        </div>

        <add-to-wishlist-modal :product="producToAdd" />
    </div>
</template>

<script>
    import Swiper from '@/assets/scripts/vendor/swiper.esm.bundle'
    import ProductRoutine from '@/ui/ProductRoutine.vue'
    import RoutineEmpty from '@/components/dashboard/home/RoutineEmpty.vue'
    import AddToWishlistModal from '@/components/dashboard/home/AddToWishlistModal.vue'
    import EventBus from '@/event-bus'

    export default {
        name: 'RoutineSlider',
        components: {
            ProductRoutine,
            AddToWishlistModal,
            RoutineEmpty
        },
        data() {
            return {
                slider: null,
                items: [],
                producToAdd: null,
                emptyMessageHeight: 'auto',
                showOpacity: false,
                showEmptyForm: false,
            }
        },
        props: {
            isShelf: true,
            routine: null,
            isLoading: true,
        },
        created() {
            EventBus.$on('on-filter-toggle', this.toggleOpacity)
        },
        beforeDestroy() {
            EventBus.$off('on-filter-toggle')
        },
        mounted () {
            this.initSlider()
        },
        methods: {
            initSlider() {
                this.slider = new Swiper(`.routine-slider .swiper-container`, {
                    slidesPerView: 1,
                    spaceBetween: 30,
                    loop: false,
                    navigation: {
                        nextEl: '.routine-slider__next',
                        prevEl: '.routine-slider__prev',
                        disabledClass: 'disabled'
                    },
                    breakpoints: {
                        1200: {
                            slidesPerView: 3,
                        },
                        991: {
                            slidesPerView: 3,
                        },
                        800: {
                            slidesPerView: 2,
                        },
                        500: {
                            slidesPerView: 1,
                        },
                    }
                })
            },
            toggleOpacity: function(val) {
                this.showOpacity = val
            },
            updateSlider: function (products) {

                let self = this      
                this.items = []
                
                this.$nextTick( () => {
                    self.items = products  
                    self.$forceUpdate()
                    let sliderHeight = self.$refs.routineSlider.clientHeight
                    
                    if(sliderHeight > 0){
                        self.emptyMessageHeight = `${self.$refs.routineSlider.clientHeight}px`;
                    }
                }) 

                setTimeout(function(){   
                    self.slider.init();
                    self.slider.update();
                    self.$forceUpdate()     
                    EventBus.$emit('slider-updated');                
                }, 0)
                
            },
            removeProductFromSlider: function (i) {
                this.items.splice(i, 1)
                let self = this
                setTimeout(() => {
                    self.slider.update()    
                }, 100)
                
            },
            openWishlistModal: function(productId) {
                this.producToAdd = productId
                this.$root.$emit('bv::show::modal', 'add_to_wishlist_modal')
            },
            addProduct: function() {
                this.showEmptyForm = true
                // EventBus.$emit('add-routine-product');
            }
        }
    }
</script>
