<template>
 	<div class="moodboard-menu">
 		<button
			class="moodboard-menu__toggle"
			@click="toggleMenu"
			>
			<svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="12.976" height="9.518" viewBox="0 0 12.976 9.518"><g data-name="Grupo 2"><path data-name="Trazado 2" d="M12.559 4.395H.417A.381.381 0 0 0 0 4.77a.4.4 0 0 0 .417.375h12.142a.377.377 0 1 0 0-.75z"/><path data-name="Trazado 3" d="M.417.75h12.142a.377.377 0 1 0 0-.75H.417A.381.381 0 0 0 0 .375.4.4 0 0 0 .417.75z"/><path data-name="Trazado 4" d="M12.588 8.769H.417a.377.377 0 1 0 0 .749h12.142a.379.379 0 0 0 .417-.375.372.372 0 0 0-.388-.374z"/></g></svg>
		</button>

		<div class="moodboard-menu__menu" :class="{ 'show': showMenu }">
			
		</div>
	</div>
</template>

<script>
	export default {
	  	name: 'MoodboardMenu',
		data () {
		    return {
		    	showMenu: false
		    }
		},
		components: {
			
		},
		props: {	

   		},
		mounted() {
			  	
		},
		methods: {
		    toggleMenu: function() {
		    	this.showMenu = !this.showMenu
		    	if(this.showMenu)
		    		document.body.classList.add('menu-open')
		    	else
		    		document.body.classList.remove('menu-open')
		    }
		}
	}
</script>
