<template>
    <b-form 
        class="routine-form"
        @submit.stop.prevent
        >
        <div class="form-group row">
            <!-- <div 
                class="col-12 mb-4 mb-sm-0"
                :class="{ 'col-md-4': inline, 'mb-3': !inline }"
                >
                <b-input
                    ref="button"
                    placeholder="select product type"
                    @click="toggleFilter"
                    :value="formatedCategory"
                    class="routine-form__input routine-form__input--type"
                    >

                 </b-input>
            </div> -->

            <div 
                class="col-12"
                :class="{ 'col-md-12': inline, 'mt-md-3': !saveOnSelect }"
                >
                <multiselect 
                    v-model="selectedProduct" 
                    label="name"
                    track-by="id" 
                    @input="submit"
                    placeholder="search brand or product type" 
                    open-direction="bottom" 
                    :options="products" 
                    :multiple="false" 
                    :searchable="true" 
                    :loading="isLoading" 
                    :internal-search="false" 
                    :options-limit="300" 
                    :max-height="600" 
                    :show-no-results="true" 
                    :hide-selected="true" 
                    :select-label="''"
                    @search-change="getProducts"
                    ref="selectInput"
                    >
                    <template slot="noOptions">Type to search...</template>
                    <div slot="noResult">Oops! No products found. Consider changing the search query.</div>
                </multiselect>
            </div>

            <div
                class="col-12 mt-4 d-flex justify-content-end flex-column flex-md-row" 
                v-if="!saveOnSelect"
                >
                <button 
                    class="btn routine-form__cta routine-form__cta--save"
                    @click="saveToRoutine"
                    >
                    Add product
                </button>

                <!-- <button 
                    class="btn routine-form__cta routine-form__cta--view ml-md-3 ml-0 mt-3 mt-md-0"
                    @click="viewShelf"
                    :class="{ 'disabled': !allowMyShelf }"
                    >
                    View my shelf
                </button> -->
            </div>
        </div>

        <filter-input
            @input="toggleFilter"
            :options="categories" 
            :search="false"
            :multiple="false"
            :value="categories"
            v-model="selectedCategory" 
            v-show="isFilterOpen"
            label="category_name"
            custom-key="category_id"
            v-closable="{ exclude: ['button'], handler: 'closeFilter' }" 
            />
    </b-form>
</template>

<script>
import Multiselect from 'vue-multiselect'
import APIClient from '@/api/requests'
import FilterInput from '@/ui/FilterInput'
import SelectedFilters from '@/ui/SelectedFilters.vue'
import { helperMixin } from '@/mixins/helperMixin.js'
import EventBus from '@/event-bus'

    export default {
        name: 'RoutineForm',
        components: {
            Multiselect,
            FilterInput,
            SelectedFilters
        },
        mixins: [helperMixin],
        props: {
            bodyType: String,
            default: null,
            inline: true,
            saveOnSelect: true
        },
        data () {
            return {
                concerns: [],
                selectedConcerns: [],
                products: [],
                isFilterOpen: false,
                selectedCategory: null,
                searchTerm: '',
                isLoading: false,
                routine: null,
                selectedProduct: null,
                allowMyShelf: false
            }
        },
        computed: {
            formatedCategory: function() {
                return this.selectedCategory ? this.selectedCategory.category_name : '';
            },
        },
        created() {
            EventBus.$on('add-routine-product', this.focusInput)
            EventBus.$on('update-routine-categories', this.updateRoutineCategories)
            // EventBus.$on('routine-filter-changed', this.updateRoutineCategories)
        },
        beforeDestroy() {
            EventBus.$off('add-routine-product')
            EventBus.$off('update-routine-categories')
            // EventBus.$off('routine-filter-changed')
        },
        watch: {
            isFilterOpen: function(val, oldVal) {
                EventBus.$emit('on-filter-toggle', val)
            },
        },
        methods: {
            // getRoutineCategories: function (bodyAreaId) {
            //     APIClient.getRoutineCategories(bodyAreaId)
            //         .then(resp => {
            //             this.categories = resp.data
            //             this.$forceUpdate()
            //         })
            //         .catch(err => {
            //             console.log(err)
            //         })
            // },
            focusInput: function () {
                this.isFilterOpen = true
            },
            getProducts: function (query) {
                clearTimeout(this.inputTimer);
                if(!query.length){
                    this.products = [];
                    return;
                }
                this.isLoading = true;
                let self = this;    


                var routineName = 'dayroutinetab';

                switch(parseInt(this.routine.type_id)) {
                    case 1:
                        routineName = 'dayroutinetab'
                        break;
                    case 2: 
                        routineName = 'nightroutinetab'
                        break;
                    case 3:
                        routineName = 'makeuproutinetab'
                        break;
                    case 4: 
                        routineName = 'hairroutinetab'
                        break;
                }

                this.inputTimer = setTimeout(function(){
                    // var categoryId = (self.selectedCategory) ? self.selectedCategory.category_id : '';
                    APIClient.getRoutineProducts(routineName, query)
                        .then(resp => {
                            self.products = resp.data
                        })
                        .catch(err => {
                            console.log(err)
                        })
                        .finally( () => {
                            self.isLoading = false
                        })
                }, 1000)
            },
            removeCategory: function () {
                this.selectedCategory = null
            },
            submit: function () {
                if(this.saveOnSelect) {
                    this.saveToRoutine()
                }
            },
            // saveToRoutine: function() {
            //     if(!this.selectedProduct) {
                        
            //     } else {
            //         this.saveToRoutine()
            //     }
            // },
            saveToRoutine: function () {
                if(!this.selectedProduct) {
                    this.$bvToast.toast(`Please, select a product`, {
                        title: 'Error',
                        autoHideDelay: 5000,
                        toastClass: 'error',
                        appendToast: true,
                    })
                    return;
                } 

                this.isLoading = true
                APIClient.saveProductRoutine(this.selectedProduct.id, this.routine.type_id)
                    .then( resp => {
                        EventBus.$emit('update-routines')
                        this.$bvToast.toast(`Added to your routine`, {
                            title: 'Success',
                            autoHideDelay: 5000,
                            toastClass: 'success',
                            appendToast: true,
                            toaster: 'b-toaster-top-center'
                        })

                        this.selectedProduct = null
                        this.products = []
                        this.searchTerm = '';
                        this.allowMyShelf = true;
                    })
                    .catch( err => {
                        console.log(err)
                    })      
                    .finally( () => {
                        this.isLoading = false
                    })
                },
            hideList: function () {
                this.isFilterOpen = false
            },
            toggleFilter: function () {
                this.isFilterOpen = !this.isFilterOpen
            },
            closeFilter: function () {
                this.isFilterOpen = false
            },
            expandSearch: function () {
                this.searchExpanded = true
            },
            condenseSearch: function () {
                this.searchExpanded = false
            },
            viewShelf: function () {
                if(this.allowMyShelf) {
                    EventBus.$emit('view-shelf');
                }
            },
            updateRoutineCategories(routine) {
                // var self = this
                this.routine = routine
                // updates after it's mounted
                // setTimeout(function(){
                //     self.routine = routine;
                //     let routineName;
                //     switch(parseInt(self.routine.type_id)) {
                //         case 1:
                //         case 2: 
                //             routineName = 1
                //             break;
                //         case 3:
                //             routineName = 4
                //             break;
                //         case 4: 
                //             routineName = 2
                //             break;
                //     }
                //     self.getRoutineCategories(routineName);
                // }, 500)
                
            }
        }
    }
</script>

