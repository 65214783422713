<template>
	<div 
		class="search-result-brand" 
		@click="openBrand"
		>
		<div class="search-result-brand__inner">
			<div 
				class="search-result-brand__thumb"
				:style="{ backgroundImage: `url(${brand.image})` }"
				>
			</div>
			<div class="search-result-brand__name">
				{{ brand.name }}
			</div>

			<div class="search-result-brand__bottom">
				<div class="search-result-brand__count">
					{{ brand.products_count }} 
					<template v-if="brand.products_count > 1">
						products
					</template>
					<template v-else>
						product
					</template>
				</div>

				<div 
					class="btn search-result-brand__button"
					@click="openProduct"
					>
					view brand
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import Spinner from '@/ui/Spinner.vue';

	export default {
		name: 'SearchResultBrand',
		components: {

		},
		props: {
			brand: null
		},
		mounted() {

		},
		methods: {
			openBrand: function() {
				this.$router.push(`/me/brand/${this.brand.link}`)
			}
		}
	}
</script>
