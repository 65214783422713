<template>
  <div class="loading-spinner text-center">
    <b-spinner type="grow" label="Spinning"></b-spinner>
    <div 
    	v-if="message"
    	class="p-2 position-absolute message"
      >
    	{{message}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
  	message: {
  		type: String,
  		default: null
  	}
  },
  components: {}
}
</script>

<style></style>
