<template>
    <b-modal 
      :id="modalId" 
      :title="title"
      @ok="confirms"
      :ok-title="'Save'"
      modal-class="edit-wishlist-modal"
      @show="setValues"
      >
      <b-form-group label="Collection Name" v-if="temp_wishlist">
        <b-form-input v-model="temp_wishlist.name"  placeholder="Name" class="mb-3" />
        <!-- <b-form-input v-model="temp_wishlist.description"  placeholder="Description Name" /> -->
      </b-form-group>
    </b-modal>
</template>

<script>
import APIClient from '@/api/requests'

export default {
  name: 'EditWishlistModal',
  components: {},
  props: {
    wishlist: {
      type: Object,
      default: null
    },
    modalId: {
      type: String,
      default: ""
    }
  },
  mounted() {

  },
  methods: {
    confirms: function(bvModalEvt) {
      bvModalEvt.preventDefault()
      if(this.isLoading) return

      let data = {
        name: this.temp_wishlist.name,
        description: this.temp_wishlist.description
      }

      console.log(this.temp_wishlist.id)
      this.isLoading = true
      APIClient.editWishlist(data, this.temp_wishlist.id)
        .then( resp => {
          this.$bvToast.toast(`Collection saved`, {
            title: 'Success',
            autoHideDelay: 5000,
            toastClass: 'success',
            appendToast: true,
          })
          this.$root.$emit('wishlists_changed')
          this.$root.$emit('bv::hide::modal', this.modalId)
        })
        .catch( err => {
          console.log(err)
          this.$bvToast.toast('Something went wrong.', {
            title: 'Error',
            autoHideDelay: 5000,
            toastClass: 'error',
            appendToast: true,
          })
        })
        .finally( () => {
          this.isLoading = false
        })

    },
    setValues: function() {
      this.temp_wishlist = this.wishlist
    }
  },
  data () {
    return {
      temp_wishlist: null
    }
  },
}
</script>
