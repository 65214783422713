<template>
	<b-modal 
		:id="customId"
		:title="title"
		modal-class="wishlist-modal"
		hide-footer="true"
		centered
		@ok="createWishlist"
		@shown="setupWishlist"
		centered
		>
		<b-row class="m-0">

			<b-col 
				cols="12"
				md="8"
				class="px-5 py-2"
				>
				<b-row>
					<b-col cols="12" class="px-2 mb-4">
						<input 
							type="text" 
							v-model="wishlistName"
							class="w-100 form-control"
							placeholder="my collection..."
							maxlength="100"
							required
							/>
					</b-col>
				
				</b-row>
				<div class="options">
					<b-row>
						<b-col
							v-for="index in 9" 
							:key="index"
							md="4"
							cols="4"
							class="px-2 mb-2"
							>
							<input 
								type="radio"
								v-model="selectedCover"
								:value="`wishlist-${index}`"
								:id="`${id}_wishlist_${index}`"
								/>
							<label 
								:for="`${id}_wishlist_${index}`"
								class="cover"
								:style="{ backgroundImage:`url(${require('@/assets/images/wishlists-covers/wishlist-' + index + '.jpg')})` }"
								/>
									
						</b-col>
					</b-row>
				</div>
			</b-col>

			<b-col
				cols="12"
				md="4"
				class="p-0"
				>
				<div class="preview p-4">
					<div 
						v-if="selectedCover"
						class="preview-inner d-none d-md-block"
						>
						<div 
							class="image"
							:style="{ backgroundImage:`url(${require('@/assets/images/wishlists-covers/' + selectedCover + '.jpg')})` }"
							>
							<div class="name">
								{{wishlistName}}
							</div>
						</div>
					</div>
					<div 
						v-else
						class="empty d-none d-md-block"
						>
						<div class="message">
							Select COLLECTION cover image
						</div>
					</div>

					<button
						class="btn btn-white w-100 my-2"
						@click="$bvModal.hide('wishlist_modal')"
						>
						Cancel
					</button>
					<button
						class="btn btn-black w-100"
						@click="createWishlist"
						>
						<span v-if="!isLoading"> 
							Save
						</span>
						<span v-else>
							<b-spinner small type="grow" label="Spinning"></b-spinner>
						</span>
					</button>
					
				</div>
			</b-col>
		</b-row>
	</b-modal>
</template>

<script>

import APIClient from '@/api/requests'
import EventBus from '@/event-bus'

export default {
	name: 'WishlistModal',
	components: {
		
	},
	props: {
		modalId: {
			type: Number,
			required: true
		},
		showWishlistsOnNewCreated: {
            type: Boolean,
            default: true
        },
        selectedWishlist: null,
        customId: {
        	type: String,
        	default: 'wishlist_modal'
        }
	},
	data() {
		return {
			wishlistName: '',
			isLoading: false,
			selectedCover: null,
			id: null,
		}
	},
	computed: {
		title: function() {
			if(this.selectedWishlist) {
				return 'Edit Collection';
			} else {
				return 'New Collection'
			}
		}
	},
	mounted () {
		this.id = this._uid
	},
	methods: {
		setupWishlist: function() {
			if(this.selectedWishlist) {
				this.wishlistName = this.selectedWishlist.name
				this.selectedCover = this.selectedWishlist.image
			}
		},
		createWishlist: function() {

			if(this.isLoading) return

			if(!this.selectedCover || !this.wishlistName.length) {
		        this.$bvToast.toast('Please, add a name and select a cover image.', {
		          title: 'Error',
		          autoHideDelay: 5000,
		          toastClass: 'error',
		          appendToast: true,
		        })
		        return
		     }

			this.isLoading = true

			let data = {
				name: this.wishlistName,
				private: 1,
				image: this.selectedCover
			}

			if(this.selectedWishlist) {
				APIClient.editWishlist(data, this.selectedWishlist.id) 
					.then(resp => {
							this.$bvModal.hide(this.customId)
							this.wishlistName = ''
							// this.$emit('onWishlistCreated');

							EventBus.$emit('wishlist-edited', resp.data);
							// if(this.showWishlistsOnNewCreated) {
							// 	this.$root.$emit('bv::show::modal', 'add_to_wishlist_modal')
							// }
						})
						.catch(err => {
							console.error(err)
						})
						.finally(() => {
							this.isLoading = false
						})
			} else {
				APIClient.createWishlist(data)
					.then(resp => {
						this.$bvModal.hide(this.customId)
						this.wishlistName = ''
						this.$emit('onWishlistCreated');

						if(this.showWishlistsOnNewCreated) {
							this.$root.$emit('bv::show::modal', 'add_to_wishlist_modal')
						}
					})
					.catch(err => {
						console.error(err)
					})
					.finally(() => {
						this.isLoading = false
					})
			}
		}
	}
}

</script>
