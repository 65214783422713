<template>
  <div class="ingredient-checker">
    <b-row no-gutters>

      <b-col
        sm="12"
        class="py-5"
      >
        <b-container>
          <b-row class="justify-content-md-center">
            <b-col
              sm="8"
              class=""
            >
              <b-row class="">
                <b-col
                  sm="6"
                  class="title"
                >
                  Ingredient Checker
                </b-col>
                <b-col
                  sm="6"
                  class="description"
                >
                  Check your ingredients below to make sure they are compatible
                  within your routine. We’ll give you a thumbs up or down and
                  alternatives if it’s not a perfect match!
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </b-col>

      <b-col sm="12">
        <multiselect v-model="ingredientOne" 
          :options="ingredientOneOptions" 
          :searchable="false" 
          :close-on-select="true" 
          :show-labels="false"
           placeholder="select ingredient"
        ></multiselect>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'IngredientChecker',
  components: {},
  data() {
    return {
      ingredientOne: null,
      ingredientOne: [
        { id: 1, value: 'Option 1' },
        { id: 2, value: 'Option 2' }
      ],
      ingredientTwo: null,
      ingredientTwo: [
        { id: 1, value: 'Option 1' },
        { id: 2, value: 'Option 2' }
      ],
      ingredientThree: null,
      ingredientThree: [
        { id: 1, value: 'Option 1' },
        { id: 2, value: 'Option 2' }
      ],
    }
  }
}
</script>

<style></style>
