<template>
    <div class="home-routines">
        <div class="home-routines__inner">
            <div class="home-routines__header">
                SHOP OUR ESSENTIALS — <span v-if="title == null">for all skin types</span><span v-else>{{ title }}</span>
            </div>
            <div class="swiper-container home-routines__slider">
                <div class="swiper-wrapper">
                    <div
                        v-for="(item, index) in items"
                        :key="index"
                        class="swiper-slide routine-slider__slide"
                        >
                        <product-routine 
                            :slider-index="index" 
                            :source="item" 
                            :routine="routine"
                            :is-shelf="isShelf"
                            :isPublic="isPublic"
                        />
                    </div>
                </div>
            </div>

            <div class="home-routines__next">
                <i class="icon-arrow-slider-right"></i>
            </div>
        </div>

        
    </div>
</template>

<script>
    import APIClient from '@/api/requests';
    import Swiper from '@/assets/scripts/vendor/swiper.esm.bundle';
    import ProductRoutine from '@/ui/ProductRoutine.vue'

    export default {
      name: 'HomeRoutines',
      components: {
        ProductRoutine
      },
      data() {
        return {
            items: [],
            slider: null,
            isLoading: false,
        }
      },
      props: {

        title: {
                type: String,
                default: null
        },
        isPublic: {
            type: Boolean,
            default: true,
        }
      },
      mounted() {
        this.slider = new Swiper(`.home-routines__slider`, {
            slidesPerView: 1,
            spaceBetween: 40,
            init: false,
            loop: true,
            watchSlidesVisibility: true,
            navigation: {
                nextEl: '.home-routines__next',
            },
            breakpoints: {
                991: {
                    slidesPerView: 4,
                },
                500: {
                    slidesPerView: 2,
                },
            },
        })

            if(this.isPublic) { 
                APIClient.getPublicRoutine()
                    .then(resp => {
                        this.items = resp.data;
                        console.log(this.items)

                        this.$nextTick( () => {
                            this.slider.init()
                        }) 
                    })
                    .catch(err => {
                        console.error(err)
                    })
            } else {
                APIClient.getPrivateRoutine()
                    .then(resp => {
                        this.items = resp.data;
                        console.log(this.items)

                        this.$nextTick( () => {
                            this.slider.init()
                        }) 
                    })
                    .catch(err => {
                        console.error(err)
                    })
            }
        },
    }
</script>