<template>
  <div class="overview-tab">
    <b-container>
    	<b-row>
    		<b-col cols="12">
    			<my-resolutions />
    		</b-col>

        <b-col cols="12">
          <twelve-overview />
        </b-col>

        <b-col cols="12">
          <ingredients-glossary />
        </b-col>

        <b-col cols="12">
          <routine-tracker />
        </b-col>

        <b-col cols="12">
          <slider-boxes />
        </b-col>

        <!-- <b-col cols="12" md="6">
          <product-preferences />
        </b-col>

        <b-col cols="12" md="6">
          <challenges />
        </b-col> -->

    	</b-row>
    </b-container>
  </div>
</template>

<script>

import MyResolutions from '@/components/dashboard/overview/MyResolutions.vue'
import TwelveOverview from '@/components/dashboard/overview/TwelveOverview.vue'
import IngredientsGlossary from '@/components/dashboard/overview/IngredientsGlossary.vue'
import RoutineTracker from '@/components/dashboard/overview/RoutineTracker.vue'
import SliderBoxes from '@/components/dashboard/overview/SliderBoxes.vue'
import ProductPreferences from '@/components/dashboard/overview/ProductPreferences.vue'
import Challenges from '@/components/dashboard/overview/Challenges.vue'

export default {
  name: 'OverviewTab',
  components: {
  	MyResolutions,
    TwelveOverview,
    IngredientsGlossary,
    RoutineTracker,
    SliderBoxes,
    ProductPreferences,
    Challenges
  }
}
</script>

