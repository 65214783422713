<template>
	<b-modal 
		:id="modalId"  
		body-class="p-0" 
		modal-class="product-modal" 
		@show="onShow"
		@shown="getProductDetails" 
		@hidden="closeModal" 
		@close="close"
		hide-footer=true
		:no-close-on-backdrop="isPublic"
		>

		<div class="container cmb-5">
			<b-row>

				<b-col 
					cols="12" 
					md="2" 
					class="section-thumbs"
					>
					<div 
						class="product-swiper-container gallery-thumbs" 
						:class="{ ready: galleryReady }"
						>
						<div class="swiper-wrapper">
							<div 
								class="swiper-slide" 
								v-for="image in images" 
								v-if="images.length"
								>
								<vue-load-image>
									<img slot="image" :src="image"/>
									<div slot="error"></div>
								</vue-load-image>
							</div>
						</div>

						
					</div>
					
					<div class="gallery-thumbs__next" v-show="images.length > 3">
						<svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="5.54" height="10.777" viewBox="0 0 5.54 10.777"><path data-name="Trazado 201" d="M0 5.389l4.835 5.389.705-.705-4.23-4.684L5.54.705 4.835 0z"/></svg>
					</div>
				</b-col>

				<b-col 
					cols="12" 
					md="5"
					class="px-1"
					>
					<div 
						class="product-swiper-container gallery-main" 
						:class="{ ready: galleryReady }"
						>
						<div class="swiper-wrapper">
						 	<div 
								class="swiper-slide" 
								v-for="image in images" 
								v-if="images.length"
								>
						  		<div class="image-frame p-3">
									<div 
										class="image" 
										:style="{ backgroundImage: `url('${image}')` }"
										/>
								</div>
							</div>                
						</div>
						<div class="navigation">
					  		<div class="button-prev">
								<svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="5.54" height="10.777" viewBox="0 0 5.54 10.777"><path data-name="Trazado 201" d="M0 5.389l4.835 5.389.705-.705-4.23-4.684L5.54.705 4.835 0z"/></svg>
							</div>
							<div class="button-next">
							<svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="5.54" height="10.777" viewBox="0 0 5.54 10.777"><path data-name="Trazado 200" d="M5.538 5.389L.703 10.778l-.705-.705 4.23-4.684L-.002.705.703 0z"/></svg>
							</div>
						</div>
					</div>
				</b-col>

				<!-- Product Description -->
				<b-col 
					cols="12" 
					md="5" 
					class="section-description"
					>

					<b-container>
				 		<b-row class="product-modal__header">
							<b-col cols="auto">
						 		<h2 
									v-if="typeof product.brand === 'object'" 
									@click="openBrand(product)"
									class="product-modal__brand"
								 	:class="{link: product && product.brand.brand_link != '' }"
								  	>
						  			{{ product.brand.name }}
					  			</h2>
								<h2 
									@click="openBrand(product)" 
									:class="{link: product.brand}" 
									class="product-modal__brand"
									v-else
									>
				  					{{ product.brand }}
								</h2>
							</b-col>

							<template v-if="price">
								<b-col cols="auto">
									<div 
										class="product-modal__price" 
										v-if="product"
										>
									 	{{ price }}
								 	</div>
								</b-col>
							</template>

							<b-col cols="12">
								<div class="product-modal__name">
									{{ product.name }}
								</div>
							</b-col>
						</b-row>
						<b-row v-if="product.body_area || (product.makeup_coverage && product.makeup_coverage.length) || (product.makeup_finishes && product.makeup_finishes.length) || make_up_shade_description">
						 	<b-col cols="12">
								<div class="product-modal__data">
							 		<div class="product-modal__type">
										<span
											v-for="(item, index) in product.categories"
											:key="index"
											v-if="product.body_area"
											>
											<template v-if="index != 0">, </template>
											{{ product.body_area.pba_name }} — {{ item.name }}
										</span>
									</div>
									<div class="product-modal__meta" v-if="product.makeup_coverage && product.makeup_coverage.length">
										coverage: 
										<template
											v-for="(item, index) in product.makeup_coverage"
											>
											<template v-if="index != 0">, </template>
											{{ item.name }}
										</template>
										<!-- <span
											v-for="(item, index) in product.categories"
											:key="index"
											>
									  		<template v-if="index != 0">, </template>
									  		{{ item.name }}
										</span> -->
									</div>
									<div class="product-modal__meta" v-if="product.makeup_finishes && product.makeup_finishes.length">
										finish: 
										<template
											v-for="(item, index) in product.makeup_finishes"
											>
											<template v-if="index != 0">, </template>
											{{ item.name }}
										</template>
									</div>
									<div class="product-modal__meta" :class="{'opened': showMoreShades, 'closed': !showMoreShades && product.make_up_shade_description.length > 30}" v-if="product.make_up_shade_description">
										shade:
										<div v-if="showMoreShades" v-html="product.make_up_shade_description.formatToBasicHtml(`<em><b><li><ul><ol><br><p>`)">
										</div>
										<span v-else v-html="product.make_up_shade_description.trimHtml({limit: 40}).html">
										</span>

										<template v-if="product.make_up_shade_description.length > 30">
											<a class="product-modal__more" @click="toggleShowMoreShades">
												<template v-if="!showMoreShades">
													Show more <i class="icon-caret-down"></i>
												</template>
												<template v-else>
													Show less <i class="icon-caret-down"></i>
												</template>
											</a>
										</template>
									</div>
								</div>
							</b-col>
						</b-row>

						<b-row v-if="product.concerns">
							<b-col cols="12">
								<div class="product-modal__concerns">
							  		<label class="mr-2">
										Concerns
									</label>
									<ul class="concerns my-2">
										<li
											v-for="(item, index) in filteredConcerns"
											:key="index"
											>
											{{ item.name }}
										</li>             
									</ul>

									<a
										v-if="product.concerns.length > 6"
										@click="toggleShowMore()"
										class="show-more mt-3"
										>
										<template v-if="!showMoreConcerns">
										  show {{ product.concerns.length - 6 }} more
										</template>
										<template v-else>
										  show less
										</template>
									</a>
								</div>      
							</b-col>
						</b-row>

						<b-row>
						 	<b-col cols="12">
								<table class="table product-modal__table">
							 		<tbody>
										<tr>
											<td>
												<label class="mr-2">
													Use
											  	</label>

											  	<span v-if="product.daytimeuse" class="use-options">
												  	<span v-if="product.daytimeuse.daytimeuse == 'day'">
														day
														<i class="icon-daytime-routine"></i>
													</span>
													<span v-if="product.daytimeuse.daytimeuse == 'night'">
														<i class="icon-nightime-routine"></i>
													</span>
													<span v-if="product.daytimeuse.daytimeuse == 'both'">
														<i class="icon-daytime-routine"></i>
														<i class="icon-nightime-routine"></i>
													</span>
												</span>
											</td>
											<td>
												reviews
												<rating 
													:score="product.avg_review_score"  
													class="float-right" 
													:showAddRating="false"
													:showScore="true"
													@onClickAdd="showReviews(true)"
													/>
											</td>
										</tr>
									</tbody>
								</table>
							</b-col>
					</b-row>
				</b-container>


				<!-- Product data -->
				<div class="product-data container mt-3">

					<b-row class="mt-3">
						<b-col sm="12">
							<a class="btn w-100 buy-now" :href="product.link || product.product_link" target="_blank">buy now</a>         
						</b-col>
					</b-row>
					<template v-if="isLoggedIn">
						<b-row class="mt-2" v-if="!isPublic">
							<!-- <b-col class="pr-0">
								<div v-if="addOptions && addOptions.length > 1">
									<multiselect
										v-model="addSelected"
										:options="addOptions"
										:multiple="false"
										:searchable="false"
										:close-on-select="true"
										:clear-on-select="true"
										:preserve-search="false"
										:taggable="false"
										:preselect-first="false"
										placeholder="add to my routine"
										:allow-empty="true"
										:deselect-label="false"
										@input="save"
										selectLabel=""
										label="name"
										track-by="id"
										open-direction="bottom"
										/>
								</div>
								<div class="h-100" v-else>
									<b-button  
										v-if="addOptions.length" 
										@click="addToSingleRoutine(addOptions[0])"
										class="h-100 w-100 add-to-single-routine"
										> 
										<span v-if="!isSavingToRoutine">
											add to {{ addOptions[0].name }}
										</span>
										<span v-else>
											<b-spinner small type="grow" label="Spinning"></b-spinner>
										</span>
									</b-button>
								</div>
							</b-col> -->
							<b-col 
								cols="auto"
								>
								<button 
									class="p-2 open-wishlists"
									@click="toggleWishlistSelect"
									>
									<svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="8.8" height="12.758" viewBox="0 0 8.8 12.758"><path data-name="Trazado 497" d="M.4.4v11.556l4-3.013 3.2 2.413.8.6V.4z" fill="none" stroke="#000" stroke-width=".8"/></svg>
								</button>
							</b-col>
						</b-row>
					</template>
					<b-row class="mt-2" v-if="!isPublic">
						<b-col sm="12">
							<div 
								class="wishlist-select-wrap" 
								:class="{ 'visible': wishlistSelectOpen }"
								>
								<wishlist-select
									:product="product"
									@onSelect="toggleWishlistSelect"
									/>
							</div>
						</b-col>
					</b-row>
				</div>

			</b-col>
		</b-row>        
	</div>

		<b-tabs 
			content-class="mt-3" 
			v-model="tabIndex" 
			pills 
			card 
			fill
			>
			<b-tab title="details" active>
				<div class="container">
					<b-row>
						<b-col sm="12" md="6" v-if="product.description && product.description.stripHtmlTags().trim().length" class="my-4">
							<h4>What it is:</h4>
							<!-- {{ product.description.stripHtmlTags() }} -->
							<div class="content" v-html="product.description.formatToBasicHtml()">
							</div>
						</b-col>
						<b-col sm="12" md="6" v-if="product.description_long" class="my-4">
							<h4>How to use:</h4>
							<div class="content" v-html="product.description_long.formatToBasicHtml()">
							</div>
						</b-col>

						<b-col sm="12" md="6" :class="{ 'mt-md-4':  product.description_long && product.description && product.description.stripHtmlTags().trim().length }" v-if="product.benefits" class="my-4">
							<h4>Benefits</h4>
							<ul class="benefits">
								<li v-for="item in product.benefits">
									{{ item.name }}
								</li>
							</ul>
						</b-col>

					</b-row>
				</div>
			</b-tab>
			<b-tab title="ingredients">
				<div class="container">
					<b-row>
						<b-col 
							v-if="product.ingredients && product.ingredients.ingredients && product.ingredients.key_ingredients" :class="{'col-md-6': product.ingredients.ingredients.stripHtmlTags().trim().length}" sm="12">
							<h4>Key Ingredients:</h4>
							<div class="content" v-html="product.ingredients.key_ingredients" v-if="product.ingredients">
							</div>

						</b-col>
						<b-col v-if="product.ingredients && product.ingredients.key_ingredients && product.ingredients.ingredients" :class="{'col-md-6': product.ingredients.key_ingredients.stripHtmlTags().trim().length}" sm="12">
							<h4>Full ingredients list:</h4>
							<div class="content ingredients" v-html="product.ingredients.ingredients" v-if="product.ingredients">
							</div>
						</b-col>
					</b-row>
					<b-row v-if="product.ingredients && !product.ingredients.key_ingredients && !product.ingredients.ingredients">
						<b-col>
							no info
						</b-col>
					</b-row>
				</div>
			</b-tab>
			<b-tab title="reviews" @click="getReviews">
				<div class="container">
					<b-row>
						<b-col sm="12" md="12">
							<reviews ref="reviews" :product-id="product.id" :isPublic="isPublic" />
						</b-col>
					</b-row>
				</div>
			</b-tab>
			<b-tab title="beaute feed" @click="getFeed" v-if="!isPublic">
				<div class="container">
					<b-row>
						<b-col sm="12" md="6">
							<p>Coming Soon!</p>
						</b-col>
					</b-row>
				</div>
			</b-tab>
		</b-tabs>

		<div class="products-navigation container py-3" v-if="!hideFooter && !isPublic && (nextProduct || previousProduct)">
			<b-row>
				<b-col md="2" class="d-none d-md-block">
					<span class="my-auto image-container p-2" v-if="previousProduct != undefined">
						<div class="image" :style="{ backgroundImage: `url(${previousProduct.image})` }"/>
					</span>
				</b-col>
				<b-col md="8" cols="12" class="prev-next text-center my-auto">
					<span>
						<a @click="showPreviousProduct">
							<i class="icon-caret-left"></i> Prev
						</a>
					</span>
					<span>
						<a @click="showNextProduct">
							Next <i class="icon-caret-right"></i>
						</a>
					</span>
				</b-col>
				<b-col md="2" class="d-none d-md-block">
					<span class="my-auto image-container p-2" v-if="nextProduct">
						<div class="image" :style="{ backgroundImage: `url(${nextProduct.image})` }"/>
					</span>
				</b-col>
			</b-row>
		</div>
		<div class="spacer" v-else>
		</div>
	</b-modal>
</template>

<script>
	import APIClient from '@/api/requests';
	import Multiselect from 'vue-multiselect';
	import Swiper from '@/assets/scripts/vendor/swiper.esm.bundle';
	import Rating from '@/ui/Rating.vue';
	import WishlistSelect from '@/ui/WishlistSelect.vue';
	import Reviews from '@/ui/Reviews.vue';
	import VueLoadImage from 'vue-load-image'
	import EventBus from '@/event-bus'

	require('@/assets/scripts/main.js');

	export default {
		name: 'ProductModal',
		components: {
			Rating,
			Multiselect,
			Reviews,
			WishlistSelect,
			'vue-load-image': VueLoadImage
		},
		computed: {
			price: function () {
				if(this.product && !this.product.price) 
					return '';

				if(this.product.price_symbol)

					return `${this.product.price_symbol}${this.product.price}`
				else
					return `$${this.product.price}`
			},
			isLoggedIn: function () {
		      return this.$store.getters.isLoggedIn
		    },
		},
		props: {
			source: Object,
			modalId: String,
			allowClose: {
				type: Boolean,
				default: false,
			},
			sourceNavigation: {
				type: Array,
				default: function () { return [] }
			},
			sourceIndex: {
				type: Number,
				default: function () { return [] }
			},
			previousProduct: {
				type: Object,
				default: undefined
			},
			nextProduct: {
				type: Object,
				default: undefined
			},
			section: {
				type: String,
				default: ''
			},
			hideFooter: {
				type: Boolean,
				default: false
			},
			openReviews: {
				type: Boolean,
				default: false
			},
			isPublic: {
				type: Boolean,
				default: false
			},
			addProductQueryToUrl: {
				type: Boolean,
				default: true
			}
		},
		data () {
			return {
				product: {},
				addOptions: [],
				addSelected: null,
				images: [],
				galleryReady: false,
				isSavingToRoutine: false,
				tabIndex: 0,
				wishlistSelectOpen: false,
				showMoreConcerns: false,
				filteredConcerns: [],
				showMoreShades: false
			}
		},
		methods: {
			toggleShowMoreShades: function () {
				this.showMoreShades = !this.showMoreShades
			},
			getProductDetails: function() {


				if(typeof this.source == 'string') {
					this.product = {
						id: this.source
					}
				}

				this.product = this.source;

				if(this.isPublic) {
					this.product = this.source;

					this.product.renameProperty('product_id', 'id');
					this.product.renameProperty('product_name', 'name');
					this.product.renameProperty('product_link', 'link');
					this.product.renameProperty('product_description', 'description');
					this.product.renameProperty('product_description_long', 'description_long');
					this.product.renameProperty('product_image', 'image_url');
					this.product.renameProperty('product_bodyarea_id', 'bodyarea_id');
					this.product.renameProperty('price_us', 'price');
					this.product.renameProperty('product_bb_rating', 'rating');
					this.getProductConcerns();
					this.getProductBenefits();
					this.populateRoutines()
					this.setupSlider();

				}else {
					APIClient.getProductDetails(this.product.id, !this.isLoggedIn)
					.then( resp => {

						resp.data.renameProperty('product_id', 'id');
						resp.data.renameProperty('product_name', 'name');
						resp.data.renameProperty('product_link', 'link');
						resp.data.renameProperty('product_description', 'description');
						resp.data.renameProperty('product_description_long', 'description_long');
						resp.data.renameProperty('product_image', 'image_url');
						resp.data.renameProperty('product_bodyarea_id', 'bodyarea_id');
						resp.data.renameProperty('price_us', 'price');
						resp.data.renameProperty('product_bb_rating', 'rating');

						this.product = resp.data;

						this.getProductConcerns();
						this.getProductBenefits();
						this.populateRoutines()
						this.setupSlider();

					})
					.catch( err => {
						console.error(err)
					})
				}

			},
			getProductBenefits: function () {
				APIClient.getProductBenefits(this.product.bodyarea_id, this.product.id, this.isPublic || !this.isLoggedIn)
				.then( res => {
					this.product.benefits = res.data
					this.$forceUpdate()
				})
				.catch( err => {
					console.error(err)
				})
				.finally( () => {

				})
			},
			toggleShowMore: function () {
				this.showMoreConcerns = !this.showMoreConcerns
				if(!this.showMoreConcerns) {
					this.filteredConcerns = this.product.concerns.slice(0, 6)
				} else {
					this.filteredConcerns = this.product.concerns
				}
			},
			getProductConcerns: function () {
				APIClient.getProductConcerns(this.product.bodyarea_id, this.product.id, this.isPublic || !this.isLoggedIn)
				.then( res => {
					this.product.concerns = res.data
					this.filteredConcerns = this.product.concerns.slice(0, 6)
					this.$forceUpdate()
				})
				.catch( err => {
					console.error(err)
				})
				.finally( () => {

				})
			},
			onShow: function () {
				this.hideSlider()
				var self = this
				this.$nextTick( () => {
    				self.addProductToUrl()
    			})  
				
			},
			hideSlider: function () {

				this.galleryReady = false;

				this.nextProduct = this.sourceNavigation[this.sourceIndex + 1];
				this.previousProduct = this.sourceNavigation[this.sourceIndex - 1];
			},
			closeModal: function () {
				this.$root.$emit('bv::hide::modal', 'modal-1', '#btnShow')


				if(this.addProductQueryToUrl) this.$router.replace({ query: {} })
			},
		getReviews: function (showReviewForm = false) {
			setTimeout(() => {
				this.$refs.reviews.showReviews(showReviewForm)
			}, 500)
		},
		getFeed: function () {

		},
		showNextProduct: function () {
			if (!this.nextProduct) return;
			this.$bvModal.hide(`${this.section}_${this.source.id}`);
			this.$bvModal.show(`${this.section}_${this.nextProduct.id}`);
		},
		showPreviousProduct: function () {  
			if (!this.previousProduct) return;
			this.$bvModal.hide(`${this.section}_${this.source.id}`);
			this.$bvModal.show(`${this.section}_${this.previousProduct.id}`);
		},
		save: function () {
			if(!this.addSelected || this.isSavingToRoutine) return;

			this.isSavingToRoutine = true;

			// Check if both
			var selected;
			if(this.addSelected.id == 0) {
				selected = [1, 2];
			} else {
				selected = this.addSelected.id
			}

			APIClient.saveProductRoutine(this.product.id, selected)
			.then( res => {
				this.$bvToast.toast(`Added to your routine`, {
					title: 'Success',
					autoHideDelay: 5000,
					toastClass: 'success',
					appendToast: true,
					toaster: 'b-toaster-top-center',
				})

				// this.$root.$emit('routine_changed')
				EventBus.$emit('update-routines');
			})
			.catch( err => {
				console.error(err)
			})
			.finally( () => {
				this.isSavingToRoutine = false
			})
		},
		addToSingleRoutine: function(id) {
			this.addSelected = id
			this.save()
		},
		showReviews: function(showReviewForm = false) {
			let self = this
			setTimeout(() => {
				self.tabIndex = 2;
				self.getReviews(showReviewForm)
			}, 100)
		},
		populateRoutines: function() {
			switch(this.product.bodyarea_id){
				case 1:
				if(this.product.daytimeuse.daytimeuse == 'both') {
					this.addOptions = [
					{ id: 1, name: 'daytime routine'},
					{ id: 2, name: 'night time routine'},
					{ id: 0, name: 'both'}
					]
				}else if(this.product.daytimeuse == 'day') {
					this.addOptions = [
					{ id: 1, name: 'daytime routine'},
					]
				} else {
					this.addOptions = [
					{ id: 2, name: 'night time routine'}
					]
				}
				break;
				case 2: 
				this.addOptions = [
				{ id: 4, name: 'hair care routine' }
				]
				break;
				case 4: 
				this.addOptions = [
				{ id: 3, name: 'makeup routine' }
				]
				break;
			}
		},
		toggleWishlistSelect: function() {
			this.wishlistSelectOpen = !this.wishlistSelectOpen
		},
		close(bvModalEvt) {
			if(this.isPublic && !this.allowClose) {
				bvModalEvt.preventDefault()

				this.$emit("onClose", this.source)

			}
		},
		openBrand: function(product) {
			if(product.brand.brand_link != null && product.brand.brand_link.length > 0) {
				this.$router.push(`/me/brand/${product.brand.brand_link}`)
			}
		},
		addProductToUrl: function() {
			if(this.addProductQueryToUrl) this.$router.replace({ query: { product: this.source.id }})
		},
		setupSlider: function() {

			this.images = [];
			this.images.push(this.product.image_url); 

			this.product.images.forEach( (image, index) => {
				if(index > 0) {
					this.images.push(image.image)
				}
			})

			console.log(this.product.images)

			// Return if popup was closed
			var sliderContainer = document.getElementsByClassName('product-swiper-container');
			if (sliderContainer.length <= 0) {
			    return;
			}


			let galleryThumbs = new Swiper('.product-swiper-container.gallery-thumbs', {
				direction: 'horizontal',
				spaceBetween: 10,
				slidesPerView: 'auto',
				watchSlidesVisibility: true,
				watchSlidesProgress: true,
				navigation: {
					nextEl: '.gallery-thumbs__next',
				},
				breakpoints: {
					768: {
						direction: 'vertical',
						slidesPerView: 3,
					}
				}
			});

			let gallery = new Swiper('.product-swiper-container.gallery-main', {
				direction: 'horizontal',
				spaceBetween: 10,
				slidesPerView: 1,
				navigation: {
					nextEl: '.button-next',
					prevEl: '.button-prev',
				},
				thumbs: {
					swiper: galleryThumbs
				}
			});

			let self = this
			setTimeout(function(){            
				gallery.update();
				galleryThumbs.update();

				setTimeout(function(){
					gallery.slideTo(1)
					gallery.slideTo(0)
					
				}, 100)
				
				self.galleryReady = true
			}, 200)
		}
	}
}
</script>
