<template>
  <b-modal 
    id="location_modal"  
    title="Please, select your location from the list below" 
    body-class="p-0" 
    modal-class="location-modal" 
    ok-title="Save"
    cancel-title="May be later"
    @shown="getCities('')"
    centered
    @ok="handleOk"
    ok-variant="black"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    :ok-disabled="location == null"
    >
    <b-container>
    	<b-row>
    		<b-col cols="12">
	          	<multiselect 
		            v-model="location" 
		            id="ajax" 
		            :label="city"
		            :custom-label="cityWithCountry" 
		            track-by="id" 
		            placeholder="Type to search" 
		            open-direction="bottom" 
		            :options="cities" 
		            :multiple="false" 
		            :searchable="true" 
		            :loading="isLoading" 
		            :internal-search="false" 
		            :options-limit="300" 
		            :limit="3" 
		            :max-height="600" 
		            :show-no-results="false" 
		            :hide-selected="true" 
		            :select-label="''"
		            @search-change="getCities"
		            >
		            <span slot="noResult">
		                Oops! No elements found. Consider changing the search query.
		            </span>
	          </multiselect>
	        </b-col>
    	</b-row>
    </b-container>
    
  </b-modal>
</template>

<script>

import APIClient from '@/api/requests';
import Multiselect from 'vue-multiselect'
import Spinner from '@/ui/Spinner';

require('@/assets/scripts/main.js');

export default {
  name: 'LocationModal',
  components: {
    Multiselect,
    Spinner
  },
  props: {
    
  },
  data () {
    return {
    	isLoading: false,
    	location: null,
    	cities: []
    }
  },
  methods: {        
    getCities: function(query) {
    	this.isLoading = true;
    	APIClient.getCities(query)
	        .then( res => {
	          this.cities = res.data
	        })
	        .catch( err => {
	          console.error(err)
	        })
	        .finally( () => {
	          this.isLoading = false;
	        })
    },
    cityWithCountry ({ city, country }) {
      // return `${city}, ${country}`;
      return `${country}`;
    },
    handleOk: function (bvModalEvt) {
    	bvModalEvt.preventDefault()
        this.updateLocation()
    },
    updateLocation: function() {
    	let args = {
	        city_id: this.location.id,
	    }
	     
	    APIClient.updateProfile(args)
	        .then( res => {
	        	this.$bvToast.toast(`Location updated`, {
		            title: 'Success',
		            autoHideDelay: 5000,
		            toastClass: 'success',
		            appendToast: true,
		        })
	          	this.$store.dispatch('getProfile').then(() => {})
	          	this.$bvModal.hide('location_modal')
	        })
	        .catch( err => {
	          	console.error(err)
	          	this.$bvToast.toast('Something went wrong. Please try again later', {
		            title: 'Error',
		            autoHideDelay: 5000,
		            toastClass: 'error',
		            appendToast: true,
	          	})
	        })
	        .finally( () => {

	        })

    }
  },
  created () {


  },
  mounted () {
    
  }
}
</script>
