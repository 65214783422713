<template>
  <div class="reviews container">
  	<b-row 
      class="mb-3 reviews-top pb-4 mb-4"
      v-if="header && !isPublic"
      :class="{ 'no-body': !reviews.data.length }"
      v-show="!isLoading"
      >

      <!-- Reviews count -->
  		<b-col md="3">
  			<div class="counter-main mb-4 mb-lg-0">
          <div class="counter">{{ header.count}}</div>
          <div class="label">reviews</div>
          <button 
            class="btn btn-gray mt-4 px-4" 
            @click="showReviewForm"
            v-show="isLoggedIn"
            >
            <span v-if="!userReview">
              write a review
            </span>
            <span v-else>
              edit review
            </span>
          </button>
  			</div>
  		</b-col>

      <!-- Rating -->
  		<b-col 
        md="9" 
        class="stars-wrapper mb-4 mb-lg-0"
        >
        <label>Ratings</label>
        <rating-bars 
          :stars="header.stars" 
          :max="header.count" 
          />
  		</b-col>

      <!-- Images -->
  		<b-col md="5" class="images-wrapper" v-show="header.reviewImages.length">
        <label>Photos</label>
        <!-- <a href="#" class="view-gallery float-right">view gallery</a> -->
        <div class="" v-if="header">

          <div class="reviews-images">
            <div class="swiper-wrapper">
              <div
                v-for="(item, index) in header.reviewImages"
                :key="index"
                class="swiper-slide article"
                >
                <div class="image-wrapper">
                  <div
                    class="w-100 h-100 image"
                    :style="{ backgroundImage: `url(${item.review_image})` }"
                    >
                    <div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
  		</b-col>
  	</b-row>

    <div v-if="!isPublic">
      <b-collapse id="form-review" :visible="showForm">
        <review-form 
          :form="form"
          :whyLovedOptions="whyLovedOptions"
          :productId="productId"
          @onAdded="refreshReviews"
          :isEditing="isEditing"
          :review="userReview"
          id="review_form"
          />
      </b-collapse>
    </div>

    <div id="comments_list" class="my-4"></div>

    <b-row v-if="reviews.data.length">
      <b-col md="12">
        <b-row class="reviews-list-top" align-h="between">
          <b-col class="review-pagination">
            {{reviews.from}} - {{reviews.to}} of {{reviews.total}} reviews
          </b-col>
          <b-col>
          </b-col>
        </b-row>
        <b-row align-h="between">
          <b-col md="12" v-for="(review, index) in reviews.data" :key="index">
            <!-- {{review}} -->
            <b-row class="single-review py-4">

              <b-col md="3" cols="12">
                <rating :score="review.stars" showScore="false" :customStyle="'dark'" />
                <div class="reviewer my-2">
                  {{ review.first_name}} {{review.last_name.charAt(0)}}
                </div>
                <div class="date">
                  {{ moment(review.created_at).fromNow() }}
                </div>
              </b-col>

              <b-col md="7" cols="12">
                <label>
                  {{review.title}}
                </label>
                <div class="content mb-3">
                  {{review.content}}
                </div>
                <div class="helpful float-md-right">
                  was this helpful? <button :id="`yes-${review.review_id}`" @click="sendHelfulReview('yes', review.review_id)">YES</button><button :id="`no-${review.review_id}`" @click="sendHelfulReview('no', review.review_id)">NO</button>
                  
                </div>
              </b-col>

              <b-col md="2" cols="12" class="mt-3 mt-md-0">
                <div class="review-image-wrapper">
                  <div v-if="review.review_image" class="review-image" :style="{ backgroundImage: `url(${review.review_image})` }"/>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <div class="nav-pagination float-right mt-5">
              <a @click="getPreviousPage" :class="{ disabled: reviews.current_page <= 1 }">
                <svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="5.54" height="10.777" viewBox="0 0 5.54 10.777"><path data-name="Trazado 201" d="M0 5.389l4.835 5.389.705-.705-4.23-4.684L5.54.705 4.835 0z"/></svg>
              </a>
              {{ reviews.current_page }}  /  {{ reviews.last_page }}
              <a @click="getNextPage" :class="{ disabled: reviews.current_page >= reviews.last_page }">
                <svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="5.54" height="10.777" viewBox="0 0 5.54 10.777"><path data-name="Trazado 200" d="M5.538 5.389L.703 10.778l-.705-.705 4.23-4.684L-.002.705.703 0z"/></svg>
              </a>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col cols="12" class="mb-5" v-if="!isLoadingReviewsList">
        No reviews yet
      </b-col>
    </b-row>

    <div v-show="isLoading" class="my-5">
      <spinner />
    </div>

  </div>
</template>

<script>
import APIClient from '@/api/requests';
import Swiper from '@/assets/scripts/vendor/swiper.esm.bundle';
import Rating from '@/ui/Rating.vue';
import RatingBars from '@/ui/RatingBars.vue';
import ReviewForm from '@/ui/ReviewForm.vue';
import Multiselect from 'vue-multiselect';
import SelectedFilters from '@/ui/SelectedFilters.vue'
import Spinner from '@/ui/Spinner.vue';
import { helperMixin } from '@/mixins/helperMixin.js'

export default {
  name: 'Reviews',
  components: {
    Rating,
    Multiselect,
    SelectedFilters,
    Swiper,
    RatingBars,
    ReviewForm,
    Spinner,
  },
  mixins: [helperMixin],
  data () {
    return {
      header: null,
      reviews: {
        data: []
      },
      userReview: null,
      moment: require('moment'),
      isLoading: false,
      isLoadingReviewsList: true,
      previousPage: null,
      nextPage: null,
      showForm: false,
      form: {
        stars: null,
        title: null,
        content: null,
        image: null,
        whyLovedSelectedAll: [],
        wouldRecommend: null
      },
      whyLovedOptions: [],
      whyLovedSelected: null,
      isSendingReview: false,
      file: null,
      isEditing: false
    }
  },
  props: {
  	productId: Number,
    isPublic: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted () {
    
  },
  methods: {
    showReviewForm() {
      
      // this.$root.$emit('bv::toggle::collapse', 'form-review')
      this.showForm = !this.showForm

      if(this.isMobile() && this.showForm){
        var VueScrollTo = require('vue-scrollto');

        var options = {
          container: '#home_product_modal',
          element: '#review_form',
          easing: 'ease-in',
          offset: -60,
          force: true,
          cancelable: true,
        }

        setTimeout( () => {
          VueScrollTo.scrollTo('#review_form', 1000, options);  
        }, 1000)
        
      }
    },
    showReviews(showReviewForm = false) {
      if(!this.isPublic) {
        this.getReviewsHeader();  
      } 
      this.getReviews(this.isPublic)

      let self = this
      setTimeout( () => {
        if(showReviewForm)  {
          // this.$root.$emit('bv::toggle::collapse', 'form-review')
        }
        self.$forceUpdate()
      }, 1000)
    },
    getReviewsHeader() {
      this.isLoading = true;
      APIClient.getProductReviewsHeader(this.productId, !this.isLoggedIn)
        .then( res => {
          this.header = res.data
          setTimeout( () => {
            let gallery = new Swiper('.reviews-images', {
                direction: 'horizontal',
                spaceBetween: 10,
                slidesPerView: 3,
            });
          }, 100)
          
        })
        .catch( err => {
          console.error(err)
        })
        .finally( () => {
          this.isLoading = false;
        })

      // this.getReviewsBenefits();
    },
    getReviewsBenefits() {
      APIClient.getReviewsBenefits(this.productId)
        .then( res => {
          
          if(!res.data) return;

          this.whyLovedOptions = res.data.map( el => {
            if(el.benefit.skin_benefit_id){
              el.id = el.benefit.skin_benefit_id
              el.name = el.benefit.name
            }
            if(el.benefit.hair_benefit_id){
              el.id = el.benefit.hair_benefit_id
              el.name = el.benefit.name
            }
            if(el.benefit.makeup_benefit_id){
              el.id = el.benefit.makeup_benefit_id
              el.name = el.benefit.name
            }
            return el;
          })
        })
        .catch( err => {
          console.error(err)
        })
        .finally( () => {

        })
    },
    getReviews(isPublic) {
      this.isLoadingReviewsList = true
      APIClient.getProductReviews(this.productId, isPublic || !this.isLoggedIn)
        .then( res => {
          this.reviews = res.data
          this.nextPage = res.data.next_page_url
          this.previousPage = res.data.prev_page_url

          // Check if first review is from user
          if(this.reviews.data && this.reviews.data.length > 0) {
            if(this.reviews.data[0].user_id == this.profile.id) {
              this.userReview = this.reviews.data[0]
              this.setCurrentReview()
            }
          }
          this.$forceUpdate()
        })
        .catch( err => {
          console.error(err)
        })
        .finally( () => {
          this.isLoadingReviewsList = false
        })
    },
    getPreviousPage (){

      if (this.reviews.current_page <= 1 || this.isLoading) return;

      this.isLoading = true;
      APIClient.getProductReviewByPage(this.previousPage)
        .then( res => {
          this.reviews = res.data
          this.nextPage = res.data.next_page_url
          this.previousPage = res.data.prev_page_url
        })
        .catch( err => {
          console.error(err)
        })
        .finally( () => {
          this.isLoading = false;
        })
    },
    getNextPage () {
      if (this.reviews.current_page >= this.reviews.last_page || this.isLoading) return;

      this.isLoading = true;
      APIClient.getProductReviewByPage(this.nextPage)
        .then( res => {
          this.reviews = res.data
          this.nextPage = res.data.next_page_url
          this.previousPage = res.data.prev_page_url
        })
        .catch( err => {
          console.error(err)
        })
        .finally( () => {
          this.isLoading = false;
        })
    },
    sendHelfulReview (value, reviewId) {

      if(value == 'yes'){
        document.getElementById(`yes-${reviewId}`).classList.add('selected')
        document.getElementById(`no-${reviewId}`).classList.remove('selected')
      }else{
        document.getElementById(`no-${reviewId}`).classList.add('selected')
        document.getElementById(`yes-${reviewId}`).classList.remove('selected')
      }

      APIClient.sendHelfulReview(value, reviewId)
        .then( res => {
          this.$bvToast.toast(`Thanks for your input`, {
            title: 'Success',
            autoHideDelay: 5000,
            toastClass: 'success',
            appendToast: true,
          })
        })
        .catch( err => {
          console.error(err)
        })
        .finally( () => {
          
        })
    },
    setCurrentReview() {
      let whyLovedSelectedAll = []

      if(this.userReview.selected_hair_benefits) whyLovedSelectedAll = whyLovedSelectedAll.concat(this.userReview.selected_hair_benefits)
      if(this.userReview.selected_makeup_benefits) whyLovedSelectedAll = whyLovedSelectedAll.concat(this.userReview.selected_makeup_benefits)
      if(this.userReview.selected_skin_benefits) whyLovedSelectedAll = whyLovedSelectedAll.concat(this.userReview.selected_skin_benefits)

      this.form = {
        stars: this.userReview.stars,
        title: this.userReview.title,
        content: this.userReview.content,
        image: this.userReview.review_image,
        whyLovedSelectedAll: whyLovedSelectedAll,
        wouldRecommend: this.userReview.would_recommend
      }
      this.isEditing = true
    },
    refreshReviews() {
      this.getReviews()
      this.$root.$emit('bv::toggle::collapse', 'form-review')
    }
  },
  computed: {
    profile: function () {
      return this.$store.getters.profile
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn
    },
  },
}
</script>
