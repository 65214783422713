import axios from 'axios'
import store from '../store'
import router from '../router';

const baseDomain = process.env.VUE_APP_BASE_URL
const baseURL = `${baseDomain}/api`

const instance = axios.create({
  baseURL,
  timeout: 20000,
  headers: {
    APIkey: process.env.VUE_APP_API_KEY,
    'Content-Type': 'application/json'
  },
  transformRequest: [function (data, headers) {
    headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return JSON.stringify(data)
  }],
})

instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if(error.response.status == 401 && error.response.data.data == 'INVALID_TOKEN') {
      store.dispatch('logout').then(resp => {
        router.push({
          name: 'login',
          params: { expired: true }
        });
      })
    }
    return Promise.reject(error);
  });

export default instance
