<template>  
    <div class="dashboard"> 
      <div>
        <transition name="fade">
          <router-view />
        </transition>
      </div>
    </div>
</template>

<script>
export default {
  components: {},
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn
    },
    profile: function () {
      return this.$store.getters.profile
    }
  },
  mounted () {
    if(!this.profile.skin_type || !this.profile.skin_type.length){
      this.$router.push('/quiz')
    }
  },
  methods: {
    logout: function () {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login')
      })
    },
    getProfile: function () {
      this.$store.dispatch('getProfile').then(resp => {
        // console.log(resp)
      })
    }
  }
}
</script>
