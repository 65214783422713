<template>
  <div class="rating-bars">
     <b-row 
      class="five-stars" 
      align-h="between"
      >
      <b-col 
        cols="3" 
        class="label pr-0"
        >
        5 stars
      </b-col>
      <b-col>
        <b-progress :value="stars[0].count" :max="max"></b-progress>
      </b-col>
      <b-col cols="2" class="counter">
        ({{stars[0].count}})
      </b-col>
    </b-row>

    <b-row class="four-stars" align-h="between">
      <b-col cols="3" class="label pr-0">
        4 stars
      </b-col>
      <b-col>
        <b-progress :value="stars[1].count" :max="max"></b-progress>
      </b-col>
      <b-col cols="2" class="counter">
        ({{stars[1].count}})
      </b-col>
    </b-row>

    <b-row class="three-stars">
      <b-col cols="3" class="label pr-0">
        3 stars
      </b-col>
      <b-col>
        <b-progress :value="stars[2].count" :max="max"></b-progress>
      </b-col>
      <b-col cols="2" class="counter">
        ({{stars[2].count}})
      </b-col>
    </b-row>

    <b-row class="two-stars">
      <b-col cols="3" class="label pr-0">
        2 stars
      </b-col>
      <b-col>
        <b-progress :value="stars[3].count" :max="max"></b-progress>
      </b-col>
      <b-col cols="2" class="counter">
        ({{stars[3].count}})
      </b-col>
    </b-row>

    <b-row class="one-stars">
      <b-col cols="3" class="label pr-0">
        1 star
      </b-col>
      <b-col>
        <b-progress :value="stars[4].count" :max="max"></b-progress>
      </b-col>
      <b-col cols="2" class="counter">
        ({{stars[4].count}})
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'RatingBars',
  components: {},
  props: {
    stars: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
     
    }
  }
}
</script>
