<template>
	<b-modal 
		:id="modalId"  
		body-class="p-0" 
		modal-class="new-product-modal" 
		@show="onShow"
		@shown="getProductDetails" 
		@hidden="closeModal" 
		@close="close"
		hide-footer=true
		:no-close-on-backdrop="isPublic"
		>

		<div class="new-product-modal__inner">
			<div class="container">
				<div class="row">
					<div class="new-product-modal__left col-md-6">
						<div class="product-swiper-container new-product-modal__gallery">
							<div class="swiper-wrapper">
							 	<div 
									class="swiper-slide" 
									v-for="image in images" 
									v-if="images.length"
									>
							  		<div class="new-product-modal__image-wrapper">
										<div 
											class="new-product-modal__image" 
											:style="{ backgroundImage: `url('${image}')` }"
											/>
									</div>
								</div>                
							</div>
							<div class="swiper-pagination">
							</div>
						</div>
					</div>
					<div class="new-product-modal__right col-md-6">

						<div class="new-product-modal__content">

							<!-- Product Header -->
							<div class="new-product-modal__header">
								<div class="new-product-modal__brand">
									<h2 
										v-if="typeof product.brand === 'object'" 
										@click="openBrand(product)"
										class="new-product-modal__brand"
									 	:class="{link: product && product.brand.brand_link != '' }"
									  	>
							  			{{ product.brand.name }}
						  			</h2>
									<h2 
										@click="openBrand(product)" 
										:class="{link: product.brand}" 
										class="new-product-modal__brand"
										v-else
										>
					  					{{ product.brand }}
									</h2>
								</div>

								<div class="new-product-modal__header-bottom">
									<div class="new-product-modal__name">
										{{ product.name }}
									</div>

									<div 
										class="new-product-modal__price" 
										v-if="product"
										>
									 	{{ price }}
								 	</div>
								 </div>
							</div>

							<!-- Product Content -->

							<div class="new-product-modal__body">
								<!-- Concerns -->
								<div class="new-product-modal__concerns">
									<b-row v-if="product.concerns">
										<b-col cols="12">
											<div class="new-product-modal__concerns-content">
										  		<div class="new-product-modal__concerns-title">
													Concerns
												</div>
												<ul class="new-product-modal__concerns-items">
													<li
														v-for="(item, index) in filteredConcerns"
														:key="index"
														class="new-product-modal__concerns-item"
														>
														{{ item.name }}
													</li>             
												</ul>
											</div>   

											<a
												v-if="product.concerns.length > 6"
												@click="toggleShowMore()"
												class="new-product-modal__concerns-more"
												>
												<template v-if="!showMoreConcerns">
												  show {{ product.concerns.length - 6 }} more
												</template>
												<template v-else>
												  show less
												</template>
											</a>   
										</b-col>
									</b-row>
								</div>

								<!-- Buy Now -->
								<a class="new-product-modal__buy" :href="product.link || product.product_link" target="_blank">buy now</a>         

								<!-- Tabs -->
								<b-tabs 
									content-class="mt-3" 
									class="new-product-modal__tabs"
									v-model="tabIndex" 
									pills 
									card 
									fill
									>
									<b-tab title="details" active>

												<b-col sm="12" md="12" v-if="product.description && product.description.stripHtmlTags().trim().length" class="my-4">
													<h4>What it is:</h4>
													<!-- {{ product.description.stripHtmlTags() }} -->
													<div class="content" v-html="product.description.formatToBasicHtml()">
													</div>
												</b-col>
												<b-col sm="12" md="12" v-if="product.description_long" class="my-4">
													<h4>How to use:</h4>
													<div class="content" v-html="product.description_long.formatToBasicHtml()">
													</div>
												</b-col>

												<b-col sm="12" md="12" :class="{ 'mt-md-4':  product.description_long && product.description && product.description.stripHtmlTags().trim().length }" v-if="product.benefits" class="my-4">
													<h4>Benefits</h4>
													<ul class="benefits">
														<li v-for="item in product.benefits">
															{{ item.name }}
														</li>
													</ul>
												</b-col>


									</b-tab>
									<b-tab title="ingredients" class="ingredients-tab">

												<b-col 
													v-if="product.ingredients && product.ingredients.ingredients && product.ingredients.key_ingredients" :class="{'col-md-12': product.ingredients.ingredients.stripHtmlTags().trim().length}" sm="12">
													<h4>Key Ingredients:</h4>
													<div class="content" v-html="product.ingredients.key_ingredients" v-if="product.ingredients">
													</div>

												</b-col>
												<b-col v-if="product.ingredients && product.ingredients.key_ingredients && product.ingredients.ingredients" :class="{'col-md-12': product.ingredients.key_ingredients.stripHtmlTags().trim().length}" sm="12">
													<h4>Full ingredients list:</h4>
													<div class="content ingredients" v-html="product.ingredients.ingredients" v-if="product.ingredients">
													</div>
												</b-col>

											<template v-if="product.ingredients && !product.ingredients.key_ingredients && !product.ingredients.ingredients">
												<b-col>
													no info
												</b-col>
											</template>

									</b-tab>
									<b-tab title="reviews" @click="getReviews">
											<b-row>
												<b-col sm="12" md="12">
													<reviews ref="reviews" :product-id="product.id" :isPublic="isPublic" />
												</b-col>
											</b-row>
									</b-tab>
								</b-tabs>


							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
	import APIClient from '@/api/requests';
	import Multiselect from 'vue-multiselect';
	import Swiper from '@/assets/scripts/vendor/swiper.esm.bundle';
	import Rating from '@/ui/Rating.vue';
	import WishlistSelect from '@/ui/WishlistSelect.vue';
	import Reviews from '@/ui/Reviews.vue';
	import VueLoadImage from 'vue-load-image'
	import EventBus from '@/event-bus'

	require('@/assets/scripts/main.js');

	export default {
		name: 'NewProductModal',
		components: {
			Rating,
			Multiselect,
			Reviews,
			WishlistSelect,
			'vue-load-image': VueLoadImage
		},
		computed: {
			price: function () {
				if(this.product && !this.product.price) 
					return '';

				if(this.product.price_symbol)

					return `${this.product.price_symbol}${this.product.price}`
				else
					return `$${this.product.price}`
			},
			isLoggedIn: function () {
		      return this.$store.getters.isLoggedIn
		    },
		},
		props: {
			source: Object,
			modalId: String,
			allowClose: {
				type: Boolean,
				default: false,
			},
			sourceNavigation: {
				type: Array,
				default: function () { return [] }
			},
			sourceIndex: {
				type: Number,
				default: function () { return [] }
			},
			previousProduct: {
				type: Object,
				default: undefined
			},
			nextProduct: {
				type: Object,
				default: undefined
			},
			section: {
				type: String,
				default: ''
			},
			hideFooter: {
				type: Boolean,
				default: false
			},
			openReviews: {
				type: Boolean,
				default: false
			},
			isPublic: {
				type: Boolean,
				default: false
			},
			addProductQueryToUrl: {
				type: Boolean,
				default: true
			}
		},
		data () {
			return {
				product: {},
				addOptions: [],
				addSelected: null,
				images: [],
				galleryReady: false,
				isSavingToRoutine: false,
				tabIndex: 0,
				wishlistSelectOpen: false,
				showMoreConcerns: false,
				filteredConcerns: [],
				showMoreShades: false
			}
		},
		methods: {
			toggleShowMoreShades: function () {
				this.showMoreShades = !this.showMoreShades
			},
			getProductDetails: function() {


				if(typeof this.source == 'string') {
					this.product = {
						id: this.source
					}
				}

				this.product = this.source;

				if(this.isPublic) {
					this.product = this.source;

					this.product.renameProperty('product_id', 'id');
					this.product.renameProperty('product_name', 'name');
					this.product.renameProperty('product_link', 'link');
					this.product.renameProperty('product_description', 'description');
					this.product.renameProperty('product_description_long', 'description_long');
					this.product.renameProperty('product_image', 'image_url');
					this.product.renameProperty('product_bodyarea_id', 'bodyarea_id');
					this.product.renameProperty('price_us', 'price');
					this.product.renameProperty('product_bb_rating', 'rating');
					this.getProductConcerns();
					this.getProductBenefits();
					this.populateRoutines()
					this.setupSlider();

				}else {
					APIClient.getProductDetails(this.product.id, !this.isLoggedIn)
					.then( resp => {

						resp.data.renameProperty('product_id', 'id');
						resp.data.renameProperty('product_name', 'name');
						resp.data.renameProperty('product_link', 'link');
						resp.data.renameProperty('product_description', 'description');
						resp.data.renameProperty('product_description_long', 'description_long');
						resp.data.renameProperty('product_image', 'image_url');
						resp.data.renameProperty('product_bodyarea_id', 'bodyarea_id');
						resp.data.renameProperty('price_us', 'price');
						resp.data.renameProperty('product_bb_rating', 'rating');

						this.product = resp.data;

						this.getProductConcerns();
						this.getProductBenefits();
						this.populateRoutines()
						this.setupSlider();

					})
					.catch( err => {
						console.error(err)
					})
				}

			},
			getProductBenefits: function () {
				APIClient.getProductBenefits(this.product.bodyarea_id, this.product.id, this.isPublic || !this.isLoggedIn)
				.then( res => {
					this.product.benefits = res.data
					this.$forceUpdate()
				})
				.catch( err => {
					console.error(err)
				})
				.finally( () => {

				})
			},
			toggleShowMore: function () {
				this.showMoreConcerns = !this.showMoreConcerns
				if(!this.showMoreConcerns) {
					this.filteredConcerns = this.product.concerns.slice(0, 6)
				} else {
					this.filteredConcerns = this.product.concerns
				}
			},
			getProductConcerns: function () {
				APIClient.getProductConcerns(this.product.bodyarea_id, this.product.id, this.isPublic || !this.isLoggedIn)
				.then( res => {
					this.product.concerns = res.data
					this.filteredConcerns = this.product.concerns.slice(0, 6)
					this.$forceUpdate()
				})
				.catch( err => {
					console.error(err)
				})
				.finally( () => {

				})
			},
			onShow: function () {
				this.hideSlider()
				var self = this
				this.$nextTick( () => {
    				self.addProductToUrl()
    			})  
				
			},
			hideSlider: function () {

				this.galleryReady = false;

				this.nextProduct = this.sourceNavigation[this.sourceIndex + 1];
				this.previousProduct = this.sourceNavigation[this.sourceIndex - 1];
			},
			closeModal: function () {
				this.$root.$emit('bv::hide::modal', 'modal-1', '#btnShow')


				if(this.addProductQueryToUrl) this.$router.replace({ query: {} })
			},
		getReviews: function (showReviewForm = false) {
			setTimeout(() => {
				this.$refs.reviews.showReviews(showReviewForm)
			}, 500)
		},
		getFeed: function () {

		},
		showNextProduct: function () {
			if (!this.nextProduct) return;
			this.$bvModal.hide(`${this.section}_${this.source.id}`);
			this.$bvModal.show(`${this.section}_${this.nextProduct.id}`);
		},
		showPreviousProduct: function () {  
			if (!this.previousProduct) return;
			this.$bvModal.hide(`${this.section}_${this.source.id}`);
			this.$bvModal.show(`${this.section}_${this.previousProduct.id}`);
		},
		save: function () {
			if(!this.addSelected || this.isSavingToRoutine) return;

			this.isSavingToRoutine = true;

			// Check if both
			var selected;
			if(this.addSelected.id == 0) {
				selected = [1, 2];
			} else {
				selected = this.addSelected.id
			}

			APIClient.saveProductRoutine(this.product.id, selected)
			.then( res => {
				this.$bvToast.toast(`Added to your routine`, {
					title: 'Success',
					autoHideDelay: 5000,
					toastClass: 'success',
					appendToast: true,
					toaster: 'b-toaster-top-center',
				})

				// this.$root.$emit('routine_changed')
				EventBus.$emit('update-routines');
			})
			.catch( err => {
				console.error(err)
			})
			.finally( () => {
				this.isSavingToRoutine = false
			})
		},
		addToSingleRoutine: function(id) {
			this.addSelected = id
			this.save()
		},
		showReviews: function(showReviewForm = false) {
			let self = this
			setTimeout(() => {
				self.tabIndex = 2;
				self.getReviews(showReviewForm)
			}, 100)
		},
		populateRoutines: function() {
			switch(this.product.bodyarea_id){
				case 1:
				if(this.product.daytimeuse.daytimeuse == 'both') {
					this.addOptions = [
					{ id: 1, name: 'daytime routine'},
					{ id: 2, name: 'night time routine'},
					{ id: 0, name: 'both'}
					]
				}else if(this.product.daytimeuse == 'day') {
					this.addOptions = [
					{ id: 1, name: 'daytime routine'},
					]
				} else {
					this.addOptions = [
					{ id: 2, name: 'night time routine'}
					]
				}
				break;
				case 2: 
				this.addOptions = [
				{ id: 4, name: 'hair care routine' }
				]
				break;
				case 4: 
				this.addOptions = [
				{ id: 3, name: 'makeup routine' }
				]
				break;
			}
		},
		toggleWishlistSelect: function() {
			this.wishlistSelectOpen = !this.wishlistSelectOpen
		},
		close(bvModalEvt) {
			if(this.isPublic && !this.allowClose) {
				bvModalEvt.preventDefault()

				this.$emit("onClose", this.source)

			}
		},
		openBrand: function(product) {
			if(product.brand.brand_link != null && product.brand.brand_link.length > 0) {
				this.$router.push(`/me/brand/${product.brand.brand_link}`)
			}
		},
		addProductToUrl: function() {
			if(this.addProductQueryToUrl) this.$router.replace({ query: { product: this.source.id }})
		},
		setupSlider: function() {

			this.images = [];
			this.images.push(this.product.image_url); 

			this.product.images.forEach( (image, index) => {
				if(index > 0) {
					this.images.push(image.image)
				}
			})

			console.log(this.product.images)

			// Return if popup was closed
			var sliderContainer = document.getElementsByClassName('product-swiper-container');
			if (sliderContainer.length <= 0) {
			    return;
			}


			// let galleryThumbs = new Swiper('.product-swiper-container.gallery-thumbs', {
			// 	direction: 'horizontal',
			// 	spaceBetween: 10,
			// 	slidesPerView: 'auto',
			// 	watchSlidesVisibility: true,
			// 	watchSlidesProgress: true,
			// 	navigation: {
			// 		nextEl: '.gallery-thumbs__next',
			// 	},
			// 	breakpoints: {
			// 		768: {
			// 			direction: 'horizontal',
			// 			slidesPerView: 3,
			// 		}
			// 	}
			// });

			let gallery = new Swiper('.product-swiper-container.new-product-modal__gallery', {
				direction: 'vertical',
				spaceBetween: 10,
				slidesPerView: 1,
			    pagination: {
			    	el: '.product-swiper-container .swiper-pagination',
                    clickable: true,
                },
                mousewheel: {
				    invert: false,
				},
				// navigation: {
				// 	nextEl: '.button-next',
				// 	prevEl: '.button-prev',
				// },
				// thumbs: {
				// 	swiper: galleryThumbs
				// }
			});

			let self = this
			setTimeout(function(){            
				gallery.update();
				// galleryThumbs.update();

				setTimeout(function(){
					// gallery.slideTo(1)
					// gallery.slideTo(0)
					
				}, 100)
				
				self.galleryReady = true
			}, 1000)
		}
	}
}
</script>
