<template>
  <div class="my-reviews-tab">
    <b-container>
      <b-row>
        <b-col cols="12">
          <h2 class="section-heading">
            My Reviews
          </h2>
        </b-col>
        <b-col>

          <ul class="list">
            <li v-for="(item, index) in data">
              <review-single 
                :source="item" 
                @onDelete="removeFromList(index)"
                @onShowEditModal="showEditModal"
                />
            </li>
          </ul>

        </b-col>
      </b-row>

      <b-row 
        align-h="end" 
        class="my-5"
        v-if="items && items.length"
        >
        <b-col
          cols="12"
          class="product-pagination text-right"
          >
          <pagination 
            :meta="meta" 
            @onNext="getNextPage" 
            @onPrevious="getPreviousPage" 
            />
        </b-col>

      </b-row>
      <b-row v-if="!isLoading && !data.length">
        <b-col
            cols="12"
            class="text-center my-5"
            >
            You don't have any reviews yet
        </b-col>
      </b-row>

    </b-container>

    <edit-review-modal 
      :source="reviewToEdit" 
      @onSuccess="updateData"
      />
  </div>
</template>

<script>

import APIClient from '@/api/requests'
import ReviewSingle from '@/components/dashboard/overview/ReviewSingle.vue'
import EditReviewModal from '@/components/dashboard/reviews/EditReviewModal.vue'
import Pagination from '@/ui/Pagination.vue'
import { helperMixin } from '@/mixins/helperMixin.js'

export default {
  name: 'MyReviewsTab',
  components: {
    ReviewSingle,
    Pagination,
    EditReviewModal
  },
  mixins: [helperMixin],
  data() {
    return {
      isLoading: false,
      data: [],
      meta: null,
      reviewToEdit: null
    }
  },
  mounted () {
    this.getReviews()
  },
  methods: {
  	getNextPage() {
      this.getReviews(this.meta.nextPage)
    },
    getPreviousPage() {
      this.getReviews(this.meta.previousPage)
    },
    updateData() {
      console.log("updateData")
      this.getReviews()
    },
    getReviews(page = null) {
      this.isLoading = true
      APIClient.getMyReviews(page)
        .then( res => {
          let parsed = this.parsePaginatedData(res.data)
          this.data = parsed.items
          this.meta = parsed.meta
        })
        .catch( error => {
          console.error(error)
        })
        .finally( () => {
          this.isLoading = false
        })
    },
    removeFromList(index) {
      this.data.splice(index, 1)
    },
    showEditModal(review) {
      APIClient.getSingleReview(review.pr_product_id, review.id)
        .then( res => {
          this.reviewToEdit = res.data
          this.$bvModal.show('review_modal')
        })
        .catch( err => {
          console.error(err)
        })
        .finally( () => {

        })
    }
  }
}
</script>

