<template>
  <div class="makeup container">
  	<b-row class="mb-4 mt-lg-0 mt-5">
      <b-col cols="12">
        <h4 class="mb-3">
          my skin tone is:
        </h4>
      </b-col>

	  	<b-col cols="12" class="container">
	  		<b-row 
          align-h="between" 
          class="mb-3" 
          v-for="option in skinTones"
        >
	  			<b-col class="radio-label">
	  				{{ option.name }}
	  			</b-col>
	  			<b-col>
	  				<input 
              type="radio" 
              @change="updateValues" 
              v-model="makeupValues.skinTone" 
              :value="option" 
              class="uiswitch float-right"
              @mouseover="changeImage(option)" 
              />
	  			</b-col>
	  		</b-row>	  		
	  	</b-col>
  	</b-row>

  	<b-row class="mb-4 pt-4">
  		<b-col cols="12">
        <h4 class="mb-3">
          my undertone is: 
          <span id="tooltip-target-1">
            <svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="13.639" height="13.638" viewBox="0 0 13.639 13.638"><g data-name="Grupo 365"><g data-name="Grupo 420"><g data-name="Grupo 3"><circle data-name="Elipse 1" cx="6.819" cy="6.819" r="6.819" fill="#d6d5d5" transform="translate(-.001)"/></g><text transform="translate(6.043 9.5)" font-size="7" font-family="BeatriceTRIAL-Light, Beatrice TRIAL" font-weight="300" letter-spacing=".04em"><tspan x="0" y="0">i</tspan></text></g></g></svg>
          </span>
          <b-tooltip placement="rightbottom" target="tooltip-target-1" triggers="hover">
            undertone is the natural colour underneath your skin’s surface. your undertone helps you discover the best foundation and concealer shade for your skin.  
          </b-tooltip>
        </h4>
	  	</b-col>

	  	<b-col 
        cols="12" 
        class="container grid-radio"
      >
	  		<div 
          class="btn-checkbox" 
          :title="option.description" 
          v-for="(option, index) in undertones"
          >
		  		<input 
            :id="'undertone_' + index" 
            :value="option" 
            v-model="makeupValues.undertone" 
            type="radio" 
            @change="updateValues" 
          />
		  		<label 
            class="btn btn-outline-gray w-100" 
            :id="'undertone_label_' + index" 
            :for="'undertone_' + index"
          >
            {{ option.name }}
          </label>
          <div class="custom-tooltip">
            {{ option.description }}
          </div>
	  		</div>	
	  	</b-col>

      <b-col 
        cols="12" 
        class="container grid-radio mt-4"
      >      
        <div 
          class="slider-checkbox" 
          v-for="(option, index) in undertones"
        >
          <input 
            :id="'undertone_slider_' + index" 
            :value="option" 
            v-model="undertoneSlider" 
            type="radio" 
            @change="updateUndertone"
          />
          <label 
            :id="'undertone_slider_label_' + index" 
            :for="'undertone_slider_' + index"
          ></label>
        </div>  
      </b-col>
  	</b-row>

  	<b-row class="mb-4 pt-4">
  		<b-col md="12">
        <h4 class="mb-3">
          my favorite finish:
        </h4>
	  	</b-col>

	  	<b-col 
        cols="12" 
        class="container grid-checkbox three"
      >
	  		<div 
          class="btn-checkbox" 
          v-for="(option, index) in favoriteFinishes"
        >
		  		<input 
            :id="'makeup_' + index" 
            :value="option" 
            v-model="makeupValues.favoriteFinishes" 
            type="checkbox" 
            @change="updateValues" 
          />
		  		<label 
            class="btn btn-outline-gray w-100" 
            :for="'makeup_' + index"
          >
            {{ option.name }}
          </label>          
	  		</div>	  		
	  	</b-col>
  	</b-row>

  	<b-row class="mb-4 pt-4">
  		<b-col cols="12">
	  		<h4 class="mb-3">
          my budget for my makeup routine is
        </h4>
	  	</b-col>
	  	<b-col 
        cols="12" 
        class="container"
      >
	  		<vue-slider
          v-model="makeupValues.budget"
          :enable-cross="false"
          :interval="10"
          :marks="false" 
          :max="budgetRange.max"
          @drag-end="updateValues"   
          :tooltip="'always'"             
          class="mt-3"
        >
          <template v-slot:tooltip="{ value }">
            <div class="custom-tooltip">
              $/£ {{ value }}
            </div>
          </template>
        </vue-slider>
	  	</b-col>
  	</b-row>  	

    <b-row class="navigation"> 
      <b-col cols="12" md="6" class="d-flex align-items-center">    
        <div class="back-skip text-center text-md-left"> 
          <a @click="back">BACK</a> | <a @click="skip">SKIP</a>
        </div>
      </b-col> 
      <b-col cols="12" md="6">   
        <button 
          class="btn btn-dark-blue px-5 float-right my-4" 
          @click="next"
        >
          NEXT
        </button>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import Draggable from 'vuedraggable'

let helper = require('@/assets/scripts/main.js');

export default {
  name: 'Makeup',
  props: {
  	options: Object,
  	default: null    
  },
  data () {
    return {
     	isLoading: false,
     	makeupValues: {
     		skinTone: null,
     		favoriteFinishes: [],
        undertone: null,
     		budget: 500,
        image: null,
        skip: false
     	},
     	isDragging: false,
	    sixMonthsResolutions: [],
	    twelveMonthsResolutions: [],
     	productsOptions: [],
     	budgetRange: {
	        min: 0,
	        max: 500
	    },
      undertoneSlider: null,
    }
  },
  components: {
  	VueSlider,
  	Draggable
  },
  mounted () {    
  	this.isLoading = true   
  },
  computed: {
    dragOptionsLeft() {
      return {
        animation: 0,
        group: "description",
        ghostClass: "ghost",
        pull: 'clone'        
      }
    },
    dragOptionsRight() {
  	  return {		 
  	  	animation: 0,
          group: "description",
          ghostClass: "ghost",

  	  }    
  	},
  	skinTones() {
  		return (this.options) ? this.options.skin_tones : [];
  	},
  	favoriteFinishes() {
  		return (this.options) ? this.options.favorite_finish : [];
  	},
  	undertones() {		
  		return (this.options) ? this.options.skin_undertones : [];
  	},
    image() {
      if(!this.makeupValues.skinTone) return;

      switch(this.makeupValues.skinTone.id) {
        case 1:
          return `makeup-very-fair.jpg`;
        case 2:
          return `makeup-light.jpg`;
        case 3:
          return `makeup-medium.jpg`;
        case 4:
          return `makeup-deep.jpg`;
        case 5: 
          return `makeup-very-deep.jpg`;
        case 6: 
          return `makeup-medium-deep.jpg`;
        default:
          return `makeup-medium-deep.jpg`;
        break;
      }
    }
  },
  methods: {
  	updateValues: function () {
      this.undertoneSlider = this.makeupValues.undertone
      this.makeupValues.skip = false
      this.makeupValues.image = this.image
  		this.$emit('input', this.makeupValues)
  	},
    next() {
      // console.log("next")
      if(!this.makeupValues.favoriteFinishes.length || !this.makeupValues.skinTone || !this.makeupValues.undertone ) {
        this.$bvToast.toast('Please, fill all fields.', {
          title: 'Error',
          autoHideDelay: 5000,
          toastClass: 'error',
          appendToast: true,
        })
        return;
      }
      this.$emit('onNext')
    }, 
    skip () {
      this.makeupValues.skip = true
      this.$emit('input', this.makeupValues)
      this.$emit('onNext')
    },
    back() {
      this.$emit('onBack')
    },
    updateUndertone() {
      this.makeupValues.undertone = this.undertoneSlider
    },
    clear () {
      this.makeupValues = {
        skinTone: null,
        favoriteFinishes: [],
        undertone: null,
        budget: 500,
        image: null,
        skip: false
      }
    },
    changeImage(option) {
      let image
      console.log(option.id)
      switch(option.id) {
        case 1:
          image = `makeup-very-fair.jpg`;
          break;
        case 2:
          image = `makeup-light.jpg`;
          break;
        case 3:
          image = `makeup-medium.jpg`;
          break;
        case 4:
          image = `makeup-deep.jpg`;
          break;
        case 5: 
          image = `makeup-very-deep.jpg`;
          break;
        case 6: 
          image = `makeup-medium-deep.jpg`;
          break;
        default:
          image = `makeup-medium-deep.jpg`;
          break;
        break;
      }

      this.$emit('onHover', image)
    }
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    }
  }
}
</script>
