<template>
    <b-modal 
      :id="modalId" 
      :title="title"
      @ok="confirms"
      modal-class="confirmation-modal"
      centered
      >
      <p class="my-4">{{ message }}</p>
    </b-modal>
</template>

<script>
export default {
  name: 'ConfirmationModal',
  components: {},
  props: {
    message: {
      type: String,
      default: "Are you sure?"
    },
    title: {
      type: String,
      default: ""
    },
    modalId: {
      type: String,
      default: ""
    }
  },
  methods: {
    confirms: function(bvModalEvt) {
      this.$emit('onConfirm')
    }
  },
  data () {
    return {
     
    }
  },
}
</script>
