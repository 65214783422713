<template>
  <div 
  	class="wishlist-item" 
  	:class="customClass"
  	>
	 <div 
	  	v-if="wishlist"
	  	class="image item" 
	  	:style="{ backgroundImage: wishlist.image }"
	  	>
	  	
	  </div>
	  <template v-if="linkToWishlist">
	  		<router-link :to="{ name: 'single-wishlist', params: { id: wishlist.id }}">
			  	<div class="content">
			  		<div class="name">
			  			{{wishlist.name}}
			  		</div>
			  	</div>
			</router-link>
		</template>
		<template v-else>
			<div class="content">
			  		<div class="name">
			  			{{wishlist.name}}
			  		</div>
			  	</div>
		</template>
	</div>
</template>

<script>
export default {
  name: 'WishlistItem',
  components: {},
  props: {
    wishlist: Object,
    customClass: String,
    linkToWishlist: {
    	type: Boolean,
    	default: true
    }
  },
}
</script>
