<template>
  <div class="events-list">
    <b-row>
      <b-col cols="12">
        <ul>
          <li v-for="date in allDates">
            <b-row>
              <b-col cols="auto">
                <div class="date">
                  <div class="day-name">
                    {{ moment(date).format('ddd') }}
                  </div>
                  <div class="date-number">
                    <span>
                      {{ moment(date).format('DD') }}
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div v-for="item in eventsStart[date]" v-if="item.products">
                  <b-row class="content-wrapper">
                    <b-col 
                      cols="auto" 
                      class="pr-0"
                      >
                      <span class="start">
                      </span>
                    </b-col>
                    <b-col>
                      <div class="brand">
                        <b-row>
                          <b-col class="">
                            {{ item.products.name }}
                          </b-col>
                          <b-col class="text-right" cols="auto">
                            <svg svg-inline="" class="button" @click="editTracker(item)" focusable="false" role="presentation" data-name="noun_Edit pencil_1416596" xmlns="http://www.w3.org/2000/svg" width="10.124" height="10.156" viewBox="0 0 10.124 10.156"><g data-name="Grupo 457"><path data-name="Trazado 505" d="M9.92 1.118L9.009.204a.724.724 0 0 0-1.008 0L.639 7.59.6 7.629 0 10.156l2.519-.6 7.4-7.425a.729.729 0 0 0 .001-1.013zM1.046 7.75L7.4 1.37l1.356 1.361L2.4 9.106zm-.155.369l1.143 1.147-1.511.369zm8.758-6.278l-.6.6-1.357-1.362.6-.6a.328.328 0 0 1 .446 0l.911.914a.322.322 0 0 1 .1.233.313.313 0 0 1-.1.211z" fill="#bbb"/></g></svg>
                            <svg svg-inline="" class="button" @click="showConfirmation(`tracker_${item.id}_start`)" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="9" height="12.456" viewBox="0 0 9 12.456"><g data-name="Grupo 386" fill="#c2c2c2"><path data-name="Trazado 483" d="M.926 11.021c0 .807.472 1.434 1.029 1.434h5.059c.579 0 1.029-.657 1.029-1.434V1.908H.926zm5.038-8.008c0-.179.086-.3.214-.3s.214.12.214.3v7.111c0 .179-.086.3-.214.3s-.214-.12-.214-.3zm-1.694 0c0-.179.086-.3.214-.3s.214.12.214.3v7.111c0 .179-.086.3-.214.3s-.214-.12-.214-.3zm-1.694 0c0-.179.086-.3.214-.3s.214.12.214.3v7.111c0 .179-.086.3-.214.3s-.214-.12-.214-.3z"/><path data-name="Trazado 484" d="M8.134 1.7H6.2V.923a.843.843 0 0 0-.706-.926H3.463a.843.843 0 0 0-.706.926V1.7H.843C.365 1.7 0 2.208 0 2.806V3.5c0 .149.114.3.228.3h8.544c.114 0 .228-.149.228-.3v-.687A1.016 1.016 0 0 0 8.134 1.7zm-4.9-.747a.3.3 0 0 1 .251-.329h2.028a.3.3 0 0 1 .251.329v.777H3.258V.953z"/></g></svg>
                          </b-col>
                        </b-row>
                      </div>
                      <div class="name">
                        {{ item.products.brand }}
                      </div>
                    </b-col>

                    <confirmation-modal 
                      :message="'Are you sure you want to remove this product?'" 
                      :modal-id="`tracker_${item.id}_start`"
                      @onConfirm="deleteTracker(item)"
                      />

                  </b-row>
                </div>

                <div v-for="item in eventsEnd[date]" v-if="item.products">
                  <b-row class="content-wrapper">
                    <b-col 
                      cols="auto" 
                      class="pr-0"
                      >
                      <span class="end">
                      </span>
                    </b-col>
                    <b-col>
                      <div class="brand">
                        <b-row>
                          <b-col class="">
                            {{ item.products.name }}
                          </b-col>
                          <b-col class="text-right" cols="auto">
                            <svg svg-inline="" class="button" @click="editTracker(item)" focusable="false" role="presentation" data-name="noun_Edit pencil_1416596" xmlns="http://www.w3.org/2000/svg" width="10.124" height="10.156" viewBox="0 0 10.124 10.156"><g data-name="Grupo 457"><path data-name="Trazado 505" d="M9.92 1.118L9.009.204a.724.724 0 0 0-1.008 0L.639 7.59.6 7.629 0 10.156l2.519-.6 7.4-7.425a.729.729 0 0 0 .001-1.013zM1.046 7.75L7.4 1.37l1.356 1.361L2.4 9.106zm-.155.369l1.143 1.147-1.511.369zm8.758-6.278l-.6.6-1.357-1.362.6-.6a.328.328 0 0 1 .446 0l.911.914a.322.322 0 0 1 .1.233.313.313 0 0 1-.1.211z" fill="#bbb"/></g></svg>
                            <svg svg-inline="" class="button" @click="showConfirmation(`tracker_${item.id}_end`)" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="9" height="12.456" viewBox="0 0 9 12.456"><g data-name="Grupo 386" fill="#c2c2c2"><path data-name="Trazado 483" d="M.926 11.021c0 .807.472 1.434 1.029 1.434h5.059c.579 0 1.029-.657 1.029-1.434V1.908H.926zm5.038-8.008c0-.179.086-.3.214-.3s.214.12.214.3v7.111c0 .179-.086.3-.214.3s-.214-.12-.214-.3zm-1.694 0c0-.179.086-.3.214-.3s.214.12.214.3v7.111c0 .179-.086.3-.214.3s-.214-.12-.214-.3zm-1.694 0c0-.179.086-.3.214-.3s.214.12.214.3v7.111c0 .179-.086.3-.214.3s-.214-.12-.214-.3z"/><path data-name="Trazado 484" d="M8.134 1.7H6.2V.923a.843.843 0 0 0-.706-.926H3.463a.843.843 0 0 0-.706.926V1.7H.843C.365 1.7 0 2.208 0 2.806V3.5c0 .149.114.3.228.3h8.544c.114 0 .228-.149.228-.3v-.687A1.016 1.016 0 0 0 8.134 1.7zm-4.9-.747a.3.3 0 0 1 .251-.329h2.028a.3.3 0 0 1 .251.329v.777H3.258V.953z"/></g></svg>
                          </b-col>
                        </b-row>
                      </div>
                      <div class="name">
                        {{ item.products.brand }}
                      </div>

                      <confirmation-modal 
                        :message="'Are you sure you want to remove this product?'" 
                        :modal-id="`tracker_${item.id}_end`"
                        @onConfirm="deleteTracker(item)"
                        />
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </li>
        </ul>
      </b-col>
    </b-row>
    <div 
      v-if="!allDates.length"
      class="empty"
      >
      No products are being tracked this month.
    </div>
  </div>
</template>

<script>

import APIClient from '@/api/requests'
import Spinner from '@/ui/Spinner.vue'
import ConfirmationModal from '@/ui/ConfirmationModal.vue'

export default {
  name: 'EventsList',
  components: {
  	Spinner,
    ConfirmationModal
  },
  data() {
    return {
      isLoading: false,
      allDates: [],
      events: null,
      moment: require('moment')
    }
  },
  props: {
    data: {
      type: Array,
      default: [],
    },
    monthSelected: {
      type: Object,
      default: null
    }
  },
  mounted() {

  },
  methods: {
    showConfirmation(modalId) {
      this.$bvModal.show(modalId)
    },
    updateList() {

      // Group by startdate and end date
      if(Array.isArray(this.data)) {
        this.eventsStart = this.data.groupBy("start_date")
        this.eventsEnd = this.data.groupBy("end_date")

        // Remove events from another month (E.g when eventsEnd is in next month)
        Object.keys(this.eventsStart).forEach((item) => {
          if(this.monthSelected != this.moment(item, 'YYYY-MM-DD').format('MM/YYYY')) {
            delete this.eventsStart[item];
          }
        })
        Object.keys(this.eventsEnd).forEach((item) => {
          if(this.monthSelected != this.moment(item, 'YYYY-MM-DD').format('MM/YYYY')) {
            delete this.eventsEnd[item];
          }
        })

        let dates
        if(this.eventsStart && this.eventsEnd){
          dates = Object.keys(this.eventsStart).concat(Object.keys(this.eventsEnd))
        }else if(this.eventsStart) {
          dates = Object.keys(this.eventsStart)
        }else if(this.eventsEnd) {
          dates = Object.keys(this.eventsEnd)
        }
        
        if(dates){
          this.allDates = dates.filter((a, b) => dates.indexOf(a) === b)
        }
      }else {
        this.allDates = []
      }

    },
    deleteTracker(item) {
      let data = {
        product_id: item.products.product_id
      }

      APIClient.removeProductTracked(data)
        .then( res => {
          this.$emit('onChange')
        })
        .catch( err => {
          console.error(err)
        })
        .finally( () => {

        })
    },
    editTracker(item) {
      this.$emit('onClickEdit', item)
    },
    updateView(calendarEvents) {
      // Update props becuase it wasn't updated automatically
      if(calendarEvents) this.data = calendarEvents
      this.updateList()
    }
  },
}
</script>

