<template>
	<div class="search-result-product">
		<div class="search-result-product__inner">
			<div 
				class="search-result-product__thumb"
				:style="{ backgroundImage: `url(${product.image})` }"
				@click="openProduct"
				>
			</div>
			<div class="search-result-product__name">
				{{ product.name }}
			</div>

			<div 
				class="btn search-result-product__button"
				@click="openProduct"
				>
				view details
			</div>
		</div>
		<div class="search-result-product__brand-wrapper">
			<div class="search-result-product__brand-inner">
				<div 
					class="search-result-product__brand" 
					@click="openBrand"
					v-if="product.brand"
					>
					{{ product.brand.brand_name }}
				</div>
				<div 
					class="search-result-product__category" 
					>
					{{ product.main_category }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import Spinner from '@/ui/Spinner.vue';

	export default {
		name: 'SearchResultProduct',
		components: {

		},
		props: {
			product: null
		},
		mounted() {
		},
		methods: {
			openBrand: function() {
				if(this.product.brand_link != null && this.product.brand_link.length > 0) {
					this.$router.push(`/me/brand/${this.product.brand_link}`)
				}
			},
			openProduct() {
				this.$emit('onProductClick', this.product)
            },
		}
	}
</script>
