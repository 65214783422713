<template>
  <div class="skin-goals-wrap px-4 py-2 container">
    <b-row>
      <b-col sm="12">
        <div class="skin-goals">
          <label class="mb-4 w-100">
            my skin resolutions:
          </label>
          <div 
            :class="{disabled: isLoading}" 
            v-if="!isLoading"
          >
            <b-row 
              class="goals-option mb-4" 
              align-v="center"
            >
              <b-col 
                md="12" 
                class="pr-0"
              >
                for the next   
                <span class="outlined">
                  <multiselect 
                    v-model="monthSelected" 
                    :options="monthsOptions" 
                    label="label" 
                    track-by="value" 
                    :allow-empty="false"
                    :searchable="false" 
                    :close-on-select="true" 
                    :show-labels="false" 
                    placeholder="Pick a month" 
                    @input="showSkinGoals"
                  ></multiselect>
                </span>
                , my skin goals are:
  	          </b-col>
  	        </b-row>

            <b-row 
              class="overview-option mb-1" 
              align-v="center"
            >
              <b-col 
                md="12" 
                class="pr-0"
              >
                <ul>
                  <b-form-group>
                    <li
                      v-for="(item, index) in options"
                      :key="index"
                      class="mb-2"
                    >
                      <div class="custom-control custom-checkbox" >
                        <input 
                          :checked="optionsSelected.includes(item.id)" 
                          type="checkbox" class="custom-control-input" 
                          :id="`checkbox_skin_goal_${item.id}`" 
                          :value="item.id"
                        >
                        <label 
                          @click="send(item.id)" 
                          class="custom-control-label" 
                          :for="`checkbox_skin_goal_${item.id}`"
                        >
                          {{item.name}}
                        </label>
                      </div>
                    </li>
                  </b-form-group>
                </ul>
              </b-col>
            </b-row>
          </div>
        </div>  
      </b-col>
    </b-row>
  </div>
</template>

<script>

import APIClient from '@/api/requests'
import Multiselect from 'vue-multiselect'
import Spinner from '@/ui/Spinner.vue'

export default {
  name: 'SkinGoals',
  components: {
    Multiselect,
    Spinner
  },
  data () {
    return {
    	monthsOptions: [ { value: 6, "label": "6 months" }, { value: 12, "label": "12 months" }],
      monthSelected: { value: 6, "label": "6 months" },
      options: [],
      optionsSelected: [],
      isLoading: false
    }
  },
  mounted () {  
    this.getUserSkinGoals()
  },
  methods: {
    // getSkinGoals: function (months){
    //   this.isLoading = true;
    //   this.options = []
    //   this.optionsSelected = []
    //   console.log(this.monthSelected.value)
    //   APIClient.getSkinGoals(this.monthSelected.value)
    //     .then(resp => {
    //       this.options = resp.data
    //       // console.log(resp)
    //       for (var i = 0; i < resp.data.length; i++) {
    //         console.log({...resp.data[i]})
    //       }
    //       this.getUserSkinGoals(this.monthSelected.value)
    //     })
    //     .catch(err => {
    //       console.error(err)
    //     })
    //     .finally( () => {
    //       this.isLoading = false
    //     })
    // },  
    send: function (checked) {
      APIClient.updateUserSkinGoals(checked)
        .then(resp => {
            // console.log(resp.data)
          })
          .catch(err => {
            console.error(err)
          })
    },
    showSkinGoals: function () {
      this.getUserSkinGoals()
    },
    getUserSkinGoals: function () {  
      this.options = []
      this.optionsSelected = []    
      this.isLoading = true
      APIClient.getUserSkinGoals(this.monthSelected.value)
        .then(resp => {      
          // console.log(resp.data)   
          for (var i = resp.data.length - 1; i >= 0; i--) {
            let selected = resp.data[i];
            selected.skin_goals[0].id = selected.id
            this.options.push({...selected.skin_goals[0]})
            if(selected.checked == "1") this.optionsSelected.push(parseInt(selected.id))
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally( () => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style></style>
