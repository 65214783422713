<template>
    <div class="single-wishlist">
      <section class="header">
        <simple-header />
      </section>
      <section class="content single-wishlist__inner" id="single_wishlist_inner">
        <b-container>
          <b-row class="flex-direction-column flex-lg-row-reverse">
            <b-col 
            cols="12" 
            md="4"
            class="px-0"
            >
            <wishlists-slider />
          </b-col>
          <b-col 
            cols="12"
            md="8"
            class="p-5"
            >

            <b-row v-if="notFound">
              <b-col 
                class="text-center not-found"
                cols="12"
                >
                List not found. Go to <router-link :to="{ name: 'overview'}">Dashboard</router-link>
              </b-col>
            </b-row>

            <b-row class="mb-3" v-if="wishlist">
              <b-col cols="12">
                <div class="top-nav pb-2 mb-3">
                    <b-row class="mb-3 align-items-center">
                      <b-col 
                        cols="12" 
                        md="6" 
                        class="text-center text-md-left mb-3 mb-md-0"
                        >
                        <h2 class="single-wishlist__heading">
                          {{ wishlist.name }}
                        </h2>
                        <span 
                          class="counter" 
                          >
                          {{ wishlist.products.length }} 
                          <template v-if="wishlist.products.length > 1">
                            items
                          </template>
                          <template v-else>
                            item
                          </template>
                        </span>
                      </b-col>
                      <b-col 
                        cols="12" 
                        md="6" 
                        v-if="wishlist.is_editable"
                        class="text-center text-md-left"
                        >
                        <a 
                          class="btn btn-delete px-4 float-md-right" 
                          @click="showConfirm" 
                          target="_blank"
                          v-if="!isLoading && !notFound"
                          >
                          Delete list
                        </a>
                        <a 
                          class="btn btn-edit px-4 float-md-right" 
                          @click="showEdit" 
                          target="_blank"
                          v-if="!isLoading && !notFound"
                          >
                          Edit
                        </a>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
            </b-row>
              <b-row>
                <transition name="fade">
                  <b-col 
                    cols="12" 
                    class="px-0 px-md-3" 
                    v-if="!isLoading"
                    >
                    <div class="grid" v-if="wishlist">
                      <div class="item" v-for="(item, index) in wishlist.products">
                          <Product 
                            :source="item" 
                            :sourceNavigation="wishlist.products" 
                            :sourceIndex="index" 
                            :removeIcon="true"
                            :addToUrlOnModalOpen="false"
                            @onRemove="removeFromWishlist(item)"
                            />
                      </div>
                    </div>
                  </b-col>
                </transition>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </section>

      <confirmation-modal 
        :message="'Are you sure you want to delete this list?'" 
        :modal-id="`delete_list`"
        @onConfirm="deleteWishlist()"
        />
      <edit-wishlist-modal 
        :wishlist="wishlist" 
        :modal-id="`edit_list`"
        @onConfirm="saveWishlist()"
        />
      <!-- <add-to-wishlist-modal 
        :selected-wishlist="wishlist" 
        /> -->
      <wishlist-modal 
        :selected-wishlist="wishlist" 
        :custom-id="`edit_wishlist_modal`"
        @onWishlistCreated="refreshWishlists" 
        />
    </div>
</template>

<script>

import APIClient from '@/api/requests'
import Product from '@/ui/products_browser/Product.vue'
import SimpleHeader from '@/ui/SimpleHeader'
import WishlistsSlider from '@/components/dashboard/overview/WishlistsSlider'
import ConfirmationModal from '@/ui/ConfirmationModal'
import EditWishlistModal from '@/ui/EditWishlistModal'
import WishlistModal from '@/ui/WishlistModal.vue'
import EventBus from '@/event-bus'

export default {
  name: 'SingleWishlist',
  components: {
    Product,
    SimpleHeader,
    ConfirmationModal,
    WishlistsSlider,
    EditWishlistModal,
    WishlistModal
  },
  computed: {
  },
  data () {
    return {
      wishlist: null,
      isLoading: false,
      notFound: false
    }
  },
  mounted () {
    this.getWishlist()
  },
  created() {
        EventBus.$on('wishlist-edited', this.onWishlistEdited)
  },
  beforeDestroy() {
      EventBus.$off('wishlist-edited')
  },
  methods: {
    getWishlist: function () {
      this.isLoading = true
      APIClient.getWishlist(this.$route.params.id)
        .then( res => {
          console.log(res)
          this.wishlist = res.data   

          // this.updateInnerContent()
        })
        .catch( err => {
          console.error(err)
          if(err.response.status == "404") {
            this.notFound = true
          }
        })
        .finally( () => {
          this.isLoading = false
        })
    },
    onWishlistEdited(wishlist) {
      console.log(wishlist)
      this.wishlist.name = wishlist.name
    },
    // updateInnerContent() {
    //   console.log()
    //   // const collectionHeight = document.getElementById('collections').clientHeight
    //   // console.log(collectionHeight)
    //   // document.getElementById('single_wishlist_inner').style.minHeight = collectionHeight + 200 + "px";
    // },
    showConfirm() {
      this.$bvModal.show(`delete_list`)
    },
    showEdit() {
      // this.$bvModal.show(`edit_list`)

      this.$bvModal.show('edit_wishlist_modal')
    },
    deleteWishlist: function() {
      this.isLoading = false
      let data = {
        id: this.$route.params.id
      }

      APIClient.deleteWishlist(data)
        .then( res => {
          console.log(res)
          this.$bvToast.toast(`List successfully deleted`, {
            title: 'Success',
            autoHideDelay: 2000,
            toastClass: 'success',
            appendToast: true,
          })

          let self = this
          setTimeout(() => {
            self.$router.push('/me/dashboard')         
          }, 2000)
          
        })
        .catch( err => {
          console.error(err)
        })
        .finally( () => {
          this.isLoading = false
        })
    },
    saveWishlist: function() {
      alert("save")
    },
    removeFromWishlist: function(item) {
      let data = {
        id: this.$route.params.id,
        product_id: item.id
      }
    
      APIClient.removeFromWishlist(data)
        .then( resp => {
          this.getWishlist()
        })
        .catch( err => {
          console.error(err)
        })
        .finally( () => {

        })
    }
  },
  watch: {
    $route(to, from) {
      this.getWishlist()
    }
  }
}
</script>
