<template>
  <b-modal
    id="notifications_modal"  
    title="all notifications" 
    body-class="p-0" 
    modal-class="notifications-modal" 
    @show="getAllNotifications" 
    @hidden="closeModal" 
    hide-footer="true" 
    centered
    >
    <div slot="modal-header-close">
      <i class="icon-close-no-background"></i>
    </div>

      <div class="container">
        <div class="py-4 px-0 px-lg-2">
          <simplebar data-simplebar-auto-hide="false" class="notifications-wrap container">
                <b-row v-if="!isLoading">
                  <b-col sm="12">
                    <div class="notifications" v-if="hasNotifications">
                      <!-- <div v-for="(items, index) in notifications" >                                            -->
                        <ul class="list">
                          <li
                            v-for="(item, item_index) in notifications"
                            :key="index"
                            class="container item mb-3 notification"
                             @click="openSingleNotification(item)"
                          >
                            <b-row
                              class="py-2"
                              align-v="center"
                            >
                              <b-col
                                sm="2"
                                cols="2"
                                class="pr-0"
                              >
                                <img
                                  src="@/assets/images/logos/bb-submark.png"
                                  class="mx-auto w-100"
                                >
                              </b-col>
                              <b-col sm="10" cols="10">
                                <b-row>
                                  <b-col>
                                    <label>{{ item.title }}</label>
                                  </b-col>
                                  <b-col class="text-right time">
                                    <label>
                                       {{ moment(item.date).calendar(null, {
                                          sameDay: '[Today]',
                                          nextDay: '[Tomorrow]',
                                          nextWeek: 'dddd',
                                          lastDay: '[Yesterday]',
                                          lastWeek: 'MMM DD',
                                          sameElse: 'MMM DD'
                                        }) }}
                                    </label>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col>
                                    <p>{{ item.content.stripHtmlTags() }}</p>
                                  </b-col>
                                </b-row>
                              </b-col>
                            </b-row>
<!-- 
                            <notification-modal 
                              :modalId="`modal_notification_${item.id}`" 
                              section="notification" 
                              :source="item" 
                              :sourceNavigation="items" 
                              :sourceIndex="index" 
                              @closed="markAsRead(item_index)" 
                              /> -->

                          </li>
                        </ul>
                      </div>            

                    <!-- </div>       -->
                    <div v-else class="notifications">

                      <div class="no-notifications text-center my-5">
                        no notifications
                      </div>

                    </div>  

                  </b-col>
                </b-row>
                <spinner v-if="isLoading" />
          </simplebar>  
        </div>
    </div>

  </b-modal>
</template>

<script>

import APIClient from '@/api/requests';
import Spinner from '@/ui/Spinner.vue'
import simplebar from 'simplebar-vue';
import NotificationModal from '@/ui/NotificationModal.vue'
import EventBus from '@/event-bus'

require('@/assets/scripts/main.js');

export default {
  name: 'NotificationsModal',
  components: {
    Spinner,
    simplebar,
    NotificationModal
  },
  props: {

  },
  data () {
    return {
      notification: [],
      moment: require('moment'),
      isLoading: false,
      hasNotifications: true,
    }
  },
  methods: {        
    closeModal: function () {
      this.$emit('closed')
      this.$root.$emit('bv::hide::modal', 'modal-1', '#btnShow')
    },
    getAllNotifications() {
      this.isLoading = true
      APIClient.getAllNotifications(100)
        .then( res => {
          // console.log(res)
          this.notifications = res.data
          if(!this.notifications.length) this.hasNotifications = false
        })
        .catch( err => {
          console.error(err)
        })
        .finally( () => {
          this.isLoading = false;
        })
    },
    openSingleNotification: function(item, index) {
      this.$bvModal.hide('notifications_modal') 
      EventBus.$emit('open-single-notification', item)
      // this.$bvModal.show(`modal_notification_${item.id}`)
    },
    markAsRead: function (item) {
      let notificationId = this.notifications[index].id      
      APIClient.markNotificationAsRead({ notification_id: [notificationId] })
        .then(resp => {
          // console.log("notification marked as read")
        })
        .catch(err => {
          // console.log(err)
        })
        .finally( () => {
          

        })
    },
  },
  created () {


  },
  mounted () {
    
  }
}
</script>
