<template>
  <div class="hair container">
  	<b-row class="mb-4 mt-lg-0 mt-5">
  		<b-col cols="12">
	  		<h4 class="mb-3">
          my hair type is:
        </h4>
	  	</b-col>
	  	
	  	<b-col 
        cols="12" 
        class="container"
      >
	  		<b-row 
          align-h="between" 
          class="mb-3" 
          v-for="option in hairTypes"
        >
	  			<b-col class="radio-label">
	  				{{ option.name }}
	  			</b-col>
	  			<b-col>
	  				<input 
              type="radio" 
              @change="updateValues" 
              v-model="hairValues.hairType" 
              :value="option" 
              class="uiswitch float-right"
              @mouseover="changeImage(option)" 
            />
	  			</b-col>
	  		</b-row>	  		
	  	</b-col>
  	</b-row>

  	<b-row class="mb-4 pt-4">
  		<b-col cols="12">
        <h4 class="mb-3">
          I'm looking for products to help with:
        </h4>
	  	</b-col>

	  	<b-col 
        cols="12" 
        class="grid-checkbox three"
      >
	  		<div 
          class="btn-checkbox" 
          v-for="(option, index) in hairConcerns"
        >
		  		<input 
            :id="'hair_' + index" 
            :value="option" 
            v-model="hairValues.hairConcerns" 
            type="checkbox" 
            @change="updateValues"
          />
		  		<label 
            class="btn btn-outline-gray w-100" 
            :for="'hair_' + index"
          >
            {{ option.name }}
          </label>
	  		</div>	  		
	  	</b-col>
  	</b-row>

  	<b-row class="mb-4 pt-4">
  		<b-col cols="12">
        <h4 class="mb-3">
          my budget for my haircare routine is
        </h4>
	  	</b-col>

	  	<b-col 
        cols="12" 
        class="container"
      >
	  		<vue-slider
          v-model="hairValues.budget"
          :enable-cross="false"
          :max="budgetRange.max"
          @drag-end="updateValues" 
          :interval="10"
          :marks="false"               
          :tooltip="'always'"         
          class="mt-3"
        >
          <template v-slot:tooltip="{ value }">
            <div class="custom-tooltip">$/£ {{ value }}</div>
          </template>
        </vue-slider>
	  	</b-col>
  	</b-row>  	

    <b-row class="navigation"> 
      <b-col cols="12" md="6" class="d-flex align-items-center">    
        <div class="back-skip text-center text-md-left">  
          <a @click="back">BACK</a> | <a @click="skip">SKIP</a> | <a @click="restartQuiz">RESTART QUIZ</a>
        </div>
      </b-col> 
      <b-col cols="12" md="6">   
        <button 
          class="btn btn-dark-blue px-3 w-100 w-md-auto float-none float-md-right my-4" 
          @click="next"
        >
          GET MY RECOMMENDATIONS
        </button>
      </b-col>
     <!--  <b-col 
        cols="12" 
        class="retake text-center text-md-left mb-5">  
        <a @click="restartQuiz">
          Restart Quiz
        </a>
      </b-col> -->
    </b-row>

  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import Draggable from 'vuedraggable'

let helper = require('@/assets/scripts/main.js');

export default {
  name: 'Hair',
  props: {
  	options: Object,
  	default: null
  },
  data () {
    return {
     	isLoading: false,
     	hairValues: {
     		hairType: null,
     		hairConcerns: [],
     		budget: 500,
     		image: null,
        skip: false
     	},
     	isDragging: false,
     	productsOptions: [],
     	budgetRange: {
	        min: 0,
	        max: 500
	    },
    }
  },
  components: {
  	VueSlider,
  	Draggable
  },
  mounted () {    
  	this.isLoading = true   
  },
  computed: {
	hairTypes() {
		return (this.options) ? this.options.types : [];
	},
	hairConcerns() {
		return (this.options) ? this.options.concerns : [];
	},
	image() {

	  if(!this.hairValues.hairType) return;

      switch(this.hairValues.hairType.id) {
        case 1:
          return `hair-straight.jpg`;
        case 2:
          return `hair-wavy.jpg`;
        case 3:
          return `hair-curly.jpg`;
        case 4:
          return `hair-coily.jpg`;
        case 5:
          return `hair-coily.jpg`;
        default:
          return `hair-coily.jpg`;
        break;
      }
    }	
  },
  methods: {
    changeImage(option) {
      let image
      switch(option.id) {
        case 1:
          image = `hair-straight.jpg`;
          break;
        case 2:
          image = `hair-wavy.jpg`;
          break;
        case 3:
          image = `hair-curly.jpg`;
          break;
        case 4:
          image = `hair-coily.jpg`;
          break;
        case 5: 
          image = `hair-coily.jpg`;
          break;
        case 6: 
          image = `hair-coily.jpg`;
          break;
        default:
          image = `hair-coily.jpg`;
        break;
      }

      this.$emit('onHover', image)
    },
  	updateValues: function () {
  		this.hairValues.image = this.image
      this.hairValues.skip = false
  		this.$emit('input', this.hairValues)
  	},
  	onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    removeItem(array, index) {
    	array.splice(0, 1)
    },
    cloneItem(item) {   
      return item;
    },
    onChangeSixMonths(event) {
    	if(event.added) {	
			this.sixMonthsResolutions = helper.removeDuplicates(this.sixMonthsResolutions, 'value')
    	}
    },
    onChangeTwelveMonths(event) {
    	if(event.added) {	
			this.twelveMonthsResolutions = helper.removeDuplicates(this.twelveMonthsResolutions, 'value')
    	}
    },
    next() {
      if(!this.hairValues.hairConcerns.length || !this.hairValues.hairType ) {
        this.$bvToast.toast('Please, fill all fields.', {
          title: 'Error',
          autoHideDelay: 5000,
          toastClass: 'error',
          appendToast: true,
        })
        return;
      }

      this.$emit('onNext')
    }, 
    back() {
      this.$emit('onBack')
    },
    skip () {
      this.hairValues.skip = true
      this.$emit('input', this.hairValues)
      this.$emit('onNext')
    },
    restartQuiz () {
      this.$emit('onRestart')
    },
    clear () {
      this.hairValues = {
        hairType: null,
        hairConcerns: [],
        budget: 500,
        image: null,
        skip: false
      }
    },
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    }
  }
}
</script>
