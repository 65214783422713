<template>
  <div class="collections px-4 py-5 col-12" id="collections">
    <div class="wishlists-slider-wrapper">
    <div 
      class="wishlists-slider"
      :class="{ 'two-items': slides.length == 1 }"
      >

      <h2 class="mb-4">
        my collections
      </h2>

      
      <div class="swiper-container px-2">
        <div class="swiper-wrapper">
          <div 
            class="swiper-slide wishlist-item" 
            @click="openCreateModal"
            >
            <div class="image item add-wishlist">
              <div class="content">
                <svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g data-name="Grupo 695"><path data-name="Trazado 61338" d="M9.333 0v9.338H0v1.333h9.333v9.333h1.333v-9.333H20V9.338h-9.333V.005z" fill="#484848"/></g></svg>
              </div>
            </div>
          </div>
        
          <wishlist-item 
            v-for="(item, index) in slides"
            :wishlist="item"
            :custom-class="`swiper-slide`"
            >
          </wishlist-item>

        </div>
        
      
      <transition name="fade">
        <div v-show="isLoading">
          <spinner />
        </div>
      </transition>

      <wishlist-modal 
        @onWishlistCreated="refreshWishlists" 
        />
    </div>

    <div class="swiper-nav-wrapper" v-show="slides.length > 1">
          <div class="swiper-pagination"></div>

          <div class="swiper-prev">
            <svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="14.774" height="7.277" viewBox="0 0 14.774 7.277"><g data-name="Grupo 145"><path data-name="Trazado 105" d="M0 3.639L3.582.101a.331.331 0 0 1 .468.467L1.29 3.305h13.151a.334.334 0 0 1 0 .668H1.29L4.05 6.71a.331.331 0 1 1-.468.467z" fill="#fff"/></g></svg>
          </div>
          <div class="swiper-next">
            <svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="14.775" height="7.277" viewBox="0 0 14.775 7.277"><g data-name="Grupo 144"><path data-name="Trazado 104" d="M14.776 3.639L11.193.101a.33.33 0 0 0-.467.467l2.759 2.737H.334a.334.334 0 1 0 0 .668h13.151L10.726 6.71a.33.33 0 0 0 .467.467z" fill="#fff"/></g></svg>
          </div>
        </div>
      </div>
  </div>
</div>
</template>

<script>

import APIClient from '@/api/requests'
import Swiper from '@/assets/scripts/vendor/swiper.esm.bundle'
import Spinner from '@/ui/Spinner.vue'
import WishlistModal from '@/ui/WishlistModal.vue'
import WishlistItem from '@/ui/WishlistItem.vue'
import EventBus from '@/event-bus'
var Sticky = require('sticky-js');

export default {
  name: 'WishlistsSlider',
  components: {
  	Spinner,
    WishlistModal,
    WishlistItem
  },
  data() {
    return {
      slides: [],
      isLoading: false,
      isCreatingWishlist: false,
      wishlistName: '',
      slider: null,
      backgrounds: []
    }
  },
  created() {
      EventBus.$on('wishlist-edited', this.refreshWishlists)
  },
  beforeDestroy() {
      EventBus.$off('wishlist-edited')
  },
  mounted() {

    this.backgrounds[0] = `url(${require('@/assets/images/backgrounds/wishlist-1.jpg')})`
    this.backgrounds[1] = `url(${require('@/assets/images/backgrounds/wishlist-2.jpg')})`
    this.backgrounds[2] = `url(${require('@/assets/images/backgrounds/wishlist-3.jpg')})`

    this.initSlider()
    this.getWishlists();

    this.$root.$on('wishlists_changed', () => {
      this.getWishlists();
      console.log("wishlists_changed")
    })

    // let section = document.getElementById('collections');
    // console.log(section)

    // window.onscroll = function() { stickyOnScroll()};

    // // Get the header
    // var header = document.getElementById("myHeader");

    // // Get the offset position of the navbar
    // var sticky = header.offsetTop;

    // // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    // function myFunction() {
    //   if (window.pageYOffset > sticky) {
    //     header.classList.add("sticky");
    //   } else {
    //     header.classList.remove("sticky");
    //   }
    // } 

    // var sticky = new Sticky('.collections');
    // sticky.update();
    
  },
  methods: {
    initSlider: function () {
      this.slider = new Swiper(`.wishlists-slider .swiper-container`, {
          slidesPerView: 1,
          slidesPerColumn: 1,
          spaceBetween: 20,
          loop: false,
          direction: 'horizontal',
          pagination: {
            el: '.swiper-pagination',
            type: 'custom',
            renderCustom: function (swiper, current, total) {
                return current + '/' + total;
            }
          },
          navigation: {
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev'
          },
          breakpoints: {
            991: {
              slidesPerView: 2,
              slidesPerColumn: 2,
            },
          }
      })
    },
    openCreateModal: function () {
      this.$bvModal.show('wishlist_modal')
    },
    getWishlists: function () {
      
      this.isLoading = true
      APIClient.getWishlists()
        .then(resp => {

          this.slides = resp.data.map( wishlist => {
            if(wishlist.image)  {
              wishlist.image = `url(${require('@/assets/images/wishlists-covers/' + wishlist.image + '.jpg')})`
            } else {
              wishlist.image = this.backgrounds[Math.floor((Math.random() * 3))]
            }
            return wishlist
          })

          let self = this
          setTimeout(() => {
            self.initSlider()
            if(Array.isArray(self.slider)) {
              self.slider[0].update()  
            }else {
              self.slider.update()    
            }
            
          }, 300)
          
        })
        .catch(e => {
          console.error(e)
        })
        .finally( () => {
          let self = this
          setTimeout(() => {
            this.isLoading = false
          }, 200)
        })
    },
    refreshWishlists: function() {
      this.getWishlists();
    }
  }
}
</script>
