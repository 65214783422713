<template>
  <div class="referrals-tab">
    <b-container>
      <b-row>

        <!-- Top -->
        <b-col cols="12">
          <div>
            <h2 class="section-heading">
              Invite Friends
            </h2>

            <b-row class="mt-4">
              <b-col cols="12" md="6" class="mb-4 mb-md-0">
                <div class="box p-4">
                  Invite your friends to join beautéboard, and earn points towards 15% off your purchase from a selected brand. 
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                  sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </div>
              </b-col>
              <b-col 
                cols="12" 
                md="6"
                class="referrals-data"
                >
                <h4>
                  my referral data
                </h4>

                <ul>
                  <li class="py-2">
                    <b-row class="justify-space-between">
                      <b-col>
                        friends joined
                      </b-col>
                      <b-col 
                        cols="auto" 
                        class="counter"
                        v-if="data"
                        >
                        ({{data.joined}})
                      </b-col>
                    </b-row>
                  </li>

                  <li class="py-2">
                    <b-row class="justify-space-between">
                      <b-col>
                        pending
                      </b-col>
                      <b-col 
                        cols="auto" 
                        class="counter"
                        v-if="data"
                        >
                        ({{data.pending}})
                      </b-col>
                    </b-row>
                  </li>

                  <li class="py-2">
                    <b-row class="justify-space-between">
                      <b-col>
                        credits
                      </b-col>
                      <b-col 
                        cols="auto" 
                        class="counter"
                        v-if="data"
                        >
                        ({{data.credits}})
                      </b-col>
                    </b-row>
                  </li>

                </ul>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <!-- Bottom Form -->
        <b-col cols="12">
           <div class="share-form"> 
            <h2 class="mb-4">
              share by email
            </h2>

            <b-form @submit="share" method="post">
              <b-row 
                v-for="row in rows"
                class="mb-3"
                >
                <b-col 
                  cols="12"
                  md="6"
                  >
                  <input 
                    class="form-control mb-3 mb-md-0"
                    v-model="row.name"
                    placeholder="friend's name"
                    type="text"
                    />
                </b-col>
                <b-col 
                  cols="12"
                  md="6"
                  >
                  <input 
                    class="form-control"
                    v-model="row.email"
                    placeholder="email address"
                    type="email"
                    />
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <a 
                    class="invite float-right"
                    @click="addRow"
                    >
                    invite more 
                      <span>
                        <svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g data-name="Grupo 695"><path data-name="Trazado 61338" d="M9.333 0v9.338H0v1.333h9.333v9.333h1.333v-9.333H20V9.338h-9.333V.005z" fill="#484848"/></g></svg>
                      </span>
                  </a>
                </b-col>
              </b-row>

              <b-row class="mt-4"> 
                <b-col>
                  <b-button 
                    type="submit"
                    variant="outline-black"
                    class="float-right px-4"
                    >
                    <span v-if="!isLoadingForm">
                      share
                    </span>
                    <span v-else>
                      <b-spinner 
                        small 
                        type="grow" 
                        label="Spinning"
                        />   
                    </span>
                  </b-button>
                </b-col>
              </b-row>

            </b-form>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

import APIClient from '@/api/requests'

export default {
  name: 'ReferralsTab',
  components: {

  },
  data() {
    return {
      rows: [
        {
          name: "",
          email: ""
        }
      ],
      isLoadingForm: false,
      data: null
    }
  },
  mounted() {
    APIClient.getReferrals()
      .then( res => {
        this.data = res.data
      })
      .catch( err => {
        console.error(err)
      })
      .finally( () => {

      })
  },
  methods: {
    addRow() {
      this.rows.push({
          name: "",
          email: ""
      })
    },
    share(evt) {
      evt.preventDefault()

      if(this.isLoadingForm) return

      let data = this.rows.filter(function(item){
        return !((!item.name || 0 === item.name.length) || (!item.email || 0 === item.email.length))
      })

      this.isLoadingForm = true
      APIClient.addReferrals(data)
        .then( res => {

          this.$bvToast.toast(`Invitations sent`, {
            title: 'Success',
            autoHideDelay: 5000,
            toastClass: 'success',
            appendToast: true,
          })

          this.rows = [{
            name: "",
            email: ""
          }]
        })
        .catch( err => {
          console.error(err)
        })
        .finally( () => {
          this.isLoadingForm = false
        })
    }
  }
}
</script>

