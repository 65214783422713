<template>
    <div class="product-routine">
        <div class="product-routine__inner">
            <div class="product-routine__top">
                <div 
                    class="product-routine__image-wrapper"
                    :class="{ 'product-routine__image-wrapper--overlay': !isShelf }"
                    >

                    <div class="product-routine__more">
                        view details
                    </div>

                    <template v-if="isLoggedIn">
                        <svg v-if="isShelf" class="product-routine__remove" @click="removeProduct" svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="9" height="12.456" viewBox="0 0 9 12.456"><g data-name="Grupo 386" fill="#c2c2c2"><path data-name="Trazado 483" d="M.926 11.021c0 .807.472 1.434 1.029 1.434h5.059c.579 0 1.029-.657 1.029-1.434V1.908H.926zm5.038-8.008c0-.179.086-.3.214-.3s.214.12.214.3v7.111c0 .179-.086.3-.214.3s-.214-.12-.214-.3zm-1.694 0c0-.179.086-.3.214-.3s.214.12.214.3v7.111c0 .179-.086.3-.214.3s-.214-.12-.214-.3zm-1.694 0c0-.179.086-.3.214-.3s.214.12.214.3v7.111c0 .179-.086.3-.214.3s-.214-.12-.214-.3z"/><path data-name="Trazado 484" d="M8.134 1.7H6.2V.923a.843.843 0 0 0-.706-.926H3.463a.843.843 0 0 0-.706.926V1.7H.843C.365 1.7 0 2.208 0 2.806V3.5c0 .149.114.3.228.3h8.544c.114 0 .228-.149.228-.3v-.687A1.016 1.016 0 0 0 8.134 1.7zm-4.9-.747a.3.3 0 0 1 .251-.329h2.028a.3.3 0 0 1 .251.329v.777H3.258V.953z"/></g></svg>
                    
                        <svg v-else="" class="product-routine__bookmark" @click="addToWishlist" svg-inline="" focusable="false" role="presentation" data-name="Componente 16 – 8" xmlns="http://www.w3.org/2000/svg" width="7.414" height="10.591" viewBox="0 0 7.414 10.591"><path data-name="Trazado 108" d="M7.414 0v10.591L3.707 7.943 0 10.591V0z" fill="#e6e6e6"/></svg>
                    </template>
                    <div 
                        class="product-routine__image" 
                        :style="{ backgroundImage: `url(${source.image || source.thumb600 || source.filename} )` }" 
                        @click="openProduct(source.id || source.product_id)"
                        >
                    </div>
                    <!-- <div
                        class="product-routine__overlay" 
                        v-if="!isShelf"
                        >
                        <template v-if="!addedToRoutine">
                            <div class="product-routine__overlay-label" v-if="routine">
                               Add this to my {{ routine.label }}?  
                            </div>
                            <div 
                                v-if="routine"
                                class="btn product-routine__overlay-cta"
                                @click="addToRoutine"
                                >
                                Yes
                            </div>
                        </template>
                        <template v-else>
                            <div class="product-routine__overlay-label" v-if="routine">
                               Added to your {{ routine.label }} 
                            </div>
                        </template>
                    </div> -->
                </div>

                <!-- <div 
                    class="product-routine__brand"  
                    :class="{ 'product-routine__brand--link' : hasBrandLink }"
                    @click="openBrand(source)"
                    >
                    <template v-if="isObject(source.brand)">
                        {{ source.brand.brand_name || source.brand.name  }}
                    </template>
                    <template v-else>
                        {{ source.brand }}
                    </template>
                </div>
                <div 
                    class="product-routine__name" 
                    @click="openProduct(source.id || source.product_id)"
                    >
                    {{ source.name || source.product_name }}
                </div> -->

                <div class="product-routine__bottom">
                  <div class="product-routine__left my-3">
                    <div class="product-routine__brand" :class="{link: source.brand && source.brand.brand_link != '' }" @click="openBrand(source)">
                      {{ source.brand }}
                    </div>
                    <div class="product-routine__name">
                      <a @click="$bvModal.show(`filter_${source.id}`)">
                        {{ source.name }}
                      </a>
                    </div>
                  </div>

                  <div class="product-routine__right price">
                    {{ price }}
                  </div>
                </div>
            </div>

            <div>
                <!-- <div class="product-routine__bottom">
                    <b-row 
                        align-h="between" 
                        no-gutters
                        >
                        <b-col 
                            cols="auto"
                            v-if="isShelf"
                            >
                            my review
                        </b-col>
                        <b-col 
                            cols="auto"
                            v-else
                            @click="openProduct(source.id || source.product_id)"
                            >
                            view details
                        </b-col>
                        <b-col cols="auto">
                            <rating 
                                :score="source.stars || 0"
                                :show-score="false" 
                                :show-add-rating="isShelf" 
                                @onClickAdd="openReviews(source.id || source.product_id, source.stars)" 
                                />
                        </b-col>
                    </b-row>
                </div> -->

                <div>
                    <b-row no-gutters>
                        <b-col>
                            <a
                                :href="source.buy_link || source.product_link || source.product_base_url || source.link"
                                target="_blank"
                                class="product-routine__cta btn"
                                @click="recordRepurchase(source.id || source.product_id)"
                                >
                                <template v-if="isShelf">
                                    Repurchase 
                                </template>
                                <template v-else>
                                    Purchase 
                                </template>
                            </a>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>
    
        <spinner v-if="isLoading"/>

        <!-- <product-modal 
            :modalId="`${this.routine}_my_routine_${source.id || source.product_id}`" 
            section="search" 
            hideFooter="true" 
            :source="source" 
            :sourceNavigation="products" 
            :sourceIndex="index"
            ref="modal"
            :isPublic="isPublic"
            :allowClose="true"
            :addProductQueryToUrl="false"
            /> -->
        <new-product-modal
          :modalId="`${this.routine}_my_routine_${source.id || source.product_id}`"  
          section="filter" 
          :source="source" 
          :sourceNavigation="products" 
          :sourceIndex="index" 
          :addProductQueryToUrl="false"
          />
    </div>
</template>

<script>
    import Rating from '@/ui/Rating.vue'
    import APIClient from '@/api/requests'
    import Spinner from '@/ui/Spinner.vue'
    import ProductModal from '@/ui/ProductModal.vue'
    import NewProductModal from '@/ui/NewProductModal.vue'
    import EventBus from '@/event-bus'
    import { helperMixin } from '@/mixins/helperMixin.js'

    export default {
        name: 'ProductRoutine',
        components: {
            Rating,
            Spinner,
            ProductModal,
            NewProductModal
        },
        mixins: [helperMixin],
        props: {
            source: Object,
            routine: String,
            sliderIndex: Number,
            isShelf: Boolean,
            isPublic: {
                type: Boolean,
                default: false,
            }
        },
        data: {
            routineId: Number,
            isLoading: {
                type: Boolean,
                default: false
            },
            hasBrandLink: {
                type: Boolean,
                default: false
            },
            addedToRoutine: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            hasBrandLink: function() {
                return this.source.brand && this.source.brand.brand_link != ''
            },
            price: function () {
                return `${this.source.price_symbol}${this.source.price}`
            },
            isLoggedIn: function () {
              return this.$store.getters.isLoggedIn
            },
        },
        methods: {
            removeProduct: function() {
                this.isLoading = true
                this.$forceUpdate()

                APIClient.removeRoutineProduct(this.source.id, this.routine.type_id)
                    .then( res => {
                        this.$emit('onProductRemoved', this.sliderIndex)
                    })
                    .catch( err => {
                        console.error(err)
                    })
                    .finally( () => {
                        this.isLoading = false
                        this.$forceUpdate()
                    })
            },
            openProduct(id) {
                console.log(this.source.id);
                console.log(id);
                console.log(`${this.routine}_my_routine_${id}`);
                this.$bvModal.show(`${this.routine}_my_routine_${id}`)
            },
            openBrand(product) {
                if(product.brand_link != null && product.brand_link.length > 0) {
                    this.$router.push(`/me/brand/${product.brand_link}`)
                }
            },
            openReviews(id, showReviewForm = false) {
                this.$bvModal.show(`${this.routine}_my_routine_${id}`)
                this.$refs.modal.showReviews(!!!showReviewForm)
            },
            addToWishlist() {
                this.$emit('onAddToWishlistClicked', this.source)
            },
            addToRoutine() {
                this.isLoading = true;
                APIClient.saveProductRoutine(this.source.id, this.routine.type_id)
                    .then(resp => {
                        this.addedToRoutine = true;
                        EventBus.$emit('update-routines');
                    })
                    .catch(err => {
                        console.error(err)
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.$forceUpdate()
                    })
            },
            recordRepurchase(productId) {
                if(!this.isPublic) {
                    let data = {
                        product_id: productId
                    }
                    APIClient.recordRepurchase(data)
                        .then( () => {})
                        .catch( err => {
                            console.error(err)
                        })
                }
            }
        }
    }
</script>