<template>
  <!-- <div class="simple"> -->
    <transition
        :name="transitionName"
        @beforeLeave="beforeLeave"
        @enter="enter"
        >
      <router-view />
    </transition>
  <!-- </div> -->
</template>

<script>
const DEFAULT_TRANSITION = 'fade';

export default {
  components: {},
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn
    },
    profile: function () {
      return this.$store.getters.profile
    }
  },
  data() {
     return {
        transitionName: DEFAULT_TRANSITION,
     };
   },
  created() {
    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName;
      // console.log(`to.meta.transitionName: ${to.meta.transitionName}`)
      // console.log(`from.meta.transitionName: ${from.meta.transitionName}`)
      if (transitionName === 'slide') {
        transitionName = (to.name === 'login') ? 'slide-right' : 'slide-left';
      }

      this.transitionName = transitionName || DEFAULT_TRANSITION;

      next();
    });
  },
  methods: {
    logout: function () {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login')
      })
    },
    getProfile: function () {
      this.$store.dispatch('getProfile').then(resp => {
        // console.log(resp)
      })
    },
    beforeLeave: function () {
      // console.log("before leave")
    },
    enter: function() {
      // console.log("enter")
    }
  }
}
</script>
