<template>
  <div class="twelve-overview overview-section">
    <h2 class="section-heading">
      12 months overview
    </h2>

    <b-row>
      <b-col 
        cols="12"
        >

        <ul class="types my-4">
          <li :class="{active: selectedData == 'normal'}">
            <a @click="selectType('normal')">
              <i class="icon-skin-normal"></i> 
              normal
            </a>
          </li>
          <li :class="{active: selectedData == 'dry'}">
            <a @click="selectType('dry')">
              <i class="icon-skin-dry"></i> 
              dry
            </a>
          </li>
          <li :class="{active: selectedData == 'oily'}">
            <a @click="selectType('oily')">
              <i class="icon-skin-oily"></i> 
              oily
            </a>
          <li :class="{active: selectedData == 'sensitive'}">
            <a @click="selectType('sensitive')">
              <i class="icon-skin-sensitive"></i> 
              sensitive
            </a>
          </li>
        </ul>
        
        <div :class="{opacity: isLoading}" >
          <vertical-bar-chart 
            :chart-data="dataCollection" 
            :options="options"
            />
        </div>

      </b-col>

    </b-row>
  </div>
</template>

<script>

import APIClient from '@/api/requests'
import VerticalBarChart from '@/ui/VerticalBarChart.vue'

export default {
  name: 'TwelveOverview',
  components: {
    VerticalBarChart
  },
  data() {
    return {
      isLoading: false,
      data: null,
      selectedData: 'normal',
      moment: require('moment'),
      currentMonth: null,
      dataCollection: null,
      options: null
    }
  },
  mounted () {
    this.isLoading = true
    APIClient.getTwelveMonthsOverview()
      .then(res => {
        this.data = res.data
        this.updateChart()
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.isLoading = false
      })
  },
  methods: {
    selectType: function(type) {
      this.selectedData = type
      this.updateChart()
    },
    updateChart() {
      let months = []
      for (var i = 0 ; i < 12; i++) {
        let month = this.moment().subtract(i, 'months').format('YYYY-MM')
        months.push(
          {
            value: month,
            label: this.moment(month, 'YYYY-MM').format('MMM')
          }  
        )
      }

      this.labels = months.map(el => {
        return el.label.toLowerCase()
      })

      let values = []
      for (var i = 0 ; i < 12; i++) {      
        let key = months[i].value
        let monthValue = this.data[this.selectedData][key]
        values.push(monthValue || 0);
      }

      // This get (30 days - value) for each month
      let valuesDifference = []
      for (var i = 0; i <= values.length - 1; i++) {
        valuesDifference.push(30 - values[i])
      }

      this.dataCollection = {
        labels: this.labels.reverse(),
        datasets: [
          {
            backgroundColor: '#4C5755',
            data: values.reverse(),
          },
          {
            backgroundColor: '#F1F1F3',
            data: valuesDifference.reverse(),
          }
        ],
      }

      this.options = {
        responsive: true, 
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              display:false
            },
            stacked: true,
            barPercentage: 0.05,
            ticks: {
              fontFamily: "Mabry",
              fontColor: "#000"
            }
          }],
          yAxes: [{
            gridLines: {
              display:false
            },
            stacked: true,
            ticks: {
              display: false
            }   
          }]
        }
      }
    }
  }
}
</script>

