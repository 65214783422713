var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.modalId,"title":"Notification","body-class":"p-0","modal-class":"notification-modal","hide-footer":"true","centered":""},on:{"show":_vm.hideSlider,"hidden":_vm.closeModal}},[(_vm.source)?_c('div',{staticClass:"container"},[_c('b-row',{staticClass:"subheader py-2",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"2","lg":"auto"}},[_c('img',{staticClass:"mx-auto w-100",attrs:{"src":_vm.source.image}})]),_vm._v(" "),_c('b-col',{attrs:{"cols":"10","lg":"auto"}},[_c('div',{staticClass:"title"},[_vm._v("\n            beautéboard\n          ")]),_vm._v(" "),_c('small',{staticClass:"date"},[_vm._v("\n            "+_vm._s(_vm.moment(_vm.source.date).calendar(null, {
              sameDay: '[Today]',
              nextDay: '[Tomorrow]',
              nextWeek: 'dddd',
              lastDay: '[Yesterday]',
              lastWeek: 'DD/MM/YYYY',
              sameElse: 'DD/MM/YYYY'
            }))+"\n          ")])]),_vm._v(" "),_c('b-col',{staticClass:"view-all ml-auto",attrs:{"cols":"auto"}},[_c('a',{on:{"click":_vm.openAllNotifications}},[_vm._v("view all")])])],1)],1):_vm._e(),_vm._v(" "),(_vm.source)?_c('div',{staticClass:"container py-4 px-4"},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.source.content)}})])],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }