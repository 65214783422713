<template>
  <div 
    class="skin-journey-notes" 
    v-if="skinJourney"
    >
    <div 
      class="heading" 
      >
      <div class="date">
        {{ moment(skinJourney.updated_at).format('MMM D') }}
      </div>

      <div class="circle">
        <img 
          class="float-right" 
          :src="skinJourney.skin_options.image" 
          />
      </div>
    </div>

    <div 
      class="notes" 
      v-if="skinJourney.notes && !showForm" 
      v-html="skinJourney.notes"
      >
    </div>

    <div 
      class="form"
      v-if="showForm"
      >
      <wysiwyg 
        v-model="note" 
        placeholder="Click here to add a beaute—note…" 
        />
      <div 
        class="btn btn-black mt-2 float-right px-5"
        @click="saveNote"
        >
        <span v-if="!isLoading">
          <span v-if="skinJourney.notes">
            save note
          </span>
          <span v-else>
            add note
          </span>
        </span>
        <span v-else>
          <b-spinner type="grow" label="Spinning" />
        </span>
      </div>
    </div>
    <div v-else>
      <div 
        class="btn btn-black mt-2 float-right px-5"
        @click="allowEditNote"
        >
        edit note
      </div>
    </div>

  </div>
</template>

<script>

import APIClient from '@/api/requests'

export default {
  name: 'SkinJourneyNotes',
  data() {
    return {
      isLoading: false,
      note: '',
      showForm: true,
      moment: require('moment'),
    }
  },
  props: {
    skinJourney: null
  },
  methods: {
    allowEditNote: function() {
      this.showForm = true
      this.note = this.skinJourney.notes
    },
    saveNote: function() {
      if (this.isLoading) return

      this.isLoading = true

      let data = {
        id: this.skinJourney.id,
        notes: this.note
      }

      APIClient.addNoteToSkinJourney(data)
        .then( res => {
          this.$bvToast.toast('Note updated.', {
            title: 'Success',
            autoHideDelay: 5000,
            toastClass: 'success',
            appendToast: true,
          })

          this.skinJourney.notes = this.note
          this.note = ''
          this.showForm = false
        })
        .catch( err => {
          this.$bvToast.toast('Something went wrong.', {
            title: 'Error',
            autoHideDelay: 5000,
            toastClass: 'error',
            appendToast: true,
          })
        })
        .finally( () => {
          this.isLoading = false
        })
    }
  },
  watch: { 
    skinJourney: function() {
      console.log(this.skinJourney.notes)
      this.showForm = !this.skinJourney.notes
      console.log(!this.skinJourney.notes)
      if(!this.skinJourney.notes) {
        this.note = ''
      }
    }
  }
}
</script>

