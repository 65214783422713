<template>
  <div class="account-details" id="information">
    <section class="mb-4">
      <h5 class="mb-4">my profile</h5>
      <div class="btn-edit" @click="editProfile">
        <div v-if="!isSavingProfile">
          <div v-if="!isEditingProfile">
            edit <i class="icon-edit"></i>
          </div>
          <div v-else>
            save
          </div>
        </div>
        <div v-else>
          saving
        </div>
      </div>
      <b-row>
        <b-col md="6" class="mb-4">
          <label>
            first name
          </label>
          <input type="text" v-model="firstName" required :class="{disabled: !isEditingProfile}"/>
        </b-col>
        <b-col md="6" class="mb-4">
          <label>
            last name
          </label>
          <input type="text" v-model="lastName" required :class="{disabled: !isEditingProfile}"/>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6" class="mb-4">
          <label>
            username
          </label>
          <input type="text" v-model="username" required :class="{disabled: !isEditingProfile}"/>
        </b-col>
        <b-col md="6" class="mb-4">
          <label>
            location
          </label>
          <multiselect 
            v-if="location || isEditingProfile"
            v-model="location" 
            id="ajax" 
            :label="city"
            :custom-label="cityWithCountry" 
            track-by="id" 
            placeholder="Type to search" 
            open-direction="bottom" 
            :options="cities" 
            :multiple="false" 
            :searchable="true" 
            :loading="isLoading" 
            :internal-search="false" 
            :options-limit="300" 
            :limit="3" 
            :max-height="600" 
            :show-no-results="false" 
            :hide-selected="true" 
            :select-label="''"
            :class="{disabled: !isEditingProfile}"
            @search-change="getCities">
              <span slot="noResult">
                Oops! No elements found. Consider changing the search query.
              </span>
          </multiselect>
          <input type="text" value="No location set" v-if="!location && !isEditingProfile" required :class="{disabled: !isEditingProfile}"/>
        </b-col>
      </b-row>

       <b-row>
        <b-col md="6">
          <label>
            birthday
          </label>
          <input :type="!isEditingProfile ? 'text' : 'date'" v-model="birthday" :class="{disabled: !isEditingProfile}" required/>
        </b-col>
      </b-row>

    </section>

    <section class="mb-4">
      <h5 class="mb-4">account information</h5>
        <div class="btn-edit" @click="editAccount">
          <div v-if="!isSavingAccount">
            <div v-if="!isEditingAccount">
              edit <i class="icon-edit"></i>
            </div>
            <div v-else>
              save
            </div>
          </div>
          <div v-else>
            saving
          </div>
        </div>

         <b-row>
          <b-col md="6">
            <label>
              email
            </label>
            <input type="text" v-model="email" :class="{disabled: !isEditingAccount}" required/>
          </b-col>
          <b-col md="6">
            <label>
              password
            </label>
            <svg class="lock-icon" svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="10.177" height="14.398" viewBox="0 0 10.177 14.398"><path data-name="Trazado 504" d="M9.117 5.714h-.594V3.435a3.435 3.435 0 1 0-6.87 0v2.279h-.594A1.058 1.058 0 0 0 0 6.773v6.565A1.058 1.058 0 0 0 1.059 14.4h8.057a1.058 1.058 0 0 0 1.059-1.059V6.789a1.052 1.052 0 0 0-1.058-1.075zM2.3 3.435a2.793 2.793 0 0 1 5.586 0v2.279H2.3zm3.114 7.239v1.043c0 .112-.144.209-.321.209s-.321-.1-.321-.209v-1.043a1.14 1.14 0 1 1 .642 0z"/></svg>
            <div class="position-relative d-inline-block" v-if="isEditingAccount">
              <input class="password" type="text" v-model="password" :type="passwordType" :class="{disabled: !isEditingAccount}" required/>
              <img class="position-absolute eye" @click="togglePasswordType" :src="passwordIcon" />
            </div>

            <div class="password-placeholder" v-else><i class="icon-lock"></i> ******</div>
          </b-col>

        </b-row>

        <b-row>
          <b-col col="12">
            <div class="checkinput">
              <input id="checkbox" :class="{disabled: !isEditingAccount}" type="checkbox" :checked="isSubscribed" :model="isSubscribed" @change="changeSubscribed($event)" />
              <label for="checkbox" :class="{disabled: !isEditingAccount}">
                send me news about new products, promos, and new features
              </label>
            </div>
          </b-col>
        </b-row>
    </section>

  </div>
</template>

<script>
import APIClient from '@/api/requests'
import Multiselect from 'vue-multiselect'

export default {
  name: 'AccountDetails',
  props: {
  	
  },
  mounted () {
    this.moment = require('moment');

    this.location = this.user.location
    this.firstName = this.user.name
    this.lastName = this.user.last_name
    this.username = this.user.user_data.username
    this.birthday = this.user.user_data.birthday
    this.email = this.user.email
    this.isSubscribed = Boolean(this.user.user_data.email_product_recommendations)

    if(window.location.hash == '#information') {
      setTimeout(() => {
        var VueScrollTo = require('vue-scrollto');
        VueScrollTo.scrollTo('#information');  
      }, 500)
    }

    this.getCities("");
  },
  data () {
    return {
      lastName: "",
      firstName: "",
      username: "",
      location: null,
      birthday: null,
      cities: [],
      email: "",
      password: "",
      isEditingProfile: false,
      isSavingProfile: false,
      isSavingAccount: false,
      isEditingAccount: false,
      isSubscribed: false,
      moment: null,
      passwordType: 'password',
      passwordIcon: require('@/assets/images/icons/eye-closed-black.png'),
    }
  },
  methods: {
    editProfile() {
      if(this.isSavingProfile) return;

      if(!this.isEditingProfile){
        this.isEditingProfile = !this.isEditingProfile
        return
      }

      let args = {
        name: this.firstName,
        last_name: this.lastName,
        username: this.username,
        birthday: this.birthday,
        city_id: this.location.id,
      }

      // console.log(args)
      // return
      this.isSavingProfile = true
      APIClient.updateProfile(args)
        .then( res => {
          // console.log(res)
          this.isEditingProfile = false;
          // Update profile in memory
          this.$store.dispatch('getProfile').then(() => {
            this.birthday = this.user.user_data.birthday
          })
        })
        .catch( err => {
          console.error(err)
        })
        .finally( () => {
          // console.log("asdf")
          this.isSavingProfile = false
        })
    },
    changeSubscribed () {
      this.isSubscribed = !this.isSubscribed
    },
    editAccount() {
      if(this.isSavingAccount) return;

      if(!this.isEditingAccount){
        this.isEditingAccount = !this.isEditingAccount
        return
      }

      let args = {
        email: this.email,
        password: this.password,
        email_product_recommendations: this.isSubscribed ? 1 : 0
      }
      console.log(args)
      // alert("asdf")
      // console.log(args)
      this.isSavingAccount = true;
      APIClient.updateAccount(args)
        .then( res => {
            // console.log(res)
            this.isEditingAccount = false;
            this.$store.dispatch('getProfile').then(() => {
              
            })
          })
          .catch( err => {
            console.error(err)
          })
          .finally( () => {
            this.isSavingAccount = false
          })
    },
    getCities (query) {
      this.isLoading = true
      // console.log(query)
      if(query.length > 0) {
        APIClient.getCities(query)
          .then( res => {
            console.log(res.data)
            this.cities = res.data
          })
          .catch( err => {
            console.error(err)
          })
          .finally( () => {
            this.isLoading = false;
          })
      }
    },
    cityWithCountry ({ city, country }) {
      // return `${city}, ${country}`;
      return `${country}`;
    },
    editLocation() {
      let args = {
        city_id: this.location.id
      }

      APIClient.editLocation(args)
        .then(res => {
          // console.log(res)
        })
        .catch( err => {
          console.error(err)
        })
        .finally( () => {

        })
    },
    togglePasswordType: function () {
      if(this.passwordType == 'password') {
        this.passwordType = 'text';
        this.passwordIcon = require('@/assets/images/icons/eye-open-black.png');
      } else {
        this.passwordType = 'password';
        this.passwordIcon = require('@/assets/images/icons/eye-closed-black.png');
      }
    },
  },
  computed: {
    user: function () {
      return this.$store.getters.profile
    }
  },
  components: {
    Multiselect
  }
}
</script>
