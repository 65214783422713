<template>
  <div class="moodboard-block">
  	<div class="moodboard-block__inner">
	  	<div class="moodboard-block__left">
	  		<div class="moodboard-block__top">
	  			<div class="moodboard-block__top-left">
	  				{{ tagline }}
	  			</div>
	  			<div class="moodboard-block__top-right">
	  				<a target="_blank" :href="link.url">
	  					{{ link.title }}
	  				</a>
	  			</div>
	  		</div>

	  		<div class="moodboard-block__bottom">
	  			<h2>
	  				{{ title }}
	  			</h2>
	  			<a :href="cta.url" target="_blank">
	  				{{ cta.title }}
	          <svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="14.775" height="7.277" viewBox="0 0 14.775 7.277"><g data-name="Grupo 144"><path data-name="Trazado 104" d="M14.776 3.639L11.193.101a.33.33 0 0 0-.467.467l2.759 2.737H.334a.334.334 0 1 0 0 .668h13.151L10.726 6.71a.33.33 0 0 0 .467.467z" fill="#fff"/></g></svg>
	  			</a>
	  		</div>
	  	</div>

	  	<div 
	  		class="moodboard-block__right" 
	  		:style="{ backgroundImage: blockBackground }"
	  		>
	  		<div class="moodboard-block__right-image" :style="{ backgroundImage: `url(${image})` }">
	  		</div>
	  	</div>
	  </div>
  </div>
</template>

<script>

	export default {
	  	name: 'MoodboardBlock',
		data () {
		    return {
		    	blockBackground: null
		    }
		},
		components: {
			
		},
		props: {
      image: '',
      title: '',
      tagline: '',
      link: {
      	url: '',
      	title: '',
      },
      cta: {
      	url: '',
      	title: '',
      }
    },
	  mounted() {
		  	this.blockBackground = `url(${require('@/assets/images/moodboard/block-background-3.jpg')})`
		  	console.log(this.blockBackground)
		},
		methods: {
		    
		}
	}
</script>
