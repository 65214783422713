<template>
	<b-modal 
		id="review_modal" 
		title="Edit Review"
		modal-class="reviews"
		@cancel="confirms"
		@shown="updateData"
		hide-footer
		>

		<review-form 
			v-if="form && whyLovedOptions && source"
			:form="form"
			:whyLovedOptions="whyLovedOptions"
			:productId="source.product_id"
			@onAdded="closeModal()"
			:isEditing="true"
			:reviewId="source.id"
			/>

</b-modal>
</template>

<script>

import APIClient from '@/api/requests';
import ReviewForm from '@/ui/ReviewForm.vue';

export default {
	name: 'EditReviewModal',
	components: {
		ReviewForm
	},
	props: {
		source: {
			type: Object,
			default: null
		},
	},
	data () {
		return {
			form: {
				type: Object,
				default: null
			},
			whyLovedOptions: {
				type: Object,
				default: null
			}
		}
	},
	mounted() {
		// console.log("mounted")
	},
	methods: {
		closeModal() {
			// console.log("close modal")
			this.$emit('onSuccess')			
		},
		getReviewsBenefits() {
	      APIClient.getReviewsBenefits(this.source.product_id)
	        .then( res => {
	          
	          if(!res.data) return;

	          this.whyLovedOptions = res.data.map( el => {
	            if(el.benefit.skin_benefit_id){
	              el.id = el.benefit.skin_benefit_id
	              el.name = el.benefit.name
	            }
	            if(el.benefit.hair_benefit_id){
	              el.id = el.benefit.hair_benefit_id
	              el.name = el.benefit.name
	            }
	            if(el.benefit.makeup_benefit_id){
	              el.id = el.benefit.makeup_benefit_id
	              el.name = el.benefit.name
	            }
	            return el;
	          })
	        })
	        .catch( err => {
	          console.error(err)
	        })
	        .finally( () => {

	        })
	    },
	    updateData() {

	    	this.getReviewsBenefits()

			let whyLovedSelectedAll = []

			if(this.source.selected_hair_benefits) whyLovedSelectedAll = whyLovedSelectedAll.concat(this.source.selected_hair_benefits)
			if(this.source.selected_makeup_benefits) whyLovedSelectedAll = whyLovedSelectedAll.concat(this.source.selected_makeup_benefits)
			if(this.source.selected_skin_benefits) whyLovedSelectedAll = whyLovedSelectedAll.concat(this.source.selected_skin_benefits)

			this.form = {
				stars: this.source.stars,
				title: this.source.title,
				content: this.source.content,
				image: this.source.review_image,
				whyLovedSelectedAll: whyLovedSelectedAll,
				wouldRecommend: this.source.would_recommend
			}

			console.log(this.form)
	    },
	},
}
</script>
