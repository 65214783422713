<template>
    <div class="account">
      <section class="header">
        <simple-header />
        <div class="top">
          
        </div>
        <div class="bottom">
          <div class="info">
            <div class="account__avatar" @click="editAvatar">
              <img :src="user.avatar || ''"/>
              <input 
                type="file" 
                style="display: none" 
                id="file" 
                ref="file" 
                v-on:change="handleUpload()" 
                />
              <div class="account__avatar-overlay" v-if="!isLoading">
                edit picture
              </div>

              <b-spinner v-if="isLoading" type="grow" label="Spinning"></b-spinner>
            </div>

            <h1 class="text-center">
              Hi, {{user.name}}!

              <div class="edit-button" @click="editPreferences">
                <div v-if="!isSavingPreferences">
                  <div v-if="!isEditingPreferences" >
                    <i class="icon-edit"></i>
                  </div>
                  <div v-else>
                    save
                  </div>
                </div>
                <div v-else>
                  saving
                </div>
              </div>


            </h1>
            <div class="preferences mt-5">

              <!-- First row -->
              <b-row>
                <b-col 
                  cols="12" 
                  lg="6"
                  class="pr-lg-5"
                  >
                  <b-row align-h="between">
                    <b-col>
                      <label>
                        my skin type
                      </label>
                    </b-col>
                    <b-col>
                      <span class="outlined">
                       <multiselect
                          v-model="skinType"
                          :options="skinTypeOptions"
                          :searchable="false" 
                          :close-on-select="true" 
                          :show-labels="false"
                          :clear-on-select="false"
                          :preserve-search="true"
                          :taggable="false"
                          :preselect-first="false"
                          placeholder="skin type"
                          deselectLabel=""
                          selectLabel=""
                          label="skin_type_name"
                          track-by="skin_type_id"
                          :allow-empty="true"
                          :class="{ 'disabled': !isEditingPreferences }"
                          class="mb-5"
                          tagPosition="bottom"
                        />
                      </span>
                    </b-col>
                  </b-row>
                </b-col>

                <!-- <b-col 
                  cols="12" 
                  lg="6"
                  class="pl-lg-5"
                  >

                  <b-row align-h="between">
                    <b-col>
                      <label>
                        my skin tone
                      </label>
                    </b-col>
                    <b-col>
                      <span class="outlined">
                       <multiselect
                          v-model="skinTone"
                          :options="skinToneOptions"
                          :close-on-select="true"
                          :clear-on-select="false"
                          :preserve-search="true"
                          :taggable="false"
                          :searchable="false"
                          :preselect-first="false"
                          placeholder="skin type"
                          deselectLabel=""
                          selectLabel=""
                          label="skincolor_name"
                          track-by="skincolor_id"
                          :allow-empty="true"
                          :class="{ 'disabled': !isEditingPreferences }"
                          class="mb-5"
                          tagPosition="bottom"
                        />
                      </span>
                    </b-col>
                  </b-row>

                </b-col> -->

                <b-col 
                  cols="12" 
                  lg="6"
                  class="pl-lg-5"
                  >
                  <b-row align-h="between">
                    <b-col cols="6" md="5">
                      <label class="mb-5">
                        my skin concerns
                      </label>
                    </b-col>
                    <b-col cols="6" md="7">
                      <span class="outlined">
                       <multiselect
                          v-model="skinConcern"
                          :options="skinConcernsOptions"
                          :close-on-select="false"
                          :preserve-search="true"
                          :taggable="false"
                          :multiple="true"
                          :searchable="false"
                          :preselect-first="false"
                          placeholder="skin concern"
                          deselectLabel=""
                          selectLabel=""
                          label="name"
                          track-by="id"
                          :allow-empty="true"
                          :class="{ 'disabled': !isEditingPreferences }"
                          class="mb-5"
                          tagPosition="bottom"
                          v-if="isEditingPreferences"
                        />
                        <div v-else class="mb-5 placeholders">
                          {{ formatedSkinConcern }}
                        </div>
                      </span>
                    </b-col>
                  </b-row>
                  
                </b-col>

                <!-- <b-col 
                  cols="12" 
                  lg="6"
                  class="pr-lg-5"
                  >
                  <b-row align-h="between">
                    <b-col>
                      <label>
                        my hair type
                      </label>
                    </b-col>
                    <b-col>
                      <span class="outlined">
                       <multiselect
                          v-model="hairType"
                          :options="hairTypeOptions"
                          :close-on-select="true"
                          :clear-on-select="false"
                          :preserve-search="true"
                          :taggable="false"
                          :searchable="false"
                          :preselect-first="false"
                          placeholder="skin type"
                          deselectLabel=""
                          selectLabel=""
                          label="hair_type_name"
                          track-by="hair_type_id"
                          :allow-empty="true"
                          :class="{ 'disabled': !isEditingPreferences }"
                          class="mb-5"
                          tagPosition="bottom"
                        />
                      </span>
                    </b-col>
                  </b-row>
                </b-col> -->

              </b-row>

              <!-- Second Row -->
              <b-row>
                <b-col 
                  cols="12" 
                  lg="6"
                  class="pr-lg-5"
                  >

                  <b-row align-h="between">
                    <b-col>
                      <label>
                        my skin tone
                      </label>
                    </b-col>
                    <b-col>
                      <span class="outlined">
                       <multiselect
                          v-model="skinTone"
                          :options="skinToneOptions"
                          :close-on-select="true"
                          :clear-on-select="false"
                          :preserve-search="true"
                          :taggable="false"
                          :searchable="false"
                          :preselect-first="false"
                          placeholder="skin type"
                          deselectLabel=""
                          selectLabel=""
                          label="skincolor_name"
                          track-by="skincolor_id"
                          :allow-empty="true"
                          :class="{ 'disabled': !isEditingPreferences }"
                          class="mb-5"
                          tagPosition="bottom"
                        />
                      </span>
                    </b-col>
                  </b-row>

                </b-col>
                <!-- <b-col 
                  cols="12" 
                  lg="6"
                  class="pr-lg-5"
                  >
                  <b-row align-h="between">
                    <b-col>
                      <label>
                        my hair type
                      </label>
                    </b-col>
                    <b-col>
                      <span class="outlined">
                       <multiselect
                          v-model="hairType"
                          :options="hairTypeOptions"
                          :close-on-select="true"
                          :clear-on-select="false"
                          :preserve-search="true"
                          :taggable="false"
                          :searchable="false"
                          :preselect-first="false"
                          placeholder="skin type"
                          deselectLabel=""
                          selectLabel=""
                          label="hair_type_name"
                          track-by="hair_type_id"
                          :allow-empty="true"
                          :class="{ 'disabled': !isEditingPreferences }"
                          class="mb-5"
                          tagPosition="bottom"
                        />
                      </span>
                    </b-col>
                  </b-row>
                </b-col> -->
                <b-col 
                  cols="12" 
                  lg="6"
                  class="pl-lg-5"
                  >

                  <b-row align-h="between">
                    <b-col cols="6" md="5">
                      <label class="mb-5">
                        my skin undertone
                      </label>
                    </b-col>
                    <b-col cols="6" md="7">
                      <span class="outlined">
                        <multiselect
                          v-model="skinUndertone"
                          :options="skinUndertoneOptions"
                          :close-on-select="true"
                          :clear-on-select="false"
                          :preserve-search="true"
                          :taggable="false"
                          :searchable="false"
                          :preselect-first="false"
                          placeholder="skin undertone"
                          deselectLabel=""
                          selectLabel=""
                          label="name"
                          track-by="id"
                          :allow-empty="true"
                          :class="{ 'disabled': !isEditingPreferences }"
                          class="mb-5"
                          tagPosition="bottom"
                        />
                      </span>
                    </b-col>
                  </b-row>
                  <!-- <b-row align-h="between">
                    <b-col cols="6">
                      <label class="mb-5">
                        my skin concerns
                      </label>
                    </b-col>
                    <b-col cols="6">
                      <span class="outlined">
                       <multiselect
                          v-model="skinConcern"
                          :options="skinConcernsOptions"
                          :close-on-select="false"
                          :preserve-search="true"
                          :taggable="false"
                          :multiple="true"
                          :searchable="false"
                          :preselect-first="false"
                          placeholder="skin concern"
                          deselectLabel=""
                          selectLabel=""
                          label="name"
                          track-by="id"
                          :allow-empty="true"
                          :class="{ 'disabled': !isEditingPreferences }"
                          class="mb-5"
                          tagPosition="bottom"
                          v-if="isEditingPreferences"
                        />
                        <div v-else class="mb-5 placeholders">
                          {{ formatedSkinConcern }}
                        </div>
                      </span>
                    </b-col>
                  </b-row> -->

                </b-col>
              </b-row>

              <!-- Third Row -->

              <b-row>
                <!-- <b-col 
                  cols="12" 
                  lg="6"
                  class="pr-lg-5"
                  >
                  <b-row align-h="between">
                    <b-col cols="6">
                      <label class="mb-5">
                        my makeup concerns
                      </label>
                    </b-col>
                    <b-col cols="6">
                      <span class="outlined">
                       <multiselect
                          v-model="makeupConcern"
                          :options="makeupConcernsOptions"
                          :clear-on-select="false"
                          :preserve-search="true"
                          :close-on-select="false"
                          :taggable="false"
                          :searchable="false"
                          :multiple="true"
                          :preselect-first="false"
                          placeholder="makeup concern"
                          deselectLabel=""
                          selectLabel=""
                          label="name"
                          track-by="id"
                          :allow-empty="true"
                          :class="{ 'disabled': !isEditingPreferences }"
                          class="mb-5"
                          tagPosition="bottom"
                          v-if="isEditingPreferences"
                        />
                        <div v-else class="mb-5 placeholders">
                          {{formatedMakeupConcern}}
                        </div>
                      </span>
                    </b-col>
                  </b-row>
                </b-col> -->

                <b-col 
                  cols="12" 
                  lg="6"
                  class="pr-lg-5"
                  >
                  <b-row align-h="between">
                    <b-col>
                      <label>
                        my hair type
                      </label>
                    </b-col>
                    <b-col>
                      <span class="outlined">
                       <multiselect
                          v-model="hairType"
                          :options="hairTypeOptions"
                          :close-on-select="true"
                          :clear-on-select="false"
                          :preserve-search="true"
                          :taggable="false"
                          :searchable="false"
                          :preselect-first="false"
                          placeholder="skin type"
                          deselectLabel=""
                          selectLabel=""
                          label="hair_type_name"
                          track-by="hair_type_id"
                          :allow-empty="true"
                          :class="{ 'disabled': !isEditingPreferences }"
                          class="mb-5"
                          tagPosition="bottom"
                        />
                      </span>
                    </b-col>
                  </b-row>
                </b-col>
                <!-- <b-col 
                  cols="12" 
                  lg="6"
                  class="pr-lg-5"
                  >
                  <b-row align-h="between">
                    <b-col cols="6">
                      <label class="mb-5">
                        my skin undertone
                      </label>
                    </b-col>
                    <b-col cols="6">
                      <span class="outlined">
                        <multiselect
                          v-model="skinUndertone"
                          :options="skinUndertoneOptions"
                          :close-on-select="true"
                          :clear-on-select="false"
                          :preserve-search="true"
                          :taggable="false"
                          :searchable="false"
                          :preselect-first="false"
                          placeholder="skin undertone"
                          deselectLabel=""
                          selectLabel=""
                          label="name"
                          track-by="id"
                          :allow-empty="true"
                          :class="{ 'disabled': !isEditingPreferences }"
                          class="mb-5"
                          tagPosition="bottom"
                        />
                      </span>
                    </b-col>
                  </b-row>
                </b-col> -->

                <b-col
                  cols="12" 
                  lg="6"
                  class="pl-lg-5"
                  >
                  <b-row align-h="between">
                    <b-col cols="6" md="5">
                      <label class="mb-5">
                        my hair concerns
                      </label>
                    </b-col>
                    <b-col cols="6" md="7">
                      <span class="outlined">
                       <multiselect
                          v-model="hairConcern"
                          :options="hairConcernsOptions"
                          :clear-on-select="false"
                          :close-on-select="false"
                          :preserve-search="true"
                          :taggable="false"
                          :searchable="false"
                          :multiple="true"
                          :preselect-first="false"
                          placeholder="hair concern"
                          deselectLabel=""
                          selectLabel=""
                          label="name"
                          track-by="id"
                          :allow-empty="true"
                          :class="{ 'disabled': !isEditingPreferences }"
                          class="mb-5"
                          tagPosition="bottom"
                          v-if="isEditingPreferences"
                        />
                        <div v-else class="mb-5 placeholders">
                          {{ formatedHairConcern }}
                        </div>
                      </span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              
            </div>
          </div>
        </div>
      </section>

      <section class="mt-5 container">
        <b-row>
          <b-col md="6">
            <b-tabs content-class="mb-4" pills card fill>
              <b-tab title="account details" active>
                <div class="container">
                  <b-row>
                    <b-col class="p-0" sm="12" md="12">
                      <account-details />
                    </b-col>
                  </b-row>
                </div>
              </b-tab>
             <!--  <b-tab title="notifications">
                <div class="container">
                  <b-row>
                    <b-col class="p-0" sm="12" md="12">
                     <notifications />
                    </b-col>
                  </b-row>
                </div>
              </b-tab>
              <b-tab title="my reviews">
                <div class="container">
                  <b-row>
                    <b-col class="p-0" sm="12" md="12">
                    
                    </b-col>
                  </b-row>
                </div>
              </b-tab>
              <b-tab title="my referrals">
                <div class="container">
                  <b-row>
                    <b-col class="p-0" sm="12" md="12">
                     
                    </b-col>
                  </b-row>
                </div>
              </b-tab> -->
            </b-tabs>

          </b-col>
          <b-col md="6">
            <b-row>
              <b-col cols="12" md="6">
                <!-- <lists-slider /> -->
                <featured :title="'my dashboard'" :description="'my dashboard'" :link="'/me/dashboard'" />
              </b-col>

              <b-col cols="12" md="6" class="mb-5 mb-md-0">
                <following />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

      </section>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import APIClient from '@/api/requests'
import AccountDetails from '@/ui/AccountDetails.vue';
import ListsSlider from '@/ui/ListsSlider.vue';
import Following from '@/ui/Following.vue';
import Spinner from '@/ui/Spinner.vue';
import SimpleHeader from '@/ui/SimpleHeader';
import Featured from '@/components/dashboard/home/Featured.vue'

export default {
  name: 'Account',
  components: {
    Multiselect,
    AccountDetails,
    ListsSlider,
    Following,
    Spinner,
    SimpleHeader,
    Featured
  },
  computed: {
    user: function () {
      return this.$store.getters.profile
    },
    formatedSkinConcern: function() {
      if(!this.skinConcern) return '';
      return this.skinConcern.map(o => o.name).join(', '); 
    },
    formatedHairConcern: function() {
      if(!this.hairConcern) return '';
      return this.hairConcern.map(o => o.name).join(', '); 
    },
    formatedMakeupConcern: function() {
      if(!this.makeupConcern) return '';
      return this.makeupConcern.map(o => o.name).join(', '); 
    }
  },
  data () {
    return {
      skinType: null,
      skinTone: null,
      hairType: null,
      skinUndertone: null,
      hairConcern: null,
      skinConcern: null,
      makeupConcern: null,
      skinUndertoneConcern: null,
      skinTypeOptions: [],
      skinToneOptions: [],
      hairTypeOptions: [],
      skinUndertoneOptions: [],
      hairConcernsOptions: [],
      makeupConcernsOptions: [],
      skinConcernsOptions: [],
      isLoading: false,
      isEditingPreferences: false,
      isSavingPreferences: false,
      file: null
    }
  },
  mounted () {
    APIClient.getFullProfile()
      .then(resp => {

        // Change name fields
        if (resp.data.skintypes.length)       resp.data.skintypes[0].skin_type_name = resp.data.skintypes[0].name
        if (resp.data.skincolor.length)       resp.data.skincolor[0].skincolor_name = resp.data.skincolor[0].name
        if (resp.data.hairtypes.length)       resp.data.hairtypes[0].hair_type_name = resp.data.hairtypes[0].name
        if (resp.data.skinundertone.length)   resp.data.skinundertone[0].skin_undertone_name = resp.data.hairtypes[0].name

        APIClient.getPreferencesOptions()
          .then(r => {
            this.skinTypeOptions = r.data.skintypes
            this.skinToneOptions = r.data.skintones
            this.hairTypeOptions = r.data.hairtypes
            this.hairConcernsOptions = r.data.hairConcerns
            this.makeupConcernsOptions = r.data.makeupConcerns
            this.skinConcernsOptions = r.data.skinConcerns
            this.skinUndertoneOptions = r.data.skinundertones

            this.skinType = (resp.data.skintypes.length) ? resp.data.skintypes[0] : this.skinTypeOptions[0];
            this.skinTone = (resp.data.skincolor.length) ? resp.data.skincolor[0] : this.skinToneOptions[0];
            this.hairType = (resp.data.hairtypes.length) ? resp.data.hairtypes[0] : this.hairTypeOptions[0];
            this.skinUndertone = (resp.data.skinundertone.length) ? resp.data.skinundertone[0] : this.skinUndertoneOptions[0];
            this.hairConcern = (resp.data.hairconcerns.length) ? resp.data.hairconcerns : [];
            this.skinConcern = (resp.data.skinconcerns.length) ? resp.data.skinconcerns : [];
            this.makeupConcern = (resp.data.makeupConcerns.length) ? resp.data.makeupConcerns : [];

            if(this.$route.params.allowEdit) {
              this.isEditingPreferences = true
            }

          })
          .catch(err => {
            console.error(err)
          })
      })
      .catch(err => {
        console.error(err)
      })


  },
  methods: {
    updatePreferences() {
      let args = {
          skintype_id: (this.skinType) ? this.skinType.skin_type_id : null, 
          hairtype_id: (this.hairType) ? this.hairType.hair_type_id : null, 
          skintone_id: (this.skinTone) ? this.skinTone.skincolor_id : null,
          undertone_id: (this.skinUndertone) ? this.skinUndertone.id : null,
          skinconcerns: (this.skinConcern) ? this.skinConcern.map((el) => el.skin_concern_id || el.id) : null,
          makeupconcerns: (this.makeupConcern) ? this.makeupConcern.map((el) => el.id) : null,
          hairconcerns: (this.hairConcern) ? this.hairConcern.map((el) => el.hair_concern_id || el.id) : null,
      }

      this.isLoading = true
      this.isSavingPreferences = true

      APIClient.updatePreferences(args)
        .then(res => {
          this.isEditingPreferences = false
          this.isSavingProfile = false

          // Update profile data
          APIClient.getFullProfile()
              .then(resp => {
                this.$store.dispatch('getProfile').then(() => { })
              })
              .catch(err => {
                console.error(err)
              })


          this.$bvToast.toast(`Your preferences have been saved`, {
            title: 'Success',
            autoHideDelay: 5000,
            toastClass: 'success',
            appendToast: true,
            toaster: 'b-toaster-top-center'
          })
        })
        .catch(err => {
          console.error(err)

          this.$bvToast.toast(`Sorry, something went wrong. Please try again later`, {
            title: 'error',
            autoHideDelay: 5000,
            toastClass: 'success',
            appendToast: true,
          })
        })
        .finally( () => {
          this.isLoading = false
          this.isSavingPreferences = false
        })
    },
    editPreferences() {
      if(this.isSavingPreferences) return;

      if(!this.isEditingPreferences){
        this.isEditingPreferences = !this.isEditingPreferences
        return
      }
      this.isSavingProfile = true
      this.updatePreferences()
    },
    editAvatar: function () {
      if(this.isLoading) return
      this.$refs.file.click()
    },
    handleUpload: function() {
        this.file = this.$refs.file.files[0];

        if(this.file.size * 0.00000095367432 > 3) {
            this.showToast(
                "Sorry, this image exceeds the limit of 3MB.", 
                'error', 
                'Error'
                );
            return
        }

        const reader = new FileReader();
        this.isLoading = true;
        let self = this
        reader.onload = function(){
            var dataURL = reader.result;
            APIClient.changeProfileImage({image: dataURL})
                .then(resp => {
                    self.$store.dispatch('getProfile').then(() => {})
                })
                .catch(err => {
                self.showToast(
                    "Please, try again.", 
                    'error', 
                    "Something went wrong"
                    );
                })
                .finally( () => {
                    self.isLoading = false;
                })
        };
        reader.readAsDataURL(this.file);
    },
  }
}
</script>
