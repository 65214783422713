<template>
  <div class="beautebox-slider">
    <div class="beautebox-slider__inner">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="beautebox-slider__images">
              <figure class="beautebox-slider__image-1">
                  <img src="@/assets/images/backgrounds/beautebox-2-1.jpg"/>
              </figure>

              <figure class="beautebox-slider__image-2">
                  <img src="@/assets/images/backgrounds/beautebox-1-1.jpg"/>
              </figure>
            </div>

            <div class="beautebox-slider__content">
              <h2 class="beautebox-slider__title">
                CURATED FOR
              </h2>

              <!-- <svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 450.5 66.2"><path d="M11.7 17.9H.7v-7.8h31.7v7.8h-11v36h-9.6v-36zM69.2 10.1v43.8h-9.6v-19H45.8v18.9h-9.6V10.1h9.6v17h13.9v-17h9.5zM103.2 46.1v7.8H75.8V10.1h26.8v7.8H85.3V28h15.9v6.8H85.3v11.3h17.9zM119 10.1h18c8.8 0 13.3 3.6 13.3 10.8v.1c0 5.2-2.5 8.4-7.3 9.4v.6c5.8 1.1 8.7 4.7 8.7 10.5v.2c0 8.1-4.8 12.2-14.2 12.2H119V10.1zm16.2 17.8c3.8 0 5.7-1.7 5.7-5.2v-.1c0-3.4-1.8-5-5.7-5h-6.6v10.3h6.6zm.2 18.4c4.2 0 6.2-2 6.2-6.1v-.1c0-4-2-5.9-6.2-5.9h-6.9v12.1h6.9zM183.8 46.1v7.8h-27.5V10.1h26.8v7.8h-17.3V28h15.9v6.8h-15.9v11.3h18zM198.9 10.1H211l12.9 43.8h-10.1l-2-7.4h-14.4l-1.9 7.4h-9.3l12.7-43.8zm11.2 29.4l-3-11.7c-.9-3.3-1.6-6.4-2.2-9.7h-.6c-.5 3.2-1.2 6.3-2.1 9.7l-3.1 11.7h11zM259.3 10.1v30.4c0 9.3-5.8 14.2-16.5 14.2-10.8 0-16.6-4.9-16.6-14.2V10.1h9.6v29.7c0 5.1 2.4 7.6 7 7.6s7-2.6 7-7.6V10.1h9.5zM274.1 17.9h-11v-7.8h31.7v7.8h-11v36h-9.6v-36zM326.1 46.1v7.8h-27.5V10.1h26.8v7.8h-17.3V28H324v6.8h-15.9v11.3h18zM312.3 1.3h9.1l-5.8 6h-7.2l3.9-6zM341.9 10.1h18c8.8 0 13.3 3.6 13.3 10.8v.1c0 5.2-2.5 8.4-7.3 9.4v.6c5.8 1.1 8.7 4.7 8.7 10.5v.2c0 8.1-4.8 12.2-14.2 12.2h-18.5V10.1zM358 27.9c3.8 0 5.7-1.7 5.7-5.2v-.1c0-3.4-1.8-5-5.7-5h-6.6v10.3h6.6zm.3 18.4c4.2 0 6.2-2 6.2-6.1v-.1c0-4-2-5.9-6.2-5.9h-6.9v12.1h6.9zM377.9 32.3v-.1c0-15.6 6.1-22.9 18.1-22.9 12 0 18.1 7.3 18.1 22.9v.1c0 15.3-6.1 22.4-18.1 22.4-12.1 0-18.1-7.1-18.1-22.4zm26.4 2.1V30c0-8.9-2.7-13.2-8.4-13.2-5.6 0-8.3 4.2-8.3 13.2v4.5c0 8.6 2.8 12.8 8.4 12.8s8.3-4.2 8.3-12.9zM426.4 32.1l-10.9-21.9h10.7l4.8 9.9c.6 1.3 1.1 2.6 1.5 3.9h.5c.4-1.3.9-2.6 1.5-3.9l4.9-9.9h10.2L438.4 31l12 23h-10.9l-5.3-11.1c-.6-1.3-1.2-2.8-1.7-4.1h-.6c-.4 1.3-.9 2.7-1.6 4.1L425 54h-10.3l11.7-21.9z"/></svg> -->

              <div class="beautebox-slider__label">
                your skin type: <span><img src="@/assets/images/text/oily.png"/></span>
              </div>

              <div class="beautebox-slider__copy">
                a selection of must-have products carefully curated for oily skin with dark spots and hyperpigmentation. this edit includes five full-size products for your morning and evening skincare routine. featuring products from Epara, LESSE, F.Miller, Shayde Beauty and Supergoop! — plus a limited edition beautébag.
              </div>
              <div class="beautebox-slider__button">
                <a href="https://shop.beauteboard.com/products/hyperpigmentation-box" target="_blank" class="beautebox-slider__cta">
                  Shop now <span></span> $275
                </a>
                <small>worth $314</small>
              </div>
            </div>
          </div>
<!--           <div class="swiper-slide">
            test 2
          </div> -->
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import { helperMixin } from '@/mixins/helperMixin.js'
  import Swiper from '@/assets/scripts/vendor/swiper.esm.bundle';

  export default {
    name: 'BeauteboxSlider',
    components: {

    },
    mixins: [helperMixin],
    data() {
      return {
        slider: null
      }
    },
    mounted () {
      this.slider = new Swiper(`.beautebox-slider .swiper-container`, {
        slidesPerView: 1,
        spaceBetween: 20,
        init: true,
        loop: true,
        watchSlidesVisibility: true,
        navigation: {
          nextEl: '.footer .swiper-next',
          prevEl: '.footer .swiper-prev'
        },
        breakpoints: {
          991: {

          },
          500: {
          },
        },
        on: {
          init: function () {
          },
          slideChange: function () {
          }
        }
      })
    },
    methods: {

    }
  }
</script>
