<template>
  <div class="location py-5 px-lg-3">
    <b-row 
      v-if="uv" 
      class="location-wrapper justify-content-center align-items-center"
      >
      <b-col 
        sm="2" 
        class="text-center text-md-left"
        >

        <label v-if="!isMobile()">
          LOCATION: 
          <span>
            {{ weather.name }}
          </span>
        </label>
        <label v-else>
          current location:
        </label>
        <div 
          class="temperature mt-2" 
          v-if="!isMobile()"
          >
          {{ parseInt(weather.main.temp) }} °F
          <span>
            {{ Math.ceil((parseInt(weather.main.temp) - 32 ) * 5 / 9) }} °C
          </span>
        </div>
        <div 
          class="city temperature mt-2 mb-5" 
          v-else
          >
          {{profile.location.city}}, {{profile.location.country}}
        </div>

      </b-col>
      <b-col 
        sm="5" 
        class="px-5" 
        v-if="pollution !== false"
        >
         <progress-bar
            label="UV Rays"
            :value="(uv.value * 100) / 110"
            :show-symbol="false"
            :max="11"
            />
          <!-- <progress-bar
            label="Pollution"
            :value="pollution"
            :show-symbol="false"
            :max="10"
            /> -->
          
      </b-col>
      <!-- <b-col 
        sm="5" 
        class="px-5""
        >
         
          <progress-bar
            label="Water Hardness"
            value="62"
            :show-symbol="true"
            :max="100"
            />
      </b-col> -->
      <b-col 
        sm="5" 
        class="px-5""
        >
        <progress-bar
            label="Humidity"
            :value="weather.main.humidity"
            :show-symbol="true"
            :max="100"
            />
      </b-col>
    </b-row>


    <b-row class="location-wrapper" v-if="!isLoggedIn">
      <b-col sm="3">
        <label>
          Update your location
        </label>
          <multiselect 
              v-model="location" 
              id="ajax" 
              :label="city"
              :custom-label="cityWithCountry" 
              track-by="id" 
              placeholder="Type to search" 
              open-direction="bottom" 
              :options="cities" 
              :multiple="false" 
              :searchable="true" 
              :loading="isLoading" 
              :internal-search="false" 
              :options-limit="300" 
              :limit="3" 
              :max-height="600" 
              :show-no-results="false" 
              :hide-selected="true" 
              :select-label="''"
              @input="getUserWeather"
              @search-change="getCities">
                <span slot="noResult">
                  Oops! No elements found. Consider changing the search query.
                </span>
            </multiselect>
      </b-col>
    </b-row>

    <b-row 
      v-if="!profile.location && !isPublic"
      class="overlay"
      >
      <b-col cols="12" class="text-center">
        Please update your location in the <router-link :to="{ name: 'account' }">account</router-link> page.
      </b-col>
    </b-row>
  </div>
</template>

<script>
import APIClient from '@/api/requests'
import ProgressBar from '@/ui/ProgressBar.vue'
import { helperMixin } from '@/mixins/helperMixin.js'
import Multiselect from 'vue-multiselect'
export default {
  name: 'Location',
  components: {
    ProgressBar,
    Multiselect
  },
  mixins: [helperMixin],
  data () {
    return {
      location: null,
      weather: {
        name: '',
        main: {
          humidity: 0,
          temp: 0
        }
      },
      cities: [],
      uv: {
        value: 0
      },
      pollution: 0
    }
  },
  props: {
      isPublic: false
  },
  methods: {
    getUserWeather: function (cityObject) {

       APIClient.getOpenWeatherData(cityObject.city)
            .then( res => {
              this.weather = res.data
              console.log(res)
              APIClient.getOpenWeatherUV(res.data.coord.lat, res.data.coord.lon)
                .then( r => {
                  this.uv = r.data
                })
                .catch( err => {
                  console.error(err)
                })

              APIClient.getOpenWeatherPollution(parseInt(res.data.coord.lat), parseInt(res.data.coord.lon))
                .then( r => {
                    this.pollution = r.data.data[0].value
                  })
                  .catch( err => {
                    console.error(err)
                  })
            })
            .catch( err => {
              console.error(err)
            })

    },
    cityWithCountry ({ city, country }) {
      // return `${city}, ${country}`;
      return `${country}`;
    },
    getCities (query) {
      // this.isLoading = true
      // console.log(query)
      if(query.length > 0) {
        APIClient.getCities(query, true)
          .then( res => {
            console.log(res.data)
            this.cities = res.data
          })
          .catch( err => {
            console.error(err)
          })
          .finally( () => {
            this.isLoading = false;
          })
      }
    },
  },
  mounted () {

    if(!this.isPublic && this.isLoggedIn) {
      if(!this.profile.location) return

      APIClient.getOpenWeatherData(this.profile.location.city)
        .then( res => {
          this.weather = res.data
          APIClient.getOpenWeatherUV(res.data.coord.lat, res.data.coord.lon)
            .then( r => {
              this.uv = r.data
            })
            .catch( err => {
              console.error(err)
            })

          APIClient.getOpenWeatherPollution(parseInt(res.data.coord.lat), parseInt(res.data.coord.lon))
            .then( r => {
                this.pollution = r.data.data[0].value
              })
              .catch( err => {
                console.error(err)
              })
        })
        .catch( err => {
          console.error(err)
        })
    } else {
      APIClient.getCities('new york', true) 
        .then( r => {
          console.log(r.data[0])
          // this.pollution = r.data.data[0].value

          this.getUserWeather(r.data[0])

        })
        .catch( err => {
          console.error(err)
        })
    }
    
  },
  computed: {
    profile: function () {
      return this.$store.getters.profile
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn
    },
  },
}
</script>
