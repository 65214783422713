<template>
	<div :class="className" id="app">
		<app-header />
		<transtition name="fade">
			<router-view />
			<!-- <app-footer v-if="showFooter" /> -->
		</transtition>

		<mobile-coming-soon />

		<cookie-law v-if="showCookie" button-text="Accept">
			<div slot="message">
			    We use cookies to enhance your experience and our service. by clicking "accept" you agree to the use of our cookies. learn more <router-link to="privacy" target="_blank">here</router-link>
			</div>
		</cookie-law>

	</div>
</template>

<script>
	import AppHeader from './components/AppHeader.vue'
	// import AppFooter from '@/components/AppFooter.vue'
	import MobileComingSoon from './components/MobileComingSoon.vue'
	import CookieLaw from 'vue-cookie-law'

	export default {
		components: {
			AppHeader,
			// AppFooter,
			MobileComingSoon,
			CookieLaw
		},    
		computed: {
			isLoggedIn: function () {
				return this.$store.getters.isLoggedIn
			},
			profile: function () {
				return this.$store.getters.profile
			},
			showFooter: function () {
				let routesWithoutFooter = ['privacy', 'terms', 'dashboard-home', 'moodboard'];
				return routesWithoutFooter.includes(this.$route.name);
			},
			showCookie: function () {
				let routesWithoutCookieMessage = ['login', 'quiz', 'dashboard-home', 'home-simple'];
				return routesWithoutCookieMessage.includes(this.$route.name);
			},
			className() {
		        return `page-${this.$route.name}`
		    }
		},
		methods: {
			logout: function () {
				this.$store.dispatch('logout').then(() => {
					this.$router.push('https://my.beauteboard.com')
				})
			},
			getProfile: function () {
				this.$store.dispatch('getProfile').then(resp => {})
			}
		}
	}
</script>

<style lang="scss">
	@import "@/assets/styles/main.scss";
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>


<script>
        if(process.env.ENVIRONMENT == 'development') {
	        window.Userback = window.Userback || {};
	        Userback.access_token = '36416|70858|ew80Cqdek2uRGWSmjcGqu35EO';
	        (function(d) {
	            var s = d.createElement('script');s.async = true;
	            s.src = 'https://static.userback.io/widget/v1.js';
	            (d.head || d.body).appendChild(s);
	        })(document);
	    }
 </script>
