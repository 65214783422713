<template>
    <div 
        class="routine-slider routine-slider--simple" 
        :class="{ 'routine-slider--loading': isLoading, 'routine-slider--opacity': showOpacity}"
        >    
        <div class="d-flex align-items-center mb-5 mb-md-2">
            <h2 class="routines__title">
                {{ title }}
            </h2>
        </div>
        <div class="swiper-container" ref="routineSlider">
            <div class="swiper-wrapper">
                <div
                    v-for="(item, index) in items"
                    :key="index"
                    class="swiper-slide routine-slider__slide"
                    >
                    
                    <product-routine 
                        :slider-index="index" 
                        :source="item" 
                        :isPublic="true"
                    />
                </div>
            </div>
        </div>

        <div class="routine-slider__prev">
            <i class="icon-arrow-slider-left">
            </i>
        </div>
        <div class="routine-slider__next">
            <i class="icon-arrow-slider-right">
            </i>
        </div>

    </div>
</template>

<script>
    import Swiper from '@/assets/scripts/vendor/swiper.esm.bundle'
    import ProductRoutine from '@/ui/ProductRoutine.vue'
    import RoutineEmpty from '@/components/dashboard/home/RoutineEmpty.vue'

    export default {
        name: 'SimpleRoutineSlider',
        components: {
            ProductRoutine,
            RoutineEmpty
        },
        data() {
            return {
                slider: null,
                producToAdd: null,
                emptyMessageHeight: 'auto',
                showOpacity: false,
                showEmptyForm: false,
            }
        },
        props: {
            isShelf: true,
            routine: null,
            isLoading: true,
            items: [],
            title: null,
            description: null,
        },
        mounted () {
            this.initSlider()
            this.updateSlider()
        },
        methods: {
            initSlider() {
                this.slider = new Swiper(`.routine-slider .swiper-container`, {
                    slidesPerView: 1,
                    spaceBetween: 30,
                    loop: false,
                    navigation: {
                        nextEl: '.routine-slider__next',
                        prevEl: '.routine-slider__prev',
                        disabledClass: 'disabled'
                    },
                    breakpoints: {
                        1200: {
                            slidesPerView: 4,
                        },
                        991: {
                            slidesPerView: 4,
                        },
                        800: {
                            slidesPerView: 3,
                        },
                        500: {
                            slidesPerView: 1,
                        },
                    }
                })
            },
            toggleOpacity: function(val) {
                this.showOpacity = val
            },
            updateSlider: function (products) {

                let self = this      
                
                // this.$nextTick( () => {
                //     self.items = products  
                //     self.$forceUpdate()
                //     let sliderHeight = self.$refs.routineSlider.clientHeight
                    
                //     if(sliderHeight > 0){
                //         self.emptyMessageHeight = `${self.$refs.routineSlider.clientHeight}px`;
                //     }
                // }) 

                setTimeout(function(){   
                    self.slider.init();
                    self.slider.update();
                    self.$forceUpdate()                  
                }, 1000)
                
            },
        }
    }
</script>
