<template>
	<div class="public-product">
      <product-modal 
        :modalId="`public_modal`" 
        section="filter" 
        :source="product"
        :isPublic="true"
        @onClose="preventClose"
        />
      <info-modal @onOkClicked="redirectToLogin" :okTitle='`Login`' :info="infoModal" />
	</div>
</template>

<script>

import APIClient from '@/api/requests'
import ProductModal from '@/ui/ProductModal.vue'
import InfoModal from '@/ui/InfoModal.vue'

export default {
  name: 'PublicProduct',
  data() {
  	return {
  		slider: null,
      slug: '',
      brand: '',
      section: '',
      product: null,
      infoModal: {
        title: '',
        message: ''
      },
  	}
  },
  components: {
    ProductModal,
    InfoModal
  },
  mounted () {
    this.section = this.$route.params.section
    this.brand = this.$route.params.brand
  	this.slug = this.$route.params.slug

    APIClient.getPublicProduct(this.section, this.brand, this.slug)
      .then( resp => {
        console.log(resp)

        if(this.isLoggedIn) {
          // Redirect to product in platform
          console.log(resp.data.product_id)
          this.$router.push('/me?product=' + resp.data.product_id) 
          return
        }

        this.product = resp.data
        this.$bvModal.show(`public_modal`)
      })
      .catch( err => {
        console.error(err)
        this.$router.push('/404')
      })
  },
  computed: {
      isLoggedIn: function () {
        return this.$store.getters.isLoggedIn
      }
  },
  methods: {
    preventClose: function() {
      if(this.isLoggedIn) {
        this.$router.push('/me') 
      } else {
        this.infoModal = {
          title: "Login Required",
          message: "Join us to access to all content"
        }
        this.$bvModal.show('info_modal')
      }
      
    },
    redirectToLogin: function () {
      this.$router.push('/') 
    }
  }
}
</script>
