<template>
  <b-row class="product-browser" :id="section">
    <b-col sm="12" class="px-0 px-lg-2">

       <div class="container mobile-header" v-if="isMobile()">
          <b-row class="" align-v="center">
            <b-col cols="3" class="px-0">
              <b-button v-b-toggle.collapse-filter><i class="icon-caret-right"></i>filter</b-button>
            </b-col>
            <b-col cols="3" class="px-0 sort-by">
                <multiselect
                  v-model="optionsSortByValue"
                  :options="optionsSortBy"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="Sort By"
                  @input="updateSortBy"
                />
            </b-col>
            <b-col v-if="!showNewProducts" v-show="isLoggedIn" cols="6" clas="text-right">
              <b-col md="auto" class="show-all pr-0">
                <span class="mr-2">
                  all products
                  <i class="ml-1 icon-info" v-b-tooltip.hover :title="tooltip"><span>i</span></i>
                </span>
                <input class="uiswitch float-right" :checked="showAll" @input="toggleShowAll" type="checkbox"/>
              </b-col>
            </b-col>
        </b-row>
      </div>

      <div class="container">
        <b-row class="products-browser">
          <b-collapse v-if="showFilters" id="collapse-filter" class="mt-2 col-lg-3 col-12 products-filters px-4">
              <b-col
                sm="12"
                class="my-lg-5 my-2 pr-md-4"
              >
                <template v-if="optionsSortBy.length">
                  <b-form-group>
                    <multiselect
                      v-model="optionsSortByValue"
                      :options="optionsSortBy"
                      @input="updateSortBy"
                      :searchable="false"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="Sort By"
                      class="mb-3"
                      v-if="!isMobile()"
                    />
                  </b-form-group>
                </template>

                <b-form-group>
                  <label class="mb-3 refine-by">
                    <svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="12.063" height="12.415" viewBox="0 0 12.063 12.415"><g data-name="Grupo 333"><path data-name="Trazado 169" d="M9.711 4.77l.2.047v7.594h.484V4.817l.2-.047a1.912 1.912 0 0 0 0-3.721l-.2-.047V.238a.242.242 0 0 0-.484 0v.764l-.2.047a1.912 1.912 0 0 0 0 3.721zm.441-3.288a1.427 1.427 0 1 1-1.427 1.427 1.429 1.429 0 0 1 1.427-1.423z"/><path data-name="Trazado 170" d="M1.47 9.782l.2.047v2.586h.484V9.829l.2-.047a1.912 1.912 0 0 0 0-3.721l-.2-.047V.242a.242.242 0 0 0-.484 0v5.772l-.2.047a1.912 1.912 0 0 0 0 3.721zm.441-3.288A1.427 1.427 0 1 1 .484 7.922a1.43 1.43 0 0 1 1.427-1.428z"/></g></svg> Refine by:
                  </label>
                </b-form-group>

                <!-- Product type -->

                <template v-if="productTypesOptions.length">
                  <b-form-group
                    class="filter-header pt-3"
                    v-if="productTypesOptions.length"
                    >
                    <div>
                      <a
                        :class="showCollapseKeyProductType ? 'collapse-button my-2 collapsed' : ' collapse-button my-2'"
                        :aria-expanded="showCollapseKeyProductType ? 'true' : 'false'"
                        aria-controls="collapse-1"
                        @click="showCollapseKeyProductType = !showCollapseKeyProductType"
                      >
                        <i class="icon-caret-down"></i>
                      </a>
                      <label for="input-sm">Product Type</label>
                    </div>
                    <div 
                      v-if="showCollapseKeyProductType" 
                      class="filter-counter float-right"
                      >
                      {{ filters.type.length }}
                    </div>
                    <div 
                      class="float-right show-collapse"
                      v-else
                      >
                      <button @click="showCollapseKeyProductType = !showCollapseKeyProductType">
                        show all
                      </button>
                    </div>
                  </b-form-group>

                  <b-form-group class="mb-0" v-if="productTypesOptions.length">
                    <b-collapse
                      id="collapse-1"
                      v-model="showCollapseKeyProductType"
                      class="mt-2"
                      >
                      <checkbox-multi-select
                        :options="productTypesOptions"
                        :selected="filters.type"
                        :searchable="false"
                        customLayout="two-columns"
                        @change="updateProductTypeFilter($event)"
                        class="mb-4"
                        />
                    </b-collapse>
                  </b-form-group>
                </template>

                <!--  concern -->

                <template v-if="concernsOptions.length && section != 'makeup'">
                  <b-form-group
                    class="filter-header pt-3"
                    >
                    <div>
                      <a
                        :class="showCollapseConcerns ? ' collapse-button my-2 collapsed' : 'collapse-button my-2'"
                        :aria-expanded="showCollapseConcerns ? 'true' : 'false'"
                        aria-controls="collapse-5"
                        @click="showCollapseConcerns = !showCollapseConcerns"
                        >
                          <i class="icon-caret-down"></i>
                        </a>
                        <label for="input-sm">Concerns</label>
                      </div>
                      <!-- <div class="filter-counter float-right">{{ filters.lifeChangeConcern.length }}</div> -->
                      <div 
                        v-if="showCollapseConcerns" 
                        class="filter-counter float-right"
                        >
                        {{ filters.concerns.length }}
                      </div>
                      <div 
                        class="float-right show-collapse"
                        v-else
                        >
                        <button @click="showCollapseConcerns = !showCollapseConcerns">
                          show all
                        </button>
                      </div>

                  </b-form-group>

                  <b-form-group class="mb-0">
                    <b-collapse
                      id="collapse-5"
                      v-model="showCollapseConcerns"
                      class="mt-2"
                    >
                      <checkbox-multi-select
                        :options="concernsOptions"
                        :selected="filters.concerns"
                        @change="updateConcernsFilter($event)"
                        class="mb-4"
                      />
                    </b-collapse>
                  </b-form-group>
                </template>

                <!-- Benefit -->

                <template v-if="benefitsOptions.length">
                  <b-form-group
                    class="filter-header pt-3"
                    v-if="benefitsOptions.length"
                    >
                    <div>
                      <a
                        :class="showCollapseBenefit ? ' collapse-button my-2 collapsed' : 'collapse-button my-2'"
                        :aria-expanded="showCollapseBenefit ? 'true' : 'false'"
                        aria-controls="collapse-3"
                        @click="showCollapseBenefit = !showCollapseBenefit"
                        >
                        <i class="icon-caret-down"></i>
                      </a>
                      <label for="input-sm">Benefit</label>
                    </div>
                    <div 
                      v-if="showCollapseBenefit" 
                      class="filter-counter float-right"
                      >
                      {{ filters.benefit.length }}
                    </div>
                    <div 
                      class="float-right show-collapse"
                      v-else
                      >
                      <button @click="showCollapseBenefit = !showCollapseBenefit">
                        show all
                      </button>
                    </div>
                  </b-form-group>

                  <b-form-group 
                    class="mb-0"
                    v-if="benefitsOptions.length"
                    >
                    <b-collapse
                      id="collapse-3"
                      v-model="showCollapseBenefit"
                      class="mt-2"
                    >
                      <checkbox-multi-select
                        :options="benefitsOptions"
                        :selected="filters.benefit"
                        @change="updateBenefitsFilter($event)"
                        class="mb-4"
                      />
                    </b-collapse>
                  </b-form-group>
                </template>

                <!-- Brands -->

                <b-form-group
                  class="filter-header pt-3"
                  v-if="!isBrandBrowser"
                  >
                  <div>
                    <a
                      :class="showCollapseBrand ? ' collapse-button my-2 collapsed' : 'collapse-button my-2'"
                      :aria-expanded="showCollapseBrand ? 'true' : 'false'"
                      aria-controls="collapse-4"
                      @click="showCollapseBrand = !showCollapseBrand"
                      >
                      <i class="icon-caret-down"></i>
                    </a>
                    <label for="input-sm">Brand</label>
                  </div>
                  <!-- <div class="filter-counter float-right">{{ filters.brand.length }}</div> -->
                  <div 
                    v-if="showCollapseBrand" 
                    class="filter-counter float-right"
                    >
                    {{ filters.brand.length }}
                  </div>
                  <div 
                    class="float-right show-collapse"
                    v-else
                    >
                    <button @click="showCollapseBrand = !showCollapseBrand">
                      show all
                    </button>
                  </div>
                </b-form-group>

                <b-form-group
                  class="mb-0"
                  v-if="!isBrandBrowser"
                  >
                  <b-collapse
                    id="collapse-4"
                    v-model="showCollapseBrand"
                    class="mt-2"
                    >
                    <checkbox-multi-select
                      :options="brandsOptions"
                      :selected="filters.brand"
                      :searchable="true"
                      @change="updateBrandsFilter($event)"
                      class="mb-4 brands"
                    />
                  </b-collapse>
                </b-form-group>

                <!-- Product Preferences -->

                <b-form-group
                  class="filter-header pt-3"
                  v-if="productPreferencesOptions.length"
                  >
                  <div>
                    <a
                      :class="showCollapseProductPreference ? ' collapse-button my-2 collapsed' : 'collapse-button my-2'"
                      :aria-expanded="showCollapseProductPreference ? 'true' : 'false'"
                      aria-controls="collapse-5"
                      @click="showCollapseProductPreference = !showCollapseProductPreference"
                      >
                        <i class="icon-caret-down"></i>
                      </a>
                      <label for="input-sm">Product Preference</label>
                    </div>
                    <!-- <div class="filter-counter float-right">{{ filters.preference.length }}</div> -->
                    <div 
                      v-if="showCollapseProductPreference" 
                      class="filter-counter float-right"
                      >
                      {{ filters.preference.length }}
                    </div>
                    <div 
                      class="float-right show-collapse"
                      v-else
                      >
                      <button @click="showCollapseProductPreference = !showCollapseProductPreference">
                        show all
                      </button>
                    </div>
                </b-form-group>

                <b-form-group 
                  class="mb-0"
                  v-if="productPreferencesOptions.length"
                  >
                  <b-collapse
                    id="collapse-5"
                    v-model="showCollapseProductPreference"
                    class="mt-2"
                  >
                    <checkbox-multi-select
                      :options="productPreferencesOptions"
                      :selected="filters.preference"
                      @change="updateProductPreferenceFilter($event)"
                      class="mb-4"
                    />
                  </b-collapse>
                </b-form-group>

                <!-- life change concern -->

                <b-form-group
                  class="filter-header pt-3"
                  >
                  <div>
                    <a
                      :class="showCollapseLifeChangeConcern ? ' collapse-button my-2 collapsed' : 'collapse-button my-2'"
                      :aria-expanded="showCollapseLifeChangeConcern ? 'true' : 'false'"
                      aria-controls="collapse-5"
                      @click="showCollapseLifeChangeConcern = !showCollapseLifeChangeConcern"
                      >
                        <i class="icon-caret-down"></i>
                      </a>
                      <label for="input-sm">Life Change</label>
                    </div>
                    <!-- <div class="filter-counter float-right">{{ filters.lifeChangeConcern.length }}</div> -->
                    <div 
                      v-if="showCollapseLifeChangeConcern" 
                      class="filter-counter float-right"
                      >
                      {{ filters.lifeChange.length }}
                    </div>
                    <div 
                      class="float-right show-collapse"
                      v-else
                      >
                      <button @click="showCollapseLifeChangeConcern = !showCollapseLifeChangeConcern">
                        show all
                      </button>
                    </div>

                </b-form-group>

                <b-form-group class="mb-0">
                  <b-collapse
                    id="collapse-5"
                    v-model="showCollapseLifeChangeConcern"
                    class="mt-2"
                  >
                    <checkbox-multi-select
                      :options="lifeChangeConcernsOptions"
                      :selected="filters.lifeChange"
                      @change="updateLifeChangeConcernsFilter($event)"
                      class="mb-4"
                    />
                  </b-collapse>
                </b-form-group>


                 <!-- life change concern -->


                <b-form-group
                  class="filter-header pt-3"
                  v-if="finishOptions.length"
                >
                  <div>
                    <a
                      :class="showCollapseLifeFinish ? ' collapse-button my-2 collapsed' : 'collapse-button my-2'"
                      :aria-expanded="showCollapseLifeFinish ? 'true' : 'false'"
                      aria-controls="collapse-5"
                      @click="showCollapseLifeFinish = !showCollapseLifeFinish"
                      >
                        <i class="icon-caret-down"></i>
                      </a>
                      <label for="input-sm">Finish</label>
                    </div>
                    <!-- <div class="filter-counter float-right">{{ filters.finish.length }}</div> -->
                    <div 
                      v-if="showCollapseLifeFinish" 
                      class="filter-counter float-right"
                      >
                      {{ filters.finish.length }}
                    </div>
                    <div 
                      class="float-right show-collapse"
                      v-else
                      >
                      <button @click="showCollapseLifeFinish = !showCollapseLifeFinish">
                        show all
                      </button>
                    </div>
                </b-form-group>

                <b-form-group class="mb-0" v-if="finishOptions.length">
                  <b-collapse
                    id="collapse-5"
                    v-model="showCollapseLifeFinish"
                    class="mt-2"
                  >
                    <checkbox-multi-select
                      :options="finishOptions"
                      :selected="filters.finish"
                      @change="updateFinishFilter($event)"
                      class="mb-4"
                    />
                  </b-collapse>
                </b-form-group>


                <!-- Price Range -->

                <b-form-group
                  class="filter-header pt-3"
                  >
                  <div>
                    <a
                      :class="showCollapsePriceRange ? ' collapse-button my-2 collapsed' : 'collapse-button my-2'"
                      :aria-expanded="showCollapsePriceRange ? 'true' : 'false'"
                      aria-controls="collapse-6"
                      @click="showCollapsePriceRange = !showCollapsePriceRange"
                      >
                        <i class="icon-caret-down"></i>
                      </a>
                      <label for="input-sm">Price Range</label>
                    </div>
                    <div 
                      class="float-right"
                      v-if="!showCollapsePriceRange"
                      >
                      <button @click="showCollapsePriceRange = !showCollapsePriceRange">
                        show all
                      </button>
                    </div>
                </b-form-group>

                <b-form-group class="mb-0">
                  <b-collapse
                    id="collapse-6"
                    v-model="showCollapsePriceRange"
                    class="mt-2"
                  >
                    <vue-slider
                      v-model="selectedRange"
                      :enable-cross="false"
                      :max="priceRange.max"
                      @drag-end="updatePriceFilter"
                      class="mt-5"
                    >
                      <template v-slot:tooltip="{ value }">
                        <div class="custom-tooltip">{{currency}}{{ value }}</div>
                      </template>
                    </vue-slider>
                  </b-collapse>
                </b-form-group>

                <b-button 
                  class="btn btn-secondary mt-5 w-100 reset-filters py-3" 
                  @click="resetFilters"
                  v-scroll-to="sectionId"
                  >
                  Reset Filters
                </b-button>

              </b-col>
          </b-collapse>

          <b-col
            lg="9"
            cols="12"
            :class="{'col-lg-12': !showFilters}"
            class="py-5 products-grid"
          >
            <b-row align-v="center" align-h="between">
              <b-col
                sm="7"
                class=""
              >
                <h4 class="m-0 pl-3" v-if="!isMobile()">
                  {{ meta.total }}
                  <span v-if="meta.total == 1">
                    product
                  </span>
                  <span v-else>
                     <span v-if="section == 'makeup'">
                      makeup
                     </span>
                    products
                  </span>
                  
                  <template v-if="isLoggedIn">
                    <span v-if="section == 'makeup'">
                      for you, {{ profile.name }}
                    </span>
                    <span v-else>
                      <span v-if="!showAll">
                        <span v-if="!isBrandBrowser && !showNewProducts">
                          for your {{ sectionType }} {{ section }}, {{ profile.name }}
                        </span>
                        <span v-else>
                          curated for you
                        </span>
                      </span>
                    </span>
                  </template>
                  
                </h4>
              </b-col>
              <b-col md="auto" class="show-all" v-if="!isMobile() && !showNewProducts && isLoggedIn">
                <span class="mr-2">
                  all products 
                  <i class="ml-1 icon-info" v-b-tooltip.hover :title="tooltip"><span>i</span></i>
                </span>
                <input class="uiswitch float-right" :checked="showAll" @input="toggleShowAll" type="checkbox"/>
              </b-col>
            </b-row>
            <b-row>
              
              <b-col
                cols="12"
                class="filters"
                >
                <div class="container">
                  <b-row align-v="center" class="filter-container">
                    <b-col cols="8" lg="10" class="p-0 pl-lg-0 pl-3">
                      <selected-filters v-if="hasFilters" :items="filters" :showLabel="true"/>
                    </b-col>
                    <b-col cols="4" lg="2" class="text-right pr-0">
                      <a v-if="hasFilters" class="reset pb-2 pr-0" @click="resetFilters">clear filters</a>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col
                cols="12"
                class="list"
              >
                <spinner v-if="isLoadingProducts" />
                <div :class="{'invisible': isLoadingProducts}">
                  <transition-group class="grid" name="list" tag="div">
                    <div class="item"
                      v-for="(item, index) in products"
                      :key="item.id"
                    >
                      <Product :source="item" :sourceNavigation="products" :sourceIndex="index" />
                    </div>
                  </transition-group>
                </div>
              </b-col>
            </b-row>

            <b-row align-h="end" class="my-5" v-if="!showNewProducts">

              <b-col
                sm="5"
                class="float-right product-pagination text-right"
                >
                <label>showing {{ meta.from }} - {{ meta.to }} of {{ meta.total }}</label>
                <div class="nav-pagination">
                  <a @click="getPreviousPage" :class="{ disabled: meta.currentPage <= 1 }">
                    <svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="5.54" height="10.777" viewBox="0 0 5.54 10.777"><path data-name="Trazado 201" d="M0 5.389l4.835 5.389.705-.705-4.23-4.684L5.54.705 4.835 0z"/></svg>
                  </a>
                  {{ meta.currentPage }}  /  {{ meta.totalPages }}
                  <a @click="getNextPage" :class="{ disabled: meta.currentPage >= meta.totalPages }">
                    <svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="5.54" height="10.777" viewBox="0 0 5.54 10.777"><path data-name="Trazado 200" d="M5.538 5.389L.703 10.778l-.705-.705 4.23-4.684L-.002.705.703 0z"/></svg>
                  </a>
                </div>
              </b-col>

            </b-row>

          </b-col>

        </b-row>
      </div>
    </b-col>

  </b-row>
</template>

<script>
import Multiselect from 'vue-multiselect'
import CheckboxMultiSelect from '@/ui/CheckboxMultiSelect.vue'
import SelectedFilters from '@/ui/SelectedFilters.vue'
import Product from '@/ui/products_browser/Product.vue'
import Spinner from '@/ui/Spinner.vue'
import APIClient from '@/api/requests'
import VueSlider from 'vue-slider-component'
import EventBus from '@/event-bus'
import { helperMixin } from '@/mixins/helperMixin.js'

const doneFilteringInterval = 1000

export default {
  name: 'ProductsBrowser',
  components: {
    Multiselect,
    CheckboxMultiSelect,
    SelectedFilters,
    Product,
    VueSlider,
    Spinner
  },
  mixins: [helperMixin],
  props: {
    showFilters: {
      type: Boolean,
      required: false,
      default: true
    },
    showNewProducts: {
      type: Boolean,
      required: false,
      default: false
    },
    section: {
      type: String,
      required: true
    },
    isBrandBrowser: {
      type: Boolean,
      required: false,
      default: false
    },
    brand: {
      type: String,
      required: false,
      default: ''
    },
    brandIds: {
      type: Array,
      required: false,
      default: []
    },
  },
  computed: {
    sectionType: function() {
      let sectionType = ""
      if(this.section == "skin") {
        sectionType = this.profile.skin_type[0].name
      } else if(this.section == 'makeup') {
        sectionType = this.profile.skin_type[0].name
      } else {
        sectionType = this.profile.hair_type[0].name
      }
      return sectionType;
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn
    },
    bodySection: function() {
      let bodySection = ""
      if(this.section == "skin" || this.section == 'makeup') {
        bodySection = "skin";
      } else {
        bodySection = "hair";
      }
      return bodySection;
    },
    tooltip: function () {
      if(this.isBrandBrowser) {
        return `when turned ON, these products won’t be curated based on your preferences`;
      }
      return `when turned ON, these products won’t be curated for your ${this.bodySection} type`;
    },
    sectionId: function () {
      return `#${this.section}`
    },
    profile: function () {
      return this.$store.getters.profile
    },
    range: function(){
      var array = [],
      j = 0;
      for(var i = this.meta.currentPage; i <= this.meta.total; i++){
        array[j] = i;
        j++;
      }
      return array;
    },
    hasFilters: function () {
      return this.filters.ingredient.length ||  this.filters.type.length || this.filters.benefit.length || this.filters.brand.length || this.filters.preference.length || this.filters.lifeChange.length || this.filters.finish.length || this.filters.concerns.length;
    }
  },
  data () {
    return {
      filters: {
        ingredient: [],
        type: [],
        benefit: [],
        brand: [],
        preference: [],
        priceMin: 0,
        priceMax: 100,
        lifeChange: [],
        finish: [],
        concerns: []
      },
      showCollapseKeyIngredient: true,
      showCollapseKeyProductType: true,
      showCollapseBenefit: true,
      showCollapseBrand: true,
      showCollapseProductPreference: true,
      showCollapsePriceRange: true,
      showCollapseLifeChangeConcern: true,
      showCollapseLifeFinish: true,
      showCollapseConcerns: true,
      selectedRange: [0, 100],
      ingredientsOptions: [],
      finishOptions: [],
      lifeChangeConcernsOptions: [
        // { value: "chemoterapy", name: "Chemoterapy" },
        { value: "pregnancySafe", name: "Pregnancy Safe" }
      ],
      optionsSortByValue: '',
      isLoadingProducts: true,
      meta: {},
      optionsSortBy: [
        'Price',
        'Brand',
        'Name'
      ],
      productTypesOptions: [],
      benefitsOptions: [],
      brandsOptions: [],
      productPreferencesOptions: [],
      concernsOptions: [],
      currency: '$',
      priceRange: {
        min: 0,
        max: 500
      },
      showAll: false,
      products: [],
    }
  },
  mounted () {
    if(this.section) {
      this.getFilters()
    }

    if(this.showNewProducts) {
      APIClient.getNewProducts()
        .then(resp => {
            // this.setProductsData(resp);
            this.products = resp.data
          })
          .catch(err => {
            console.error(err)
          })
          .finally( () => {
            this.isLoadingProducts = false;
            this.$emit('onProductsLoaded')
          })
    }

    // if(this.isBrandBrowser) {
      // this.showAll = true
    // }
  },
  methods: {
    getFilters (brand = null) {
      APIClient.getFilters(this.section, this.isBrandBrowser, brand, !this.isLoggedIn)
      .then(  resp => {

        
        if(this.isLoggedIn) {
          this.updateUserPriceRange()
        }

        if(!this.isBrandBrowser) {

          console.log(resp.data.types)
          this.ingredientsOptions = resp.data.ingredients.map( el => {
            return el
          })

          this.productTypesOptions = resp.data.types || []
          this.benefitsOptions = resp.data.benefits
          this.brandsOptions = resp.data.brands
          this.productPreferencesOptions = resp.data.preferences
          this.finishOptions = resp.data.finish || []
          this.concernsOptions = resp.data.concerns
          this.currency = resp.data.priceRangeCurrency



          this.$forceUpdate()
          
        } else {
          
          this.productTypesOptions = resp.data.category
        }

        this.getProducts();
      })
      .catch(err => {
        // console.log(err)
      })
    },
    goToTop: function() {
      var VueScrollTo = require('vue-scrollto');
      VueScrollTo.scrollTo(`#${this.section}`);
    },
    getNextPage: function () {

      if (this.meta.currentPage >= this.meta.totalPages || this.isLoadingProducts) return;

      this.goToTop()

      this.products = []
      this.isLoadingProducts = true;
      // console.log(this.meta.nextPage)
      APIClient.searchProductByPage(this.meta.nextPage)
        .then( res => {
          // // console.log(res)
          this.setProductsData(res)
        })
        .catch( err => {
          console.error(err)
        })
        .finally( () => {
          this.isLoadingProducts = false;
        })

    },
    getPreviousPage: function () {

      if (this.meta.currentPage <= 1 || this.isLoadingProducts) return;

      this.goToTop()

      this.isLoadingProducts = true;
      APIClient.searchProductByPage(this.meta.previousPage)
        .then( res => {
          // // console.log(res)
          this.setProductsData(res)
        })
        .catch( err => {
          console.error(err)
        })
        .finally( () => {
          this.isLoadingProducts = false;
        })
    },
    updateUserPriceRange() {

      if(this.isBrandBrowser) {
        this.filters.priceMax = 500;
        this.selectedRange = [0, 500]
        return
      }
      switch(this.section) {
        case "skin":
          this.selectedRange = [0, parseInt(this.profile.user_data.skin_max_budget)]
          break;
        case "hair":
          this.selectedRange = [0, parseInt(this.profile.user_data.hair_max_budget)]
          break;
        case "makeup":
          this.selectedRange = [0, parseInt(this.profile.user_data.makeup_max_budget)]
          break;
      }
    },
    updateProductTypeFilter: function (e) {
      this.filters.type = e
      this.getProducts()
    },
    updateBenefitsFilter: function (e) {
      this.filters.benefit = e
      this.getProducts()
    },
    updateBrandsFilter: function (e) {
      this.filters.brand = e
      this.getProducts()
    },
    updateProductPreferenceFilter: function (e) {
      this.filters.preference = e
      this.getProducts()
    },
    updateLifeChangeConcernsFilter: function (e) {
      this.filters.lifeChange = e
      this.getProducts()
    },
    updateConcernsFilter: function (e) {
      this.filters.concerns = e
      this.getProducts()
    },
    updateFinishFilter: function (e) {
      this.filters.finish = e
      this.getProducts()
    },
    updateIngredientsFilter: function (e) {
      this.getProducts()
    },
    updateSortBy: function (e) {
      this.getProducts()
    },
    updatePriceFilter: function () {
      this.filters.priceMin = this.selectedRange[0]
      this.filters.priceMax = this.selectedRange[1]
      this.getProducts()
    },
    toggleShowAll: function () {
      this.showAll = !this.showAll

      // change the price range
      if(this.showAll) {
        this.selectedRange = [0, this.priceRange.max]
      } else {
        this.updateUserPriceRange()
      }
      this.getProducts()
    },
    resetFilters: function () {
      this.selectedRange = [0, this.priceRange.max]
      this.filters = {
        ingredient: [],
        type: [],
        benefit: [],
        brand: [],
        preference: [],
        lifeChange: [],
        concerns: [],
        finish: [],
        priceMin: this.selectedRange[0],
        priceMax: this.selectedRange[1]
      }
      this.showAll = true
    },
    getProducts: function () {
      this.products = []
      clearTimeout(this.filterTimer)
      let section = this.section
      // console.log(this.section)

      let args = {
        ingredients: this.filters.ingredient.map( i => parseInt(i.id) ),
        types: this.filters.type.map( i => parseInt(i.id) ),
        benefits: this.filters.benefit.map( i => parseInt(i.id) ),
        brands: this.filters.brand.map( i => parseInt(i.id )),
        preferences: this.filters.preference.map( i => parseInt(i.id ) ),
        priceMin: this.filters.priceMin,
        priceMax: this.filters.priceMax,
        curated: this.showAll ? 'off' : 'on',
        finish: this.filters.finish,
        chemoterapy: (this.filters.lifeChange.filter(function(e) { return e.value === 'chemoterapy'; }).length > 0) ? 'yes' : '',
        pregnancySafe: (this.filters.lifeChange.filter(function(e) { return e.value === 'pregnancySafe'; }).length > 0) ? 'yes' : '',
        sortby: this.optionsSortByValue.toLowerCase(),
        concerns: this.filters.concerns.map( i => parseInt(i.id ))
      }

      var self = this;
      this.filterTimer = setTimeout(function () {
        self.isLoadingProducts = true;

        if (self.isBrandBrowser) {
          // Set brand
          args.brands = self.brandIds
          APIClient.filterProductsByBrand({ params: { s: JSON.stringify(args) } })
            .then(resp => {
              self.setProductsData(resp);
              
            })
            .catch(err => {
              console.error(err)
            })
            .finally( () => {
              self.isLoadingProducts = false;
              self.$emit('onProductsLoaded')
            })
        } else {
          APIClient.filterProducts(section, { params: { s: JSON.stringify(args) } }, self.isLoggedIn)
            .then(resp => {
              self.setProductsData(resp);
            })
            .catch(err => {
              console.error(err)
            })
            .finally( () => {
              self.isLoadingProducts = false;
              self.$emit('onProductsLoaded')
            })
        }
        
      }, doneFilteringInterval)
    },
    setProductsData: function(resp) {
      this.products = resp.data.data
      this.meta = {
        nextPage: resp.data.next_page_url,
        previousPage: resp.data.prev_page_url,
        total: resp.data.total,
        totalPages: resp.data.last_page,
        currentPage: resp.data.current_page,
        from: resp.data.from,
        to: resp.data.to,
      }
      EventBus.$emit('on-products-filtered', this.meta.total);
      this.$emit('onFilteredProducts', this.meta.total);
    }
  }
}
</script>

<style lang="scss">
.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

</style>