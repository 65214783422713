<template>
    <div class="home">
      <div class="position-fixed back-to-top">
        <a v-scroll-to="'#app'" id="back_to_top"><i class="icon-caret-down"></i></a>
      </div>

      <!-- Menu - Mobile -->
      <home-menu-mobile></home-menu-mobile>

      <!-- Header -->
      <div id="fullpage" class="">
        <b-container
          fluid
          class="p-0 section"
          >
          <b-row no-gutters>
            <b-col sm="12">
              <dashboard-header />
            </b-col>
          </b-row>
        </b-container>


        <div class="row section fp-auto-height m-0 px-lg-0">
          <div class="col-12 px-0">
            <div class="home__sticky">
              <div class="home__dashboard-button d-none d-sm-block">
                    <router-link :to="{ name: 'overview'}" >
                        <div class="home__dashboard-button-content">
                            my dashboard
                            <svg svg-inline="" class="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="7.924" height="12" viewBox="0 0 7.924 12"><path data-name="Trazado 61489" d="M3.626 1.632q0 3.279.005 6.559V12h.692V8.191h-.005V1.614l3.494 2.914.112-.208S5.572 1.783 3.978 0L0 4.317l.133.213z"/></svg>
                        </div>
                    </router-link>
              </div>
            </div>

            <!-- Profile, Routines and Slider -->
            <!-- <routines /> -->

            <home-routines 
              :isPublic="false" 
              :title="`Curated for you, ${profile.name}`">
            </home-routines>

            <!-- Moodboard -->
            <moodboard />

            <!-- <b-container
              fluid
              class="p-0"
              >
              <b-row no-gutters>
                <b-col sm="12">
                   <location />
                </b-col>
              </b-row>
            </b-container> -->

            <b-container
              fluid
              >
              <b-row>
                <b-col sm="12" class="px-0">
                  <section-header
                    title="Skin"
                    background-url="skin"
                    id="skin_header"
                    :total="skinTotal"
                    >
                    your skin by your rules—with our help. 
                    these are the products that work best for your skin type and concerns, 
                    helping you feel bare-skin confident
                  </section-header>
                </b-col>
              </b-row>
            </b-container>

            <b-container>
              <b-row>
                <b-col sm="12" class="background-white">
                  <products-browser @onFilteredProducts="updateHeader('skin', ...arguments)" @onProductsLoaded="resizeScreen" section="skin" />
                </b-col>
              </b-row>
            </b-container>

            <!-- <b-container
              fluid
              >
              <b-row>
                <b-col sm="12" class="px-0">
                  <section-header
                    title="Makeup"
                    background-url="makeup"
                    id="makeup_header"
                    :position="'right'"
                    :total="makeupTotal"
                    >
                    makeup as it should be—curated for you. 
                    this is your feed featuring makeup products for your skin tone, type, concerns, 
                    budget and everything in between.
                  </section-header>
                </b-col>
              </b-row>
            </b-container>

            <b-container>
              <b-row>
                <b-col sm="12" class="background-white">
                  <products-browser @onFilteredProducts="updateHeader('makeup', ...arguments)" @onProductsLoaded="resizeScreen" section="makeup" />
                </b-col>
              </b-row>
            </b-container>

            <b-container
              fluid
              >
              <b-row>
                <b-col sm="12" class="px-0">
                  <section-header
                    title="Hair"
                    background-url="hair"
                    id="hair_header"
                    :total="hairTotal"
                    >
                    your hair texture—celebrated always. these 
                    products are specifically for your hair type and concerns. 
                    new style or concern? that’s cool, just let us know via your account 
                    settings and you’ll see an updated curation of products for your new needs.
                  </section-header>
                </b-col>
              </b-row>
            </b-container> -->

            <b-container>
              <b-row>
                <b-col sm="12">
                  <products-browser @onFilteredProducts="updateHeader('hair', ...arguments)" @onProductsLoaded="resizeScreen" section="hair" />
                </b-col>
              </b-row>
            </b-container>

            <product-modal 
            :source='product'
            :modalId="`home_product_modal`" 
            />
          </div>

          <app-footer class="" />
          
        </div>
        
      </div>
    </div>
</template>

<script>
import DashboardHeader from '@/components/dashboard/home/Header.vue'
import Profile from '@/components/dashboard/home/Profile.vue'
import Routine from '@/components/dashboard/home/Routine.vue'
// import Routines from '@/components/dashboard/home/Routines.vue'
import Location from '@/components/dashboard/home/Location.vue'
import Recommendations from '@/components/dashboard/home/Recommendations.vue'
import SliderArticles from '@/components/dashboard/home/SliderArticles.vue'
import IngredientChecker from '@/components/dashboard/home/IngredientChecker.vue'
import ProductsBrowser from '@/components/dashboard/home/ProductsBrowser.vue'
import Moodboard from '@/components/dashboard/home/Moodboard.vue'
import Featured from '@/components/dashboard/home/Featured.vue'
import SectionHeader from '@/ui/SectionHeader.vue'
import ProductModal from '@/ui/ProductModal.vue'
import AppFooter from '@/components/AppFooter.vue'
import { helperMixin } from '@/mixins/helperMixin.js'
import fullpage from 'fullpage.js'
import IScroll from 'fullpage.js/vendors/scrolloverflow'
import HomeMenuMobile from '@/components/common/HomeMenuMobile.vue';
import HomeRoutines from '@/components/common/HomeRoutines.vue';

export default {
  name: 'Home',
  components: {
    DashboardHeader,
    HomeMenuMobile,
    Profile,
    Routine,
    // Routines,
    Location,
    Recommendations,
    SliderArticles,
    IngredientChecker,
    ProductsBrowser,
    SectionHeader,
    Featured,
    ProductModal,
    Moodboard,
    AppFooter,
    HomeRoutines
  },
  mixins: [helperMixin],
  created () {
    if(this.$route.query && this.$route.query.product) {
      this.product = {
        id: this.$route.query.product
      } 
    }
  },
  data() {
    return {
      fullPage: null,
      skinTotal: null,
      makeupTotal: null,
      hairTotal: null,
    }
  },
  beforeDestroy() {
    // this.fullPage.destroy()
  },
  mounted () {
    if(this.product) {
      this.$bvModal.show(`home_product_modal`);
    }

    // this.fullPage = new fullpage('#fullpage', {
    //   //options here
    //   scrollHorizontally: false,
    //   scrollOverflow: true,
    //   scrollOverflowReset: true,
    //   normalScrollElements: '.side-menu, #notifications_modal',
    //   afterRender: function(){
    //     // test.reBuild()
    //   },
    //   onSlideLeave: function(section, origin, destination, direction){
    //     console.log(section)
    //   }
    // });
  

      // setTimeout(function(){
      //   window.dispatchEvent(new Event('resize'));
      //   console.log('resize')
      // },5000)

    
    if(this.isMobile()) return;

    // Back to top
    var backToTopBtn = document.getElementById("back_to_top");

    window.onscroll = function (e) {
      var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
      if (scrollTop >= 500 ) {
          backToTopBtn.classList.add('visible')
      } else {
          backToTopBtn.classList.remove('visible');
      }
    }
  },
  computed: {
    profile: function () {
      return this.$store.getters.profile
    },
  },
  data() {
    return {
      productId: null
    } 
  },
  methods: {
    offset(el) {
      var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    },
    resizeScreen() {
      window.dispatchEvent(new Event('resize'));
    },
    updateHeader(section, total) {
      switch(section) {
        case 'skin': 
          console.log(total)
          this.skinTotal = total;
          break;
        case 'makeup': 
          console.log(total)
          this.makeupTotal = total;
          break;
        case 'hair': 
          console.log(total)
          this.hairTotal = total;
          break;
      }
      this.$forceUpdate()
    }
  }
}

</script>