<template>
  <div class="product-preferences overview-section no-border">

    <h2 class="section-heading">
      Product Preferences
    </h2>

    <b-row>

      <b-col 
        cols="12"
        v-if="items"
        >
        <ul class="list">
          <li 
            v-for="(item, index) in items.slice(0, limit)"
            :class="{checked: item.checked, inactive: !item.description}"
            >
            <div class="collapse-button" v-b-toggle="`collapse-${index}`">
              <label>
                {{ item.name }}
              </label>

              <template v-if="item.description">
                <!-- <span class="caret"></span> -->
              </template>
              <div 
                class="check"
                >
                <i class="icon-check" v-if="item.checked"></i>
              </div>
            </div>
            <!-- <b-collapse 
              :id="`collapse-${index}`" 
              v-if="item.description"
              >
              <b-card>
                {{ item.description }}
              </b-card>
            </b-collapse> -->
            
          </li>
        </ul>

        <a 
          class="show-more float-right"
          @click="toggleItems" 
          >
          <span v-if="items.slice(0, limit).length < items.length">
            + show {{ items.length - 8 }} more
          </span>
          <span v-else>
            show less
          </span>
        </a>
      </b-col>
    </b-row>

  </div>
</template>

<script>

import APIClient from '@/api/requests'

export default {
  name: 'ProductPreferences',
  components: {

  },
  data() {
    return {
      isLoading: false,
      items: null,
      limit: 8
    }
  },
  mounted () {
    this.isLoading = true;
    APIClient.getProductPreferences()
      .then( res => {
        // console.log({...res.data})
        this.items = res.data
      })
      .catch( err => {
        console.error(err)
      })
      .finally( () => {
        this.isLoading = false;
      })
  },
  methods: {
    toggleItems: function() {
      if(this.items.slice(0, this.limit).length < this.items.length) {
        this.limit = this.items.length
      } else {
        this.limit = 8;
      }
    },
  }
}
</script>

