<template>
    <div class="routine-empty">
        <div class="routine-empty__close" v-if="showClose" @click="onClose">
            <i class="icon-close-no-background"></i>
        </div>
        <div class="routine-empty__copy" v-if="routine">
            Your {{ routine.label }} shelf is empty. Fill your shelf by 
            searching for a brand or product name.
        </div>

        <routine-form 
            :save-on-select="false" 
            :inline="false" 
            ref="routineForm"
            />
    </div>
</template>

<script>
    import RoutineForm from '@/ui/routine/RoutineForm.vue'

    export default {
        name: 'RoutineEmpty',
        components: {
            RoutineForm
        },
        data() {
            return {
                
            }
        },
        mounted() {
            // console.log("mounted")
            // this.$refs.routineForm.updateRoutineCategories(this.routine)
        },
        methods: {
            onClose: function() {
                this.$emit('onClose')
            }
        },
        props: {
            routine: null,
            showClose: false
        }
    }
</script>

