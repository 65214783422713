<template>
  <div class="rating" :class="customStyle">
  	<span class="bullets" v-if="score > 0">
	    <span v-for="n in parseInt(score)">
	    	<div class="bullet filled" />
	    </span><span v-for="n in (5 - parseInt(score))"><div class="bullet" /></span>
	  </span>
    <span class="bullets" v-if="score == 0">
      <span v-for="n in 5"><div class="bullet" /></span>
    </span>
	  <span 
      v-if="showAddRating && !score" 
      @click="onClickAdd" 
      class="add-rating"
      >
      +
    </span><span class="score" v-if="showScore == true">{{ Math.round(score * 10) / 10 }}</span>

    <span 
      v-if="showAddRating && score" 
      @click="onClickAdd" 
      class="add-rating"
      >
      <img
        :src="require('@/assets/images/icons/eye-closed-black.png')" 
        />
    </span>
  </div>
</template>

<script>
export default {
  name: 'Rating',
  props: {
  	score: {
      type: Number,
      default: 0,
    },
  	showScore: {
  		type: Boolean,
  		default: true
  	},
  	showAddRating: {
  		type: Boolean,
  		default: false
  	},
    customStyle: {
      type: String,
      default: 'light'
    }
  },
  methods: {
    onClickAdd: function() {
      this.$emit('onClickAdd')
    }
  },
  components: {}
}
</script>
