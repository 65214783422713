<template>
    <div class="skin-tabs">
        <div class="skin-tabs__inner">
            <div class="skin-tabs__left">
                <b-tabs pills card fill>
                  <b-tab title="oily" active>
                    <h2 class="skin-tabs__title">
                        FOR OILY SKIN <span>with hyperpigmentation</span>
                    </h2>

                    <table class="table">
                      <thead>
                        
                      </thead>
                      <tbody>
                        <tr>
                          <td>Cleanser</td>
                          <td class="regular">Epara</td>
                          <td>Cleansing Oil</td>
                        </tr>
                        <tr>
                          <td>Toner</td>
                          <td class="regular">F.Miller</td>
                          <td>Toning Mist</td>
                        </tr>
                        <tr>
                          <td>Serum</td>
                          <td class="regular">LESSE</td>
                          <td>Ritual Serum</td>
                        </tr>
                        <tr>
                          <td>Moisturiser</td>
                          <td class="regular">SHAYDE Beauty</td>
                          <td>Luxe Night Cream</td>
                        </tr>
                        <tr>
                          <td>Sunscreen</td>
                          <td class="regular">Supergoop!</td>
                          <td>Unseen Sunscreen</td>
                        </tr>
                      </tbody>
                    </table>

                    <a href="https://shop.beauteboard.com/products/hyperpigmentation-box" class="skin-tabs__cta" target="_blank">
                      <span>
                        Shop Now
                      </span>
                    </a>
                  </b-tab>
                  <b-tab title="sensitive">
                    Coming Soon
                  </b-tab>
                  <b-tab title="dry">
                    Coming Soon
                  </b-tab>
                  <b-tab title="normal">
                    Coming Soon
                  </b-tab>
                  <b-tab title="combination">
                    Coming Soon
                  </b-tab>
              </b-tabs>
            </div>
            <div class="skin-tabs__right">
                <figure class="skin-tabs__image">
                    <img src="@/assets/images/backgrounds/skin-tabs.jpg"/>
                </figure>
            </div>
        </div>
    </div>
</template>

<script>
    import { helperMixin } from '@/mixins/helperMixin.js'

    export default {
      name: 'SkinTabs',
      components: {
        
      },
      mixins: [helperMixin],
      data() {
        return {
            backgroundImage: `url(${require('@/assets/images/backgrounds/bb-open-site-hero.jpg')})`,
        }
      },
      mounted () {
        
      },
      methods: {
        
      }
    }
</script>
