<template>
    <div class="brands-page" id="brands_page">
      <loader-full-screen v-if="isLoading"/>
      <section class="header">
        <simple-header />
      </section>
      <b-container
        fluid
        class="p-0"
        >
        <b-row no-gutters>
          <b-col sm="12">
            <section-header
              :title="brandTitle"
              :background-url="brandImage"
              id="skin_header"
              :total="total"
              :curated-message="' curated for you'"
              >
              {{ brandDescription }}
            </section-header>
          </b-col>
        </b-row>
      </b-container>

      <b-container>
        <b-row>
          <b-col sm="12" class="background-white">
            <products-browser
              :is-brand-browser="true"
              :brand="brand"
              :section="section"
              :brandIds="brandIds"
              ref="productsBrowser"
              />
          </b-col>
        </b-row>
      </b-container>

    </div>
</template>

<script>
import APIClient from '@/api/requests'
import ProductsBrowser from '@/components/dashboard/home/ProductsBrowser.vue'
import SectionHeader from '@/ui/SectionHeader.vue'
import SimpleHeader from '@/ui/SimpleHeader'
import LoaderFullScreen from '@/ui/LoaderFullScreen.vue'
import { helperMixin } from '@/mixins/helperMixin.js'
import EventBus from '@/event-bus'

export default {
  name: 'Brands',
  components: {
    ProductsBrowser,
    SectionHeader,
    SimpleHeader,
    LoaderFullScreen
  },
  mixins: [helperMixin],
  data() {
    return {
      section: '',
      brand: '',
      brandIds: [],
      brandTitle: '',
      brandDescription: '',
      brandImage: null,
      isLoading: true,
    }
  },
  created() {
    this.section = this.$route.params.section
    this.brand = this.$route.params.brand
    EventBus.$on('on-products-filtered', this.updateTotal)
  },
  beforeDestroy() {
    EventBus.$off('on-products-filtered')
  },
  mounted () {
    APIClient.getBrand(this.brand)
      .then( resp => {
        console.log(resp.data[0])
        this.brandTitle = resp.data[0].brand_name
        this.brandDescription = resp.data[0].brand_description
        this.brandImage = resp.data[0].brand_banner
        this.brandIds = resp.data.map( e => { return e.brand_id })

        console.log(resp)

        this.$refs.productsBrowser.getFilters(this.brand);          

        setTimeout( () => {
          var VueScrollTo = require('vue-scrollto');
          VueScrollTo.scrollTo('#brands_page');
        }, 1500)
        
      })
      .catch( err => {
        console.error(err)
        this.$router.push('/404')
      })
      .finally( () => {
        let self = this
        setTimeout( () => {
          self.isLoading = false
        }, 500)
        
      })


  },
  computed: {
    profile: function () {
      return this.$store.getters.profile
    }
  },
  methods: {
    updateTotal: function(total) {
      this.total = total
      this.$forceUpdate()
    }
  }
}

</script>