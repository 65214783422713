import API from './API'
import OpenWeather from './OpenWeather'
import axios from 'axios'

export default {
  login (payload) {
    return API.post('auth/login', payload)
  },
  signup (payload) {
    return API.post('auth/signup', payload)
  },
  getProfile () {
    return API.get('v1/user')
  },
  getFullProfile () {
    return API.get('v1/user/body_data')
  },
  getUnreadNotifications (limit) {
    return API.get(`v1/user/notifications/${limit}/unread`)
  },
  getAllNotifications (limit) {
    return API.get(`v1/user/notifications/${limit}`)
  },
  getFilters (endpoint, searchInBrand = false, brandSlug = null, isPublic = false) {
    if(searchInBrand) {
      return API.get(`v1/brand/pages/products/filters/${brandSlug}`)
    } else {
      if(isPublic) {
        return API.get(`pub/home/${endpoint}/filters`)
      } else {
        return API.get(`v1/products/filters/${endpoint}/products`)  
      }
      
    }
  },
  getNewProducts() {
    return API.get(`v1/notifications/new_products`)
  },
  markNotificationAsRead (payload) {
    return API.post(`v1/user/notification_read`, payload)
  },
  filterProducts (endpoint, payload, isPrivate = false) {
    if(isPrivate) {
      return API.get(`v1/products/${endpoint}/search`, payload);
    } else {
      return API.get(`/pub/home/${endpoint}/search`, payload);
    }
  },
  filterProductsByBrand (payload) {
    return API.get(`v1/brand/pages/products/search`, payload)
  },
  searchProductByPage(page) {
    return API.get(page)
  },
  getProductDetails (id, isPublic = false) {
    if(isPublic) {
      return API.get(`pub/home/product/${id}`)
    } else {
      return API.get(`v1/product/${id}`)  
    }
    
  },
  getProductConcerns (bodyAreaId, productId, isPublic = false) {
    if(isPublic)
      return API.get(`product/concerns/${bodyAreaId}/${productId}`)
    else 
      return API.get(`v1/product/concerns/${bodyAreaId}/${productId}`)
  },
  getConcernsByBodyType(bodyType = 'skin') {
    return API.get(`v1/routine/${bodyType}/concerns`)
  },
  getProductBenefits (bodyAreaId, productId, isPublic = false) {
    if(isPublic)
      return API.get(`product/benefits/${productId}`)
    else 
      return API.get(`v1/product/benefits/${productId}`)
  },
  getRoutines () {
    return API.get(`v1/user/routines`)
  },
  getRoutineRecommendations (type, data) {
    return API.post(`v1/routine/recommendations/${type}`, data)
  },
  getPublicRoutine () {
    return API.get(`/pub/home/routine`)
  },
  getPrivateRoutine () {
    return API.get(`/v1/routine/beauty_essentials`)
  },
  searchProducts (term, isPublic = false) {
    if(isPublic) 
      return API.get(`pub/search`, term)
    else
      return API.get(`v1/products/search`, term)
  },
  searchBrands (term, isPublic = false) {
    if(isPublic) 
      return API.get(`pub/brand/search`, term)
    else
      return API.get(`v1/brand/search`, term)
  },
  getUserSearchData (term) {
    return API.get(`v1/user/search_data`, term)
  },
  getSearchData() {
    return API.get(`pub/search_data`)
  },
  removeRecentSearch (data) {
    return API.post(`v1/products/search/remove`, data)
  },
  getSkinFeelOptions () {
    return API.get(`v1/static/sfo`)
  },
  saveSkinFeelOptions (id) {
    return API.get(`v1/user/skin_journey/save/${id}`)
  },
  getSkinJouney(month, year) {
    return API.get(`v1/user/skin_journey/${month}/${year}`)
  },
  getSkinGoals(months) {
    return API.get(`v1/static/sgo/${months}`)
  },
  getUserSkinGoals(months) {
    return API.get(`v1/user/skin_goals/${months}`)
  },
  updateUserSkinGoals(skinGoalId) {
    return API.get(`/v1/user/skingoal_complete/${skinGoalId}`)
  },
  saveProductRoutine(productId, routines){
    return API.get(`v1/routine/add_product?productid=${productId}&routines=${routines}`)
  },
  getWishlists() {
    return API.get(`v1/user/wishlists`)
  },
  getWishlist(id) {
    return API.get(`v1/user/wishlist/${id}`)
  },
  saveToWishlist(data) {
    return API.post(`v1/user/wishlist/add_product`, data)
  },
  removeFromWishlist(data) {
    return API.post(`v1/user/wishlist/remove_product`, data)
  },
  saveToBreakup(data) {
    return API.post(`/v1/user/wishlist/breakups/add_product`, data)
  },
  getRoutineCategories(bodyAreaId) {
    return API.get(`v1/products/types/${bodyAreaId}`)
  },
  // getRoutineProducts(type, keyword) {
  //   return API.get(`v1/products/dayroutinetab/search?type=${type}&keyword=${keyword}`)
  // },
  getRoutineProducts(routine, keyword) {
    return API.get(`v1/products/${routine}/search?keyword=${keyword}`)
  },
  removeRoutineProduct(productId, type){
    return API.get(`v1/routine/remove_product?routinetype=${type}&productid=${productId}`)
  },
  changeProfileImage(data){
    return API.post(`v1/user/profile_photo/upload`, data)
  },
  getQuizSkinOptions() {
    return API.get(`v1/quiz/options/skin`)
  },
  getQuizMakeupOptions() {
    return API.get(`v1/quiz/options/makeup`)
  },
  getQuizHairOptions() {
    return API.get(`v1/quiz/options/hair`)
  },
  getQuizRecommendations(payload) {
    return API.post(`v1/quiz/recommendations`, payload)
  },
  registerUser(data) {
    return API.post(`auth/signup`, data)
  },
  recoverPassword(data) {
    return API.post(`auth/forgot`, data)
  },
  submitContactForm(data) {
    return API.post(`/v1/contact_us`, data)
  },
  updateProfile(data) {
    return API.post(`/v1/user/details/edit`, data)
  },
  updateAccount(data) {
    return API.post(`/v1/user/account/edit`, data)
  },
  getCities(keyword, isPublic = false) {
    if (isPublic)
      return API.get(`/pub/home/other/cities?k=${keyword}`)
    else 
      return API.get(`/v1/static/cities?k=${keyword}`)
  },
  editLocation(data) {
    return API.post(`/v1/user/account/edit_location`, city_id)
  },
  getPreferencesOptions(data) {
    return API.get(`/v1/static/body_data`)
  },
  updatePreferences(data) {
    return API.post(`/v1/user/update/body_data`, data)
  },
  getProductReviews(productId, isPublic = false) {
    if(isPublic)
      return API.get(`/product/reviews/${productId}`)
    else
      return API.get(`/v1/product/reviews/${productId}`)
  },
  getProductReviewByPage(page) {
    return API.get(page)
  },
  getProductReviewsHeader(productId, isPublic = false) {
    if(isPublic)
      return API.get(`/product/reviews/header/${productId}`)
    else
      return API.get(`/v1/product/reviews/header/${productId}`)
  },
  createReview(data) {
    return API.post(`/v1/product/reviews/create`, data)
  },
  getSingleReview(productId, reviewId) {
    return API.get(`/v1/product/${productId}/review/${reviewId}/details`)
  },
  sendHelfulReview(value, reviewId) {
    return API.get(`/v1/product/reviews/${reviewId}/${value}`)
  },
  deleteReview(data) {
    return API.post(`/v1/product/reviews/delete`, data)
  },
  editReview(data) {
    return API.post(`/v1/product/reviews/edit`, data)
  },
  getOpenWeatherData(city) {
    return OpenWeather.get(`/data/2.5/weather?APPID=bfa7286647fe2cae3ab27c92cdd4072f&q=${city}&units=imperial`)
  },
  getOpenWeatherUV(lat, lon) {
    return OpenWeather.get(`/data/2.5/uvi?APPID=bfa7286647fe2cae3ab27c92cdd4072f&lat=${lat}&lon=${lon}`)
  },
  getOpenWeatherPollution(lat, lon) {
    return OpenWeather.get(`/pollution/v1/co/${lat},${lon}/current.json?appid=bfa7286647fe2cae3ab27c92cdd4072f`)
  },
  getReviewsBenefits(product_id) {
    return API.get(`/v1/product/benefits_reviews/${product_id}`)
  },
  deleteWishlist(data) {
    return API.post(`/v1/user/wishlist/delete`, data)
  },
  createWishlist(data) {
    return API.post(`/v1/user/wishlist/create`, data)
  },
  editWishlist(data, wishlist_id){
    return API.post(`/v1/user/wishlist/edit/${wishlist_id}`, data)
  },
  getTwelveMonthsOverview() {
    return API.get(`/v1/user/dashboard/12monthreview`)
  },
  getIngredientsGlossary() {
    return API.get(`/v1/user/dashboard/skiningredientsglossary`)
  },
  getTopRated(limit) {
    return API.get(`/v1/user/dashboard/toprated/${limit}`)
  },
  getRepurchased(limit) {
    return API.get(`/v1/user/dashboard/repurchased/${limit}`)
  },
  getBreakUps(limit) {
    return API.get(`/v1/user/dashboard/breakups/${limit}`)
  },
  addNoteToSkinJourney(data){
    return API.post(`/v1/user/skin_journey_notes`, data)
  },
  getMyReviews(page = null) {
    return API.get(page || `/v1/user/dashboard/userreviews`)
  },
  getProductPreferences() {
    return API.get(`/v1/user/dashboard/preferencesglossary`)
  },
  getProductsToTrack() {
    return API.get(`/v1/user/dashboard/products_tracker/products/available`)
  },
  getProductsTracked() {
    return API.get(`/v1/user/dashboard/products_tracker/products/tracked`)
  },
  saveTracker(data) {
    let endpoint = (Boolean(data.id)) ? 'edit' : 'add';
    return API.post(`/v1/user/dashboard/products_tracker/products/${endpoint}`, data)
  },
  removeProductTracked(data) {
    return API.post(`/v1/user/dashboard/products_tracker/products/remove`, data)
  },
  getReferrals() {
    return API.get(`/v1/user/dashboard/referrals`)
  },
  addReferrals(data) {
    return API.post(`/v1/user/dashboard/referrals/create`, data)
  },
  getPrivacy() {
    return API.get(`/v1/legal/privacy`)
  },
  getTerms() {
    return API.get(`/v1/legal/tc`)
  },
  recordRepurchase(data) {
    return API.post(`/v1/product/repurchase`, data)
  },
  getBrand(brand) {
    return API.get(`/v1/brand/pages/${brand}`)
  },
  getPublicProduct(section, brand, slug) {
    return API.get(`/products/${section}/${brand}/${slug}`)
  },
  getMoodboard() {
    return API.get(`/v1/static/moodboard_images`)
  },
  getMoodboardPublic() {
    return API.get(`/pub/home/moodboard_images`)
  },
  getNewsletterEditions() {
    return API.get(`/v1/static/newsletter/editions`)
  },
  joinWaitingList(data) {
    return API.post(`/user/waitlist/add`, data)
  },
  moodboardPage(slug) {
    return API.get(`/moodboard/${slug}`)
  }
}
