<template>
  <div 
    class="quiz container" 
    id="top"
    >
    <a 
      @click="logout"
      class="btn-login position-absolute d-none d-md-block"
      >
      back to login
    </a>
    
    <b-row 
      v-if="!isMobile()" 
      class="h-100"
      >
      <b-col 
        md="7" 
        class="px-0"
        :class="{'d-none': quizSubmited}"
        >
        <div 
          class="accordion-content" 
          v-if="!quizSubmited"
          >

          <!-- Accordion -->
          <ul class="accordion-list h-100">
            <li :class="{ active: selectedTab == 'skin' }">
              <input
                id="rad1"
                type="radio"
                name="rad"
                value="skin"
                v-model="selectedTab"
                checked="checked"
                class="accordion-radio"
                @click="selectedTab = 'skin'"
                >
              <label
                class="accordion-tab"
                for="rad1"
                >
                <div>skin</div>
              </label>
              <simplebar class="accslide p-4">
                <div class="content">
                  <spinner v-if="isLoading"/>
                  <skin 
                    ref="skin" 
                    v-model="skinValues" 
                    :options="skinOptions" 
                    @onNext="nextTab" 
                    v-if="!isLoading" 
                    @onHover="changeImageAndDescription"
                    />
                </div>
              </simplebar>
            </li>

            <!-- <li :class="{ active: selectedTab == 'makeup' }">
              <input
                id="rad2"
                type="radio"
                name="rad"
                value="makeup"
                v-model="selectedTab"
                :disabled="selectedTab == 'skin'"
                class="accordion-radio"
                @click="selectedTab = 'makeup'"
                >
              <label
                class="accordion-tab"
                for="rad2"
                >
                <div>makeup</div>
              </label>
              <simplebar class="accslide p-4">
                <div class="content">
                  <makeup 
                    ref="makeup" 
                    v-model="makeupValues" 
                    :options="makeupOptions" 
                    @onNext="nextTab" 
                    @onBack="previousTab" 
                    @onHover="changeImage"
                    />                  
                </div>
              </simplebar>
            </li> -->
            <li>
            </li>

            <!-- <li :class="{ active: selectedTab == 'hair' }">
              <input
                id="rad3"
                type="radio"
                name="rad"
                value="hair"
                :disabled="selectedTab == 'skin' || selectedTab == 'makeup'"
                class="accordion-radio"
                v-model="selectedTab"
                @click="selectedTab = 'hair'"
                >
              <label
                class="accordion-tab"
                for="rad3"
                >
                <div>hair</div>
              </label>
              <simplebar class="accslide p-4">
                <div class="content">
                  <transition name="fade">
                    <hair 
                      ref="hair" 
                      v-model="hairValues" 
                      v-if="!isLoading" 
                      :options="hairOptions" 
                      @onNext="nextTab" 
                      @onBack="previousTab" 
                      @onRestart="onRestart" 
                      @onHover="changeImage"
                      />
                  </transition>
                  <transition name="fade">
                    <spinner v-if="isLoading" />
                  </transition>
                </div>
              </simplebar>
            </li> -->
          </ul>
          <!-- / Accordion -->
        </div>

        <!-- Reommendations -->
        <!-- <transition name="fade">
          <div 
            class="recommendations container" 
            v-if="quizSubmited"
            >
            <b-row class="h-100">
              <b-col 
                md="12" 
                class="text-center h-100" 
                v-if="!isLoading"
              >
                <h2 class="mb-4">
                  MY RECOMMENDATIONS
                </h2>

                <simplebar class="relative grid-wrapper">
                    <div class="grid">
                      <a 
                        class="product position-relative" 
                        v-for="product in products" 
                        >

                        <vue-load-image>
                          <div 
                            slot="image" 
                            class="image"
                            :style="`background-image: url(${product.image})`" 
                            :data-src='product.image' 
                            />
                            <div 
                              class="image-error" 
                              slot="error"
                              >
                              <span>

                              </span>
                            </div>
                            <div class="overlay">
                              <div class="brand">
                                {{ product.brand }}
                              </div>
                              <div class="name">
                                {{ product.name }}
                              </div>
                            </div>
                        </vue-load-image>

                      </a>
                    </div>
                </simplebar>
              </b-col>
              <spinner v-if="isLoading" />
            </b-row>
          </div>
        </transition> -->
        <!-- / Reommendations -->
      </b-col>
      <!-- Preview -->
      <b-col 
        :md="!quizSubmited ? '5' : '12'" 
        class="preview px-0 position-relative" 
        :class="{ nobackground: quizSubmited }"
        >
        <a 
          @click="logout"
          class="btn-login position-absolute d-block d-md-none"
          >
          back to login
        </a>
        <div class="inner w-100 h-100 pt-4 pt-md-0" :class="{ 'd-flex': quizSubmited }">
          <transition name="fade">                    
            <quiz-preview 
              :section="selectedTab" 
              :previewData="previewData" 
              :descriptionOnHover="descriptionOnHover"  
              :titleOnHover="titleOnHover"
              :imageOnHover="imageOnHover" 
              v-if="!quizSubmited" />
          </transition>
          <transition name="fade">
            <div 
              class="signup-form" 
              v-if="quizSubmited"
              >
              <h2 class="mb-2 text-center">
                signup
              </h2>

              <div class="signup-copy">
                enter your name and email below to get free access to your curated world of products
              </div>
              <form id="validate-form">
                <b-row class="mb-4">
                  <b-col md="6">
                    <input class="form-control px-3 py-2" :class="{ filled: formValues.firstName.length }" type="text" required placeholder="first name" v-model="formValues.firstName" name="firstName" />
                    <span class="alert-message" v-if="validationErrors.firstName" v-text="validationErrors.firstName"></span>
                  </b-col>
                  <b-col md="6">
                    <input class="form-control px-3 py-2" :class="{ filled: formValues.lastName.length }" type="text" required placeholder="last name" v-model="formValues.lastName" name="lastName" @blur="generateUsername"/>
                    <span class="alert-message" v-if="validationErrors.lastName" v-text="validationErrors.lastName"></span>
                  </b-col>
                </b-row>

                <b-row class="mb-4">
                  <b-col md="12">
                    <input class="form-control px-3 py-2" :class="{ filled: formValues.email.length }" type="email" required placeholder="email address" v-model="formValues.email" name="email" />
                    <span class="alert-message" v-if="validationErrors.email" v-text="validationErrors.email"></span>
                  </b-col>
                </b-row>

                <b-row class="mb-4">
                  <b-col md="12">
                    <input class="form-control px-3 py-2" :class="{ filled: formValues.username.length }" type="text" required placeholder="username" v-model="formValues.username" name="username" />
                    <span class="alert-message" v-if="validationErrors.username" v-text="validationErrors.username"></span>
                  </b-col>
                </b-row>

                <b-row class="mb-4">
                  <b-col md="12">
                    <input class="form-control px-3 py-2" :class="{ filled: formValues.password.length }" required :type="passwordType" placeholder="enter password" v-model="formValues.password" pattern="^(?=.*[a-z])(?=.*\d).{8,}$" title="Password should have at least 8 characters, and include numbers and letters" name="password" />
                    <img class="position-absolute eye" @click="togglePasswordType"  :src="passwordIcon" />
                  </b-col>
                </b-row>

                <b-row class="mb-4">
                  <b-col md="12">
                    <input class="form-control px-3 py-2" :class="{ filled: formValues.confirmPassword.length }" :type="passwordType" placeholder="confirm password" v-model="formValues.confirmPassword" name="confirmPassword" />
                    <img class="position-absolute eye" @click="togglePasswordType" :src="passwordIcon" />
                    <span class="alert-message" v-if="validationErrors.password" v-text="validationErrors.password"></span>
                  </b-col>
                </b-row>

                <b-row class="mb-4">
                  <b-col md="12" class="terms">
                    <span>i agree to beauteboard’s 
                      <a 
                        class="relative" 
                        target="_blank"
                        href="/terms"
                        >
                        terms of use
                      </a> and 
                      <a 
                        class="relative" 
                        target="_blank"
                        href="/privacy"
                        >
                        privacy policy
                      </a>
                    </span>
                    <input class="uiswitch float-right" v-model="formValues.acceptTerms" type="checkbox"/>
                  </b-col>
                  <b-col md="12">
                     <span class="alert-message" v-if="message" v-text="message"></span>
                   </b-col>
                </b-row>

                <b-row align-h="between">
                  <b-col md="auto">
                    <button class="btn btn-white px-4" @click.stop.prevent="register">
                      <template v-if="isLoading">
                        Loading...
                      </template>
                      <template v-else>
                        CREATE PROFILE
                      </template>
                    </button>
                  </b-col>
                </b-row>

              </form>
            </div>
          </transition>
        </div>
      </b-col>
      <!-- / Profile Preview -->

    </b-row>

    <!-- Mobile -->
    <b-row v-else>
      <b-col 
        cols="12" 
        class="p-0" 
        v-if="!quizSubmited"
      >
        <!-- Skin -->
        <b-tabs 
          pills 
          card 
          fill 
          v-model="tabIndex"
        >
          <b-tab 
            no-body 
            title="skin"
          >
            <b-col 
              cols="12" 
              class="preview px-0 relative" 
              :class="{ nobackground: quizSubmited }"
              >
              <a 
                @click="logout"
                class="btn-login position-absolute d-block d-md-none"
                >
                back to login
              </a>
              <div class="inner w-100 pt-4 pt-md-0">
                <transition name="fade">      
                  <quiz-preview 
                    :section="selectedTab" 
                    :previewData="previewData" 
                    :imageOnHover="imageOnHover" 
                    v-if="!quizSubmited" 
                  />
                </transition>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="content">
                <spinner v-if="isLoading" />
                <skin 
                  ref="skin" 
                  v-model="skinValues" 
                  :options="skinOptions" 
                  @onNext="nextTab" 
                  v-if="!isLoading" 
                  @onHover="changeImage" 
                />
              </div>
            </b-col>
          </b-tab>

          <b-tab 
            no-body 
            title="makeup"
          >
            <b-col 
              cols="12" 
              class="preview px-0 relative" 
              :class="{ nobackground: quizSubmited }"
              >
              <a 
                @click="logout"
                class="btn-login position-absolute d-block d-md-none"
                >
                back to login
              </a>
              <div class="inner w-100 pt-4 pt-md-0">
                <transition name="fade">                  
                  <quiz-preview 
                    :section="selectedTab" 
                    :previewData="previewData" 
                    :imageOnHover="imageOnHover" 
                    v-if="!quizSubmited" 
                  />
                </transition>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="content">
                <spinner v-if="isLoading" />
                <makeup 
                  ref="makeup" 
                  v-model="makeupValues" 
                  :options="makeupOptions" 
                  @onNext="nextTab" 
                  @onBack="previousTab"
                  @onHover="changeImage"
                />                  
              </div>
            </b-col>
          </b-tab>

          <b-tab no-body title="hair">
            <b-col 
              cols="12" 
              class="preview px-0 relative" 
              :class="{ nobackground: quizSubmited }"
              v-if="!isLoading"
              >
              <a 
                @click="logout"
                class="btn-login position-absolute d-block d-md-none"
                >
                back to login
              </a>
              <div class="inner w-100 pt-4 pt-md-0">
                <transition name="fade">                    
                  <quiz-preview 
                    :section="selectedTab" 
                    :previewData="previewData" 
                    :imageOnHover="imageOnHover" 
                    v-if="!quizSubmited" 
                  />
                </transition>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="content">
                <hair 
                  ref="hair" 
                  v-model="hairValues" 
                  v-if="!isLoading" 
                  :options="hairOptions" 
                  @onNext="nextTab" 
                  @onBack="previousTab" 
                  @onRestart="onRestart" 
                  @onHover="changeImage"
                />
              </div>
            </b-col>
            <!-- <b-row class="vh-100 position-absolute w-100 m-0" v-if="isLoading">
              <b-col class="p-0">
                <spinner :message="'Please wait, we’re getting recommendations for you'" />
              </b-col>
            </b-row> -->
          </b-tab>
        </b-tabs>
      </b-col>
      <b-col cols="12" v-if="quizSubmited" class="px-0">
        <!-- <transition name="fade">
          <div class="recommendations container p-5" v-if="quizSubmited">
            <b-row class="h-100">
              <b-col md="12" class="text-center h-100" v-if="!isLoading">
                <h2 class="mb-4">MY RECOMMENDATIONS</h2>

                <simplebar class="relative grid-wrapper">
                    <div class="grid" v-if="products">
                      
                      <a class="product relative" v-for="product in products" :href="product.link" target="_blank">
                        <progressive-background class="image" :src="product.thumb600" size="contain" >
                          <div slot="content" slot-scope="{ visible }">
                            <div class="overlay">
                              <div class="brand">
                                {{ product.brand }}
                              </div>
                              <div class="name">
                                {{ product.name }}
                              </div>
                            </div>
                            <div v-show="visible">
                              <b-spinner type="grow" label="Spinning"></b-spinner>
                            </div>
                          </div>
                        </progressive-background>
                      </a>

                    </div>
                </simplebar>
              </b-col>
              <spinner v-if="isLoading" />
            </b-row>
          </div>
        </transition> -->

        <!-- Form -->
        <transition name="fade">
          <div class="mobile-signup py-5">
              <div class="signup-form  px-3" v-if="quizSubmited">
                <h2 class="mb-3 text-center">signup</h2>
                <div class="signup-copy">
                  enter your name and email below to get free access to your curated world of products
                </div>
                <form id="validate-form">

                  <b-row class="">
                    <b-col md="6" class="mb-4">
                      <input class="form-control px-3 py-2" :class="{ filled: formValues.firstName.length }" type="text" required placeholder="first name" v-model="formValues.firstName" name="firstName"/>
                      <span class="alert-message" v-if="validationErrors.firstName" v-text="validationErrors.firstName"></span>
                    </b-col>
                    <b-col md="6" class="mb-4">
                      <input class="form-control px-3 py-2" :class="{ filled: formValues.lastName.length }" type="text" required placeholder="last name" v-model="formValues.lastName" name="lastName"/>
                      <span class="alert-message" v-if="validationErrors.lastName" v-text="validationErrors.lastName" @blur="generateUsername"></span>
                    </b-col>
                  </b-row>

                  <b-row class="mb-4">
                    <b-col md="12">
                      <input class="form-control px-3 py-2" :class="{ filled: formValues.email.length }" type="email" required placeholder="email address" v-model="formValues.email" name="email" />
                      <span class="alert-message" v-if="validationErrors.email" v-text="validationErrors.email"></span>
                    </b-col>
                  </b-row>

                  <b-row class="mb-4">
                    <b-col md="12">
                      <input class="form-control px-3 py-2" :class="{ filled: formValues.username.length }" type="text" required placeholder="username" v-model="formValues.username" name="username" />
                      <span class="alert-message" v-if="validationErrors.username" v-text="validationErrors.username"></span>
                    </b-col>
                  </b-row>

                  <b-row class="mb-4">
                    <b-col md="12">
                      <input class="form-control px-3 py-2" :class="{ filled: formValues.password.length }" :type="passwordType" required placeholder="enter password" v-model="formValues.password" pattern="^(?=.*[a-z])(?=.*\d).{8,}$" title="Password should have at least 8 characters, and include numbers and letters" name="password" />
                      <img class="position-absolute eye" @click="togglePasswordType" :src="passwordIcon" />
                    </b-col>
                  </b-row>

                  <b-row class="mb-4">
                    <b-col md="12">
                      <input class="form-control px-3 py-2" :class="{ filled: formValues.confirmPassword.length }" :type="passwordType" placeholder="confirm password" v-model="formValues.confirmPassword" name="confirmPassword" />
                      <img class="position-absolute eye" @click="togglePasswordType" :src="passwordIcon" />
                      <span class="alert-message" v-if="validationErrors.password" v-text="validationErrors.password"></span>
                    </b-col>
                  </b-row>

                  <b-row class="mb-4">
                    <b-col md="12" class="terms">
                      <span>i agree to beauteboard’s <a class="relative" href="" _blank="">terms of use</a> and <a class="relative" href="" _blank="">privacy policy</a></span>
                      <input class="uiswitch float-right" v-model="formValues.acceptTerms" type="checkbox"/>
                    </b-col>
                    <b-col md="12">
                       <span class="alert-message" v-if="message" v-text="message"></span>
                     </b-col>
                  </b-row>

                  <b-row align-h="between">
                    <b-col md="auto">
                      <button class="btn btn-white px-4" @click.stop.prevent="register">
                        <template v-if="isLoading">
                          Loading...
                        </template>
                        <template v-else>
                          CREATE PROFILE
                        </template>
                      </button>
                    </b-col>
                    <!-- <b-col md="auto">
                      <p class="mt-2">or <router-link to="/" class="">login</router-link></p>
                    </b-col> -->
                  </b-row>

                </form>
              </div>
            </div>
          </transition>
      </b-col>
    </b-row>
    <info-modal :info="infoModal" />
  </div>
</template>

<script>
import Skin from '@/components/simple/quiz/Skin.vue'
import Makeup from '@/components/simple/quiz/Makeup.vue'
import Hair from '@/components/simple/quiz/Hair.vue'
import QuizPreview from '@/components/simple/quiz/QuizPreview.vue'
import APIClient from '@/api/requests';
import simplebar from 'simplebar-vue';
import Spinner from '@/ui/Spinner.vue'
import InfoModal from '@/ui/InfoModal.vue'
import 'simplebar/dist/simplebar.min.css';
import { helperMixin } from '@/mixins/helperMixin.js'

let helper = require('@/assets/scripts/main.js');

export default {
  components: {
    Skin,
    QuizPreview,
    Makeup,
    Hair,
    simplebar,
    Spinner,
    InfoModal
  },
  mixins: [helperMixin],
  data () {
    return {
      selectedTab: 'skin',
      skinValues: null,
      makeupValues: null,
      hairValues: null,
      skinOptions: null,
      makeupOptions: null,
      hairOptions: null,
      quizSubmited: false,
      isLoading: false,
      imageOnHover: '',
      descriptionOnHover: '',
      titleOnHover: '',
      tabIndex: 0,
      infoModal: {
        title: '',
        message: ''
      },
      formValues: {
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        password: '',
        confirmPassword: '',
        acceptTerms: false
      },
      message: null,
      products: [],
      validationErrors: {
        firstName: null,
        lastName: null,
        username: null,
        email: null,
        password: null
      },
      passwordType: 'password',
      passwordIcon: require('@/assets/images/icons/eye-closed.png'),
      profile_preferences: null,
    }
  },
  mounted () {
    this.isLoading = true
    this.changeImage('skin-normal.jpg')

    // For Beta
    // this.formValues.email = "oscarjiv91@gmail.com"
    this.formValues.email = this.user.email || ''

    APIClient.getQuizSkinOptions()
      .then(res => {
        this.skinOptions = res.data
      })
      .catch(err => {
        console.error(err)
      })
      .finally( () => {
        this.isLoading = false
      })

    APIClient.getQuizMakeupOptions()
      .then(res => {
        this.makeupOptions = res.data
      })
      .catch(err => {
        console.error(err)
      })
      .finally( () => {
        
      })

    APIClient.getQuizHairOptions()
      .then(res => {
        // console.log({...res.data})
        this.hairOptions = res.data
      })
      .catch(err => {
        console.error(err)
      })
      .finally( () => {
        
      })
    this.selectedTab = 'skin'
  },
  methods: {    
    togglePasswordType: function () {
      if(this.passwordType == 'password') {
        this.passwordType = 'text';
        this.passwordIcon = require('@/assets/images/icons/eye-open.png');
      } else {
        this.passwordType = 'password';
        this.passwordIcon = require('@/assets/images/icons/eye-closed.png');
      }
    },
    nextTab: function () {
      // console.log(this.selectedTab)

      var VueScrollTo = require('vue-scrollto');
      VueScrollTo.scrollTo('#top');

      this.getQuizRecommendations();
      // if(this.selectedTab == 'skin') { this.selectedTab = "makeup"; this.tabIndex = 1 ;return; }
      // if(this.selectedTab == 'makeup') { this.selectedTab = "hair"; this.tabIndex = 2; return; }
      // if(this.selectedTab == 'hair') { this.getQuizRecommendations() }
    },
    previousTab: function () {
      if(this.selectedTab == 'makeup') { this.selectedTab = "skin"; this.tabIndex = 0; return; }
      if(this.selectedTab == 'hair') { this.selectedTab = "makeup"; this.tabIndex = 1; return; }
    },
    getQuizRecommendations() {
      this.isLoading = false
      
      let args = {
        "skin": this.skinValues,
        // "makeup": this.makeupValues.skip ? null : this.makeupValues,
        // "hair": this.hairValues.skip ? null : this.hairValues
      }

      this.quizSubmited = true;
      return

      // Don't get recommendations yet
      
      var self = this
      setTimeout(function() {
         APIClient.getQuizRecommendations(args)
            .then( res => {
              
              self.products = res.data
              if(self.products.length > 9){
                self.products = self.products.slice(0, 9);
                // console.log({...self.products[0]})
                self.products = helper.shuffleArray(self.products)
              }
              self.quizSubmited = true
            })
            .catch( error => {
              console.error(error)
            })
            .finally( () => {
              self.isLoading = false
            })
      }, 1000)
     
    },
    register: function () {

      if(this.isLoading) {
        return;
      }

      if (this.validateForm()) {
        // console.log('formValidated');
        if(!this.formValues.acceptTerms) {
          this.message = "Please accept our terms of use"
          return;
        }

        let args = {
          "skin": this.skinValues,
          "makeup": this.makeupValues.skip ? null : this.makeupValues,
          "hair": this.hairValues.skip ? null : this.hairValues
        }
        let data = {
            "name": this.formValues.firstName,
            "last_name": this.formValues.lastName,
            "username": this.formValues.username,
            "email": this.formValues.email,
            "password": this.formValues.password,
            "password_confirmation": this.formValues.confirmPassword,
            "preferences": args
        }

        // console.log(JSON.stringify(data))

        this.isLoading = true
        this.$store
          .dispatch('register', data)
          .then(() => {

            APIClient.getFullProfile()
              .then(resp => {
                // console.log({...resp.data})
                this.profile_preferences = resp.data
                this.$store.dispatch('getProfile').then(() => { 
                  if(this.profile_preferences.skintypes){
                    if(this.profile_preferences.skintypes.length) {
                      // redirect to Home
                      this.$router.push('/me')                
                    }else{
                      // redirect to Quiz
                      this.$router.push('/quiz')
                    }
                  } else {
                    this.$router.push('/quiz')
                  }
                  this.isLoading = false;
                })
                
              })
              .catch(err => {
                console.error(err)
                this.isLoading = false;
              })

            // this.$router.push('/me')
            // console.log(localStorage.getItem('token'))
            // this.$store.dispatch('getProfile').then(() => {})
          })
          .catch(err => {
            if(err.response.status == 401) {
              this.message = "incorrect credentials "
            }else{
              var keys = Object.keys(err.response.data.message)
              console.log(keys);
              this.message = err.response.data.message[keys[0]][0]
            }
            this.isLoading = false;
          })
          .finally( () => {
            
          })
      }
      
    },
    validateForm (formId = 'validate-form', errorObjectName = 'validationErrors') {
        var nodes = document.querySelectorAll(`#${formId} :invalid`);
        var vm = this; //current vue instance;        
        Object.keys(this[errorObjectName]).forEach(key => {
            this[errorObjectName][key] = null
        });        
        // console.log({...nodes})
        if (nodes.length > 0) {
            nodes.forEach(node => {
              // console.log(node.name)
                if (node.title) {
                    this[errorObjectName][node.name] = node.title;
                }
                else {
                    this[errorObjectName][node.name] = node.validationMessage;
                }
                node.addEventListener('change', function (e) {
                    vm.validateForm(formId, errorObjectName);
                });
            });
            return false;
        }else {
          let passwordField = document.getElementsByName("password")[0];
          let passwordConfirmField = document.getElementsByName("confirmPassword")[0];

          if(passwordField.value !== passwordConfirmField.value){          
            this.validationErrors["password"] = "Passwords should match"
            return false
          }else{
            this.validationErrors["password"] = null
            return true;
          }
        }
    },
    changeImage(image) {
      console.log(image)
      this.imageOnHover = require(`@/assets/images/quiz/${image}`)
    },
    changeImageAndDescription(image, description, title) {

      this.imageOnHover = require(`@/assets/images/quiz/${image}`)
      this.descriptionOnHover = description
      this.titleOnHover = title
    },
    onRestart() {
      this.$refs.skin.clear()
      this.$refs.makeup.clear()
      this.$refs.hair.clear()

      this.selectedTab = "skin";
      this.$forceUpdate()
    },
    openPrivacyModal() {
      this.$bvModal.show('info_modal')
      APIClient.getPrivacy()
        .then( res => {
          this.infoModal = {
            title: res.data.name,
            message: res.data.content
          }
        })
        .catch( error => {
          console.error(error)
        })
        .finally( () => {

        })
    },
    logout: function () {
      this.$store.dispatch('logout').then(() => {
        window.location = "/"
      })
    },
    openTermsModal() {
      this.$bvModal.show('info_modal')
      APIClient.getTerms()
        .then( res => {
          this.infoModal = {
            title: res.data.name,
            message: res.data.content
          }
        })
        .catch( error => {
          console.error(error)
        })
        .finally( () => {
          
        })
    },
    generateUsername() {
      const name = this.formValues.firstName
      name.replace(/\s/g, '_')

      const last = this.formValues.lastName
      last.replace(/\s/g, '_')
      this.formValues.username = name + '_' + last
      this.formValues.username = this.formValues.username.toLowerCase()
    }
  },
  computed: {
    user() {
      return this.$store.getters.profile
    },
    previewData() {

      // Preload images
      var preloadImage1 = new Image();
      preloadImage1.src = require(`@/assets/images/quiz/skin-normal.jpg`);
      var preloadImage2 = new Image();
      preloadImage2.src = require(`@/assets/images/quiz/skin-combination.jpg`);
      var preloadImage3 = new Image();
      preloadImage3.src = require(`@/assets/images/quiz/skin-dry.jpg`);
      var preloadImage4 = new Image();
      preloadImage4.src = require(`@/assets/images/quiz/skin-oily.jpg`);
      var preloadImage5 = new Image();
      preloadImage5.src = require(`@/assets/images/quiz/skin-sensitive.jpg`);
      // -- Preload images

      console.log(this.selectedTab)
      if(this.selectedTab == 'skin') {
        if(!this.skinValues) {        
          return { 
            "image": require(`@/assets/images/quiz/skin-normal.jpg`),
            "imageSelected": false,
            "row_1": {
              "label": "my skin type",
              "value": ""
            },
            "row_2": {
              "label": "my skin concerns",
              "value": ""
            },
            "row_3": {
              "label": "budget per product",
              "value": ""
            }
          }
        };

        return { 
          "image": this.skinValues.image ? require(`@/assets/images/quiz/${this.skinValues.image}`) : null,
          "imageSelected": this.skinValues.image ? true : false,
          "row_1": {
            "label": "my skin type",
            "value": this.skinValues.skinType ? this.skinValues.skinType.name : null
          },
          "row_2": {
            "label": "my skin concerns",
            "value": this.skinValues.skinProduct ? this.skinValues.skinProduct.map(function(item){ return item.name }).join(', ') : null
          },
          "row_3": {
            "label": "budget per product",
            "value": this.skinValues.budget ? `$${this.skinValues.budget}` : null
          }
        }
      } 

      if(this.selectedTab == 'makeup')  {
        if(!this.makeupValues) {
          return { 
            "image": require(`@/assets/images/quiz/makeup-tan.jpg`),
            "imageSelected": false,
            "row_1": {
              "label": "my skin tone",
              "value": ""
            },
            "row_2": {
              "label": "my skin undertone",
              "value": ""
            },
            "row_3": {
              "label": "my favorite finish",
              "value": ""
            }
          }
        };
        return { 
          "image": this.makeupValues.image ? require(`@/assets/images/quiz/${this.makeupValues.image}`) : null,
          "imageSelected": this.makeupValues.image ? true : false,
          "row_1": {
            "label": "my skin tone",
            "value": this.makeupValues.skinTone ? this.makeupValues.skinTone.name : null
          },
          "row_2": {
            "label": "my skin undertone",
            "value": this.makeupValues.undertone ? this.makeupValues.undertone.name : null
          },
          "row_3": {
            "label": "my favorite finish",
            "value": this.makeupValues.favoriteFinishes ? this.makeupValues.favoriteFinishes.map(function(item){ return item.name }).join(', ') : null
          }
        }

        console.log(this.makeupValues.image)
      }

      if(this.selectedTab == 'hair') { 
        if(!this.hairValues) {
          return { 
            "image": require(`@/assets/images/quiz/hair-curly.jpg`),
            "imageSelected": false,
            "row_1": {
              "label": "my hair type",
              "value": ""
            },
            "row_2": {
              "label": "my hair concerns",
              "value": ""
            },
            "row_3": {
              "label": "budget per product",
              "value": ""
            }
          }
        };
        return { 
          "image": this.hairValues.image ? require(`@/assets/images/quiz/${this.hairValues.image}`) : null,
          "imageSelected": this.hairValues.image ? true : false,
          "row_1": {
            "label": "my hair type",
            "value": this.hairValues.hairType ? this.hairValues.hairType.name : null
          },
          "row_2": {
            "label": "my hair concerns",
            "value": this.hairValues.hairConcerns ? this.hairValues.hairConcerns.map(function(item){ return item.name }).join(', ') : null
          },
          "row_3": {
            "label": "budget per product",
            "value": this.hairValues.budget ? `$${this.hairValues.budget}` : null
          }
        }
      }

    }
  }
}
</script>
