<template>
    <b-modal 
        :id="customId" 
        title="add to a collection" 
        body-class="p-0" 
        modal-class="add-to-wishlist-modal" 
        @hidden="closeModal"
        @shown="getWishlists" 
        hide-footer="true"
        ref="addToWishlist"
        centered
        >
        <b-container>
            <transition name="fade-absolute">
                <b-row v-if="!addedSuccess">
                    <b-col 
                        cols="12" 
                        class="px-0"
                        >
                        <div 
                            class="swiper-container swiper-container-3d swiper-container-coverflow add-to-wishlist-modal__slider transparent-transition"
                            :class="{ 'add-to-wishlist-modal__slider--loading': isLoading }"
                            >
                            <div class="swiper-wrapper">

                                <!-- Create Wishlist Slide -->
                                <div 
                                    class="swiper-slide"
                                    @click="openCreateModal"
                                    >
                                    <div class="add-to-wishlist-modal__new">
                                        <div class="add-to-wishlist-modal__new-content">
                                            <svg svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g data-name="Grupo 695"><path data-name="Trazado 61338" d="M9.333 0v9.338H0v1.333h9.333v9.333h1.333v-9.333H20V9.338h-9.333V.005z" fill="#484848"/></g></svg>
                                        </div>
                                    </div>
                                </div>

                                <!-- Existing Wishlists -->
                                <div 
                                    class="swiper-slide"
                                    v-for="(item, index) in slides"
                                    @click="addToWishlist(item)"
                                    >
                                    <wishlist-item 
                                        :wishlist="item"
                                        :link-to-wishlist="false"
                                        >
                                    </wishlist-item>
                                </div>
                            </div>
                            <div class="swiper-pagination">
                            </div>
                        </div>
                        <spinner v-if="isLoading"/>
                    </b-col>
                </b-row>
            </transition>

            <transition name="fade-absolute">
                <b-row v-if="addedSuccess">
                    <b-col
                        class="add-to-wishlist-modal__success-left"
                        cols="6"
                        md="6"
                        >
                        <div 
                            class="add-to-wishlist-modal__success-image" 
                            :style="{ backgroundImage: `url(${product.image})` }" 
                            >
                        </div>
                    </b-col>
                    <b-col
                        class="add-to-wishlist-modal__success-right" 
                        cols="6"
                        md="6"
                        >
                        <div class="d-flex align-items-center justify-content-center flex-column p-3 btn add-to-wishlist-modal__message">
                            <em>{{ product.name }}</em> has been saved to the <span>{{ selectedWishlist.name }}</span> collection. 
                            <router-link 
                                class="btn add-to-wishlist-modal__see-wishlist"
                                :to="{ name: 'single-wishlist', params: { id: selectedWishlist.id }}"
                                >
                                See it now
                            </router-link>
                        </div>
                    </b-col>
                </b-row>
            </transition>
        </b-container>

        <wishlist-modal 
            @onWishlistCreated="getWishlists" 
            />

    </b-modal>
</template>

<script>
    import APIClient from '@/api/requests';
    import Spinner from '@/ui/Spinner.vue';
    import WishlistItem from '@/ui/WishlistItem.vue'
    // import Swiper from '@/assets/scripts/vendor/swiper.esm.bundle';
    import Swiper from 'swiper';
    import WishlistModal from '@/ui/WishlistModal.vue'

    export default {
        name: 'AddToWishlistModal',
        components: {
            Spinner,
            WishlistItem,
            WishlistModal,
        },
        props: {
            customId: {
                type: String,
                default: 'add_to_wishlist_modal'
            },
            product: {
                type: Number
            }
        },
        data () {
            return {
                slides: [],
                isLoading: false,
                backgrounds: [
                    `url(${require('@/assets/images/backgrounds/wishlist-1.jpg')})`,
                    `url(${require('@/assets/images/backgrounds/wishlist-2.jpg')})`,
                    `url(${require('@/assets/images/backgrounds/wishlist-3.jpg')})`
                ],
                addedSuccess: false,
                selectedWishlist: null
            }
        },
        methods: {        
            closeModal: function () {
                this.$root.$emit('bv::hide::modal', this.customId, '#btnShow')
                this.addedSuccess = false;
            },
            addToWishlist: function(item) {

                this.selectedWishlist = item

                let data = {
                    id: item.id,
                    product_id: this.product.id
                }

                APIClient.saveToWishlist(data)
                    .then( res => {
                        // this.$bvToast.toast(`Added to your wishlist`, {
                        //     title: 'Success',
                        //     autoHideDelay: 5000,
                        //     toastClass: 'success',
                        //     appendToast: true,
                        // })
                        this.addedSuccess = true;
                    })
                    .catch( err => {
                        console.error(err)
                        this.$bvToast.toast(`Something went wrong. Please try again later.`, {
                            title: 'Error',
                            autoHideDelay: 5000,
                            toastClass: 'error',
                            appendToast: true,
                        })
                    })
            },
            openCreateModal: function () {
              this.$bvModal.show('wishlist_modal')
            },
            getWishlists: function() {
                this.isLoading = true;
                APIClient.getWishlists()
                    .then(resp => {
                        this.slides = resp.data.map( wishlist => {
                            if(wishlist.image)  {
                                wishlist.image = `url(${require('@/assets/images/wishlists-covers/' + wishlist.image + '.jpg')})`
                            } else {
                                wishlist.image = this.backgrounds[Math.floor((Math.random() * 3))]
                            }
                            return wishlist
                        })

                        let self = this
                        setTimeout(() => {
                            self.initSlider()
                            if(Array.isArray(self.slider)) {
                                self.slider[0].update()  
                            }else {
                                self.slider.update()    
                            }
                        }, 100)

                    })
                    .catch(e => {
                        console.error(e)
                    })
                    .finally( () => {
                        let self = this
                        setTimeout(() => {
                            this.isLoading = false
                        }, 100)
                    })
            },
            initSlider: function () {

                let slidesCount = this.slides.length >= 3 ? 4 : this.slides.length + 1;

                this.slider = new Swiper(`.add-to-wishlist-modal .swiper-container`, {
                    // slidesPerView: slidesCount,
                    // slidesPerColumn: 1,
                    spaceBetween: 0,
                    loop: true,
                    effect: 'coverflow',
                    grabCursor: true,
                    centeredSlides: true,
                    slidesPerView: 'auto',
                    coverflowEffect: {
                        rotate: 30,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    },
                    pagination: {
                        el: '.add-to-wishlist-modal .swiper-pagination',
                        clickable: true,
                    },
                    navigation: {
                        nextEl: '.add-to-wishlist-modal .swiper-next',
                        prevEl: '.add-to-wishlist-modal .swiper-prev'
                    },
                    // breakpoints: {
                    //     991: {
                    //         slidesPerView: ,
                    //         slidesPerColumn: 1,
                    //     },
                    // },
                })
            },
        },
    }
</script>
