<template>
  <div class="progressbar">
    <div class="">
      <label>{{ label }}</label>
      <span class="value float-right mt-1">{{ parseInt(value) }}<span v-if="showSymbol">%</span></span>
    </div>

    <b-progress
      :value="Number(value)"
      :max="max"
    />
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  components: {},
  props: {
    label: String,
    value: Number,
    showSymbol: Boolean,
    max: Number
  },
  data () {
    return {
      value: 10,
      location: 'Los Angeles, CA'
    }
  }
}
</script>
