<template>
  <b-modal :id="modalId"  title="Save to Beaute Routine" body-class="p-0" modal-class="routine-modal" @show="hideSlider" @shown="getProductDetails" @hidden="closeModal" hide-footer=true>
      <template slot="modal-header-close">
        <i class="icon-close-no-background"></i>
      </template>
      <div class="container">
        <b-row>
          <b-col md="2" cols="12" class="section-thumbs">
            <div class="product-swiper-container gallery-thumbs" :class="{ ready: galleryReady }">
                <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="image in images" v-if="images.length">
                    <b-img
                      :src="image"
                      alt="Product"
                    />
                  </div>
                </div>
            </div>
          </b-col>
          <b-col md="5" cols="12">
            <div class="product-swiper-container gallery-main" :class="{ ready: galleryReady }">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="image in images" v-if="images.length">
                  <div class="image-frame p-3">
                    <div class="image" :style="{ backgroundImage: `url(${image})` }"/>
                  </div>
                </div>                
              </div>
            </div>
          </b-col>
          <b-col md="5" cols="12" class="section-description">
            <div class="product-header container my-2">
              <b-row>
                <b-col sm="12">
                  <h2>
                    {{ product.brand }}
                  </h2>
                  <span class="float-right price mt-2">
                    ${{ product.price || '' }}
                  </span>
                </b-col>
              </b-row>
            </div>
            <div class="product-data container mt-3">
              <b-row class="my-1">
                <b-col sm="12">
                  <label class="mr-2">
                    Product
                  </label>
                  {{ product.name }}                
                </b-col>                
              </b-row>

              <b-row class="my-1">
                <b-col sm="12">
                  <label class="mr-2">
                    Type
                  </label>
                  <span
                    v-for="(item, index) in product.categories"
                    :key="index"
                  >
                    <i v-if="index != 0">, </i>
                    {{ item.name }}
                  </span>               
                </b-col>
              </b-row>

              <b-row class="my-1">
                <b-col sm="12">
                  <label class="mr-2">
                    Concerns
                  </label>
                  <span
                    v-for="(item, index) in product.concerns"
                    :key="index"
                  ><i v-if="index != 0">, </i>{{item.name}}</span>               
                </b-col>               
              </b-row>

              <b-row class="my-3 px-3">
                <b-col md="12" class="divider"></b-col>
              </b-row>

              <b-row class="my-1 routine-options">
                <b-col sm="12" class="mb-2">
                  <label>
                  	Save to my beaute routine <i class="icon-info"><span>i</span></i>
                  </label>        
                </b-col>    

                <b-form-checkbox-group id="checkbox-group-routine" class="w-100" v-model="selected" name="flavour-2">
                 <b-col sm="12" class="option-label">
                 	<i class="icon-haircare-routine"></i> hair care routine
                 	<div class="float-right">
        					 <b-form-checkbox
        					      value="4"
        					    >
        					   </b-form-checkbox>					   
        					 </div>
                 </b-col>          
                 <b-col sm="12" class="option-label">
                 	<i class="icon-makeup-routine"></i> makeup routine
                 	<div class="float-right">
        					    <b-form-checkbox
        					      value="3"
        					    >
        					    </b-form-checkbox>					   
					         </div>
                 </b-col>
                 <b-col sm="12" class="option-label">          
                 	<i class="icon-nightime-routine"></i> night time routine
                 	<div class="float-right">
      					    <b-form-checkbox
      					      value="2"
      					    >
      					    </b-form-checkbox>					   
      					 </div>
                 </b-col>          
                 <b-col sm="12" class="option-label">
                 	<i class="icon-daytime-routine"></i> morning routine    
	                <div class="float-right">
        					    <b-form-checkbox
        					      value="1"
        					    >
        					    </b-form-checkbox>					   
        					 </div>           	
                 </b-col> 
                 </b-form-checkbox-group>         
              </b-row>

               <b-row class="mt-3">
                <b-col sm="12">
                  <a class="btn w-100 buy-now mb-2" target="_blank" @click="save" v-if="!isSavingToRoutine">save</a>         
                  <a class="btn w-100 buy-now mb-2" target="_blank" v-if="isSavingToRoutine">
                    <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
                  </a>
                </b-col>
              </b-row>
               <b-row>
                <b-col sm="12" class="mt-1">
                  <!-- <a class="btn btn-outline-dark w-100" target="_blank">add to wishlist</a>  -->
                      <multiselect 
                        v-model="wishlistSelected" 
                        track-by="id" 
                        label="name" 
                        placeholder="add to wishlist" 
                        :options="wishlists" 
                        :searchable="false" 
                        :allow-empty="false" 
                        @input="saveToWishlist"
                      > 
                        <template 
                          slot="singleLabel" 
                          slot-scope="{ option }"
                        >
                            {{ option.name }}
                        </template>
                    </multiselect>     
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>        
    </div>

    <div class="routine-footer container py-3 mt-4">
      <b-row>
        
        <b-col sm="12" class="prev-next text-center my-auto">
          <a class="px-4 py-1 button" :href="product.link" target="_blank">Buy now</a>
        </b-col>
        
      </b-row>
    </div>
    
  </b-modal>
</template>

<script>
import APIClient from '@/api/requests';
import Multiselect from 'vue-multiselect';
import Swiper from '@/assets/scripts/vendor/swiper.esm.bundle';

require('@/assets/scripts/main.js');

export default {
  name: 'ProductModal',
  components: {
    Multiselect
  },
  props: {
    source: Object,
    modalId: String,    
    section: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      product: {},
      addOptions: [],
      addSelected: {},
      images: [],
      galleryReady: false,
      wishlists: [],
      wishlistSelected: null,
      isSavingToRoutine: false,
      selected: null
    }
  },
  methods: {
    getProductDetails: function() {
      this.getWishlists()

      this.product = this.source;
      APIClient.getProductDetails(this.product.id)
        .then( resp => {

          // Rename keys before assign
          resp.data.renameProperty('product_id', 'id');
          resp.data.renameProperty('product_name', 'name');
          resp.data.renameProperty('product_link', 'link');
          resp.data.renameProperty('product_description', 'description');
          resp.data.renameProperty('product_description_long', 'description_long');
          resp.data.renameProperty('product_image', 'image_url');
          resp.data.renameProperty('product_bodyarea_id', 'bodyarea_id');
          resp.data.renameProperty('price_us', 'price');
          resp.data.brand = resp.data.brand.name;

          this.product = resp.data;

          // Set images
          this.images = [];
          this.images.push(this.product.image_url);          
          for (var i = this.product.images.length - 1; i >= 0; i--) {
            this.images.push(this.product.images[i].image)
          }

          // console.log({ ...this.product })

          this.getProductConcerns();
          this.getProductBenefits();

          // Set up sliders
          let galleryThumbs = new Swiper('.product-swiper-container.gallery-thumbs', {
            direction: 'vertical',
            spaceBetween: 10,
            slidesPerView: 4,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            breakpoints: {
              768: {
                 direction: 'horizontal',
                 slidesPerView: 3
              }
            }
          });

          let gallery = new Swiper('.product-swiper-container.gallery-main', {
            direction: 'horizontal',
            spaceBetween: 10,
            slidesPerView: 1,
            thumbs: {
              swiper: galleryThumbs
            }
          });

          // Fix slider layout when it's ready
          let self = this
          setTimeout(function(){            
            gallery.update();
            galleryThumbs.update();
            self.galleryReady = true
          }, 5)
                    
        })
        .catch( err => {
          console.error(err)
        })
    },
    getWishlists: function () {
      APIClient.getWishlists()
        .then( res => {
          // console.log({...res})
          this.wishlists = res.data
        })
        .catch( err => {
          console.error(err)
        })
    },
    getProductBenefits: function () {
      APIClient.getProductBenefits(this.product.bodyarea_id, this.product.id)
        .then( res => {
          // console.log({...res})
          this.product.benefits = res.data
        })
        .catch( err => {
          console.error(err)
        })
    },
    getProductConcerns: function () {
      APIClient.getProductConcerns(this.product.bodyarea_id, this.product.id)
        .then( res => {
          this.product.concerns = res.data
          this.$forceUpdate()
        })
        .catch( err => {
          console.error(err)
        })
    },
    save: function () {
      if(!this.selected.length) return;

      this.isSavingToRoutine = true;
      APIClient.saveProductRoutine(this.product.id, this.selected.join())
        .then( res => {
          // console.log(res)
        })
        .catch( err => {
          console.error(err)
        })
        .finally( () => {
          this.isSavingToRoutine = false
        })
    },
    saveToWishlist: function () {
      APIClient.saveToWishlist(this.product.id, this.wishlistSelected.id)
        .then( res => {
          this.$bvToast.toast(`Added to your wishlist`, {
            title: 'Success',
            autoHideDelay: 5000,
            toastClass: 'success',
            appendToast: true,
            toaster: 'b-toaster-top-center'
          })
        })
        .catch( err => {
          console.error(err)
        })
    },
    hideSlider: function () {
      // Hide slider before getting all images and configure it
      this.galleryReady = false;

      // Make sure the source has the correct order ()
    },
    closeModal: function () {
      this.$root.$emit('bv::hide::modal', 'modal-1', '#btnShow')
    }
  },
  created () {


  },
  mounted () {
    
  }
}
</script>
