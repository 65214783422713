<template>
    <div class="simple-home">

        <!-- Menu -->
        <home-menu></home-menu>

        <!-- Menu - Mobile -->
        <home-menu-mobile></home-menu-mobile>

        <!-- Header -->
        <home-header></home-header>

        <div class="simple-home__bar" v-if="isMobile()">
            <router-link :to="{ name: 'login' }">
                <div class="simple-home__bar-button">
                    login for beauty curated <em>for you</em>
                    <svg svg-inline="" class="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="7.924" height="12" viewBox="0 0 7.924 12"><path data-name="Trazado 61489" d="M3.626 1.632q0 3.279.005 6.559V12h.692V8.191h-.005V1.614l3.494 2.914.112-.208S5.572 1.783 3.978 0L0 4.317l.133.213z"/></svg>
                </div>
            </router-link>
        </div>

        <beaute-curated></beaute-curated>  

        <!-- Location -->
        <!-- <location is-public="true"></location> -->

        <beautebox-slider></beautebox-slider>
        <skin-tabs></skin-tabs>
        

        <!-- Moodboard -->
        <home-moodboard></home-moodboard>   

        <div id="routines" class="simple-home__sticky-container">

            <div class="simple-home__bar" v-if="!isMobile()">
                <router-link :to="{ name: 'login' }">
                    <div class="simple-home__bar-button">
                        login for beauty curated <em>for you</em>
                        <svg svg-inline="" class="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="7.924" height="12" viewBox="0 0 7.924 12"><path data-name="Trazado 61489" d="M3.626 1.632q0 3.279.005 6.559V12h.692V8.191h-.005V1.614l3.494 2.914.112-.208S5.572 1.783 3.978 0L0 4.317l.133.213z"/></svg>
                    </div>
                </router-link>
            </div>

            <!-- Routines -->
            <home-routines :isPublic="true"></home-routines>

            <!-- Products - Skin -->
            <section-header
                title="Skin"
                background-url="skin"
                id="skin_header"
                :total="skinTotal"
                >
                your skin by your rules—with our help. 
                these are the products that work best for your skin type and concerns, 
                helping you feel bare-skin confident
            </section-header>

            <b-container>
                <products-browser section="skin" />
            </b-container>

            <!-- Products - Makeup -->
            <!-- <section-header
                title="Makeup"
                background-url="makeup"
                id="makeup_header"
                :position="'right'"
                :total="makeupTotal"
                >
                makeup as it should be—curated for you. 
                this is your feed featuring makeup products for your skin tone, type, concerns, 
                budget and everything in between.
            </section-header>

            <b-container>
                <products-browser section="makeup" />
            </b-container> -->

            <!-- Products - Hair -->
            <!-- <section-header
                title="Hair"
                background-url="hair"
                id="hair_header"
                :total="hairTotal"
                >
                your hair texture—celebrated always. these 
                products are specifically for your hair type and concerns. 
                new style or concern? that’s cool, just let us know via your account 
                settings and you’ll see an updated curation of products for your new needs.
            </section-header>

            <b-container>
                <products-browser section="hair" />
            </b-container> -->
        </div>

        <!-- Footer -->
        <app-footer />
    </div>
</template>

<script>
    import HomeHeader from '@/components/common/HomeHeader.vue';
    import HomeMenu from '@/components/common/HomeMenu.vue';
    import HomeMenuMobile from '@/components/common/HomeMenuMobile.vue';
    import HomeMoodboard from '@/components/common/HomeMoodboard.vue';
    import Location from '@/components/dashboard/home/Location.vue';
    import HomeRoutines from '@/components/common/HomeRoutines.vue';
    import SkinTabs from '@/components/common/SkinTabs.vue';
    import BeauteCurated from '@/components/common/BeauteCurated.vue';
    import SectionHeader from '@/ui/SectionHeader.vue';
    import ProductsBrowser from '@/components/dashboard/home/ProductsBrowser.vue';
    import AppFooter from '@/components/AppFooter.vue'
    import BeauteboxSlider from '@/components/common/BeauteboxSlider.vue';
    import { helperMixin } from '@/mixins/helperMixin.js'

    export default {
      name: 'Home',
      components: {
        HomeHeader,
        HomeMenu,
        HomeMenuMobile,
        HomeMoodboard,
        Location,
        HomeRoutines,
        ProductsBrowser,
        SectionHeader,
        AppFooter,
        SkinTabs,
        BeauteboxSlider,
        BeauteCurated,
    },
    mixins: [helperMixin],
}
</script>
