<template>
  <div class="skin container">
  	<b-row class="mb-4 mt-lg-0 mt-5">
  		<b-col cols="12">
        <h4 class="mb-3">
          how would you describe your skin?
        </h4>
	  	</b-col>
      
	  	<b-col 
        cols="12" 
        class="container"
      >
	  		<b-row 
          align-h="between" 
          class="mb-3" 
          v-for="option in skinOptions"
        >
	  			<b-col class="radio-label">
	  				{{ option.name }}
	  			</b-col>
	  			<b-col>
	  				<input 
              type="radio" 
              @change="updateValues" 
              v-model="skinValues.skinType" 
              :value="option" 
              class="uiswitch float-right" 
              @mouseover="changeImage(option)" 
              @mouseout="showSelectedValues"
            />
	  			</b-col>
	  		</b-row>	  		
	  	</b-col>
  	</b-row>

  	<b-row class="mb-4 pt-4">
  		<b-col cols="12">
        <h4 class="mb-3">
          i’m looking for products that help with:
        </h4>
      </b-col>

      <b-col 
        cols="12" 
        class="container grid-checkbox three"
      >
        <div 
          class="btn-checkbox" 
          v-for="option in skinProducts"
        >
		  		<input 
            :id="option.id" 
            :value="option" 
            v-model="skinValues.skinProduct" 
            type="checkbox" 
            @change="updateValues" 
          />
		  		<label 
            class="btn btn-outline-gray w-100" 
            :for="option.id"
          >
            {{ option.name }}
          </label>
	  		</div>	  		
	  	</b-col>
  	</b-row>

  	<b-row class="mb-4 pt-4">
  		<b-col cols="12">
	  		<h4 class="mb-3">
          my budget for my skincare routine is
        </h4>
	  	</b-col>

	  	<b-col 
        cols="12" class="container">
	  		 <vue-slider
                v-model="skinValues.budget"
                :enable-cross="false"
                :max="500"
                @drag-end="updateValues"  
                :interval="10"
                :marks="false"     
                :tooltip="'always'"         
                class="mt-3"
              >
                <template v-slot:tooltip="{ value }">
                  <div class="custom-tooltip">$/£ {{ value }}</div>
                </template>
              </vue-slider>
	  	</b-col>
  	</b-row>

  	<b-row class="mb-4 pt-4">
  		<b-col cols="12">
	  		<h4 class="mb-3">my skin resolutions:</h4>
          <div class="skin-hint" :class="{ 'show': showHint }">
            <div class="skin-hint__inner">
              <p>
                your skin regenerates every 26-28 days meaning that the cells that form your epidermis (the outer layer of your skin) are renewed and replaced with new ones. the deeper layers of your skin (the dermis and the subcutaneous tissue) do not regenerate and this is why scars and deep blemishes are harder to treat. 
              </p>

              <p>
                as you get older, your skin regeneration cycle slows down and that’s why setting resolutions are a great way to maintain healthy skin. resetting a skincare routine too frequently can result in sensitized, irritated skin and can disrupt your skin’s protective barrier — so keeping to a routine with the same products for at least 4–8 weeks will help you decided if your products are actually working. 
              </p>

              <p>
                set skin resolutions for 3 and/or 6 months to help you 1. stick to a routine 2. so we recommend products that help you meet your goals and 3. so you can monitor if the products you’re spending your hard-earned cash on are working.
              </p>
            </div>
            <div class="skin-hint__more" @click="showHint = !showHint">
              Read 
                <template v-if="!showHint">
                  more <i class="icon-caret-down"></i>
                </template>
                <template v-if="showHint">
                  less <i class="icon-caret-down"></i>
                </template>
            </div>
          </div>
  	  		<div class="light-text mb-4 mt-3">
            select one or more resolution
          </div>
	  	</b-col>

	  	<b-col 
        cols="12" 
        class="drag-section" 
      >
        <div class="container">
	  		  <b-row>
            <b-col cols="12">
              <div 
                class="btn-checkbox" 
                v-for="option in skinResolutions"
                >
                <input 
                  :id="'res_' + option.id" 
                  :value="option" 
                  v-model="sixMonthsResolutions" 
                  type="checkbox" 

                />
                <label 
                  class="btn btn-outline-gray w-100" 
                  :for="'res_' + option.id"
                  >
                  {{ option.name }}
                </label>
              </div>
            </b-col>

	  		 	 	<!-- <b-col 
	  		 	 		cols="6"
	  		 	 	>
							<draggable
				        class="dragdrop"
				        :list="skinResolutions"
				        :group="{ name: 'description', put: false }"
				        :clone="cloneItem"
				      >
				        <div 
                  class="btn btn-outline-gray w-100 mb-4 draggable" 
                  v-for="element in skinResolutions" 
                  :key="skinResolutions.id"
                >
				          {{ element.name }}
				        </div>
				    	</draggable>
	  		 		</b-col>

	  		 		<b-col 
	  		 			cols="6"
	  		 			class="pr-0"
	  		 		>
		  		 		<div class="dragged-section mb-4 px-4 py-2 text-center">
		  		 			<h5 class="mt-2 mb-3">
		  		 				3 months
		  		 			</h5>
	              <div class="position-relative">
	  	  		 			<draggable 
	  	  		 				@change="onChangeSixMonths" 
	  	  		 				class="dragdrop" 
	  	  		 				element="span" 
	  	  		 				v-model="sixMonthsResolutions" 
	  	  		 				v-bind="dragOptionsRight" 
	  	  		 				:move="onMoveRight"
	  	  		 			>
	  					      <transition-group 
	  					        name="no" 
	  					        class="list-group" 
	  					        tag="ul"
	  					      >
	  					        <div 
	  					         	class="btn btn-gray w-100 mb-4 dragged" 
	  					         	v-for="(element, index) in sixMonthsResolutions" 
	  					         	:key="element.id"
	  					         >
	  					          <span>
	  					          	{{element.name}}
	  					          </span>
	  					          <i 
	  					          	class="icon-close-no-background" 
	  					          	@click="removeItem(sixMonthsResolutions, index)"
	  					          ><span class="path1"></span><span class="path2"></span>
	  					        	</i>
	  					        </div>
	  					      </transition-group>
	  					    </draggable>
	  					    <div 
	  					    	class="btn btn-outline-gray w-100 mb-4 empty-slot" 
	  					    	v-for="element in 1"
	  					    >
	              	</div>
						 		</div>
		  		 		</div>

		  		 		<div class="dragged-section px-4 py-2 text-center">
		  		 			<h5 class="mt-2 mb-3">
		  		 				6 months
		  		 			</h5>
	              <div class="position-relative">
	  	  		 			<draggable 
	  	  		 				@change="onChangeTwelveMonths" 
	  	  		 				class="dragdrop" 
	  	  		 				element="span" 
	  	  		 				v-model="twelveMonthsResolutions" 
	  	  		 				v-bind="dragOptionsRight" 
	  	  		 				:move="onMoveRight"
	  	  		 			>
	  					      <transition-group 
	  					      	name="no" 
	  					      	class="list-group" 
	  					      	tag="ul"
	  					      >
	  					        <div 
	  					        	class="btn btn-gray w-100 mb-4 dragged" 
	  					        	v-for="(element, index) in twelveMonthsResolutions" 
	  					        	:key="element.id"
	  					        >
	  					          <span>
	  					          	{{element.name}}
	  					          </span>
	  					          <i 
	  					          	class="icon-close-no-background" 
	  					          	@click="removeItem(twelveMonthsResolutions, index)">
	  					          	<span class="path1"></span><span class="path2"></span>
	  					          </i>
	  					        </div>
	  					      </transition-group>
	  					    </draggable>
	  					  	<div 
	  					  		class="btn btn-outline-gray w-100 mb-4 empty-slot" 
	  					  		v-for="element in 3"
	  					  	>
	              	</div>
						 		</div>
		  		 		</div>
	  		 		</b-col> -->
	  		 	</b-row>
        </div>
	  	</b-col>

      <!-- <b-col 
      	md="12" 
      	class="skin-checkboxes" 
      	v-if="isMobile()"
      >
        <h4 class="mb-3">
        		For 6 months
        </h4>
        <div 
        	class="mb-2 w-100" 
        	v-for="element in skinResolutions" 
        	:key="skinResolutions.id"
        >
          <input 
          	type="checkbox" 
          	:value="element" 
          	v-model="sixMonthsResolutions" 
          	:id="`six_${element.id}`"
          >
          	<label 
          		class="p-2 w-100" 
          		:for="`six_${element.id}`"
              :class="{ 'disabled': helper.containsObject(element, twelveMonthsResolutions)}"
          	>
          		{{ element.name }}
          	</label>
        </div>

        <h4 class="my-4">
        	For 12 months
        </h4>
        <div 
        	class="mb-2 w-100" 
        	v-for="element in skinResolutions" 
        	:key="skinResolutions.id"
        >
           <input 
           	type="checkbox" 
           	:value="element" 
           	v-model="twelveMonthsResolutions" 
           	:id="`twelve_${element.id}`"
           >
           	<label 
           		class="p-2 w-100"
              :class="{ 'disabled': helper.containsObject(element, sixMonthsResolutions)}"
           		:for="`twelve_${element.id}`"
           	>
           		{{ element.name }}
           	</label>
        </div>  
      </b-col> -->

  	</b-row>

  	<b-row class="navigation">  
  		<b-col cols="12">		
	  		<button 
	  			class="btn btn-dark-blue px-5 float-right my-4" 
	  			@click="next"
	  		>
	  			<!-- NEXT -->
          get my recommendations
	  		</button>
	  	</b-col>
  	</b-row>

  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import Draggable from 'vuedraggable'
import { helperMixin } from '@/mixins/helperMixin.js'
import EventBus from '@/event-bus'

// let helper = require('@/assets/scripts/main.js');

export default {
  name: 'Skin',
  props: {
  	options: Object,
  	default: null
  },
  data () {
    return {
     	isLoading: false,
     	skinValues: {
     		skinType: null,
     		skinProduct: [],
     		budget: 500,
        image: null,
        sixMonthsResolutions: null,
        twelveMonthsResolution: null
     	},
     	isDragging: false,
	    sixMonthsResolutions: [],
	    twelveMonthsResolutions: [],
     	productsOptions: [],
      alert: null,
      helper: require('@/assets/scripts/main.js'),
      showHint: false
    }
  },
  mixins: [helperMixin],
  components: {
  	VueSlider,
  	Draggable,
  },
  mounted () {    
  	this.isLoading = true   
  },
  computed: {
    dragOptionsLeft() {
      return {
        animation: 0,
        group: "description",
        ghostClass: "ghost",
        pull: 'clone'        
      }
    },
    dragOptionsRight() {
  	  return {		 
  	  	animation: 0,
          group: "description",
          ghostClass: "ghost",

  	  }
  	},
  	skinOptions() {
  		return (this.options) ? this.options.how_would_describe : [];
  	},
  	skinProducts() {
  		return (this.options) ? this.options.help_with : [];
  	},
  	skinResolutions() {
  		return (this.options) ? this.options.resolutions : [];
  	},
    image() {
      if(!this.skinValues.skinType) return;

      let image;
      switch(this.skinValues.skinType.id) {
        case 1:
          image = `skin-dry.jpg`;
          break;
        case 2:
          image = `skin-oily.jpg`;
          break;
        case 3:
          image = `skin-combination.jpg`;
          break;
        case 4:
          image = `skin-sensitive.jpg`;
          break;
        case 5:
          image = `skin-normal.jpg`;
          break;
        default:
          image = `skin-normal.jpg`;
        break;
      }
      return image;
      
    },
  },
  methods: {
    showSelectedValues: function() {
      EventBus.$emit('showSelectedValue', true);
    },
    showHoverValues: function() {
      EventBus.$emit('showSelectedValue', false);
    },
  	updateValues: function () {
      this.skinValues.image = this.image
  		this.$emit('input', this.skinValues)
  	},
  	onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    removeItem(array, index) {
      // console.log(array[index])
      this.skinResolutions.push(array[index]);
    	array.splice(index, 1)
    },
    cloneItem(item) {   
      return item;
    },
    onChangeSixMonths(event) {
    	if(event.added) {	
			 this.sixMonthsResolutions = this.helper.removeDuplicates(this.sixMonthsResolutions, 'id')
    	}

      // console.log(this.sixMonthsResolutions)
      if(this.sixMonthsResolutions.length > 3) {
        this.removeItem(this.sixMonthsResolutions, this.sixMonthsResolutions.length - 1)
        // this.sixMonthsResolutions.splice(this.sixMonthsResolutions.length - 1, 1)
      }
    },
    onChangeTwelveMonths(event) {
    	if(event.added && this.twelveMonthsResolutions.length < 3) {	
			   this.twelveMonthsResolutions = this.helper.removeDuplicates(this.twelveMonthsResolutions, 'id')
    	}
    },
    clear () {
      this.skinValues = {
        skinType: null,
        skinProduct: [],
        budget: 500,
        image: null
      }
      this.alert = null
      this.twelveMonthsResolutions = []
      this.sixMonthsResolutions = []
    },
    next() {
      if (this.skinValues.skinType == null || 
        this.skinValues.skinProduct.length == 0 ) {
        	this.$bvToast.toast('Please, fill all fields.', {
            title: 'Error',
            autoHideDelay: 5000,
            toastClass: 'error',
            appendToast: true,
          })
          return;
      }

      if(this.twelveMonthsResolutions.length == 0 && this.sixMonthsResolutions.length == 0) {
        this.$bvToast.toast('Please, select at least one skin resolution.', {
          title: 'Error',
          autoHideDelay: 5000,
          toastClass: 'error',
          appendToast: true,
        })
        return;
      }

      this.skinValues.sixMonthsResolutions = this.sixMonthsResolutions
      this.skinValues.twelveMonthsResolutions = this.twelveMonthsResolutions
      this.$emit('onNext')
    },
    changeImage(option) {
      let image, description, title;

      switch(parseInt(option.id)) {
        case 1:
          image = `skin-dry.jpg`;
          description = `if your skin is dry, it often feels tight and rough—it can also look dull. dry skin produces less sebum than normal skin types meaning that it doesn’t have enough of the lipids needed to retain moisture. this weakens your skin’s protective barrier against external and environmental aggressors, resulting in skin that is prone to redness, irritation, and infection.`;
          title = `Dry`;
          break;
        case 2:
          image = `skin-oily.jpg`;
          description = `if your skin is oily, it produces excess oil and is prone to clogged pores. this can be triggered by hormonal imbalances (menstruation, pregnancy etc) or external factors such as pollution. oily skin is shiny in appearance (particularly the t-zone) and is prone to acne, breakouts, and blemishes.`;
          title = `Oily`;
          break;
        case 3:
          image = `skin-combination.jpg`;
          description = `if you have combination skin, your skin is oily in parts such as your forehead and nose (your t-zone), and normal to dry in other areas. combination skin can also change with the seasons and become oilier in the summer months and drier in the winter. `;
          title = `Combination`;
          break;
        case 4:
          image = `skin-sensitive.jpg`;
          description = `if you have sensitive skin, your skin is prone to inflammation and irritation. it’s important to note that there is a difference between sensitive and sensitized skin—sensitized skin is skin that is temporarily sensitive due to incorrect products or environmental aggressors.`;
          title = `Sensitive`;
          break;
        case 5:
          image = `skin-normal.jpg`;
          description = `if you have normal skin, your skin is well balanced and is neither oily or dry. normal skin produces enough sebum to hydrate your skin and has good blood circulation. `;
          title = `Normal`;
          break;
        default:
          image = `skin-normal.jpg`;
          description = ``
        break;
      }

      this.$emit('onHover', image, description, title)
      this.showHoverValues()
    }
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },

  }
}
</script>
