<template>
  <b-modal 
    :id="customId" 
    title="contact us" 
    body-class="p-0" 
    modal-class="contact-modal" 
    @hidden="closeModal" 
    @shown="checkFormToOpen"
    hide-footer=true
    ref="contactModal"
    centered
    >

    <div class="container p-0">
      <!-- List of options -->
      <ul 
        class="list" 
        v-if="!showForm"
        >
        <li 
          class="py-3" 
          @click="toggleForm('Support Issue')"
          >
          <label class="px-5">
            have a support issue?
          </label>
        </li>
        <li 
          class="py-3" 
          @click="toggleForm('Leave Feedback')"
          >
          <label class="px-5">
            leave a feedback
          </label>
        </li>
        <li 
          class="py-3" 
          @click="toggleForm('Product Request')"
          >
          <label class="px-5">
            request a product
          </label>
        </li>
        <li 
          class="py-3" 
          @click="toggleForm('Feature Idea')"
          >
          <label class="px-5">
            have a new feature idea?
          </label>
        </li>
      </ul>

      <!-- Form -->
      <form 
        class="p-3 pb-4 form" 
        @submit.prevent="submit" 
        v-else
        >
        <b-row class="mb-3">
          <b-col 
            cols="auto"
            class="pr-0"
            >
            <label>to:</label>
          </b-col>
          <b-col>
            <span class="address">
              beautéboard team
            </span>
          </b-col>
        </b-row>
        <b-row class="mb-3" align-v="center">
          <b-col 
            cols="auto"
            class="pr-0"
            >
            <label>subject: </label> <span>{{type}}</span>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <textarea 
              required 
              v-model="content" 
              class="p-2" 
              rows="10"
              :placeholder="placeholder"
              />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <button 
              class="btn btn-white w-100 mt-3" 
              :class="{'disabled': isLoading}" 
              type="submit">
              <template v-if="!isLoading">
                SEND
              </template>
              <template v-else>
                SENDING...
              </template>
            </button>
          </b-col>
        </b-row>
      </form>

    </div>
  </b-modal>
</template>

<script>

import APIClient from '@/api/requests';
import Spinner from '@/ui/Spinner.vue'
import simplebar from 'simplebar-vue';
import NotificationModal from '@/ui/NotificationModal.vue'

require('@/assets/scripts/main.js');

export default {
  name: 'NotificationsModal',
  components: {
    Spinner,
    simplebar,
  },
  props: {
    customId: {
      type: String,
      default: 'contact_modal'
    },
    formToShow: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      notification: [],
      moment: require('moment'),
      isLoading: false,
      hasNotifications: true,
      showForm: false,
      type: '',
      content: '',
      message: null,
      placeholder: ''
    }
  },
  methods: {        
    closeModal: function () {
      this.showForm = false
      this.message = null
      this.subject = ''
      this.type = ''
      this.content = ''
      this.$emit('closed')
      this.$root.$emit('bv::hide::modal', this.customId, '#btnShow')
    },    
    toggleForm: function (type = null) {
      this.type = type
      if( type == 'Product Request' ){
        this.placeholder = 'Please let us know what product(s) you would like to see in beautéboard...'
      } else {
        this.placeholder = ''
      }
      this.showForm = true;
    },
    submit () {
      if(this.isLoading) return;

      this.isLoading = true
      let data = {
        user_id: this.user.id,
        type: this.type,
        subject: this.type,
        content: this.content
      }
      
      APIClient.submitContactForm(data)
        .then( res => {
          this.message = res.data
          this.content = ''
          this.subject = ''
          this.showForm = false
          this.$bvToast.toast(res.data, {
            title: 'Success',
            autoHideDelay: 2000,
            toastClass: 'success',
            appendToast: true,
          })

          this.closeModal();
        })
        .catch( err => {
          console.error(err)
          this.message = err.data
          this.$bvToast.toast(`Sorry, something went wrong`, {
            title: 'Error',
            autoHideDelay: 5000,
            toastClass: 'error',
            appendToast: true,
          })
        })
        .finally( () => {
          this.isLoading = false
        })
    },
    checkFormToOpen: function() {
      if(this.formToShow) this.toggleForm(this.formToShow)
    }
  },
  computed: {
    user: function () {
      return this.$store.getters.profile
    }
  }
}
</script>
